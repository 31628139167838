export const ActionTypes = {

    USER_SIGNIN_REQUEST: 'USER_SIGNIN_REQUEST',
    USER_SIGNIN_SUCCESS: 'USER_SIGNIN_SUCCESS',
    USER_SIGNIN_FAIL: 'USER_SIGNIN_FAIL',
    USER_SIGNOUT: 'USER_SIGNOUT',
    SELECTED_PRODUCT: 'SELECTED_PRODUCT',
    REMOVE_SELECTED_PRODUCT: 'REMOVE_SELECTED_PRODUCT',
    SET_CATALOG_LIST: 'SET_CATALOG_LIST',
    REMOVE_CATALOG_LIST: 'REMOVE_CATALOG_LIST',
    SET_MENU_MOBILE: 'SET_MENU_MOBILE',
    SET_SEARCH_PRODUCTS: 'SET_SEARCH_PRODUCTS',
    REMOVE_SEARCH_PRODUCTS: 'REMOVE_SEARCH_PRODUCTS',
    PRODUCT_ADD_COMPARE: 'PRODUCT_ADD_COMPARE',
    PRODUCT_REMOVE_COMPARE: 'PRODUCT_REMOVE_COMPARE',
    PRODUCT_REMOVE_COMPARE_ALL: 'PRODUCT_REMOVE_COMPARE_ALL',

    GET_USER_NEWS: 'GET_USER_NEWS',
    GET_USER_BANNER: 'GET_USER_BANNER',
    GET_USER_POPUP: 'GET_USER_POPUP',

}