// This is used to determine if a user is authenticated and
// if they are allowed to visit the page they navigated to.

// If they are: they proceed to the page
// If not: they are redirected to the login page.
import React from 'react'
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom'

const LoginRoute = ({ component: Component, sidebar: LeftSidebar, ...rest }) => {

  const userSignin = useSelector((state) => state.userInfo);
  const { userInfo } = userSignin;

  // Add your own authentication on the below line.
  const isLoggedIn = userInfo;
    // console.log(isLoggedIn, 'isLoggedIn')
  return (
    <Route
      {...rest}
      render={props =>
        isLoggedIn ? (
          <Redirect to={{ pathname: '/' }} />
        ) : (
            <>
              <LeftSidebar {...props} />
              <Component {...props} />
            </>
        )
      }
    />
  )
}

export default LoginRoute;