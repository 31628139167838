import React, { useEffect, useState, createRef, useRef } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import axios from 'axios';
import { axiosinstance } from '../configAxios';
import { useSelector, useDispatch } from 'react-redux';
import MaterialTable from "material-table";
import { Paper } from '@material-ui/core';
import useMediaQuery from '@mui/material/useMediaQuery';
import { toast } from 'react-toastify';
import MessageInfoBox from '../components/other/MessageInfoBox';

import { getSaleStatistics, globalStatistic } from '../redux/actions/otherActions'
import { setCartClone } from '../redux/actions/cartActions'
import { getPageSeo } from '../redux/actions/pageActions';

import CustomDatePicker from '../components/other/CustomDatePicker';
import PatchedPagination from '../components/other/PatchedPagination';
import ModalOrderStatus from '../components/modal/ModalOrderStatus';
import ModalCityCargo from '../components/modal/ModalCityCargo';
import ModalOrderDocUpload from '../components/modal/ModalOrderDocUpload';
import CurrencyFormat from '../components/other/CurrencyFormat';
import PageHelmet from '../components/helmet/PageHelmet';
import CustomTableOrderStatus from '../components/CustomTableOrderStatus';

import styles from '../css/OrderStatus.module.css'
import { useTranslation } from 'react-i18next';
import { createLogger } from 'redux-logger';
import { useLocation } from 'react-router-dom';


// import OrderStatusItem from '../components/other/OrderStatusItem';

const OrderStatusPageTest = () => {
    const { t } = useTranslation('orderStatusPage');
    const inputRef = useRef(null);

    const dispatch = useDispatch()
    const userInfoData = useSelector((state) => state.userInfo.userInfo);
    const getSaleStatisticsItems = useSelector((state) => state.getSaleStatisticsItems);
    const getCurrencyItems = useSelector((state) => state.getCurrencyItems);
    const getB2bSettings = useSelector((state) => state.getB2bSettings);
    const deliveryTypeSettings = getB2bSettings.items ? getB2bSettings.items.deliveryType : [];
    const location = useLocation();
    const [orderList, setOrderList] = useState([]);
    const [show, setShow] = useState(false);
    const [showCityModal, setShowCityModal] = useState(false);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [selectedItemUpload, setSelectedItemUpload] = useState({});
    const [orderDetailList, setOrderDetailList] = useState([]);
    const [muiTableKey, setMuiTableKey] = useState(0);
    const [pageSeoData, setPageSeoData] = useState({});
    const [cityCargoData, setCityCargoData] = useState({});
    // const [tableState, setTableState] = useState({});
    const [originalData, setOriginalData] = useState({});
    const [isSorted, setIsSorted] = useState(false);
    const [filters, setFilters] = useState({});
    const [setText, setSetText] = useState('');
    const [filterValues, setFilterValues] = useState({
        acKey: '',
        acDocKey: '',
        adDate: '',
        anValue: '',
        anVAT: '',
        anForPay: '',
        acCurrency: '',
        acDelivery: '',
        acOutsourceShipment: '',
        acStatus: ''
    });

    const tableRef = useRef(null);

    var attributes = {};

    useEffect(() => {

        dispatch(getPageSeo(302)).then(res => {
            if (res) {
                setPageSeoData(res);
            }
        });
        attributes['anCompanyKey'] = userInfoData ? userInfoData.company.anCompanyKey : 0;
        const configRes = axiosinstance(attributes, '8224', 'postSaleHistory');
        axios(configRes)
            .then(function (response) {
                // console.log("response");
                // Obrisati kada se proširi baza podataka (kolona anCargoId)
                // const modifiedOrderList = response.data.response.saleHistoryList.map(order => {
                //     return {
                //         ...order,
                //         acOutsourceShipment: '45359598,45359597'
                //     };
                // });

                // setOrderList(modifiedOrderList);
                setOrderList(response.data.response.saleHistoryList);
            })
            .catch(function (error) {
                console.log(error);
            });

        dispatch(getSaleStatistics());
        dispatch(globalStatistic(302, 'PAGE'));
    }, []);
    // console.log(orderList, "ordList");
    // console.log(deliveryTypeSettings, "deliveryTypeSettings");
    const setOrderDetailFunction = (itemsListOrder) => {
        console.log(itemsListOrder, "itemsListOrder");
        setOrderDetailList(itemsListOrder);
        setShow(true);
    };

    const handleClose = () => {
        setShow(false);
    };

    const showUploadModalFunction = (item) => {
        setShowUploadModal(true);
        setSelectedItemUpload(item)
    };
    const notEditableAttachment = () => {
        toast(<MessageInfoBox message="notEditableDeliveryAttachment" type="0" />, {
            className: 'red-background',
        });
    };
    const handleCloseUploadModal = () => {
        setShowUploadModal(false);
    };
    const handleCloseCityModal = () => {
        setShowCityModal(false);
        setCityCargoData({});
    };

    const setFunctionAgainOrderAction = (item, val) => {
        dispatch(setCartClone(item, getCurrencyItems, val))
    };

    const handleCargoId = (id) => {

        var config = {
            method: 'get',
            url: 'https://api.cityexpress.rs/shipmentbycargoid?apikey=4c7f020f39af445f8f741c9121efe6e5&cargoid=' + id,
            headers: {
                'Content-Type': 'application/json'
            },
        };
        setShowCityModal(true);
        axios(config)
            .then(function (response) {
                // console.log(response.data.data, 'response.data.data');
                if (response && response.data && response.data.data) {
                    const cityCargoDataTmp = {
                        createDate: response.data.data.CreateDate,
                        shipmentId: response.data.data.ShipmentID,
                        receiverDepo: response.data.data.ReceiverDepo.Name ? response.data.data.ReceiverDepo.Name : '',
                        sender: response.data.data.Sender.Name ? response.data.data.Sender.Name : '',
                        address: response.data.data.Cosignee.Name + ', ' + response.data.data.Cosignee.Street + ', ' + response.data.data.Cosignee.Place,
                        sendDate: response.data.data.SendDate,
                        trackingDetails: response.data.data.Events,
                        returnDocuments: response.data.data.Collies ? (response.data.data.Collies.length > 0 ? (response.data.data.Collies[0].Events ? response.data.data.Collies[0].Events.reverse() : []) : []) : [],
                        refNo: response.data.data.Ref1,
                        packageWeight: response.data.data.VerifiedWeight,
                        status: response.data.data.Collies ? (response.data.data.Collies.length > 0 ? (response.data.data.Collies[0].Events ? response.data.data.Collies[0].Events[0].StatusDescription : '/') : '/') : '/',
                        deliveryDate: response.data.data.Collies ? (response.data.data.Collies.length > 0 ? (response.data.data.Collies[0].Events ? response.data.data.Collies[0].Events[0].TimeOfScan : '') : '') : '',
                    }

                    setCityCargoData(cityCargoDataTmp);
                }

            })
            .catch(function (error) {
                console.log(error);
            });
    };
    // const [sorting, setSorting] = useState({});

    const [sortOrder, setSortOrder] = useState(() => {
        const savedSortOrder = localStorage.getItem('sortOrder');
        return savedSortOrder ? JSON.parse(savedSortOrder) : {};
    });

    // const handleSortChange = (orderBy, orderDirection) => {
    //     setSortOrder(prevSortOrder => {
    //         const newSortOrder = {
    //             ...prevSortOrder,
    //             [orderBy]: orderDirection
    //         };
    //         localStorage.setItem('sortOrder', JSON.stringify(newSortOrder));
    //         return newSortOrder;
    //     });
    // };
    const handleSortChange = (orderBy, orderDirection) => {
        const newSortOrder = {
            [orderBy]: orderDirection
        };

        localStorage.setItem('sortOrder', JSON.stringify(newSortOrder));
        setSortOrder(newSortOrder);
    };
    // useEffect(() => {
    //     if (tableRef.current && sortOrder) {
    //         const columns = tableRef.current.state.columns.map(column => ({
    //             ...column,
    //             sortDirection: sortOrder[column.field] || ''
    //         }));

    //         tableRef.current.setState({
    //             ...tableRef.current.state,
    //             columns: columns,
    //         });
    //     }
    // }, [sortOrder, showCityModal, cityCargoData, showUploadModal, show]);




    // useEffect(() => {
    //     // console.log(filters, "filters");
    //     if (filters) {
    //         tableRef.current.setState((prevState) => ({
    //             ...prevState,
    //             filters: filters
    //         }));
    //     }
    // }, [filters]);
    // }, [tableState]);
    const queries = useMediaQuery('(max-width: 450px)')
    const queries1 = useMediaQuery('(max-width: 1366px)')
    const queries2 = useMediaQuery('(min-width: 450px) and (max-width: 1100px)')

    const gatherStatisticCloneCart = () => {
        dispatch(globalStatistic(0, 'CLONE_CART'));
    }
    // console.log(setText, "settecxt");
    // const setSearchText = (text) => {
    //     // console.log("proba", text);
    //     if (tableRef.current) {
    //         const columns = tableRef.current.state.columns;
    //         // console.log(columns, "coll");

    //         const updatedFilters = columns.map(column => {
    //             const currentFilter = text[column.field];
    //             // console.log(text[column.value], "text[column.field] i current filter");

    //             if (currentFilter !== undefined) {
    //                 // console.log('udje u nije undefined');
    //                 // setSetText(text[column.field]);
    //                 // return {
    //                 //     ...column,
    //                 //     filterValue: currentFilter
    //                 // };
    //                 setFilterValues(prevState => ({
    //                     ...prevState,
    //                     [column.field]: currentFilter
    //                 }));

    //             }
    //             return column;
    //         });
    //         // console.log(updatedFilters, "updatedFilters");
    //         tableRef.current.setState({
    //             ...tableRef.current.state,
    //             filters: updatedFilters
    //         });
    //     }
    // };

    // const clearAllFilters = () => {
    //     // Očisti filtere
    //     const clearedFilters = {
    //         acKey: '',
    //         acDocKey: '',
    //         adDate: '',
    //         anValue: '',
    //         anVAT: '',
    //         anForPay: '',
    //         anForPay: '',
    //         acCurrency: '',
    //         acDelivery: '',
    //         acOutsourceShipment: '',
    //         acStatus: ''
    //     };
    //     setFilterValues(clearedFilters);
    //     localStorage.setItem('sortOrder', '');
    //     if (tableRef.current) {
    //         // Očisti filtere u tabeli
    //         const columns = tableRef.current.state.columns;
    //         const updatedFilters = columns.map(column => ({
    //             ...column,
    //             filterValue: ''
    //         }));

    //         const clearedSorting = columns.map(column => ({
    //             ...column,
    //             sorting: ''
    //         }));

    //         tableRef.current.setState({
    //             ...tableRef.current.state,
    //             filters: updatedFilters,
    //             sorting: clearedSorting
    //         });
    //     }
    // };


    return (
        <>
            {Object.keys(pageSeoData).length !== 0 &&
                <PageHelmet
                    title={pageSeoData ? `${pageSeoData.acSeoTitle}` : "Ewe Comp"}
                    description={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    keywords={pageSeoData ? `${pageSeoData.acSeoKeywords}` : ""}
                    ogDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    ogTitle={pageSeoData ? `${pageSeoData.acSeoTitle}` : ""}
                    ogUrl={pageSeoData ? `https://ewe.rs/${pageSeoData.acSeoUrl}` : ""}
                    ogImg="https://www.ewe.rs/media/ewe-og-img.jpg"
                    twitterDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                />
            }
            <div className={`container-content ${styles["stats-order-content"]} container-catalog-content`}>
                <div className="container-fluid container-fluid-catalog-content">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className={styles["stats-order-content-holder"]}>
                                <h1>{pageSeoData.acSeoHeader ? pageSeoData.acSeoHeader : 'Status porudžbina'}</h1>
                                {pageSeoData.acSeoShortPageDescription && <p>{pageSeoData.acSeoShortPageDescription}</p>}
                                {Object.keys(getSaleStatisticsItems.items).length != 0 ?
                                    getSaleStatisticsItems.loading == false ? (
                                        <div className="row">
                                            <div className={queries1 ? "col-sm-12" : "col-sm-4"}>
                                                <h3 className="status-order-box-title">{t('trendMeseca')}</h3>
                                                <div className="status-holder-box-trend">
                                                    <div className="status-box-trend">
                                                        <div className="status-box-trend-value">{getSaleStatisticsItems.items.UKUPANTREND[0].anSaleStatistcsValue}%</div>
                                                        <div className="status-box-trend-desc">{t('uOdnosuNaTekuciMesec')}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={queries1 ? "col-sm-6" : "col-sm-4"}>
                                                <h3 className={`status-order-box-title ${queries1 && "mt-4"}`}>{t('kategorijeKojePadaju')}</h3>
                                                <div className="status-holder-box">
                                                    <div className="status-box-diagram">
                                                        <div className="status-box-diagram-item"></div>
                                                        <div className="status-box-diagram-item"></div>
                                                        <div className="status-box-diagram-item"></div>
                                                        <div className="status-box-diagram-item"></div>
                                                        <div className="status-box-diagram-item"></div>
                                                        <div className="status-diagram-lines">
                                                            {getSaleStatisticsItems.items.KATEGORIJA.map(item => {
                                                                return <OverlayTrigger placement="top"
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-top`}>
                                                                            {item.acSaleStatisticsValue} ({item.anSaleStatistcsValue}%)
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <div className="status-diagram-line" style={{ width: Math.abs(item.anSaleStatistcsValue) + '%' }}><span style={item.anSaleStatistcsValue > -40 ? { color: '#fe285c' } : { color: '#fff' }}>{item.acSaleStatisticsValue}</span></div>
                                                                </OverlayTrigger>
                                                            })}
                                                        </div>
                                                    </div>
                                                    <div className="status-box-diagram-value">
                                                        <div className="status-box-diagram-item-value">0%</div>
                                                        <div className="status-box-diagram-item-value">-20%</div>
                                                        <div className="status-box-diagram-item-value">-40%</div>
                                                        <div className="status-box-diagram-item-value">-60%</div>
                                                        <div className="status-box-diagram-item-value">-80% <span>-100%</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={queries1 ? "col-sm-6" : "col-sm-4"}>
                                                <h3 className={`status-order-box-title ${queries1 && "mt-4"}`}>{t('brendoviKojiPadaju')}</h3>
                                                <div className="status-holder-box">
                                                    <div className="status-box-diagram">
                                                        <div className="status-box-diagram-item"></div>
                                                        <div className="status-box-diagram-item"></div>
                                                        <div className="status-box-diagram-item"></div>
                                                        <div className="status-box-diagram-item"></div>
                                                        <div className="status-box-diagram-item"></div>
                                                        <div className="status-diagram-lines">
                                                            {getSaleStatisticsItems.items.BREND.map(item => {
                                                                return <OverlayTrigger placement="top"
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-top`}>
                                                                            {item.acSaleStatisticsValue} ({item.anSaleStatistcsValue}%)
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <div className="status-diagram-line" data-toggle="tooltip" data-placement="top" style={{ width: Math.abs(item.anSaleStatistcsValue) + '%' }}><span style={item.anSaleStatistcsValue > -40 ? { color: '#fe285c' } : { color: '#fff' }}>{item.acSaleStatisticsValue}</span></div>
                                                                </OverlayTrigger>

                                                            })}
                                                        </div>
                                                    </div>
                                                    <div className="status-box-diagram-value">
                                                        <div className="status-box-diagram-item-value">0%</div>
                                                        <div className="status-box-diagram-item-value">-20%</div>
                                                        <div className="status-box-diagram-item-value">-40%</div>
                                                        <div className="status-box-diagram-item-value">-60%</div>
                                                        <div className="status-box-diagram-item-value">-80% <span>-100%</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        ""
                                    ) :
                                    <div className="loyalty-msg-holder">
                                        <div className="alert alert-primary" role="alert">
                                            {t('nemaPodatakaOtrendovima')}
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="status-order-table">
                                <div className="editing-cart-items-table order-status-page-table">
                                </div>
                            </div>
                        </div>
                    </div>
                    <CustomTableOrderStatus data={orderList} handleCargoId={handleCargoId} setOrderDetailFunction={setOrderDetailFunction} gatherStatisticCloneCart={gatherStatisticCloneCart} setFunctionAgainOrderAction={setFunctionAgainOrderAction} showUploadModalFunction={showUploadModalFunction} notEditableAttachment={notEditableAttachment} deliveryTypeSettings={deliveryTypeSettings} />
                </div>
            </div>

            {show && <ModalOrderStatus item={orderDetailList} handleClose={handleClose} getCurrencyItems={getCurrencyItems} show={show} />}
            {showUploadModal && <ModalOrderDocUpload item={selectedItemUpload} handleClose={handleCloseUploadModal} show={showUploadModal} setOrderList={setOrderList} />}
            {showCityModal && <ModalCityCargo data={cityCargoData} handleClose={handleCloseCityModal} show={showCityModal} />}
        </>
    )
}

export default OrderStatusPageTest;