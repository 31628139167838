import React, { useState } from 'react';

const InputQtyPlusMinus = ({ qty, functionSetQty }) => {

    const handleFocus = (event) => event.target.select();

    const [qtyVal, setQtyVal] = useState(1);

    const setQtyMinus = () => {
        if (qtyVal > 1) {
            setQtyVal(parseInt(qtyVal) - 1);
            functionSetQty(parseInt(qtyVal) - 1);
        }
    };
    const setQtyPlus = () => {
        if (qtyVal < qty) {
            setQtyVal(parseInt(qtyVal) + 1);
            functionSetQty(parseInt(qtyVal) + 1);
        }
    };
    const setQtyFunction = (targetQty) => {
        if (parseInt(targetQty) > parseInt(qty)) {
            setQtyVal(qty);
            functionSetQty(qty)
        } else if (parseInt(targetQty) < 1) {
            setQtyVal(1);
            functionSetQty(1)
        } else {
            setQtyVal(targetQty);
            functionSetQty(targetQty);
        }
    };

    return (
        <div className="button-div-item-qty">
            <button className="minus-item-qty" onClick={(e) => setQtyMinus()}>-</button>
            <input type="text" className="input-qty-add-item" value={qtyVal} onChange={(e) => setQtyFunction(e.target.value)} onFocus={handleFocus} />
            <button className="plus-item-qty" onClick={(e) => setQtyPlus()}>+</button>
        </div>
    )
}

export default InputQtyPlusMinus;