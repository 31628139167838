import React from 'react';
import CurrencyFormat from '../other/CurrencyFormat';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';


const CartInfoTotalSum = () => {

    const getActiveCartFunction = useSelector((state) => state.getActiveCartFunction[0]);
    const activeCurrency = useSelector((state) => state.activeCurrency.item);
    const getB2bSettings = useSelector((state) => state.getB2bSettings.items);
    const isMoneyDifference = getB2bSettings.isMoneyDifference;
    // console.log(getB2bSettings, "getB2bSettings u klorpi");
    const roundedValueWithPdv = Math.round(getActiveCartFunction.anValueWithPdv);
    const summaryRounded = roundedValueWithPdv - getActiveCartFunction.anValueWithPdv;



    const { t } = useTranslation('cart');

    return (
        <div className="main-cart-total-sum-block">
            <div className="table-cart-sum">
                <table className="table">
                    <tbody>
                        <tr>
                            <td className="main-cart-table-sum-name">{t('iznos')}</td>
                            <td className="main-cart-table-sum-value"><CurrencyFormat num={getActiveCartFunction.anValueNoPdv} /> {getActiveCartFunction.anValueNoPdv != 0 && activeCurrency.acShortName}</td>
                        </tr>
                        <tr>
                            <td className="main-cart-table-sum-name">{t('suma')}</td>
                            <td className="main-cart-table-sum-value">- <CurrencyFormat num={getActiveCartFunction.anVoucher} /> {getActiveCartFunction.anVoucher != 0 && activeCurrency.acShortName}</td>
                        </tr>
                        <tr>
                            <td className="main-cart-table-sum-name">{t('porez')}</td>
                            <td className="main-cart-table-sum-value"><CurrencyFormat num={getActiveCartFunction.anPdv} /> {getActiveCartFunction.anPdv != 0 && activeCurrency.acShortName}</td>
                        </tr>
                        {isMoneyDifference == 1 &&
                            <tr>
                                <td className="main-cart-table-sum-name">Израмнување на дени:</td>
                                <td className="main-cart-table-sum-value"><CurrencyFormat num={summaryRounded} /> {summaryRounded != 0 && activeCurrency.acShortName}</td>
                            </tr>
                        }
                        <tr>
                            <td className="main-cart-table-sum-name total-sum-cart-main-td">{t('ukupnoZaUplatu')}</td>
                            <td className="main-cart-table-sum-value total-sum-cart-main-td"> {isMoneyDifference == 1 ? roundedValueWithPdv : <CurrencyFormat num={getActiveCartFunction.anValueWithPdv} />} {getActiveCartFunction.anValueWithPdv != 0 && activeCurrency.acShortName}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default CartInfoTotalSum;