import React, { useState, useEffect } from 'react';
import ProductDetailSpecificationItem from './ProductDetailSpecificationItem';
import { getProductSpecification } from '../../redux/actions/productActions';
import { globalStatistic } from '../../redux/actions/otherActions';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const ProductDetailSpecification = ({ tab, id, product }) => {

    const [showMore, setShowMore] = useState(false);
    const dispatch = useDispatch();
    const productSpecificationList = useSelector((state) => state.getProductSpecification);
    const { t } = useTranslation('product');

    useEffect(() => {
        dispatch(getProductSpecification(id));
    }, []);

    const gatherStatistics = (isShownMore) => {
        // ukoliko je otvoreno ne skupljamo info o zatvaranju
        if (!isShownMore) {
            dispatch(globalStatistic(id, 'SPECIFICATION_PRODUCT'));
        }
    }

    return (
        <>
            {productSpecificationList.list && productSpecificationList.list.length == 0 ? (
                <div className="alert-info-specification alert alert-info"><i className="fas fa-info-circle"></i>{t('uskoroDetSpec')}</div>
            ) : (
                <>
                    <div className={`masonry-wrapper ${tab === 1 && 'active'}`}>
                        {/* <div className="col-sm-12">
                        <div className="alert alert-danger"><i className="fas fa-info-circle"></i> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</div>
                    </div> */}
                        {productSpecificationList.list && productSpecificationList.list.map((item, key) => {
                            return (
                                <>
                                    {item.filterSet.length > 0 && <ProductDetailSpecificationItem name={item.acGroupName} key={key} show={showMore} filterSet={item.filterSet} type={key < 6 ? 'show' : 'hidden'} />}
                                </>
                            )
                        })}
                        {productSpecificationList.list && productSpecificationList.list.length <= 6 ? (
                            <div className='figure'>
                                <div className="product-detail-specification-item">
                                    <div className="product-detail-specification-title">{t('deklaracija')}</div>
                                    <div className="product-detail-specification-table">
                                        <table className="table table-striped">
                                            <tbody>
                                                {product.acEan != '' && (
                                                    <tr>
                                                        <td className="row-spec-td-name">{t('EAN')}</td>
                                                        <td>{product.acEan}</td>
                                                    </tr>
                                                )}
                                                {product.acSupplier != '' && (
                                                    <tr>
                                                        <td className="row-spec-td-name">{t('uvoznik')}</td>
                                                        <td>{product.acSupplier}</td>
                                                    </tr>
                                                )}
                                                {product.acCountry != '' && (
                                                    <tr>
                                                        <td className="row-spec-td-name">{t('zemljaPorekla')}</td>
                                                        <td>{product.acCountry}</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        ) :
                            (
                                <div className={`figure ${!showMore && 'show-more-specification-product-detail'}`}>
                                    <div className="product-detail-specification-item">
                                        <div className="product-detail-specification-title">{t('deklaracija')}</div>
                                        <div className="product-detail-specification-table">
                                            <table className="table table-striped">
                                                <tbody>
                                                    {product.acEan != '' && (
                                                        <tr>
                                                            <td className="row-spec-td-name">{t('EAN')}</td>
                                                            <td>{product.acEan}</td>
                                                        </tr>
                                                    )}
                                                    {product.acSupplier != '' && (
                                                        <tr>
                                                            <td className="row-spec-td-name">{t('uvoznik')}</td>
                                                            <td>{product.acSupplier}</td>
                                                        </tr>
                                                    )}
                                                    {product.acCountry != '' && (
                                                        <tr>
                                                            <td className="row-spec-td-name">{t('zemljaPorekla')}</td>
                                                            <td>{product.acCountry}</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            )}

                    </div>
                    {productSpecificationList.list && productSpecificationList.list.length > 6 && (
                        <div className="col-lg-12">
                            <div className="show-more-specification-product-detail-btn" onClick={(e) => { setShowMore(!showMore); gatherStatistics(showMore) }}>
                                <div className="btn-show-more-spec">
                                    {t('detSpecifikacija')}<i className={`${showMore ? 'fas fa-minus' : 'fas fa-plus'}`}></i>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default ProductDetailSpecification;