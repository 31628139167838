import React, { useEffect, useState } from 'react';
import ReclamationStatusItem from '../components/other/ReclamationStatusItem';
import PageHelmet from '../components/helmet/PageHelmet';
import { useDispatch } from 'react-redux';
import { globalStatistic } from '../redux/actions/otherActions';
import { getPageSeo } from '../redux/actions/pageActions';
import { useTranslation } from 'react-i18next';

const ReclamationStatus = () => {

    const dispatch = useDispatch();
    const [pageSeoData, setPageSeoData] = useState({});
    const { t } = useTranslation('reclamationStatusPage');

    useEffect(() => {
        dispatch(getPageSeo(771)).then(res => {
            if (res) {
                setPageSeoData(res);
            }
        });
        dispatch(globalStatistic(771, 'PAGE'));
    }, [])

    return (
        <>
            {Object.keys(pageSeoData).length !== 0 &&
                <PageHelmet
                    title={pageSeoData ? `${pageSeoData.acSeoTitle}` : "Ewe Comp"}
                    description={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    keywords={pageSeoData ? `${pageSeoData.acSeoKeywords}` : ""}
                    ogDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    ogTitle={pageSeoData ? `${pageSeoData.acSeoTitle}` : ""}
                    ogUrl={pageSeoData ? `https://ewe.rs/${pageSeoData.acSeoUrl}` : ""}
                    ogImg="https://www.ewe.rs/media/ewe-og-img.jpg"
                    twitterDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                />
            }
            <div className="container-content container-main-content reclamation-status-content top-margin-navbar-escape">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="stats-order-content-holder">
                                <h1>{pageSeoData.acSeoHeader ? pageSeoData.acSeoHeader : t('statReklamacija')}</h1>
                                {pageSeoData.acSeoShortPageDescription && <p>{pageSeoData.acSeoShortPageDescription}</p>}
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="status-order-filter-line">
                                <form className="form-inline">
                                    <div className="status-order-filter-items">
                                        <div className="status-order-filter-item">
                                            <div className="form-group">
                                                <label htmlFor="inputPassword6">{t('datumOd')}</label>
                                                <input type="date" className="form-control form-control-sm mx-sm-3" />
                                            </div>
                                        </div>
                                        <div className="status-order-filter-item">
                                            <div className="form-group">
                                                <label htmlFor="inputPassword6">{t('datumDo')} </label>
                                                <input type="date" className="form-control form-control-sm mx-sm-3" />
                                            </div>
                                        </div>
                                        <div className="status-order-filter-item status-order-filter-search-input">
                                            <input type="text" className="form-control form-control-sm status-filter-search-input" />
                                            <i className="fas fa-search"></i>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="status-order-table">
                                <div className="editing-cart-items-table">
                                    <div className="table-responsive">
                                        <table className="table editing-cart-table">
                                            <thead>
                                                <tr>
                                                    <th>{t('brTiketa')}</th>
                                                    <th>{t('artikal')}</th>
                                                    <th>{t('serBr')}</th>
                                                    <th>{t('datumPrijema')}</th>
                                                    <th>{t('nacinResavanja')}</th>
                                                    <th>{t('datumPrijema')}</th>
                                                    <th>{t('status')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <ReclamationStatusItem status="Prihvaćeno" statusColor="blue" />
                                                <ReclamationStatusItem status="Odbijeno" statusColor="red" />
                                                <ReclamationStatusItem status="Testiranje" statusColor="orange" />
                                                <ReclamationStatusItem status="Odbijeno" statusColor="red" />
                                                <ReclamationStatusItem status="Odbijeno" statusColor="red" />
                                                <ReclamationStatusItem status="Odbijeno" statusColor="red" />
                                                <ReclamationStatusItem status="Odbijeno" statusColor="red" />
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ReclamationStatus;