import React from 'react';

import CurrencyFormat from './CurrencyFormat';
import { useTranslation } from 'react-i18next';
import SecureLS from 'secure-ls';

const AuctionBidItem = ({ item, key }) => {

    const { t } = useTranslation('other');

    const d = new Date(item.adTimeIns);
    let ls = new SecureLS({ encodingType: 'aes' });
    var userInfoData = ls.get('userInfo');
    // console.log(d.getFullYear(), 'getFullYear()')
    // console.log(d.getMonth(), 'getMonth()')
    // console.log(d.getDate(), 'getDate()')
    // console.log(d.getHours(), 'getHours()')
    // console.log(d.getMinutes(), 'getMinutes()')
    // console.log(d.getSeconds(), 'getSeconds()')
    var sec = '';
    if (parseInt(d.getSeconds()) < 10) {
        sec = '0' + d.getSeconds()
    } else {
        sec = d.getSeconds()
    }



    return (
        <li key={key}>
            <div className="auction-list-block-table-li">
                <div className='offer-text-auction'>{item.anUserKey == userInfoData.company.department[0].user[0].anUserKey && "Vaša "}{t('ponuda')}

                    <span className='auction-date'>{d.getDate()}.{d.getMonth() + 1}.{d.getFullYear()}. {d.getHours()}:{d.getMinutes()}:{sec}.{d.getMilliseconds()}</span>
                </div>
                {key}
                <span><CurrencyFormat num={item.anBidPrice} />€</span>
            </div>
        </li>
    )
}

export default AuctionBidItem;