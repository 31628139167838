import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link, useHistory } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';

import { getCatalogMenuList } from '../redux/actions/menuActions';
import { getBrandsMenuList } from '../redux/actions/menuActions';

import SearchBoxLogoutPage from '../components/search/SearchBoxLogoutPage';
import PageBanner from '../components/banner/PageBanner';
import OutletCategoryItem from '../components/other/OutletCategoryItem';
import SliderLogoutPage from '../components/other/SliderLogoutPage';
import LogoItem from '../components/other/LogoItem';
import PageHelmet from '../components/helmet/PageHelmet';

import { getPageSeo } from '../redux/actions/pageActions';
import { globalStatistic } from '../redux/actions/otherActions';

import axios from 'axios';
import { axiosinstance } from '../configAxios';

import { removeSpecialChar } from '../helperFunctions'
import { useTranslation } from 'react-i18next';

const HomePageLogout = () => {

  const dispatch = useDispatch();
  const params = useParams();
  const { id } = params;
  const catalogMenuList = useSelector((state) => state.getCatalogMenuList);
  var history = useHistory();
  const { t } = useTranslation(['homePageLogout', 'routes']);

  const getBrandsMenuListItems = useSelector((state) => state.getBrandsMenuList);

  const [presentation, setPresentation] = useState([]);
  const [pageSeoData, setPageSeoData] = useState({});

  const queries = useMediaQuery('(min-width: 1500px)');
  const queries515 = useMediaQuery('(max-width: 515px)');
  // console.log(queries515, 'queries515');
  useEffect(() => {
    dispatch(getCatalogMenuList());
    getPresentationList();
    dispatch(getBrandsMenuList());
    dispatch(globalStatistic(763, 'PAGE'));
    dispatch(getPageSeo(763)).then(res => {
      if (res) {
        setPageSeoData(res);
      }
    })
  }, []);

  const getPresentationList = async () => {
    const attributes = {};
    attributes['isGroup'] = 0;
    const configRes = axiosinstance(attributes, "8224", "presentationList");

    await axios(configRes)
      .then(function (response) {
        setPresentation(response.data.response.tpList);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  let item = [];
  var subitem = false;

  catalogMenuList.filter(item => item._id == id).map((item2) => {
    item2.subCategories.map((item3) => {
      item.push(item3);
    })
  });

  if (item.length == 0) {
    catalogMenuList.map((item4, key4) => {
      item4.subCategories.filter(item => item._id == id).map((item2) => {
        item2.subCategories.map((item5, key5) => {
          item.push(item5);
          subitem = true;
        })
      });
    })
  }

  var mainCatalogItem = catalogMenuList.filter(item => item._id == id);
  if (mainCatalogItem.length == 0) {
    catalogMenuList.map((item4, key4) => {
      item4.subCategories.filter(item => item._id == id).map((item2) => {
        mainCatalogItem.push(item2);
      });
    });
  }

  if (!presentation) {
    return null;
  }

  return (
    <>
      {!id && Object.keys(pageSeoData).length !== 0 &&
        <PageHelmet
          title={pageSeoData ? `${pageSeoData.acSeoTitle}` : "Ewe Comp"}
          description={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
          keywords={pageSeoData ? `${pageSeoData.acSeoKeywords}` : ""}
          ogDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
          ogTitle={pageSeoData ? `${pageSeoData.acSeoTitle}` : ""}
          ogUrl={pageSeoData ? `https://ewe.rs/${pageSeoData.acSeoUrl}` : ""}
          ogImg="https://www.ewe.rs/media/ewe-og-img.jpg"
          twitterDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
        />
      }
      <div className="container-content products-logout">
        <div className="container-fluid-custom">
          <div className="row">
            <div className="col-sm-12">
              <div className="banner-image-on-top-page">
                <PageBanner categoryType="" categoryId="" pageId="357" />
              </div>
              <div className="logout-page-search">
                <SearchBoxLogoutPage />
              </div>
              <div className="logout-page-text text-center">
                {!id && pageSeoData ?
                  <h1><b>{t('homePageLogout:naslov1')}</b></h1> :
                  <><b>{t('homePageLogout:naslov1')}</b></>}
                <span>{t('homePageLogout:podnaslov1')}</span>
              </div>
              <div className="logout-page-products">
                {id && mainCatalogItem.length > 0 && (
                  <>
                    <PageHelmet
                      title={`${mainCatalogItem[0].acSeoTitle}`}
                      description={`${mainCatalogItem[0].acSeoDescription}`}
                      keywords={`${mainCatalogItem[0].acSeoKeywords}`}
                      ogDescription={`${mainCatalogItem[0].acSeoDescription}`}
                      ogTitle={`${mainCatalogItem[0].acSeoTitle}`}
                      ogUrl={`https://ewe.rs${window.location.pathname}`}
                      // ogImg={`https://resource.ewe.rs/manufacturers/${mainCatalogItem[0].acBrand}.png`}
                      twitterDescription={`${mainCatalogItem[0].acSeoDescription}`}
                    />
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="content-header-main-category">
                          <div className="main-category-title">
                            <h1>{mainCatalogItem[0].acSeoHeader} <span>({mainCatalogItem[0].anProductCount})</span> </h1>
                            <p className="main-subtitle">{mainCatalogItem[0].acSeoShortPageDescription}</p>
                          </div>
                          <button className="back-button" onClick={() => history.goBack()}><i className="fas fa-chevron-left"></i>&nbsp;&nbsp; {queries515 ? t('homePageLogout:nazad') : t('homePageLogout:povratak')} </button>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className="row">
                  {id == undefined ?
                    catalogMenuList.map((item, index) => {
                      if (item._id != 1 && item._id != 12) {
                        return (
                          <div key={index} className={`${queries ? "col-md-3" : "col-md-4"} col-sm-6`}>
                            <Link to={`/${t('routes:proizvodi')}/${item._id}/${item.acSeoUrl ? removeSpecialChar(item.acSeoUrl) : removeSpecialChar(item.acName)}`} className="logout-page-items">
                              <OutletCategoryItem item={item} />
                            </Link>
                          </div>
                        )
                      }
                    })
                    :
                    item.map((item1, index) => {
                      return (
                        <div key={index} className={`${queries ? "col-md-3" : "col-md-4"} col-sm-6`}>
                          {subitem ? (
                            <Link to={`/${t('routes:katalog')}/podpodkategorija/${item1._id}/${item1.acSeoUrl ? removeSpecialChar(item1.acSeoUrl) : removeSpecialChar(item1.acName)}`} className="logout-page-items">
                              <OutletCategoryItem item={item1} />
                            </Link>
                          ) : (
                            <>
                              {item1.subCategories && item1.subCategories.length == 1 ? (
                                <Link to={`/${t('routes:katalog')}/podkategorija/${item1._id}/${item1.acSeoUrl ? removeSpecialChar(item1.acSeoUrl) : removeSpecialChar(item1.acName)}`} className="logout-page-items">
                                  <OutletCategoryItem item={item1} />
                                </Link>
                              ) : (
                                <Link to={`/${t('routes:proizvodi')}/${item1._id}/${item1.acSeoUrl ? removeSpecialChar(item1.acSeoUrl) : removeSpecialChar(item1.acName)}`} className="logout-page-items">
                                  <OutletCategoryItem item={item1} />
                                </Link>
                              )}
                            </>
                          )}
                          {/* <Link to={`/katalog/podpodkategorija/${item1._id}`} className="logout-page-subitems clearfix"> */}
                          {/* {item1.subCategories && item1.subCategories.length == 1 ? (
                            <Link to={`/katalog/podkategorija/${item1._id}`} className="logout-page-items">
                              <OutletCategoryItem item={item1} />
                            </Link>
                          ) : (
                            <Link to={`/katalog/podpodkategorija/${item1._id}`} className="logout-page-items">
                              <OutletCategoryItem item={item1} />
                            </Link>
                          )} */}
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="slider-logout-page-content">
            <div className="row">
              <div className="col-lg-2 col-md-4 col-sm-12 col-xs-12">
                <h2 className="h2-silder-title-logout-page">{t('homePageLogout:naslov2')}</h2>
              </div>
              <div className="col-lg-10 col-md-8 col-sm-12 col-xs-12">
                <SliderLogoutPage items={presentation} />
              </div>
            </div>
          </div>
          <div className="logout-page-brands-list">
            <div className="row">
              <div className="col-sm-12">
                <div className="logout-page-text text-center">
                  <b>{t('homePageLogout:naslov3')}</b><br />
                  <span>{t('homePageLogout:podnaslov2')}</span>
                </div>
              </div>
            </div>
            <div className="logout-page-logo-content">

              {getBrandsMenuListItems.map((item, key) => (
                item.anCnt > 0 && <LogoItem logo={item.acName} />
              ))}
              {/* <LogoItem logo="A4 TECH" />
              <LogoItem logo="ACER" />
              <LogoItem logo="A-DATA" />
              <LogoItem logo="AMD" />
              <LogoItem logo="AOC" />
              <LogoItem logo="APACER" />
              <LogoItem logo="ASUS" />
              <LogoItem logo="BARKAN" />
              <LogoItem logo="BEKO" />
              <LogoItem logo="BENQ" />
              <LogoItem logo="BLAUPUNKT" />
              <LogoItem logo="CHIEFTEC" />
              <LogoItem logo="COOLER MASTER" />
              <LogoItem logo="DAHUA" />
              <LogoItem logo="DELL" />
              <LogoItem logo="D-LINK" />
              <LogoItem logo="ENERGY SISTEM" />
              <LogoItem logo="EPSON" />
              <LogoItem logo="AMD" />
              <LogoItem logo="INTEL" />
              <LogoItem logo="MICROSOFT" />
              <LogoItem logo="FUJITSU" />
              <LogoItem logo="GENIUS" />
              <LogoItem logo="GIGABYTE" />
              <LogoItem logo="GRUNDIG" />
              <LogoItem logo="HISENSE" />
              <LogoItem logo="INFOSEC COMMUNICATION" />
              <LogoItem logo="INTEL" />
              <LogoItem logo="KINGSTON" />
              <LogoItem logo="KYOCERA" />
              <LogoItem logo="MEDIACOM" />
              <LogoItem logo="MICROSOFT" />
              <LogoItem logo="MSI" />
              <LogoItem logo="NJOY" />
              <LogoItem logo="NZXT" />
              <LogoItem logo="PALOALTO" />
              <LogoItem logo="PHILIPS" />
              <LogoItem logo="SEAGATE" />
              <LogoItem logo="SENCOR" />
              <LogoItem logo="SHARKOON" /> 
              <LogoItem logo="SHARP" />
              <LogoItem logo="SPIRE" />
              <LogoItem logo="TARGUS" />
              <LogoItem logo="TESY" />
              <LogoItem logo="THECUS" />
              <LogoItem logo="TOSHIBA" />
              <LogoItem logo="VEGA" />
              <LogoItem logo="WD" />
              <LogoItem logo="YENKEE" /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default HomePageLogout;