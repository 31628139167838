import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    removeCartItemFunction
} from '../../redux/actions/cartActions';

const RemoveCartItem = (props) => {

    const dispatch = useDispatch();
    const getActiveCartFunction = useSelector((state) => state.getActiveCartFunction);

    const removeCartItem = (anCartItemKey) => {
        dispatch(removeCartItemFunction(anCartItemKey, getActiveCartFunction));
    };

    return (
        <div
            className="remove-cart-item"
            onClick={(e) => removeCartItem(props.anCartItemKey)}
        >
            <i className="far fa-times-circle"></i>
        </div>
    );
};

export default RemoveCartItem;