import React, { useState, useEffect } from 'react';
import InputRange from 'react-input-range';
import { useHistory } from 'react-router';
// import { useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
// import axios from 'axios';
// import { axiosinstance } from '../../configAxios';
import { useTranslation } from 'react-i18next';

function useQuery() {
    return new URLSearchParams(useLocation().search);
};

const FilterPriceItem = ({ checkFilterFunction, catalogFiltersPrice }) => {

    const [showFilter, setShowFilter] = useState(true);
    let query = useQuery();
    const history = useHistory();
    let pathname = history.location.pathname;
    let searchHistory = history.location.search;
    const { t } = useTranslation('filters');

    const [valuesRange, setValuesRange] = useState({
        max: catalogFiltersPrice.anPriceFilterMax,
        min: catalogFiltersPrice.anPriceFilterMin,
    });

    const handleValuesChange = (component, values) => {
        setValuesRange({
            max: component.max,
            min: component.min,
        });
    };

    const filteredProductFunction = (val) => {
        var anPriceMin = val.min;
        var anPriceMax = val.max;

        checkFilterFunction(anPriceMin, anPriceMax);
    };

    // useEffect(() => {
    //     setValuesRange({
    //         max: catalogFiltersPrice.anPriceFilterMax,
    //         min: catalogFiltersPrice.anPriceFilterMin,
    //     });
    // }, [catalogFiltersPrice.anPriceFilterMax, catalogFiltersPrice.anPriceFilterMin]);

    useEffect(() => {
        if (searchHistory.includes('cena')) {
            var historyAttributes = searchHistory.substr(1).split('&');
            if (historyAttributes != '') {
                var i = historyAttributes.length;
                var x;
                while (i--) {
                    x = historyAttributes[i].split('=');
                    if (x[0] == 'cena_0') {
                        setValuesRange({
                            max: x[1].split('_')[1],
                            min: x[1].split('_')[0],
                        });
                    }
                }
            }
        } else {
            setValuesRange({
                max: catalogFiltersPrice.anPriceFilterMax,
                min: catalogFiltersPrice.anPriceFilterMin,
            });
        }
    }, [query.toString(), catalogFiltersPrice.anPriceFilterMax, catalogFiltersPrice.anPriceFilterMin]);

    return (
        <div className="range-filter-content">
            {/* prices.anPriceFilterMax: {catalogFiltersPrice.anPriceFilterMax}<br/>
            prices.anPriceFilterMin: {catalogFiltersPrice.anPriceFilterMin}<br/>
            valuesRange.max: {valuesRange.max}<br/>
            valuesRange.min: {valuesRange.min}<br/> */}
            <div>
                <h5 onClick={(e) => setShowFilter(!showFilter)}><span className="filter-title-holder">{t('cena')}</span> <span className="toggle-icon-filters-show">
                    {showFilter ? <i className="fas fa-chevron-down"></i> : <i className="fas fa-chevron-right"></i>}
                </span>
                </h5>
                <div className="line-h5-filter"></div>
            </div>
            <div className={`filter-list-items ${showFilter ? 'show-filters' : 'hide-filters'}`}>
                <InputRange
                    step={1}
                    maxValue={catalogFiltersPrice.anPriceFilterMax}
                    minValue={catalogFiltersPrice.anPriceFilterMin}
                    value={valuesRange}
                    formatLabel={value => ''}
                    onChange={handleValuesChange.bind(this)}
                    onChangeComplete={value => filteredProductFunction(value)}
                />
                <div className="container-price-block-inputs">
                    <div className="price-block-input-item price-block-input-min-item">
                        <div className="range-slider-values-print">
                            <small>{t('minCena')}:</small>
                            <div className="slider-values-print-item">{valuesRange.min}</div>
                        </div>
                    </div>
                    <div className="price-block-input-item">

                        <div className="range-slider-values-print">
                            <small>{t('maxCena')}:</small>
                            <div className="slider-values-print-item">{valuesRange.max}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default FilterPriceItem;