import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { getActionProductsList } from '../redux/actions/productActions';

import CatalogPreviewBlock from '../components/catalog/CatalogPreviewBlock';
import PageBanner from '../components/banner/PageBanner';

const ArticlesPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    var idents = history.location.search.split('=')[1];

    const getActionProducts = useSelector((state) => state.getActionProducts);

    useEffect(() => {
        dispatch(getActionProductsList(0, '', idents));
    }, []);

    return (
        <div className="container-content container-catalog-content">
            <div className="container-fluid">
                <div className="row">
                    <div className="text-center banner-image-on-top-page">
                        <PageBanner categoryType="" categoryId="" pageId={369} />
                    </div>
                </div>
                <div className="row">
                    <div className="weekly-block-page">
                        <CatalogPreviewBlock itemsList={getActionProducts} sortable="0" preview="1" export="1" itemPreview="catalog" pdfType="nedeljna" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ArticlesPage;