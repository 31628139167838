import React from "react";
import { useSelector } from "react-redux";
import { Page, Text, View, Document, StyleSheet, Font, Image } from "@react-pdf/renderer";
import CatalogPdfItem from "./CatalogPdfItem";

const CatalogPdfTemplate = (props) => {

    const { list, currency, getUserInfo } = props;

    Font.register({
        family: "Roboto",
        src: "/css/fonts/roboto/RobotoCondensed-Light.ttf",
    });
    const styles = StyleSheet.create({
        body: {
            paddingTop: 10,
            paddingBottom: 30,
            paddingHorizontal: 10,
            width: 100,
            fontFamily: "Roboto",
            fontSize: 11,
            fontWeight: 200,
        },
        header: {
            paddingHorizontal: "0.5cm",
            marginBottom: 30,
        },
        headerTable: {
            borderBottomStyle: "dotted",
        },

        table: {
            display: "table",
            width: "150",
            position: "fixed",
            left: 0,
            top: 0,
            right: 0,
            marginBottom: 10,
            width: "auto",
        },
        tableRow: { flexDirection: "row" },
        tableCol: {
            width: "50%",
            borderBottom: 1,
            borderBottomStyle: "dotted",
            paddingVertical: 2,
            borderColor: "#ccc",
        },

        tableCellLeft: {
            textAlign: "left",
        },
        tableCellRight: {
            textAlign: "right",
        },
        tableCellHeader: { margin: "auto", marginTop: 5, fontSize: 10 },
        pageNumber: {
            position: "absolute",
            fontSize: 12,
            bottom: 30,
            left: 0,
            right: 0,
            textAlign: "center",
            color: "grey",
        },
    });

    return (
        <Document>
            <Page size="A4" style={styles.body} wrap>
                <View style={styles.table}>
                    {list.map((item, key) => (
                        <CatalogPdfItem key={key} item={item} currency={currency} getUserInfo={getUserInfo} />
                    ))}
                </View>
                <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} fixed />
            </Page>
        </Document>
    );
};

export default CatalogPdfTemplate;
