import React from 'react';
import Slider from "react-slick";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector } from 'react-redux';

function SampleArrow(props) {
    return null;
}

const ImageBanner = ({ categoryId, pageId }) => {

    const queries1 = useMediaQuery('(min-width: 1024px)');

    const settingsSlider = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        adaptiveHeight: false,
        slidesToScroll: 1,
        nextArrow: <SampleArrow />,
        prevArrow: <SampleArrow />,
    };

    var bennerList = [];

    const getUserBanner = useSelector((state) => state.getUserBanner.items);

    if (pageId != 0) {
        bennerList = getUserBanner.filter(x => x.anPageKey == pageId);
    } else {
        bennerList = getUserBanner.filter(x => x.anCategoryKey == categoryId);
    }


    return (
        <div className="page-banner-top-image">
            <div className="content-slider-special-action-banner">
                <Slider {...settingsSlider}>
                    {bennerList.map((item, key) => {

                        var slide = queries1 ? item.acBannerImage : item.acFilenameMobile;

                        return (
                            item.acBannerLink != null && item.acBannerLink !== "" ? (
                                <a href={item.acBannerLink} key={key}>
                                    <img
                                        src={`https://resource.ewe.rs/media/${slide}`}
                                        className="img-responsive page-banner-image-slider page-banner-image-slider-desktop clickable-img"
                                        alt={item.acSeoDescription}
                                        title={item.acSeoTitle}
                                    />
                                </a>
                            ) : (
                                <img
                                    src={`https://resource.ewe.rs/media/${slide}`}
                                    className="img-responsive page-banner-image-slider page-banner-image-slider-desktop"
                                    alt={item.acSeoDescription}
                                    title={item.acSeoTitle}
                                    key={key}
                                />
                            )
                        );
                    })}
                </Slider>
            </div>
        </div>
    );
};

export default ImageBanner;