import React, { useState, useEffect } from "react";

const CompareProductsItem = (props) => {

    const { specs, filterId, groupId } = props;
    const [filterValue, setFilterValue] = useState('');

    useEffect(() => {

        let val = specs ? specs.filterSet.find((fv) => fv.anFilterId === filterId) : null;
        if (val) {
            setFilterValue(val.filters);
        }

    });


    return <td dangerouslySetInnerHTML={{ __html: filterValue }}></td>;

}

export default CompareProductsItem;
