import { ActionTypes } from '../contants/action-types';
import axios from 'axios';
import { axiosinstance } from '../../configAxios';
import SecureLS from 'secure-ls';

export const getCompanyPromoMaterial = (anCompanyKey = 0) => async (dispatch) => {
    try {
        let ls = new SecureLS({ encodingType: 'aes' });
        var userInfoData = ls.get('userInfo');
        const attributes = {};
        attributes['anCompanyKey'] = userInfoData ? userInfoData.company.anCompanyKey : 0;
        attributes["anUserKey"] = userInfoData ? userInfoData.company.department[0].user[0].anUserKey : 0;
        const configRes = axiosinstance(attributes, '8224', 'postSetCompanyPromoMaterial');
        axios(configRes)
            .then(function (response2) {
                dispatch({
                    type: 'GET_USER_NEWS',
                    payload: response2.data.response.news,
                });
                localStorage.setItem('getUserNews', JSON.stringify(response2.data.response.news));

                dispatch({
                    type: 'GET_USER_BANNER',
                    payload: response2.data.response.banner,
                });
                localStorage.setItem('getUserBanner', JSON.stringify(response2.data.response.banner));
                if (userInfoData) {
                    dispatch({
                        type: 'GET_USER_POPUP',
                        payload: response2.data.response.popup,
                    });
                    localStorage.setItem('getUserPopup', JSON.stringify(response2.data.response.popup));
                    dispatch({
                        type: 'GET_USER_SURVEY',
                        payload: response2.data.response.survey,
                    });
                    localStorage.setItem('getUserSurvey', JSON.stringify(response2.data.response.survey));
                }

            })
            .catch(function (error) {
                console.log(error, 'error');
            });
    } catch (error) {
        console.log(error)
    }

};

