import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import styles from "../css/TehnovacijeRegistrationPage.module.css";
import { happeningRegistration } from '../redux/actions/userActions';
import { axiosinstance } from '../configAxios';
import axios from 'axios';
import MessageInfoBox from '../components/other/MessageInfoBox';
import captchaImg from '../../src/img/captcha.jpg';
import ReCAPTCHA from "react-google-recaptcha";
import SecureLS from 'secure-ls';

const TehnovacijeRegistrationPage = () => {
    const [isValidName, setIsValidName] = useState(0);
    const [isValidCompany, setIsValidCompany] = useState(0);
    const [isValidEmail, setIsValidEmail] = useState(0);
    const [name, setName] = useState('');
    const [company, setCompany] = useState('');
    const [email, setEmail] = useState('');
    const [isRegistered, setIsRegistered] = useState(0);



    const [captcha, setCaptcha] = useState('I');
    const [captchaInput, setCaptchaInput] = useState({
        captchaValue: ""
    });
    const [isValidCaptcha, setIsValidCaptcha] = useState(0); // Dodato za Captcha validaciju
    const dispatch = useDispatch();

    const characters = 'qwertyuopasdfghjkzxcvbnm1234567890';

    useEffect(() => {
        let ls = new SecureLS({ encodingType: 'aes' });
        var userInfoData = ls.get('userInfo');
        const attributes = {};
        attributes["anUserKey"] = userInfoData ? userInfoData.company.department[0].user[0].anUserKey : 0;
        attributes["acHappeningName"] = "Tehnovacije2023";

        const configRes = axiosinstance(attributes, "8224", "happeningCheck");

        axios(configRes)
            .then(function (response) {
                if (response.data.response.isOk !== 1) {
                    setIsRegistered(1)
                } else {
                    setIsRegistered(2)
                }
            })
            .catch(function (error) {
                console.log(error, "error");
            });

    }, []);

    useEffect(() => {
        generateCaptcha();
    }, []);

    const generateCaptcha = () => {
        const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let result = '';

        for (let i = 0; i < 6; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }

        setCaptcha(result);
    };

    const validateName = (name) => {
        return name.match(/^[a-zA-Zа-яА-Я\s']*$/);
    };

    const validateCompanyName = (company) => {
        return company.match(/^[a-zA-Z0-9\s&@-]*$/);
    };

    const validateEmail = (email) => {
        return email.match(/^(([^<>()[\]\\.,;:~`\s@\"]+(\.[^<>()[\]\\.,;:~`\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    };

    const onBlurInput = (name, value) => {
        if (name === 'inputName') {
            if (value === '') {
                setIsValidName(1);
            } else if (!validateName(value)) {
                setIsValidName(1);
                toast(<MessageInfoBox message="nameError" type="0" />, {
                    className: 'red-background',
                });
            } else {
                setIsValidName(2);
            }
        } else if (name === 'inputCompany') {
            if (value === '') {
                setIsValidCompany(1);
            } else if (!validateCompanyName(value)) {
                setIsValidCompany(1);
                toast(<MessageInfoBox message="companyNameError" type="0" />, {
                    className: 'red-background',
                });
            } else {
                setIsValidCompany(2);
            }
        } else if (name === 'inputEmail') {
            if (value === '') {
                setIsValidEmail(1);
            } else if (!validateEmail(value)) {
                setIsValidEmail(1);
                toast(<MessageInfoBox message="emailNotFoundError" type="0" />, {
                    className: 'red-background',
                });
            } else {
                setIsValidEmail(2);
            }
        } else if (name === 'inputCaptcha') { // Dodato za Captcha validaciju
            if (value === '') {
                setIsValidCaptcha(1);
                toast(<MessageInfoBox message='errorRequiredCaptcha' type={0} />, {
                    className: 'red-background',
                });
            } else {
                setIsValidCaptcha(2);
            }
        }
    };


    const handleInput = () => {
        if (isValidName !== 2 || isValidCompany !== 2 || isValidEmail !== 2) {
            toast(<MessageInfoBox message="errorMissingOneOrMoreFields2" type="0" />, {
                className: 'red-background',
            });
        } else {

            // Proverava da li je Captcha kod tačan
            if (captchaInput.captchaValue.trim() !== captcha.trim()) {
                toast(<MessageInfoBox message='errorRequiredCaptcha' type={0} />, {
                    className: 'red-background',
                });
            } else {
                // Slanje podataka i registracija
                let values = {
                    'acName': name,
                    'acCompany': company,
                    'acEmail': email,
                    'acType': "Tehnovacije2023",
                    'captcha': captchaInput.captchaValue,

                };

                dispatch(happeningRegistration(values)).then(res => {
                    if (res.isOk === 1) {
                        setIsRegistered(1);

                        setIsValidName(0);
                        setIsValidCompany(0);
                        setIsValidEmail(0);
                        setIsValidCaptcha(0);

                        setName('');
                        setCompany('');
                        setEmail('');
                        setCaptchaInput({ captchaValue: '' }); //resetovanje vrednosti
                        generateCaptcha(); // Generise novu Captcha vrednost nakon uspešne registracije
                    } else {
                        setIsRegistered(2);
                    }
                });
            }
        }
    };

    // Dodato za generisanje i prikaz Captcha koda
    useEffect(() => {
        generateString(6); // Postaviti dužinu Captcha koda prema potrebi
    }, []);

    function generateString(length) {
        let result = '';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        setCaptcha(result);
        //  return result;
    }

    // console.log(captcha, 'kepča')

    return (
        <div className={`${styles['main-container-tehnovacije']}`}>
            <div className={`${styles['form-container-tehnovacije']}`}>
                <div className={`container ${styles['inner-container-tehnovacije']}`}>
                    <img className={`${styles['tehnovacije-logo']}`} src='https://resource.ewe.rs/media/tehnovacije/tehnovacije-logo.svg' alt='logo'></img>



                    <div className={`${styles['form-and-vertical-text-container']}`}>
                        <p className={`${styles['vertical-text']}`}>VALUE ADDED DISTRIBUTOR</p>
                        <form noValidate="novalidate" className={`${styles['form-tehnovacije']}`}>
                            <div className={`${styles['holder-form-tehnovacije']}`} style={{ marginBottom: '25%' }}>
                                <div className={`${styles['tehnovacije-title']}`}>
                                    <h1>Prijava</h1>
                                    <h2>za Tehnovacije by EWE</h2>
                                    <p style={{ fontSize: '1.2rem', color: '#b9ff1a', marginTop: '-5px', textTransform: 'uppercase', letterSpacing: '1px', fontWeight: 'bold' }}>više nije moguća</p>
                                </div>
                            </div>
                            {/* <div className={`${styles['holder-form-tehnovacije']}`}>
                                <div className={`${styles['tehnovacije-title']}`}>
                                    <h1>Prijava</h1>
                                    <h2>Tehnovacije by EWE</h2>
                                </div>
                                <div>
                                    <Form.Label className={`${styles['label-tehnovacije']}`}>IME I PREZIME</Form.Label>
                                    <Form.Control
                                        type="text"
                                        className={`form-control ${styles['input-tehnovacije']}`}
                                        name="inputName"
                                        aria-describedby="nameHelp"
                                        onChange={(e) => setName(e.target.value)}
                                        onBlur={() => onBlurInput('inputName', name)}
                                        isInvalid={isValidName === 1}
                                        isValid={isValidName === 2}
                                        value={name}
                                        autoComplete="off"
                                    />
                                </div>
                                <div>
                                    <Form.Label className={`${styles['label-tehnovacije']}`}>NAZIV FIRME</Form.Label>
                                    <Form.Control
                                        type="text"
                                        className={`form-control ${styles['input-tehnovacije']}`}
                                        name="inputCompany"
                                        aria-describedby="nameHelp"
                                        onChange={(e) => setCompany(e.target.value)}
                                        onBlur={() => onBlurInput('inputCompany', company)}
                                        isInvalid={isValidCompany === 1}
                                        isValid={isValidCompany === 2}
                                        value={company}
                                        autoComplete="off"
                                    />
                                </div>
                                <div>
                                    <Form.Label className={`${styles['label-tehnovacije']}`}>E-MAIL</Form.Label>
                                    <Form.Control
                                        type="email"
                                        className={`form-control ${styles['input-tehnovacije']}`}
                                        name="inputEmail"
                                        aria-describedby="nameHelp"
                                        onChange={(e) => setEmail(e.target.value)}
                                        onBlur={() => onBlurInput('inputEmail', email)}
                                        isInvalid={isValidEmail === 1}
                                        isValid={isValidEmail === 2}
                                        value={email}
                                        autoComplete="off"
                                    />
                                </div>

                                <div className="form-group row contact-captcha-image-code unselectable">
                                    <div className="mask-captcha-tehnovacije"></div>
                                    <p id="captcha" className={`${styles['captcha-tehnovacije']}`}>{captcha}</p>
                                    <img src={captchaImg} className="mt-3 mb-3 w-100" height={window.innerWidth <= 767 ? 30 : 50} alt="captcha" />
                                </div>
                                <Form.Control
                                    type="text"
                                    name="captchaValue"
                                    placeholder="Unesite kod sa slike..."
                                    className={`form-control ${styles['input-tehnovacije']}`}
                                    messageError="Molimo unesite kod sa slike!"
                                    isRequired="1"
                                    value={captchaInput.captchaValue}
                                    onChange={(e) => setCaptchaInput({ captchaValue: e.target.value })}
                                    autocomplete="off"
                                />



                                <div className={`${styles['btn-holder-tehnovacije']}`} onClick={handleInput}>
                                    <div className={`${styles['button-tehnovacije']}`}>
                                        PRIJAVI SE
                                    </div>
                                </div>
                            </div> */}
                        </form>
                    </div>
                    <img src='https://resource.ewe.rs/media/tehnovacije/tehnovacije-prijava-forma.png' alt='' />
                </div>
            </div>
        </div>
    );
};

export default TehnovacijeRegistrationPage;
