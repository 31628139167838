import React, { useState, useEffect, createRef } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import MaterialTable, { MTableToolbar } from "material-table";
import { Paper } from '@material-ui/core';
import Moment from 'react-moment';
import useMediaQuery from '@mui/material/useMediaQuery';
import { getTransactions } from '../redux/actions/otherActions';
import PatchedPagination from '../components/other/PatchedPagination';
import CurrencyFormat from '../components/other/CurrencyFormat';
import { currencyFormat } from "../helperFunctions";
import { globalStatistic } from '../redux/actions/otherActions';
import { getPageSeo } from '../redux/actions/pageActions';
import PageHelmet from '../components/helmet/PageHelmet';
import { useTranslation } from 'react-i18next';
import SecureLS from 'secure-ls';

const AnalyticPage = () => {

    const dispatch = useDispatch();
    const [pageSeoData, setPageSeoData] = useState({});
    const tableRef = createRef();
    const queries = useMediaQuery('(max-width: 1366px)');
    const getTransactionItems = useSelector((state) => state.getTransactionItems);
    let ls = new SecureLS({ encodingType: 'aes' });
    const getUserAdditionalInfo = ls.get('userInfo').companyAdditionalInfo;
    const { t } = useTranslation('analyticPage');

    const [selectVal, setSelectVal] = useState("K")
    const filteredTransaction = getTransactionItems.loading == false ? getTransactionItems.items.acctTransactList.filter(x => x.acType == selectVal) : [];
    const statusRow = filteredTransaction.length != 0 ? filteredTransaction.filter(x => x.acKey == "Stanje na dan")[0] : {};

    const index = filteredTransaction.length != 0 ? filteredTransaction.indexOf(statusRow) : ""
    index > -1 && filteredTransaction.splice(index, 1); filteredTransaction.splice(0, 0, statusRow)

    useEffect(() => {
        dispatch(getPageSeo(773)).then(res => {
            if (res) {
                setPageSeoData(res);
            }
        });
        dispatch(getTransactions());
        dispatch(globalStatistic(773, 'PAGE'));
    }, [])

    const onChangeSelect = (e) => {
        setSelectVal(e.target.value)
    }

    return (
        <>
            {Object.keys(pageSeoData).length !== 0 &&
                <PageHelmet
                    title={pageSeoData ? `${pageSeoData.acSeoTitle}` : "Ewe Comp"}
                    description={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    keywords={pageSeoData ? `${pageSeoData.acSeoKeywords}` : ""}
                    ogDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    ogTitle={pageSeoData ? `${pageSeoData.acSeoTitle}` : ""}
                    ogUrl={pageSeoData ? `https://ewe.rs/${pageSeoData.acSeoUrl}` : ""}
                    ogImg="https://www.ewe.rs/media/ewe-og-img.jpg"
                    ogType="article"
                    twitterDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                />
            }
            <div className="container-content container-main-content analytic-content top-margin-navbar-escape">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="stats-order-content-holder">
                                <h1>{pageSeoData.acSeoHeader ? pageSeoData.acSeoHeader : 'Analitička kartica'}</h1>
                                {pageSeoData.acSeoShortPageDescription && <p>{pageSeoData.acSeoShortPageDescription}</p>}
                            </div>
                        </div>
                        {/* <div className="col-sm-12">
                        <div className="analytic-box-info-filter">
                            <div className="analytic-box-info-filter-items">
                                <div className="analytic-box-info-filter-item analytic-info-box-footer-red">
                                    Dug van valute: <span>1.250.134,00</span>
                                </div>
                                <div className="analytic-box-info-filter-item analytic-info-box-footer-green">
                                    Ukupan dug: <span>1.250.134,00</span>
                                </div>
                            </div>
                        </div>
                    </div> */}

                        <div className='col-sm-12 analytic-box-top-margin'>
                            <div className="row analytic-box-options">
                                <div className={queries ? " " : "col-md-2 pl-0"}>
                                    <div className={queries ? " " : "col-sm-6 pl-0"}>
                                        <select
                                            className="form-control form-control-sm"
                                            name="acType"
                                            onChange={(e) => onChangeSelect(e)}
                                            value={selectVal}
                                        >
                                            <option value="K">{t('kupac')}</option>
                                            <option value="D">{t('dobavljac')}</option>
                                        </select>
                                    </div>
                                </div>
                                {/* <div className="col-md-6">
                                    <label className="control-label">Tipovi transkacija:</label>
                                    <select className="form-control form-control-sm">
                                        <option value="1">Odaberi...</option>
                                        <option value="1">Option 2</option>
                                        <option value="1">Option 3</option>
                                        <option value="1">Option 4</option>
                                    </select>
                                </div> */}
                                <div className="analytic-box-info-filter">
                                    <div className="analytic-box-info-filter-items">
                                        <div className="analytic-box-info-filter-item analytic-info-box-footer-red">
                                            {t('dugVanValute')} <span>{currencyFormat(getUserAdditionalInfo.anOverDue)}</span>
                                        </div>
                                        <div className="analytic-box-info-filter-item analytic-info-box-footer-green">
                                            {t('ukupanDug')} <span>{currencyFormat(getUserAdditionalInfo.anFinancialState)}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-sm-12">
                            <div className="status-order-table">
                                <div className="editing-cart-items-table">
                                    <MaterialTable
                                        components={{
                                            Pagination: PatchedPagination,
                                            Container: props => <Paper {...props} elevation={0} />,
                                            Toolbar: (props) => (
                                                <div
                                                    style={{
                                                        height: "10px",
                                                    }}
                                                >
                                                    <MTableToolbar {...props} />
                                                </div>
                                            ),
                                        }}
                                        data={filteredTransaction}
                                        localization={{
                                            toolbar: {
                                                searchPlaceholder: '',
                                                searchTooltip: '',
                                            },
                                            pagination: {
                                                labelRowsSelect: '',
                                                firstAriaLabel: 'Prva strana',
                                                firstTooltip: 'Prva strana',
                                                previousAriaLabel: 'Prethodna strana',
                                                previousTooltip: 'Prethodna strana',
                                                nextAriaLabel: 'Sledeća strana',
                                                nextTooltip: 'Sledeća strana',
                                                lastAriaLabel: 'Poslednja strana',
                                                lastTooltip: 'Poslednja strana',
                                            },
                                            header: {
                                                actions: 'Actions'
                                            },
                                            body: {
                                                emptyDataSourceMessage: (
                                                    <div className="loyalty-msg-holder">
                                                        <div className="alert alert-primary" role="alert">
                                                            {t('nemaPodatakaOStatusuTransakcije')}
                                                        </div>
                                                    </div>
                                                ),
                                            },
                                        }}
                                        tableRef={tableRef}
                                        columns={[
                                            {
                                                title: "Nalog",
                                                field: "acKey",
                                            },
                                            {
                                                title: "Datum",
                                                field: "adDate",
                                                render: item => {
                                                    return <Moment format="DD.MM.YYYY.">{item.adDate}</Moment>
                                                }
                                            },
                                            {
                                                title: "Duguje",
                                                field: "anDebit",
                                                render: item => {
                                                    return <CurrencyFormat num={item.anDebit} />;
                                                }
                                            },
                                            {
                                                title: "Potražuje",
                                                field: "anCredit",
                                                render: item => {
                                                    return <CurrencyFormat num={item.anCredit} />;
                                                }
                                            },
                                            {
                                                title: "Saldo",
                                                field: "anSumTotal",
                                                render: item => {
                                                    return <CurrencyFormat num={item.anSumTotal} />;
                                                }
                                            },
                                            {
                                                title: "Datum dokumenta",
                                                field: "adDateDoc",
                                                render: item => {
                                                    return <Moment format="DD.MM.YYYY.">{item.adDateDoc}</Moment>
                                                }
                                            },
                                            {
                                                title: "Dokument",
                                                field: "acDoc",
                                            },
                                        ]}
                                        options={{
                                            filtering: false,
                                            search: false,
                                            draggable: false,
                                            showTitle: false,
                                            sorting: false,
                                            pageSize: 20,
                                            pageSizeOptions: [20, 50, 100],
                                            cellStyle: {
                                                whiteSpace: "nowrap",
                                                padding: "8px"
                                            },
                                            headerStyle: {
                                                padding: "8px",
                                                fontWeight: "bold",
                                                whiteSpace: "nowrap"
                                            },
                                        }}
                                        title=""
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </>
    );
};

export default AnalyticPage;