import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { addNewUser } from '../../redux/actions/userActions';

import MessageInfoBox from '../other/MessageInfoBox';
import { useTranslation } from 'react-i18next';

const ModalAddNewUser = ({ show, handleClose, getCompanyDepartmentList, getRolesList }) => {

    const dispatch = useDispatch();
    const { t } = useTranslation('modal');

    const [values, setValues] = useState({
        acFirstName: "",
        acLastName: "",
        acEmail: "",
        anDepartmentKey: getCompanyDepartmentList[0].anDepartmentKey,
        anRoleKey: 2,
        acJobPosition: "",
        isActive: 1,
        acPassword: "",
        passRep: "",
    })

    const [validPassword, setValidPassword] = useState(0)
    const [validEmail, setValidEmail] = useState(0)
    const [validNewPassword, setValidNewPassword] = useState(0)

    const onChangeFunction = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value })

        if ([e.target.name] == "passRep") {
            if (e.target.value !== values.acPassword) {
                setValidPassword(1)
            } else {
                setValidPassword(2)
            }
        }

        if ([e.target.name] == "acEmail") {
            if (!e.target.value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
                setValidEmail(1)
            } else {
                setValidEmail(2)
            }
        }

        if ([e.target.name] == "acPassword") {
            if (!e.target.value || !e.target.value.replace(/\s/g, '').length) {
                setValidNewPassword(1)
            } else {
                setValidNewPassword(2)
            }
        }
    }

    const sendDataInput = () => {

        values.acPassword == "" && setValidNewPassword(1)
        values.acEmail == "" && setValidEmail(1)
        values.passRep !== values.acPassword && setValidPassword(1)

        if (validEmail == 2 && validPassword == 2 && validNewPassword == 2) {
            dispatch(addNewUser(values));
            handleClose(false);
        } else {
            toast(<MessageInfoBox message='errorReqiuredFormInputs' type={0} />, {
                className: 'red-background',
            });
        }
    }

    return (
        <Modal
            show={show}
            onHide={() => handleClose(false)}
            keyboard={false}
            dialogClassName={'modal-xl'}
            aria-labelledby=""
            centered
        >
            <div className="close-action-modal" variant="secondary" onClick={() => handleClose(false)}>
                <i className="far fa-times-circle"></i>
            </div>
            <Modal.Header>
                <Modal.Title>
                    <h3 className="title-block-profile-data">{t('dodajNovog')}</h3>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="block-profile-data">
                    <div className="block-profile-data-content">
                        <form>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor="acFirstName">{t('ime')}:</label>
                                        <input
                                            id='acFirstName'
                                            name="acFirstName"
                                            type="text"
                                            className="form-control custom-input-check form-control-sm"
                                            value={values.acFirstName}
                                            onChange={(e) => onChangeFunction(e)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="acLastName">{t('prezime')}:</label>
                                        <input
                                            id='acLastName'
                                            name="acLastName"
                                            type="text"
                                            className="form-control custom-input-check form-control-sm"
                                            value={values.acLastName}
                                            onChange={(e) => onChangeFunction(e)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="acEmail">{t('mail')}:</label>
                                        <Form.Control
                                            size="sm"
                                            type="text"
                                            id="acEmail"
                                            name="acEmail"
                                            isInvalid={validEmail == 1}
                                            isValid={validEmail == 2}
                                            value={values.acEmail}
                                            onChange={(e) => onChangeFunction(e)}
                                            autoComplete="off"
                                        />
                                        {validEmail == 1 &&
                                            <div className="invalid-feedback d-block">
                                                {t('adresaNijeValidna')}
                                            </div>
                                        }
                                        {/* <input
                                            id='acEmail'
                                            name="acEmail"
                                            type="text"
                                            className="form-control custom-input-check form-control-sm"
                                            value={values.acEmail}
                                            autoComplete="off"
                                            onChange={(e) => onChangeFunction(e)}
                                        /> */}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="anDepartmentKey">{t('radnja')}:</label>
                                        <select
                                            className="form-control form-control-sm"
                                            name="anDepartmentKey"
                                            id="anDepartmentKey"
                                            onChange={(e) => onChangeFunction(e)}
                                            value={values.anDepartmentKey}
                                        >
                                            {getCompanyDepartmentList.map((item2, key) => (
                                                <option key={key} value={item2.anDepartmentKey}>{item2.acDepartment}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor="anRoleKey">{t('rola')}:</label>
                                        <select
                                            className="form-control form-control-sm"
                                            name="anRoleKey"
                                            id="anRoleKey"
                                            onChange={(e) => onChangeFunction(e)}
                                            value={values.anRoleKey}
                                        >
                                            {getRolesList.map((item2, key) => (
                                                <option key={key} value={item2._id}>{item2.acRole}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="acJobPosition">{t('pozicija')}:</label>
                                        <input
                                            id='acJobPosition'
                                            type="text"
                                            name="acJobPosition"
                                            className="form-control custom-input-check form-control-sm"
                                            value={values.acJobPosition}
                                            onChange={(e) => onChangeFunction(e)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="acPassword">{t('novaLozinka')}:</label>
                                        <Form.Control
                                            size="sm"
                                            type="password"
                                            id="acPassword"
                                            name="acPassword"
                                            isInvalid={validNewPassword == 1}
                                            isValid={validNewPassword == 2}
                                            value={values.acPassword}
                                            onChange={(e) => onChangeFunction(e)}
                                            autoComplete="new-password"
                                        />
                                        {validNewPassword == 1 &&
                                            <div className="invalid-feedback d-block">
                                                {t('obavezno')}
                                            </div>
                                        }
                                        {/* <input
                                            id='acPassword'
                                            name="acPassword"
                                            type="password"
                                            className="form-control custom-input-check form-control-sm"
                                            autoComplete="new-password"
                                            value={values.acPassword}
                                            onChange={(e) => onChangeFunction(e)}
                                        /> */}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="passRep">{t('ponovoUnesiteNovu')}</label>
                                        <Form.Control
                                            size="sm"
                                            type="password"
                                            id="passRep"
                                            name="passRep"
                                            isInvalid={validPassword == 1}
                                            isValid={validPassword == 2}
                                            // autoComplete="off"
                                            onChange={(e) => onChangeFunction(e)}
                                        />
                                        {validPassword == 1 &&
                                            <div className="invalid-feedback d-block">
                                                {t('lozNijeIsta')}
                                            </div>
                                        }
                                    </div>
                                    <div className="form-group">
                                        <label>{t('aktivanKorisnik')}</label>
                                        <div className="delivery-form-buttons-payment">
                                            <div className="form-check form-check-inline">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="isActive"
                                                    id="inlineRadio1"
                                                    value={1}
                                                    defaultChecked={values.isActive == 1}
                                                    onClick={(e) => onChangeFunction(e)}
                                                />
                                                <label className="form-check-label" htmlFor="inlineRadio1">{t('da')}</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="isActive"
                                                    id="inlineRadio2"
                                                    value={0}
                                                    onClick={(e) => onChangeFunction(e)}
                                                />
                                                <label className="form-check-label" htmlFor="inlineRadio2">{t('ne')}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-success" onClick={sendDataInput}>{t('potvrdi')}</button>
                <button className="btn btn-secondary" onClick={() => handleClose(false)}>{t('zatvori')}</button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalAddNewUser