import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SecureLS from 'secure-ls';

const MenuLoggedUserInfo = ({ sidebar, userInfo, effectCompanyInfoFun }) => {
    const iconPath = process.env.PUBLIC_URL + '/media/';
    const changeEffectCompanyInfoFun = () => {
        effectCompanyInfoFun();
    }
    let ls = new SecureLS({ encodingType: 'aes' });
    var isLoggedIn = ls.get('userInfo');
    const { t } = useTranslation('menu');


    return (
        <div className={`sidebar-info-user ${sidebar ? 'active-sidebar-image-info' : ''} ${isLoggedIn && 'sidebar-info-user-content'}`}>
            <div className="sidebar-user-info-holder-upper margin-bottom-20">
                {isLoggedIn ? (
                    <>

                        {!sidebar && (
                            <div className="info-user-detail-sidebar">
                                <div className="user-detail-info-separator"></div>
                                <div className="info-user-detail-label">{t('firma')}:</div>
                                <div className="user-detail-info-text">
                                    <Link to={'/profil'}>
                                        {userInfo?.userInfo?.company?.acCompany || ''}
                                    </Link>
                                </div>
                                <div className="user-detail-info-separator"></div>
                                <div className="info-user-detail-label">{t('korisnik')}:</div>
                                <div className="user-detail-info-text">
                                    <Link to={'/profil'}>
                                        {userInfo?.userInfo?.company?.department?.[0]?.user?.[0]?.acEmail || ''}
                                    </Link>
                                </div>

                                {/* {userEmails.length > 1 ? (
                                    <>
                                        <div className="info-user-detail-label">Email lista:</div>
                                        {userEmails.map((value, index) => (
                                            <div className="user-detail-info-text">{value}</div>
                                        ))}
                                    </>
                                ) : (
                                    <>
                                        <div className="info-user-detail-label">Korisnik:</div>
                                        <div className="user-detail-info-text">{userInfo.userInfo.company.acCompanyMail1}</div>
                                    </>
                                )

                                } */}

                            </div>
                        )}
                    </>
                ) : (
                    ''
                )}
            </div>
            <div className="sidebar-user-info-holder">
                {isLoggedIn ? (
                    <>
                        {userInfo.userInfo.company.clerkResponsible['0'].acPicture == '' ? (
                            <div
                                className="info-image-sidebar"
                                onMouseEnter={changeEffectCompanyInfoFun}
                                onMouseLeave={changeEffectCompanyInfoFun}
                                style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/no-user-image.png'})` }}
                            ></div>
                        ) : (
                            <div
                                className="info-image-sidebar"
                                onMouseEnter={changeEffectCompanyInfoFun}
                                onMouseLeave={changeEffectCompanyInfoFun}
                                style={{ backgroundImage: `url(https://resource.ewe.rs/images/hr/avatars/${userInfo.userInfo.company.clerkResponsible['0'].acPicture})` }}
                            ></div>
                        )}
                        {!sidebar && (
                            <div className="info-user-detail-sidebar user-info-top-border">
                                <div className="">{userInfo.userInfo.company.clerkResponsible['0'].acJobPosition}</div>
                                <div className="loggedin-username">{userInfo.userInfo.company.clerkResponsible['0'].acFirstName} {userInfo.userInfo.company.clerkResponsible['0'].acLastName}</div>
                                <div className="social-contact-user-box">

                                    {userInfo.userInfo.company.clerkResponsible['0'].acPhone1 && (
                                        <a href={`tel:${userInfo.userInfo.company.clerkResponsible['0'].acPhone1}`}>
                                            <div className="social-contact-user-box-item" title={userInfo.userInfo.company.clerkResponsible['0'].acPhone1}>
                                                {<img
                                                    src={`${iconPath}icon-korisnik-kontakt.svg`}
                                                    alt="kontakt-telefon-icon"
                                                />}
                                            </div>
                                        </a>
                                    )}
                                    {userInfo.userInfo.company.clerkResponsible['0'].acPhone2 && (
                                        <a href={`tel:${userInfo.userInfo.company.clerkResponsible['0'].acPhone2}`}>
                                            <div className="social-contact-user-box-item" title={userInfo.userInfo.company.clerkResponsible['0'].acPhone2}>
                                                {<img
                                                    src={`${iconPath}icon-korisnik-kontakt.svg`}
                                                    alt="korisnik-kontakt-icon"
                                                />}
                                            </div>
                                        </a>
                                    )}

                                    <a href={`mailto:${userInfo.userInfo.company.clerkResponsible['0'].acEmail}`} title={userInfo.userInfo.company.clerkResponsible['0'].acEmail}>
                                        <div className="social-contact-user-box-item">
                                            {<img
                                                src={`${iconPath}icon-korisnik-mejl.svg`}
                                                alt="korisnik-mejl-icon"
                                            />}
                                        </div>
                                    </a>
                                    {userInfo.userInfo.company.clerkResponsible['0'].acSkype && (
                                        <a href={`skype:${userInfo.userInfo.company.clerkResponsible['0'].acSkype}?chat`}>
                                            <div className="social-contact-user-box-item">
                                                <i className="fab fa-skype" style={{ color: '#0078d4' }}></i>
                                            </div>
                                        </a>
                                    )}
                                </div>
                            </div>
                        )}
                    </>
                ) : (
                    ''
                )}
            </div>
        </div>
    )
}

export default MenuLoggedUserInfo;
