import React from 'react';
import Countdown from 'react-countdown';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { subscribeToAuction, setAuctionItemBidFunction } from '../../redux/actions/productActions'

import CurrencyFormat from '../other/CurrencyFormat';

import { removeSpecialChar } from '../../helperFunctions'
import { useTranslation } from 'react-i18next';

const AuctionItem = ({ item, setDetailItemFunction }) => {

    const dispatch = useDispatch();
    const today = new Date();
    today.setDate(today.getDate() + 1);
    today.setHours(0, 0, 0);
    const { t } = useTranslation(['other', 'routes']);

    const ttoday = today.getTime();

    // R  andom component
    // const Completionist = () => <span>Vreme za licitaciju je isteklo!</span>;
    // const Completionist2 = () => <span>Vreme za licitaciju je isteklo!</span>;

    // Renderer callback with condition
    // const renderer = ({ days, hours, minutes, seconds, completed }) => {
    //     if (completed) {
    //         // Render a completed state
    //         return <Completionist />;
    //     } else {
    //         // Render a countdown
    //         return (
    //             <>
    //                 <div>{days < 10 ? '0' + days : days}</div>:<div>{hours < 10 ? '0' + hours : hours}</div>:<div>{minutes < 10 ? '0' + minutes : minutes}</div>:<div>{seconds < 10 ? '0' + seconds : seconds}</div>
    //             </>
    //         )
    //     }
    // };

    var d = new Date();
    //minuti
    if (d.getMinutes() < 10) {
        var minutes = '0' + d.getMinutes();
    } else {
        var minutes = d.getMinutes();
    }
    var month = d.getMonth() + 1;
    //dani
    if (d.getMonth() + 1 < 10) {
        let sumMonth = d.getMonth() + 1;
        month = '0' + sumMonth;
    }

    const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };

    var currentDate = d.getFullYear() + '-' + month + '-' + d.getDate() + ' ' + d.getHours() + ":" + minutes + ":" + d.getSeconds();
    var startDate = item.adDateFrom
    var endDate = item.adDateTo

    currentDate = new Date(currentDate)
    startDate = new Date(startDate)
    endDate = new Date(endDate)

    const trackAuction = () => {
        dispatch(subscribeToAuction(item));
    }

    let bidValue = item.anCurentPrice + item.anPace;
    const fastBid = () => {
        dispatch(setAuctionItemBidFunction(item, bidValue));
    }

    return (
        <div className="auction-content-item">
            <Link to={`/${t('routes:proizvod')}/${item.anProductKey}/${removeSpecialChar(item.acProductName)}`} title={t('other:pogledajteDetaljeArtikala')}>
                <div className="auticon-item-title">
                    <div className="title-action-text">{item.acProduct} - {item.acDept} {item.acProductName}</div>
                </div>
            </Link>
            <Link to={`/${t('routes:proizvod')}/${item.anProductKey}/${removeSpecialChar(item.acProductName)}`} title={t('other:pogledajteDetaljeArtikala')}>
                <div className="auticon-item-image">
                    <img alt="" src={`${item.acImage}`} />
                </div>
            </Link>
            <div className="auction-info-block" onClick={() => setDetailItemFunction(1, item)}>
                <div className="auticon-item-price">
                    <div className="auction-price-title">{t('other:trenutnaCena')}</div>
                    <div className="auction-price-value"><CurrencyFormat num={item.anCurentPrice} />€</div>
                </div>
                <div className="auticon-item-next-price">
                    <div className="auction-price-next-offer">{t('other:sledecaMinimalna')}<CurrencyFormat num={item.anCurentPrice + item.anPace} />€</div>
                </div>
                <div className="auticon-item-time">
                    <div className="auction-time-title">{currentDate <= endDate && currentDate >= startDate && t('other:preosaloVrZaLic')}{currentDate <= startDate && t('other:vremeZaLic')}</div>
                    <div className="auction-time-value">
                        <div className="timer-item">
                            {currentDate <= endDate && currentDate >= startDate && (
                                <Countdown
                                    date={item.adDateTo}
                                />
                            )}
                            {currentDate <= startDate && (
                                <Countdown
                                    date={item.adDateFrom}
                                />
                            )}
                            {currentDate >= startDate && currentDate >= endDate && (
                                <span>{t('other:vremeZaLicitacijuIsteklo')}</span>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {currentDate <= endDate && currentDate >= startDate && (
                <div className="auticon-item-buttons">
                    <div className="button-bid-list" title={`Izborom brze licitacije dajete prvu sledeću minimalnu ponudu. Sledeća minimalna ponuda je: ${bidValue.toFixed(2).replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}€`} onClick={fastBid}>{t('other:brzoLicitiraj')}</div>
                    <div className="button-bid-quote" onClick={() => setDetailItemFunction(1, item)}>{t('other:licitiraj')}</div>
                </div>
            )}
            {currentDate <= startDate && (
                <div className="auticon-item-buttons">
                    <div className="button-bid-quote" onClick={trackAuction}>{t('other:pratiAukciju')}</div>
                </div>
            )}
        </div>
    )
}

export default AuctionItem;