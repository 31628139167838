import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import axios from 'axios';
import { axiosinstance } from '../configAxios';
// import { toast } from 'react-toastify';

// import FormInput from '../components/form/FormInput';
// import MessageInfoBox from '../components/other/MessageInfoBox';
import PageBanner from '../components/banner/PageBanner';
import PageHelmet from '../components/helmet/PageHelmet';
// import globalVariable from '../global';

import { useDispatch } from 'react-redux';
import { globalStatistic } from '../redux/actions/otherActions';
import { getPageSeo } from '../redux/actions/pageActions';
import { useTranslation } from 'react-i18next';

const WePresentPage = () => {

    const [presentation, setPresentation] = useState([]);
    //const [presentationByCat, setPresentationByCat] = useState([]);
    const dispatch = useDispatch();
    const [pageSeoData, setPageSeoData] = useState({});
    const { t } = useTranslation(['wePresentPage', 'routes']);
    const getPresentationList = async () => {
        const attributes = {};
        attributes['isGroup'] = 0;
        const configRes = axiosinstance(attributes, "8224", "presentationList");

        await axios(configRes)
            .then(function (response) {
                setPresentation(response.data.response.tpList);
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    useEffect(() => {
        dispatch(getPageSeo(548)).then(res => {
            if (res) {
                setPageSeoData(res);
            }
        });
        getPresentationList();
        dispatch(globalStatistic(548, 'PAGE'));
    }, []);

    if (!presentation) {
        return null;
    }
    //var result = [];
    // var result = presentation.reduce(function (r, a) {
    //     r[a.acCategoryName] = r[a.acCategoryName] || [];
    //     r[a.acCategoryName].push(a);
    //     return r;
    // }, Object.create(null));

    // //setPresentationByCat(result);
    // console.log(result);

    const groupByCategory = presentation.reduce((group, product) => {
        const { acCategoryName } = product;
        group[acCategoryName] = group[acCategoryName] ?? [];
        group[acCategoryName].push(product);
        return group;
    }, {});
    // console.log(groupByCategory);

    return (
        <>
            {Object.keys(pageSeoData).length !== 0 &&
                <PageHelmet
                    title={pageSeoData ? `${pageSeoData.acSeoTitle}` : "Ewe Comp"}
                    description={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    keywords={pageSeoData ? `${pageSeoData.acSeoKeywords}` : ""}
                    ogDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    ogTitle={pageSeoData ? `${pageSeoData.acSeoTitle}` : ""}
                    ogUrl={pageSeoData ? `https://ewe.rs/${pageSeoData.acSeoUrl}` : ""}
                    ogImg="https://www.ewe.rs/media/ewe-og-img.jpg"
                    twitterDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                />
            }
            <div className="container-content container-catalog-content contact-page-content">
                <div className="container-fluid-custom">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="banner-image-on-top-page">
                                <div className="custom-banner-element-holder">
                                    <PageBanner categoryType="" categoryId="" pageId="548" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="contact-title">
                                <h1>{t('naslov')}</h1>
                            </div>

                            <div className="contact-desc">
                                <p>
                                    {t('pragraf1')}
                                </p>
                                <p>
                                    {t('paragraf2')}
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* <div className="row">
                        <div className="col-sm-12">
                            <div className="row">
                                {presentation.map((item, index) => {
                                    return (
                                        <div className="col-sm-3">
                                            <div className="slider-logout-page-item">
                                                <div className="slider-logout-page-item-image">
                                                    <img src={`https://www.ewe.rs/media/${item.acImage}`} className="img-fluid" alt={item.acPageName} />
                                                </div>
                                                <div className="slider-logout-page-item-body">
                                                    <Link
                                                        to={`/stranica/${item.anPageId}/${item.acTitle.replace(/\s+/g, '-').toLowerCase()}`}
                                                    >
                                                        <h3>{item.acTitle}</h3>
                                                    </Link>
                                                    <div className="slider-logout-page-item-link">
                                                        Link ka proizvodima
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                                {Object.values(groupByCategory).map((item, key) => {
                                    return (
                                        <>
                                            <div className="col-sm-12">
                                                <h2 className="presentation-page-title">{item[0].acCategoryName}</h2>
                                            </div>
                                            {item.map((item2, index) => {
                                                return (
                                                    <div className="col-sm-6 col-md-4 col-lg-3">
                                                        <div className="we-present-page-item">
                                                            <div className="we-present-page-item-image">
                                                                <img src={`https://resource.ewe.rs/media/${item2.acImage}`} className="img-fluid" alt={item2.acPageName} />
                                                            </div>
                                                            <div className="we-present-page-item-body">
                                                                <Link
                                                                    to={`/stranica/${item2.anPageId}/${item2.acTitle.replace(/\s+/g, '-').toLowerCase()}`}
                                                                >
                                                                    <h3>{item2.acTitle}</h3>
                                                                </Link>

                                                                <div className="we-present-page-divider">

                                                                </div>
                                                                <div className="we-present-page-item-link">
                                                                    Link ka proizvodima
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </>
                                    )
                                })}
                            </div>
                        </div>
                    </div> */}
                    <div className="row">
                        {Object.values(groupByCategory).map((item, key) => {
                            return (
                                <>
                                    <div className="col-sm-12">
                                        <h2 className="presentation-page-title">{item[0].acCategoryName}</h2>
                                    </div>
                                    <div className="we-present-page-item-holder">
                                        {item.map((item2, index) => {
                                            return (

                                                <div className="we-present-page-item">
                                                    <div className="we-present-page-item-image">
                                                        <img src={`https://resource.ewe.rs/media/${item2.acImage}`} className="img-fluid" alt={item2.acPageName} />
                                                    </div>
                                                    <div className="we-present-page-item-body">
                                                        <Link
                                                            to={`/${t('routes:stranica')}/${item2.anPageId}/${item2.acTitle.replace(/\s+/g, '-').replace("/", '-').toLowerCase()}`}
                                                        >
                                                            <h3>{item2.acTitle}</h3>
                                                        </Link>

                                                        <div className="we-present-page-divider">

                                                        </div>
                                                        <div className="we-present-page-item-link">
                                                            <Link
                                                                to={`/${t('routes:artikli')}?idents=${item2.acProducts}`}
                                                            >
                                                                {t('pogledajProizvode')}
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </>
                            )
                        })}
                    </div>
                </div>
            </div >
        </>
    );
};

export default WePresentPage;