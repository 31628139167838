import React from 'react';
import Slider from "react-slick";
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import BlockBannerItem from './BlockBannerItem';

const BlockBanner = () => {

    const settingsSlider = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        adaptiveHeight: false,
        slidesToScroll: 1,
        prevArrow: '',
        nextArrow: '',
        autoplay: true,
        autoplaySpeed: 5000,
    };

    const history = useHistory();
    const getUserBanner = useSelector((state) => state.getUserBanner.items);
    const bennerListItems = getUserBanner.filter(x => x.anBannerPosition === 1);
    const bennerList = bennerListItems.filter(x => x.anPageKey === 251);


    return (
        <div className="big-banner-block">
            <div className="content-slider-special-action-banner">
                <Slider {...settingsSlider}>
                    {bennerList.length > 0 && bennerList.map((item, key) => (
                        <BlockBannerItem item={item} key={key} />
                        // <div className="banner-block-home-item" key={key}>
                        //     <img src={process.env.PUBLIC_URL + '/media/banners/1438x500.jpg'} className="slider-image-home-block" alt="b2b" />
                        // </div>
                    ))}
                    {bennerList.length == 0 && (
                        <div className="banner-block-home-item">
                            <img src={'https://resource.ewe.rs/media/banners/no-image-placeholder.jpg'} className="slider-image-home-block" alt="Placeholder" title="Image placeholder" />
                        </div>
                    )}
                </Slider>
            </div>
        </div>
    );
};

export default BlockBanner;