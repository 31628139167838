import { ActionTypes } from "../contants/action-types";

const initialState = {
    products: [],
};
const compareItemsState = {
    compareItems: [],
};
const initialStateSearch = {
    products: [],
};

// export const compareReducer = (state = { compareItems: [] }, action) => {
//     switch (action.type) {
//         case "PRODUCT_ADD_COMPARE":
//             const item = action.payload;
//             return {
//                 ...state,
//                 compareItems: [...state.compareItems, item],
//             };
//         case "PRODUCT_REMOVE_COMPARE":
//             return {
//                 ...state,
//                 compareItems: state.compareItems.filter((x) => x._id !== action.payload._id),
//             };

//         case "PRODUCT_REMOVE_COMPARE_ALL":
//             return compareItemsState;
//         default:
//             return state;
//     }
// };
export const compareReducer = (state = { compareItems: [] }, action) => {
    switch (action.type) {
        case "PRODUCT_ADD_COMPARE":
            const item = action.payload;
            return {
                ...state,
                compareItems: [...state.compareItems, item],
            };
        case "PRODUCT_REMOVE_COMPARE":
            return {
                ...state,
                compareItems: state.compareItems.filter((x) => x.anProductKey !== action.payload.anProductKey),
            };

        case "PRODUCT_REMOVE_COMPARE_ALL":
            return compareItemsState; // Ovde bi trebalo da vratite stanje bez stavki za poređenje
        default:
            return state;
    }
};

export const compareReducerSpecifications = (state = [], action) => {
    if (action.type === 'SET_PRODUCT_SPECIFICATION_COMPARE') {
        const item = action.payload;


        return [...state, item]

    }
    return state;
};

export const selectedProduct = (state = {}, action) => {
    switch (action.type) {
        case ActionTypes.SELECTED_PRODUCT:
            return { ...state, ...action.payload };
        case ActionTypes.REMOVE_SELECTED_PRODUCT:
            return {};
        default:
            return state;
    }
};

export const setCatalogList = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SET_CATALOG_LIST:
            return { ...state, products: action.payload };
        case ActionTypes.REMOVE_CATALOG_LIST:
            return initialState;
        default:
            return state;
    }
};

export const setSearchProducts = (state = { items: [] }, action) => {
    switch (action.type) {
        case "SET_SEARCH_PRODUCTS":
            return {
                items: action.payload,
                loading: false
            }
        case "SET_SEARCH_PRODUCTS_REQUEST":
            return {
                loading: true,
                items: []
            }
        default:
            return state;
    }
};

export const getWishList = (state = { items: [] }, action) => {
    switch (action.type) {
        case 'SET_WISH_LIST':
            return {
                items: action.payload
            };
        case 'REMOVE_FROM_WISH_LIST':
            return {
                ...state,
                items: state.items.filter(x => x.anProductKey !== action.payload.anProductKey)
            };
        case 'REMOVE_FROM_WISH_LIST_ALL':
            return {
                ...state,
                items: []
            };
        default:
            return state;
    }
};

export const catalogProducts = (state = { items: [] }, action) => {
    switch (action.type) {
        case "SET_CATALOG_ITEMS_REQUEST":
            return {
                loading: true,
                items: [],
            };
        case "SET_CATALOG_ITEMS":
            return {
                loading: false,
                items: action.payload,
            };
        default:
            return state;
    }
};

export const getProductDetail = (state = { items: [] }, action) => {
    switch (action.type) {
        case "SET_PRODUCT_DETAIL_REQUEST":
            return {
                loading: true,
                items: [],
            };
        case "SET_PRODUCT_DETAIL":
            return {
                loading: false,
                items: action.payload,
            };
        // case "SET_PRODUCT_DETAIL":
        //     return {
        //         loading: false,
        //         items: action.payload,
        //     };

        default:
            return state;
    }
};

export const relatedCategoryProducts = (state = [], action) => {
    switch (action.type) {
        case "SET_RELATED_CATEGORY_ITEMS":
            return action.payload;

        default:
            return state;
    }
};

export const catalogBannerProduct = (state = [], action) => {
    switch (action.type) {
        case "SET_BANNER_IDENT":
            return action.payload;
        default:
            return state;
    }
};

export const catalogDefaultFilters = (state = "", action) => {
    switch (action.type) {
        case "SET_DEFAULT_URL_FILTERS":
            return action.payload;
        default:
            return state;
    }
};

export const finishedLoadingFilters = (state = false, action) => {
    switch (action.type) {
        case "SET_FINISHED_FILTERS":
            return action.payload;
        default:
            return state;
    }
};

export const catalogFilters = (state = [], action) => {
    switch (action.type) {
        case "SET_FILTERS":
            return action.payload;
        default:
            return state;
    }
};
export const catalogFiltersBrand = (state = [], action) => {
    switch (action.type) {
        case "SET_FILTERS_BRANDS":
            return action.payload;
        default:
            return state;
    }
};
export const catalogFiltersSpecialAction = (state = [], action) => {
    switch (action.type) {
        case "SET_FILTERS_SPECIAL_ACTION":
            return action.payload;
        default:
            return state;
    }
};
export const catalogFiltersPrice = (state = {}, action) => {
    switch (action.type) {
        case "SET_FILTERS_PRICE":
            return {
                anPriceFilterMax: action.payload.anPriceFilterMax,
                anPriceFilterMin: action.payload.anPriceFilterMin,
            };
        default:
            return state;
    }
};

export const getSearchItemsBox = (state = { categoryList: [], brendList: [], productList: [], loading: [false] }, action) => {
    switch (action.type) {
        case "SEARCH_LIST_AUTOCOMPLETE_CLEAR":
            return {
                categoryList: [],
                brendList: [],
                productList: [],
                searchList: [],
                loading: false
            };
        case "SEARCH_LIST_AUTOCOMPLETE_REQUEST":
            return {
                categoryList: [],
                brendList: [],
                productList: [],
                searchList: [],
                loading: action.payload.loading
            };
        case "SEARCH_LIST_AUTOCOMPLETE":
            return {
                categoryList: action.payload.data.categoryList,
                brendList: action.payload.data.brendList,
                productList: action.payload.data.productList,
                searchList: action.payload.data.searchList,
                loading: action.payload.loading
            };
        default:
            return state;
    }
};

export const getProductSpecification = (state = [], action) => {
    switch (action.type) {
        case "SET_PRODUCT_SPECIFICATION":
            return action.payload;
        default:
            return state;
    }
};

export const setFilterForFiltering = (state = { attributes: [], brands: [] }, action) => {
    switch (action.type) {
        case "SET_FILTER_FOR_FILTERING_ATTRIBUTES":
            var item = action.payload;
            return {
                ...state,
                attributes: [...state.attributes, item],
            };
        case "REMOVE_FILTER_FOR_FILTERING_ATTRIBUTES":
            var item = action.payload;
            return {
                ...state,
                attributes: state.attributes.filter((x) => x.anAttributeValue !== item),
            };
        case "SET_FILTER_FOR_FILTERING_BRANDS":
            var item = action.payload;
            return { ...state, brands: [...state.brands, item] };
        default:
            return state;
    }
};

export const getProductRelatedSale = (state = [], action) => {
    switch (action.type) {
        case "GET_RELATED_SALE":
            return action.payload;
        default:
            return state;
    }
};

export const getSearchBrands = (state = [], action) => {
    switch (action.type) {
        case "SET_SEARCH_BRANDS":
            return action.payload;
        default:
            return state;
    }
};

export const getSearchCategories = (state = [], action) => {
    switch (action.type) {
        case "SET_SEARCH_CATEGORIES":
            return action.payload;
        default:
            return state;
    }
};


export const getCustomPdfOffer = (state = [], action) => {
    switch (action.type) {
        case "SET_CUSTOM_PDF":
            return action.payload;
        default:
            return state;
    }
};

export const getLoyaltyProducts = (state = [], action) => {
    switch (action.type) {
        case "SET_LOYALTY_PRODUCTS":
            return {
                items: action.payload.companyLoyalityShop,
                isSaleConnected: action.payload.isSaleConnected
            };
        default:
            return state;
    }
};

export const weeklyProducts = (state = { items: [] }, action) => {
    switch (action.type) {
        case "SET_WEEKLY_ITEMS_REQUEST":
            return {
                loading: true,
                items: [],
            };
        case "SET_WEEKLY_ITEMS":
            return {
                loading: false,
                items: action.payload,
            };
        default:
            return state;
    }
};

export const inStockAgainProducts = (state = { items: [] }, action) => {
    switch (action.type) {
        case "SET_STOCK_AGAIN_ITEMS":
            return {
                loading: false,
                items: action.payload,
            };
        default:
            return state;
    }
};

export const recommendedProducts = (state = { items: [] }, action) => {
    switch (action.type) {
        case "SET_RECOMMENDED_ITEMS":
            return {
                loading: false,
                items: action.payload,
            };
        default:
            return state;
    }
};


export const setAuctionProducts = (state = [], action) => {
    switch (action.type) {
        case "SET_AUCTION_PRODUCTS":
            return action.payload;
        default:
            return state;
    }
};

export const setActionProducts = (state = { items: [] }, action) => {
    switch (action.type) {
        case "SET_SPECIAL_ACTION_PRODUCTS":
            return {
                items: action.payload,
                loading: false
            }
        default:
            return state;
    }
};

export const setLastProductsList = (state = { items: [] }, action) => {
    switch (action.type) {
        case "SET_LAST_PRODUCTS":
            return {
                items: action.payload,
                loading: false
            }
        default:
            return state;
    }
};
