import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import { toast } from 'react-toastify';

import { getDeliveryCity } from '../redux/actions/cartActions';

import FormInput from '../components/form/FormInput';
import MessageInfoBox from '../components/other/MessageInfoBox';
import PageBanner from '../components/banner/PageBanner';
import PageHelmet from '../components/helmet/PageHelmet';
import { globalStatistic } from '../redux/actions/otherActions';
import { getPageSeo } from '../redux/actions/pageActions';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';

import styles from '../css/RegistrationPage.module.css';
const RegistrationPage = () => {

    const dispatch = useDispatch();
    const [pageSeoData, setPageSeoData] = useState({});
    const deliveryCityList = useSelector((state) => state.getDeliveryCity.items);
    const { t } = useTranslation('registrationPage');
    const [captchaValue, setCaptchaValue] = useState(null);
    const currentLanguage = document.documentElement.lang ? document.documentElement.lang : 'en';

    const [values, setValues] = useState({
        email: "",
        company: "",
        phone1: "",
        phone2: "",
        address: "",
        city: "",
        taxId: "",
        companyId: "",
        contactName: "",
        contactPosition: "",
        deliveryAddress: "",
        subjects: "",
        companyField: "",
        companySpace: "",
        merch: "",
        others: "",
        account: 1,
        xml: 1,
        newsletter: 0,
    });

    const [errors, setErrors] = useState({
        email: 1,
        company: 1,
        phone1: 1,
        taxId: 1,
        companyId: 1,
        contactName: 1,
    });

    const [validInput, setValidInput] = useState({
        email: 0,
        company: 0,
        phone1: 0,
        taxId: 0,
        companyId: 0,
        contactName: 0,
    })

    const onChangeFunction = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    };

    const setErrorsFunction = (name, val) => {
        setErrors({ ...errors, [name]: val });
    };

    const handleSubmit = (e) => {

        if (!captchaValue) {
            toast(<MessageInfoBox message='errorCaptcha' type={0} />, {
                className: 'red-background',
            });
            return;
        }

        setValidInput({
            ...validInput,
            email: values.email == '' && 1,
            company: values.company == '' && 1,
            phone1: values.phone1 == '' && 1,
            taxId: values.taxId == '' && 1,
            companyId: values.companyId == '' && 1,
            contactName: values.contactName == '' && 1,
        });

        e.preventDefault();

        if (errors.email == 0 && errors.company == 0 && errors.phone1 == 0 && errors.taxId == 0 && errors.companyId == 0 && errors.contactName == 0) {

            //slanje email-a
            axios({
                method: "POST",
                url: "https://newb2b.ewe.rs:5000/registration",
                data: {
                    values: values,
                }
            }).then((response) => {

                if (response.data.isOk == 1) {

                    toast(<MessageInfoBox message={response.data.acMsg} type={response.data.isOk} />, {
                        className: response.data.isOk == 1 ? 'green-background' : 'red-background',
                    });

                    //resetovanje inputa i provera obaveznih polja
                    setValidInput({
                        email: 0,
                        company: 0,
                        phone1: 0,
                        taxId: 0,
                        companyId: 0,
                        contactName: 0,
                        contactPosition: 0,
                    });

                    setErrors({
                        email: 1,
                        company: 1,
                        phone1: 1,
                        taxId: 1,
                        companyId: 1,
                        contactName: 1,
                    })

                    setValues({
                        email: "",
                        company: "",
                        phone1: "",
                        phone2: "",
                        address: "",
                        city: "",
                        taxId: "",
                        companyId: "",
                        contactName: "",
                        contactPosition: "",
                        deliveryAddress: "",
                        subjects: "",
                        companyField: "",
                        companySpace: "",
                        merch: "",
                        others: "",
                        account: 1,
                        xml: 1,
                        newsletter: 0,
                    });

                }
            });



        } else {
            toast(<MessageInfoBox message='errorReqiuredFormInputs' type={0} />, {
                className: 'red-background',
            });
        }

    };

    const itemsCities = useMemo(
        () =>
            deliveryCityList.map((item) => ({
                value: item._id,
                id: item.acPostCode,
                label: item.acPostCode + ' ' + item.acCity,
            })),
        [deliveryCityList]
    );

    const cityChangeFunction = (e, val) => {
        setValues({ ...values, city: val.label });
    }

    useEffect(() => {
        dispatch(getPageSeo(769)).then(res => {
            if (res) {
                setPageSeoData(res);
            }
        });
        dispatch(getDeliveryCity());
        dispatch(globalStatistic(769, 'PAGE'));
    }, []);

    const handleCaptchaChange = (value) => {
        setCaptchaValue(value);
        console.log("Captcha value:", value);
    };

    return (
        <>
            {Object.keys(pageSeoData).length !== 0 &&
                <PageHelmet
                    title={pageSeoData ? `${pageSeoData.acSeoTitle}` : "Ewe Comp"}
                    description={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    keywords={pageSeoData ? `${pageSeoData.acSeoKeywords}` : ""}
                    ogDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    ogTitle={pageSeoData ? `${pageSeoData.acSeoTitle}` : ""}
                    ogUrl={pageSeoData ? `https://ewe.rs/${pageSeoData.acSeoUrl}` : ""}
                    ogImg="https://www.ewe.rs/media/ewe-og-img.jpg"
                    twitterDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                />
            }
            <div className='container-fluid container-catalog-content container-registration-content'>
                <div className='row'>
                    <div className='col-sm-12 text-center banner-image-on-top-page'>
                        <PageBanner categoryType="" categoryId="" pageId="349" />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-sm-12'>
                        <h1 style={{ marginBottom: "15px", marginTop: "15px" }}>{pageSeoData.acSeoHeader ? pageSeoData.acSeoHeader : 'Postanite partner'}</h1>
                        {pageSeoData.acSeoShortPageDescription && <p>{pageSeoData.acSeoShortPageDescription}</p>}
                        <blockquote style={{ padding: "10px 20px", margin: "0 0 20px", fontSize: "17.5px", borderLeft: "5px solid #eee" }}>{t('paragraf1')}</blockquote>
                    </div>
                </div>
                <div className='delivery-form-info' style={{ padding: "30px" }}>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='row'>

                                <div className="form-group col-sm-12" style={{ position: "relative" }}>
                                    <FormInput
                                        type="text"
                                        name="email"
                                        placeholder={t('placeholder1')}
                                        label={t('label1')}
                                        isRequired="1"
                                        nameClass="custom-input-check"
                                        messageError={t('error1')}
                                        value={values['email']}
                                        onChange={(e) => onChangeFunction(e)}
                                        validInputValue={validInput.email}
                                        setErrorsFunc={setErrorsFunction}
                                    />
                                </div>

                                <div className="form-group col-sm-12" style={{ position: "relative" }}>
                                    <FormInput
                                        type="text"
                                        name="company"
                                        placeholder={t('placeholder2')}
                                        label={t('label2')}
                                        isRequired="1"
                                        nameClass="custom-input-check"
                                        messageError={t('error2')}
                                        value={values['company']}
                                        onChange={(e) => onChangeFunction(e)}
                                        validInputValue={validInput.company}
                                        setErrorsFunc={setErrorsFunction}
                                    />
                                </div>

                                <div className="form-group col-sm-6" style={{ position: "relative" }}>
                                    <FormInput
                                        type="number"
                                        name="phone1"
                                        placeholder={t('placeholder3')}
                                        label={t('label3')}
                                        isRequired="1"
                                        nameClass="custom-input-check"
                                        messageError={t('error2')}
                                        value={values['phone1']}
                                        onChange={(e) => onChangeFunction(e)}
                                        validInputValue={validInput.phone1}
                                        setErrorsFunc={setErrorsFunction}
                                    />
                                </div>
                                <div className="form-group col-sm-6" style={{ position: "relative" }}>
                                    <label htmlFor="phone2">{t('label4')}</label>
                                    <input
                                        type="number"
                                        name="phone2"
                                        id="phone2"
                                        placeholder={t('placeholder4')}
                                        className="form-control"
                                        value={values['phone2']}
                                        onChange={(e) => onChangeFunction(e)}
                                    />
                                </div>
                                <div className="form-group col-sm-6" style={{ position: "relative" }}>
                                    <label htmlFor="address">{t('label5')}</label>
                                    <input
                                        type="text"
                                        name="address"
                                        id="address"
                                        placeholder={t('placeholder5')}
                                        className="form-control"
                                        value={values['address']}
                                        onChange={(e) => onChangeFunction(e)}
                                    />
                                </div>
                                <div className="form-group col-sm-6" style={{ position: "relative" }}>
                                    <label htmlFor="autocomplete-custom-input">{t('label6')}</label>
                                    <Autocomplete
                                        id="autocomplete-custom-input"
                                        options={itemsCities}
                                        onChange={(event, val) => {
                                            cityChangeFunction(event, val)
                                        }}
                                        renderInput={(params) => (
                                            <div ref={params.InputProps.ref}>
                                                <input type="text" className="form-control" name="city" {...params.inputProps} placeholder={t('placeholder6')} />
                                            </div>
                                        )}
                                    />
                                </div>
                                <div className="form-group col-sm-6" style={{ position: "relative" }}>
                                    <FormInput
                                        type="number"
                                        name="taxId"
                                        placeholder={t('placeholder7')}
                                        label={t('label7')}
                                        isRequired="1"
                                        nameClass="custom-input-check"
                                        messageError={t('error2')}
                                        value={values['taxId']}
                                        onChange={(e) => onChangeFunction(e)}
                                        validInputValue={validInput.taxId}
                                        setErrorsFunc={setErrorsFunction}
                                    />
                                </div>
                                <div className="form-group col-sm-6" style={{ position: "relative" }}>
                                    <FormInput
                                        type="number"
                                        name="companyId"
                                        placeholder={t('placeholder8')}
                                        label={t('label8')}
                                        isRequired="1"
                                        nameClass="custom-input-check"
                                        messageError={t('error2')}
                                        value={values['companyId']}
                                        onChange={(e) => onChangeFunction(e)}
                                        validInputValue={validInput.companyId}
                                        setErrorsFunc={setErrorsFunction}
                                    />
                                </div>
                                <div className="form-group col-sm-6" style={{ position: "relative" }}>
                                    <FormInput
                                        type="text"
                                        name="contactName"
                                        placeholder={t('placeholder9')}
                                        label={t('label9')}
                                        isRequired="1"
                                        nameClass="custom-input-check"
                                        messageError={t('error2')}
                                        value={values['contactName']}
                                        onChange={(e) => onChangeFunction(e)}
                                        validInputValue={validInput.contactName}
                                        setErrorsFunc={setErrorsFunction}
                                    />
                                </div>
                                <div className="form-group col-sm-6" style={{ position: "relative" }}>
                                    <FormInput
                                        type="text"
                                        name="contactPosition"
                                        placeholder={t('placeholder10')}
                                        label={t('label10')}
                                        isRequired="1"
                                        nameClass="custom-input-check"
                                        messageError=""
                                        value={values['contactPosition']}
                                        onChange={(e) => onChangeFunction(e)}
                                        validInputValue="0"
                                    />
                                </div>
                                <div className="form-group col-sm-12" style={{ position: "relative" }}>
                                    <FormInput
                                        type="text"
                                        name="deliveryAddress"
                                        placeholder={t('placeholder11')}
                                        label={t('label11')}
                                        isRequired="1"
                                        nameClass="custom-input-check"
                                        messageError=""
                                        value={values['deliveryAddress']}
                                        onChange={(e) => onChangeFunction(e)}
                                        validInputValue="0"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='row'>
                                <div className='col-sm-6'>
                                    <label htmlFor="news">{t('newsletter')}</label>
                                    <div className="checkbox">
                                        <label>
                                            <input type="checkbox" name="news" id="news" className="news" defaultValue="Ne" onChange={(e) => setValues({ ...values, newsletter: e.target.checked ? 1 : 0 })} /> {t('paragraf2')}
                                        </label>
                                    </div>
                                </div>
                                <div className="form-group col-sm-6" style={{ position: "relative" }}>
                                    <label htmlFor="subjects">{t('label12')}</label>
                                    <select name='subjects' className='form-control' value={values['subjects']} onChange={(e) => setValues({ ...values, subjects: e.target.value })}>
                                        <option value={""}>{t('option1')}</option>
                                        <option value={"Pravnim licima"}>{t('option2')}</option>
                                        <option value={"Fizičkim licima"}>{t('option3')}</option>
                                        <option value={"Za sopstvene potrebe"}>{t('option4')}</option>
                                    </select>
                                </div>
                                <div className="form-group col-sm-6" style={{ position: "relative" }}>
                                    <label htmlFor="companyField">{t('label13')}</label>
                                    <select name='companyField' className='form-control' value={values['companyField']} onChange={(e) => setValues({ ...values, companyField: e.target.value })}>
                                        <option value={""}>{t('option1')}</option>
                                        <option value={"Maloprodaja IT"}>{t('option5')}</option>
                                        <option value={"Maloprodaja Bela tehnika"}>{t('option6')}</option>
                                        <option value={"Webshop"}>{t('option7')}</option>
                                        <option value={"Servis IT"}>{t('option8')}</option>
                                        <option value={"Servis mobilnih telefona"}>{t('option9')}</option>
                                        <option value={"Maloprodaja mobilnih telefona"}>{t('option10')}</option>
                                        <option value={"Diler CCTV"}>{t('option11')}</option>
                                        <option value={"Diler za firme"}>{t('option12')}</option>
                                        <option value={"Za sopstvene potrebe"}>{t('option13')}</option>
                                        <option value={"Drugo"}>{t('option14')}</option>
                                    </select>
                                </div>
                                <div className="form-group col-sm-6" style={{ position: "relative" }}>
                                    <label htmlFor="companySpace">{t('label14')}</label>
                                    <select name='companySpace' className='form-control' value={values['companySpace']} onChange={(e) => setValues({ ...values, companySpace: e.target.value })}>
                                        <option value={""}>{t('option1')}</option>
                                        <option value={"Da, do 30m2"}>{t('option15')}</option>
                                        <option value={"Da, do 100m2"}>{t('option16')}</option>
                                        <option value={"Da, preko 100m2"}>{t('option17')}</option>
                                        <option value={"Samo kancelarije"}>{t('option18')}</option>
                                    </select>
                                </div>
                                <div className="col-sm-6">
                                    <div className="">
                                        {t('label15')}
                                    </div>
                                    <div className="">
                                        <div className="form-check form-check-inline">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="portal"
                                                id="portal1"
                                                defaultValue={t('da')}
                                                defaultChecked={true}
                                                onChange={(e) => setValues({ ...values, account: 1 })}
                                            />
                                            <label className="form-check-label" htmlFor="portal1">{t('da')}</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="portal"
                                                id="portal2"
                                                defaultValue={t('ne')}
                                                onChange={(e) => setValues({ ...values, account: 0 })}
                                            />
                                            <label className="form-check-label" htmlFor="portal2">{t('ne')}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="">
                                        {t('label16')}
                                    </div>
                                    <div className="">
                                        <div className="form-check form-check-inline">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="xml"
                                                id="xml1"
                                                defaultValue={t('da')}
                                                defaultChecked={true}
                                                onChange={(e) => setValues({ ...values, xml: 1 })}
                                            />
                                            <label className="form-check-label" htmlFor="xml1">{t('da')}</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="xml"
                                                id="xml2"
                                                defaultValue={t('ne')}
                                                onChange={(e) => setValues({ ...values, xml: 0 })}
                                            />
                                            <label className="form-check-label" htmlFor="xml2">{t('ne')}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group col-sm-12" style={{ position: "relative" }}>
                                    <label htmlFor="merch">{t('label17')}</label>
                                    <input
                                        type="text"
                                        name="merch"
                                        id="merch"
                                        placeholder={t('placeholder12')}
                                        className="form-control"
                                        value={values['merch']}
                                        onChange={(e) => onChangeFunction(e)}
                                    />
                                </div>
                                <div className="form-group col-sm-12" style={{ position: "relative" }}>
                                    <label htmlFor="others">{t('label18')}</label>
                                    <input
                                        type="text"
                                        name="others"
                                        id="others"
                                        placeholder={t('placeholder13')}
                                        className="form-control"
                                        value={values['others']}
                                        onChange={(e) => onChangeFunction(e)}
                                    />
                                </div>
                                <div className="re-captcha-container">
                                    {currentLanguage == 'mk' ? (
                                        <ReCAPTCHA
                                            sitekey="6LchZCAoAAAAAOM85rAfDy6bLZ9JBIE9gEok9Kyq"
                                            onChange={handleCaptchaChange}
                                        />
                                    ) : (
                                        <ReCAPTCHA
                                            sitekey="6LchZCAoAAAAAOM85rAfDy6bLZ9JBIE9gEok9Kyq"
                                            onChange={handleCaptchaChange}
                                        />
                                    )
                                    }
                                </div>
                                <div className="col-sm-12 text-right">
                                    <button type='subimt' className='btn btn-success' onClick={(e) => handleSubmit(e)}>{t('dugme')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-sm-12' style={{ padding: "20px" }}>
                        <blockquote>{t('placeholder14')}</blockquote>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RegistrationPage
