import React from 'react';
import { useLocation } from 'react-router-dom';

const CatalogFilterTopItemCheckbox = ({ items, item2, key, clickFunctionItem }) => {
    
    const location = useLocation();
    var checkedBox = 0;
    var selectedFilters = [];
    var checkboxItems = location.search.split('?');
    if(checkboxItems.slice(0, -1).length > 0) {
        checkboxItems.forEach(item => {
            if(item != '') {
                var checboxItem = item.split('&');
                checboxItem.forEach(item2 => {
                    selectedFilters.push(item2.split('=')[1].split('_')[1]);
                });
            }
        });
    }
    checkedBox = selectedFilters.includes(item2.anAttributeValue.toString());
    var checkBoxValue = checkedBox ? 1 : 0;

    const onChangeFunction = () => {}
  
    return (
        <li key={key} className="filter-top-item-modal">
          <div className="filter-top-item-modal-input">
            <input 
              type="checkbox" 
              className="checkbox-filters-top-modal" 
              onClick={(e) => clickFunctionItem(e, item2, items, 'filter', key)}
              id={`checbox-top-filters-${item2.anAttributeValue}`} 
              checked={checkBoxValue == 1 ? 'checked' : ''}
              onChange={(e) => onChangeFunction()}
            />
          </div>
          <div className="filter-top-item-modal-text">
            <label className="form-check-label" htmlFor={`checbox-top-filters-${item2.anAttributeValue}`}>{item2.acAttributeValue} <span className="total-count-filter">({item2.anProductCnt})</span></label>
          </div>
        </li>
    )
};

export default CatalogFilterTopItemCheckbox;