import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from "react-router-dom";

import { filtersDataCheck } from '../functions/filters';
import { getFilteredProducts } from '../redux/actions/productActions';
import 'react-input-range/lib/css/index.css';

import FilterItem from './filters/FilterItem';
import FilterBrandItem from './filters/FilterBrandItem';
import FilterSpecialActionItem from './filters/FilterSpecialActionItem';
import FilterPriceItem from './filters/FilterPriceItem';
import { useTranslation } from 'react-i18next';

function useQuery() {
    return new URLSearchParams(useLocation().search);
};

const Filters = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const [filters, setFilters] = useState([]);
    const filtersData = useSelector((state) => state.catalogFilters);
    const catalogFiltersPrice = useSelector((state) => state.catalogFiltersPrice);
    const catalogDefaultFilters = useSelector((state) => state.catalogDefaultFilters);
    const finishedLoadingFilters = useSelector((state) => state.finishedLoadingFilters);
    const filtersDataSpecialAction = useSelector((state) => state.catalogFiltersSpecialAction);
    const { t } = useTranslation('routes');

    // sortiranje filtera
    function compare(a, b) {
        if (!Number.isInteger(parseInt(a.acAttributeValue.charAt(0))) && !Number.isInteger(parseInt(b.acAttributeValue.charAt(0)))) {
            if (a.acAttributeValue < b.acAttributeValue) {
                return -1;
            }
            if (a.acAttributeValue > b.acAttributeValue) {
                return 1;
            }
        } else {
            if (parseInt(a.acAttributeValue) < parseInt(b.acAttributeValue)) {
                return -1;
            }
            if (parseInt(a.acAttributeValue) > parseInt(b.acAttributeValue)) {
                return 1;
            }
        }
        return 0;
    }

    filtersData.map((item) => {
        item.attributeValueList.sort(compare)
    })

    let query = useQuery();
    let pathname = history.location.pathname;
    let searchHistory = history.location.search;
    const { categoryId, catagoryType } = useParams();

    if (catalogDefaultFilters != "") {
        query = catalogDefaultFilters.url
    }

    const checkFilter = (e, item2, item, type) => {
        const checked = e.target.checked;
        filtersDataCheck(checked, item2, item, type, history, searchHistory);
        dispatch({
            type: 'SET_DEFAULT_URL_FILTERS',
            payload: ""
        });
    };
    const rangeFilterPrice = (anPriceMin, anPriceMax) => {

        var acAttributeURLName = 'cena_0';
        var acUrlValue = anPriceMin;
        var anValue = anPriceMax;
        var kvp = searchHistory.substr(1).split('&');

        //uklanjanje iz url-a cenu od do
        if (kvp == '') {
            history.push('?' + acAttributeURLName + '=' + acUrlValue + '_' + anValue);
        } else {
            var i = kvp.length;
            var x;
            while (i--) {
                x = kvp[i].split('=');
                if (x[0] == acAttributeURLName) {
                    var index = kvp.indexOf(kvp[i]);
                    if (index > -1) {
                        kvp.splice(index, 1);
                    }
                    break;
                }
            }
            if (i < 0) {
                kvp[kvp.length] = [acAttributeURLName, acUrlValue + '_' + anValue].join('=');
                kvp = kvp.join('&');
                history.push('?' + kvp);
            } else {
                var i = kvp.length;
                var x;
                while (i--) {
                    x = kvp[i].split('=');
                    if (x[0] == acAttributeURLName) {
                        if (x[1] == acUrlValue + '_' + anValue) {
                            break;
                        } else {
                            kvp[kvp.length] = [acAttributeURLName, acUrlValue + '_' + anValue].join('=');

                            break;
                        }
                    }
                }
                if (i < 0) {
                    kvp[kvp.length] = [acAttributeURLName, acUrlValue + '_' + anValue].join('=');
                }
                kvp = kvp.join('&');
                history.push('?' + kvp);
            }

        }
        // dispatch(getFilteredProducts(categoryId, catagoryType, query.toString(), anPriceMin, anPriceMax));
    };


    useEffect(() => {
        if (!pathname.includes(t('katalog')) && catalogDefaultFilters != "") {
            if (!pathname.includes("kreiraj")) {
                history.replace({ search: "" })
                dispatch({
                    type: 'SET_DEFAULT_URL_FILTERS',
                    payload: ""
                });
            }
        } else {
            if (catalogDefaultFilters != "" && finishedLoadingFilters) {
                // history.push(pathname + "?" + catalogDefaultFilters.url);
                history.replace({ pathname: pathname, search: catalogDefaultFilters.url })
            }

        }

        window.onpopstate = e => {
            dispatch({
                type: 'SET_FINISHED_FILTERS',
                payload: false
            });

            if (finishedLoadingFilters) {
                history.replace({ pathname: e.target.location.pathname, search: e.target.location.search })
            }
        }

    }, [pathname, finishedLoadingFilters]);

    useEffect(() => {
        // console.log('useEffect from filters.js', 'translation:', t('katalog'));
        if (pathname.includes(t('katalog')) || pathname.includes(t('каталог'))) {
            dispatch(getFilteredProducts(categoryId, catagoryType, query.toString()));
        }
    }, [query.toString(), categoryId]);

    return (
        <div className="filters-body-catalog">
            {!Object.keys(catalogFiltersPrice).length == 0 && <FilterPriceItem checkFilterFunction={rangeFilterPrice} catalogFiltersPrice={catalogFiltersPrice} />}
            {filtersDataSpecialAction.length > 0 && <FilterSpecialActionItem checkFilterFunction={checkFilter} />}
            <FilterBrandItem checkFilterFunction={checkFilter} />
            {filtersData.length > 0 && (
                <>
                    {filtersData.map((item, index) => (
                        <div key={index}>
                            <FilterItem checkFilterFunction={checkFilter} filters={filters} item={item} key={index} numRow={index} />
                        </div>
                    ))}
                </>
            )}
        </div>
    );
};

export default Filters;