import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import CatalogBrandFilterItem from '../catalog/CatalogBrandFilterItem';
import { globalStatistic } from '../../redux/actions/otherActions';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const FilterBrandItem = ({ checkFilterFunction }) => {
    const dispatch = useDispatch();
    const params = useParams();
    const { categoryId } = params;

    const [showFilter, setShowFilter] = useState(true);
    const catalogFiltersBrand = useSelector((state) => state.catalogFiltersBrand);
    const { t } = useTranslation('filters');

    const checkFilterFunctionFunc = (e, item2, item, type) => {

        if (item2.acString.includes("_")) {
            item2.acString = item2.acString.replace("_", "")
        }

        if (item.includes("_")) {
            item = item.replace("_", "")
        }
        checkFilterFunction(e, item2, item, type);
        dispatch(globalStatistic(categoryId + '-' + item2.anId, 'FILTER_CATEGORY'));
    };

    return (
        <div style={{ marginBottom: '10px' }}>
            <div>
                <h5 onClick={(e) => setShowFilter(!showFilter)}><span className="filter-title-holder">{t('brendovi')}</span> <span className="toggle-icon-filters-show">
                    {showFilter ? <i className="fas fa-chevron-down"></i> : <i className="fas fa-chevron-right"></i>}
                </span>
                </h5>
                <div className="line-h5-filter"></div>
            </div>
            <div className={`filter-list-items ${showFilter ? 'show-filters' : 'hide-filters'}`}>
                {catalogFiltersBrand.length > 0 && (
                    <>
                        {catalogFiltersBrand.map((item, index) => (
                            <CatalogBrandFilterItem key={index} item={item} checkFilterFunction={checkFilterFunctionFunc} index={index} />
                        ))}
                    </>
                )}
            </div>
        </div>
    )
};

export default FilterBrandItem;