import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Image, Container, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import axios from 'axios';
import { axiosinstance } from '../../configAxios';

import PollAnswerContent from '../poll/PollAnswerContent';
import MessageInfoBox from '../other/MessageInfoBox';

import { getCompanyPromoMaterial } from '../../redux/actions/companyPromoMaterialActions';
import { useTranslation } from 'react-i18next';

const BlockPoll = ({ item }) => {

    const dispatch = useDispatch();
    const popups = useSelector((state) => state.getUserPopup.items);
    const getUserInfo = useSelector((state) => state.userInfo.userInfo.company);
    const [show, setShow] = useState(false);
    const isAllSeen = popups.every(item => item.isSeen == 1)
    const { t } = useTranslation('block');

    const handleClose = () => {
        try {
            const attributes = {};
            attributes['acSurveyKey'] = item._id;
            attributes['anUserKey'] = getUserInfo.department[0].user[0].anUserKey;

            const configRes = axiosinstance(attributes, '8224', 'seenSurvey');

            axios(configRes)
                .then(function (response) {
                    dispatch(getCompanyPromoMaterial());
                })
                .catch(function (error) {
                    console.log(error);
                    toast(<MessageInfoBox message="globalError" type="0" />, {
                        className: 'red-background',
                    });
                });

        } catch (error) {
            console.log(error)
            toast(<MessageInfoBox message="globalError" type="0" />, {
                className: 'red-background',
            });
        }
        setShow(false);
    };

    const showAutomatically = () => {
        setTimeout(() => {
            setShow(true)
        }, 1000);
    }

    const escFunction = (event) => {
        if (event.keyCode === 27) {
            handleClose();
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);

        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, []);

    useEffect(() => {
        isAllSeen && item.anWeeklyReminder == 1 && item.isSeen == 0 && showAutomatically();
    }, [item.isSeen, isAllSeen]);

    return (
        <div className="poll-block">
            <div className="row">
                <div className="col-sm-8">
                    <div className="poll-block-info">
                        <h3>{t('vaseMisljenjeNamJeVazno')} <br />{t('tema')}: {item.acName}</h3>
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="poll-block-info-buton">
                        <button className="btn" onClick={() => setShow(true)}>{t('pogledajPitanja')}</button>
                    </div>
                </div>
            </div>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="poll-modal"
            >
                <div className="close-action-modal" variant="secondary" onClick={handleClose}>
                    <i className="far fa-times-circle"></i>
                </div>
                <Modal.Body>
                    <div className="poll-container-modal">
                        <div className="poll-header-info">
                            <div className="poll-header-info-text">
                                {t('uvekSeTrudimoDaUnapredimoSvojeUsluge')}
                            </div>
                            <div className="poll-header-desc">
                                {item.acDescription}
                            </div>
                        </div>
                        {item ? <PollAnswerContent item={item} setShow={handleClose} /> : null}
                    </div>
                </Modal.Body>
            </Modal>

        </div>
    )
}

export default BlockPoll;