import React, { useState } from 'react';
import { useHistory, Link } from "react-router-dom";
import { customLogger } from '../../helperFunctions';

const BannerSliderItem = ({ itemData }) => {

    const [mouseMoved, setMouseMoved] = useState(false);
    const history = useHistory();

    // handlovanje klika na slajder
    const handleClick = () => {
        if (!mouseMoved) {
            history.push(itemData.acBannerLink);
        }
    };

    customLogger('other', 'item from BannerSliderItem', itemData);

    return (
        <Link
            onMouseMove={() => setMouseMoved(true)}
            onMouseDown={() => setMouseMoved(false)}
            onMouseUp={() => handleClick()}
        >
            <img src={`https://resource.ewe.rs/media/${itemData.acBannerImage}`} className="img-fluid" alt={`${itemData.acSeoDescription}`} title={`${itemData.acSeoTitle}`} />
            {/* <div className="banner-item-block-image" style={{backgroundImage: `url(http://www.ewe.rs/media/${item.acBannerImage})`}}></div> */}
        </Link>
    );
};

export default BannerSliderItem;