import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AuctionItem from '../components/other/AuctionItem';
import AuctionDetailItem from '../components/other/AuctionDetailItem';
import PageHelmet from '../components/helmet/PageHelmet';

import { getAuctionProducts } from '../redux/actions/productActions';
import { globalStatistic } from '../redux/actions/otherActions';
import { getPageSeo } from '../redux/actions/pageActions';
import { useTranslation } from 'react-i18next';

const AuctionPage = () => {

    const dispatch = useDispatch();
    const getAuctionProductsList = useSelector((state) => state.getAuctionProducts);
    const [detailItem, setDetailItem] = useState(0);
    const [detailAuctionItem, setDetailAuctionItem] = useState([]);
    const [res, setRes] = useState();

    const [showDetail, setShowDetail] = useState(false);
    const [key, setKey] = useState('');
    const [pageSeoData, setPageSeoData] = useState({});
    const { t } = useTranslation('auctionPage');

    // Create WebSocket connection.
    // const socket = new WebSocket('wss://socketsbay.com/wss/v2/2/demo/');

    const socketFunction = () => {
        const socket = new WebSocket('wss://newb2b.ewe.rs:8775/websocket');

        socket.addEventListener('open', function (event) {
            // socket.send('Hello Server!');
        });

        socket.addEventListener('message', function (event) {
            if (event.data != 'one-time message from server') {
                setRes(JSON.parse(event.data));
            }
            // console.log('Message from server ', event.data);
            // setRes(JSON.parse(event.data));
        });
    }
    // Connection opened
    // socket.addEventListener('open', function (event) {
    //     socket.send('Hello Server!');
    // });

    // // Listen for messages
    // socket.addEventListener('message', function (event) {
    //     console.log('Message from server ', event.data);
    // });

    useEffect(() => {
        socketFunction();
        dispatch(getPageSeo(540)).then(res => {
            if (res) {
                setPageSeoData(res);
            }
        });
        dispatch(globalStatistic(540, 'PAGE'));
    }, []);

    const functionSetDetailAuctionItem = (id, itemVal) => {
        setKey(itemVal.acAuctionKey);
        setShowDetail(true);
        document.getElementById("auction-holder").scrollIntoView({ behavior: 'smooth' })
    };

    if (res) {
        if (res.auctionList.length > 0) {
            var test = res.auctionList.filter((x) => x.acAuctionKey == key);
        }
    }
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
    };

    return (
        <>
            {Object.keys(pageSeoData).length !== 0 &&
                <PageHelmet
                    title={pageSeoData ? `${pageSeoData.acSeoTitle}` : "Ewe Comp"}
                    description={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    keywords={pageSeoData ? `${pageSeoData.acSeoKeywords}` : ""}
                    ogDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    ogTitle={pageSeoData ? `${pageSeoData.acSeoTitle}` : ""}
                    ogUrl={pageSeoData ? `https://ewe.rs/${pageSeoData.acSeoUrl}` : ""}
                    ogImg="https://www.ewe.rs/media/ewe-og-img.jpg"
                    ogType="application"
                    twitterDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                />
            }
            <div className="container-content auticon-content container-catalog-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="auticon-content-holder">
                            <img src="/media/aukcija-landing.jpg" />
                            <div id="auction-holder"></div>
                            {showDetail ? (
                                <>
                                    <div className="close-auction-body-detail" variant="secondary" onClick={() => setShowDetail(false)}>
                                        <i className="far fa-times-circle"></i>
                                    </div>
                                    <AuctionDetailItem item={test} />
                                    <div className="auction-other-items">
                                        <div className="auction-other-items-title">{t('pogledajteOstaleAukcije')}</div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="auciton-title-description">
                                        <div className="auction-title">{t('licitirajPametno')}</div>
                                        <div className="auction-desc">{t('aukcijaGlavniOpis')}<br />
                                            {t('aukcijaOpis')}
                                        </div>
                                    </div>
                                    <div className="auction-sub-desc">
                                        {t('dodavanjePDVa')}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-sm-12">
                            <div className="auction-content-items">
                                {res && res.auctionList.length > 0 ?
                                    res.auctionList.map((item, key) => (
                                        <AuctionItem item={item} setDetailItemFunction={functionSetDetailAuctionItem} key={key} />
                                    ))
                                    :
                                    <div className="col-sm-12">
                                        <div className="alert alert-danger w-100 alert-info-loyalty" role="alert">
                                            {t('nemaArtikla')}
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AuctionPage;