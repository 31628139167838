import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

const NewsItem = ({ data, today, options, scrollEnabled }) => {
  const { t } = useTranslation('news');

  const getNewsKey = useSelector((state) => state.getNewsKey.items);
  let newsDate = new Date(data.adTimeIns).toLocaleDateString('sr-RS', options)
  const dispatch = useDispatch();
  useEffect(() => {
    if (scrollEnabled) {
      document.getElementById("news-item-holder") && document.getElementById("news-item-holder").scrollIntoView({ behavior: 'smooth' })
    }
  }, [scrollEnabled])

  useEffect(() => {
    return () => {
      dispatch({
        type: 'SET_NEWS_KEY',
        payload: -1
      });
    };
  }, [])

  // console.log(data, "data")
  return (
    <div className="news-item-box2" id={getNewsKey == data.anNewsKey ? "news-item-holder" : ""}>
      <div className="news-content-center">
        <div className="news-box-item-image2">
          <img src={`https://resource.ewe.rs/${data.acNewsPicture}`} alt="News" />
        </div>
        <div className="news-box-item-body2">
          {newsDate == today && (
            <>
              <span className="news-box-item-badge2">{t('novo')}</span>
            </>
          )}
          <div className="news-box-item-date">{t('objavljeno')} <Moment format="DD.MM.YYYY.">{data.adTimeIns}</Moment></div>
          <div className="news-box-item-title">{data.acNewsTitle}</div>
          <div className="news-box-item-description">{data.acNews}</div>
          {data.acNewsLink != '' && (
            <div className="news-box-item-button-link">
              <a href={data.acNewsLink} target='_blank' rel='noreferrer'><button className="btn btn-info">{t('detaljnije')}</button></a>
            </div>
          )}
        </div>
      </div>
    </div>

  );
};


export default NewsItem;
