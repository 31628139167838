import axios from 'axios';
import { axiosinstance } from '../../configAxios';
import { toast } from 'react-toastify';
import MessageInfoBox from '../../components/other/MessageInfoBox';

export const getPageSeo = (pageId) => {
    return (dispatch, state) => {

        const attributes = {};
        if (pageId) {
            attributes['anElementId'] = pageId;
        } else {
            attributes['anElementId'] = 0;
        }

        const configRes = axiosinstance(attributes, '8223', 'postSeoElementDef');

        return axios(configRes)
            .then(response => {
                // toast(<MessageInfoBox message={response.data.response.acMsg} type={response.data.response.isOk} />, {
                //     className: response.data.response.isOk == 1 ? 'green-background' : 'red-background',
                // });
                // console.log(response.data.response, 'usao u axios > response.data.response')
                if (response.data.isOk == '1') {
                    return response.data.response.setSeoElementDef;
                } else {
                    return response.data.response
                }
            })
            .catch(function (error) {
                console.log(error, 'error');
                toast(<MessageInfoBox message="globalError" type="0" />, {
                    className: 'red-background',
                });
            });
    }
};