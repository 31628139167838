import React, { useEffect, useState, createRef } from 'react';
import MaterialTable from "material-table";
import Moment from 'react-moment';
import { Paper } from '@material-ui/core';

import PatchedPagination from './PatchedPagination';
import CurrencyFormat from './CurrencyFormat';
import { useTranslation } from 'react-i18next';

const TargetBox = ({ item }) => {

    const adDateFrom = item.adDateFrom
    const adDateTo = item.adDateTo
    const { t } = useTranslation('other');

    const tableRef = createRef();
    return (
        <div className="target-box-holder">
            <div className="target-box-title">{item.acTargetDescription}</div>
            <div className="target-box-table">
                <div className="editing-cart-items-table">
                    <MaterialTable
                        components={{
                            Pagination: PatchedPagination,
                            Container: props => <Paper {...props} elevation={0} />
                        }}
                        data={item.companySaleTargetItem}
                        tableRef={tableRef}
                        columns={[
                            {
                                title: t('opis'),
                                field: "acConditionLevel",
                                cellStyle: { minWidth: "400px", padding: "8px" },
                                headerStyle: { textAlign: "left" }
                            },
                            {
                                title: t('period'),
                                render: item => {
                                    return <div>
                                        <Moment format="DD.MM.YYYY">
                                            {adDateFrom}
                                        </Moment> - <Moment format="DD.MM.YYYY">
                                            {adDateTo}
                                        </Moment>
                                    </div>
                                }
                            },
                            {
                                title: t('uslov'),
                                field: "anCondition",
                                render: item => {
                                    return <CurrencyFormat num={item.anCondition} />;
                                }
                            },
                            {
                                title: t('uslov%'),
                                field: "anConditionPercent",
                                render: item => {
                                    return item.anConditionPercent + "%";
                                }
                            },
                            {
                                title: t('ostvPromet'),
                                field: "anTurnOver",
                                render: item => {
                                    return <div style={{ color: item.isCompleted == 0 ? "#FFAF60" : "#57C443" }}><CurrencyFormat num={item.anTurnOver} /></div>;
                                }
                            },
                            {
                                title: t('ostvPromet%'),
                                field: "anTurnOverPercent",
                                render: item => {
                                    return <div style={{ color: item.isCompleted == 0 ? "#FFAF60" : "#57C443" }}>{item.anTurnOverPercent + "%"}</div>;
                                }
                            },
                            {
                                title: "",
                                field: "isCompleted",
                                render: item => {
                                    return <div className="text-center">
                                        {item.isCompleted == 0 ?
                                            <span className="reservation-box-color target-box-color" style={{ backgroundColor: "#FFAF60", border: "solid 1px orange" }}><i className="fas fa-exclamation" style={{ color: "#fff" }}></i></span>
                                            :
                                            <span className="reservation-box-color target-box-color" style={{ backgroundColor: "#57C443", border: "solid 1px green" }}><i className="fas fa-check" style={{ color: "#fff" }}></i></span>
                                        }
                                    </div>;
                                }
                            },
                            {
                                title: t('dokRealizacije'),
                                field: "acDocumentOfRealisation",
                            },
                        ]}
                        options={{
                            filtering: false,
                            search: false,
                            sorting: false,
                            toolbar: false,
                            draggable: false,
                            paging: false,
                            headerStyle: {
                                whiteSpace: "nowrap",
                                textAlign: "center",
                                fontWeight: 600,
                                textTransform: 'uppercase'
                            },
                            cellStyle: {
                                textAlign: "center",
                                whiteSpace: "nowrap",
                                borderLeft: "solid 1px #E0E0E0",
                                borderRight: "solid 1px #E0E0E0",
                                padding: "8px 16px"
                            }
                        }}
                        title=""
                    />
                </div>
            </div>
        </div>
    )
}

export default TargetBox;