import axios from 'axios';
import { axiosinstance } from '../../configAxios';
import SecureLS from 'secure-ls';

let ls = new SecureLS({ encodingType: 'aes' });

export const createLoyaltyOrder = (objArr) => async (dispatch) => {

    var userInfoData = ls.get('userInfo');
    const attributes = {};

    attributes['anUserKey'] = userInfoData ? userInfoData.company.department[0].user[0].anUserKey : 2061;
    attributes["anCompanyKey"] = userInfoData ? userInfoData.company.anCompanyKey : 1940;
    attributes['acLoyalityTable'] = objArr;

    const configRes = axiosinstance(attributes, "8225", "postLoyalityFinishCart");

    axios(configRes)
        .then(function (response) {

            dispatch({
                type: 'SET_MESSAGE_INFO',
                payload: response.data
            });

        })
        .catch(function (error) {
            console.log(error);
        });
};

export const getLoyaltyProducts = () => async (dispatch) => {
    
    var userInfoData = ls.get('userInfo');
    const attributes = {};

    attributes["anCompanyKey"] = userInfoData ? userInfoData.company.anCompanyKey : 1940;

    const configRes = axiosinstance(attributes, "8225", "postCompanyLoyalityShop");

    axios(configRes)
        .then(function (response) {
            dispatch({
                type: "SET_LOYALTY_PRODUCTS",
                payload: response.data.response.companyLoyalityShop[0].loyaltyShopItemList,
            });
        })
        .catch(function (error) {
            console.log(error);
        });
};