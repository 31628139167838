import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import imageExists from 'image-exists';

import InputQtyPlusMinusCart from '../other/InputQtyPlusMinusCart';
import CurrencyFormat from '../other/CurrencyFormat';
import RemoveCartItem from '../other/RemoveCartItem';
import { useTranslation } from 'react-i18next';

import { removeSpecialChar } from '../../helperFunctions'

const SidebarCartItemModal = ({ item }) => {

    const activeCurrency = useSelector((state) => state.activeCurrency.item);
    const activeVat = useSelector((state) => state.activeVat.item);
    const [imageShow, setImageShow] = useState(0);
    const { t } = useTranslation('routes');

    useEffect(() => {
        imageExists('https://resource.ewe.rs/products/' + item.acIdent + '_v.jpg', img => {
            if (img) {
                setImageShow(1);
            };
        });
    }, []);

    return (
        <li>
            <div className="cart-item-image-list">
                <Link to={`/${t('proizvod')}/${item.anProductKey}/${removeSpecialChar(item.acProducName)}`}>
                    {imageShow == 1 ? (
                        <img src={`https://resource.ewe.rs/products/${item.acIdent}_v.jpg`} className="img-cart-list-item" alt={`${item.acDept} ${item.acProducName}`} />
                    ) : (
                        <img src="https://resource.ewe.rs/products/noImage.jpg" className="img-cart-list-item" alt={`${item.acDept} ${item.acProducName}`} />
                    )}
                </Link>

            </div>
            <div className="cart-item-name-list">
                <div className="cart-item-ident-list">{item.acIdent}</div>
                <Link to={`/${t('proizvod')}/${item.anProductKey}/${removeSpecialChar(item.acProducName)}`}>
                    <div className="item-cart-name">{item.acDept.replace(" 1****", "")} {item.acProducName}</div>
                </Link>
            </div>
            <div className="cart-item-qty-list">
                <InputQtyPlusMinusCart qty={item.anQty} product={item} />
            </div>
            <div className="cart-item-price-list">
                <div className="cart-item-price-value"><CurrencyFormat num={activeVat == 1 ? item.anSumPriceWithPdv : item.anSumPriceNoPdv} /> {activeCurrency.acShortName}</div>
                <RemoveCartItem anCartItemKey={item.anCartItemKey} />
            </div>
        </li>
    );
};

export default SidebarCartItemModal;