import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Moment from 'react-moment';
import { globalStatistic } from '../../redux/actions/otherActions';
import { useTranslation } from 'react-i18next';

const BlockNewsNotices = () => {

  const getUserNews = useSelector((state) => state.getUserNews.items);
  getUserNews.sort((a, b) => {
    return new Date(b.adTimeIns) - new Date(a.adTimeIns);
  });
  const options = { month: '2-digit', day: '2-digit' };
  let today = new Date().toLocaleDateString('sr-RS', options)
  const { t } = useTranslation('block');

  const dispatch = useDispatch();
  const gatherStatisticAllNews = () => {
    dispatch(globalStatistic(0, 'ALL_NEWS'));
  }

  const addNewsId = (val) => {
    dispatch({
      type: 'SET_NEWS_KEY',
      payload: val
    });
  }

  return (
    <div className="news-block">
      <div className="news-block-content">
        <div className="news-block-title">
          <h3>{t('obaveštenja')}</h3>
        </div>
        <div className="news-block-body">
          <div className="news-items-list">
            {getUserNews.length == 0 ? (
              <div className="alert alert-primary w-100" role="alert">
                {t('nemaAktivnihObaveštenja')}
              </div>
            ) : (
              <>
                {getUserNews.map((item, key) => {
                  let newsDate = new Date(item.adTimeIns).toLocaleDateString('sr-RS', options)

                  return <div key={key}>
                    {key < 5 &&
                      <div className="news-item" >
                        <div className="news-date-item">
                          <span>
                            <Moment format="DD.MM.">
                              {item.adTimeIns}
                            </Moment>
                          </span>
                          {newsDate == today && (
                            <>
                              <div className="news-notification-item">
                                <span>{t('novo')}</span>
                              </div>
                            </>
                          )}
                        </div>

                        <div className="news-body-item">
                          <Link to='/vesti' onClick={() => addNewsId(item.anNewsKey)}>
                            <h4 title={item.acNewsTitle}>{item.acNewsTitle}</h4>
                          </Link>
                          <div className="news-text-body" title={item.acNews}>
                            {item.acNews}
                          </div>
                        </div>
                        <i className={item.acNewsIcon} style={{ "color": `${item.acNewsColor}` }}></i>
                      </div>
                    }
                  </div>
                })}
              </>
            )}
          </div>
        </div>
      </div>
      {getUserNews.length > 0 && (
        <div className="news-block-footer" >
          {t('ostalaObavestenja')} <Link to="/vesti"><span className="detail-footer-notice" onClick={(e) => gatherStatisticAllNews()} >{t('detaljnije')}</span></Link>
        </div>
      )}
    </div>
  )
};

export default BlockNewsNotices;