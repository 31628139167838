import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import {
    getCartFunction
} from '../../redux/actions/cartActions';
import { axiosinstance } from '../../configAxios';

import MessageInfoBox from '../../components/other/MessageInfoBox';
import SecureLS from 'secure-ls';

const InputQtyPlusMinusCart = ({ qty, product }) => {
    const [qtyVal, setQtyVal] = useState(qty);
    const dispatch = useDispatch();
    const getActiveCartFunction = useSelector((state) => state.getActiveCartFunction);
    const [loaderQty, setLoaderQty] = useState(0);
    let ls = new SecureLS({ encodingType: 'aes' });

    //oduzimanje kolicine
    const setQtyMinus = (val) => {
        if (val > 0) {
            setQtyVal(val);
            functionSetQty(val);
        } else {
            setQtyVal(1);
            toast(<MessageInfoBox message="cartMinStockError" type="0" />, {
                className: 'red-background',
            });
        }
    }

    //dodavanje kolicine
    const setQtyPlus = (val) => {
        if (val <= product.anStock) {
            setQtyVal(val);
            functionSetQty(val);
        } else {
            toast(<MessageInfoBox message="cartOverStockError" type="0" />, {
                className: 'red-background',
            });
        }
    }

    //direktna promena vrednosti
    const setQtyFunction = (e) => {
        var valueItem = e.target.value;
        if (e.key == 'Enter') {
            if (valueItem > product.anStock) {
                toast(<MessageInfoBox message="cartOverStockError" type="0" />, {
                    className: 'red-background',
                });
                setQtyVal(qty);
            } else {
                setQtyVal(valueItem);
                functionSetQty(valueItem);
            }
        }
    }

    //funkcija koja poziva funkciju za snimanje u korpu
    const functionSetQty = (qtyValue) => {

        setLoaderQty(1); //setujemo loader za proizvod kojem se menja kolicina

        var qtyFinal = qtyValue - qty;

        var userInfoData = ls.get('userInfo');
        var activeCurrency = JSON.parse(localStorage.getItem('activeCurrency'));

        const attributes = {};
        attributes['anCompanyKey'] = userInfoData ? userInfoData.company.anCompanyKey : 0;
        attributes['anUserKey'] = userInfoData ? userInfoData.company.department[0].user[0].anUserKey : '0';
        attributes['acCartKey'] = getActiveCartFunction[0]._id ? getActiveCartFunction[0]._id : '';
        attributes['anProductKey'] = product.anProductKey;
        attributes['anQty'] = qtyFinal;
        attributes['anCurrency'] = activeCurrency._id;
        attributes['isRelated'] = product.isRelatedSale;

        const configRes = axiosinstance(attributes, '8224', 'addItemToCart');

        axios(configRes)
            .then(function (response) {
                if (response.data.response.acInfoMessage) {
                    response.data.response.acInfoMessage = response.data.response.acInfoMessage.split(":")[0];
                    toast(<MessageInfoBox message={response.data.response.acInfoMessage} type={0} />, {
                        className: 'red-background',
                    });
                    if (response.data.response.acInfoMessage.includes("relatedSaleQtyNotAllowed")) {
                        setQtyVal(product.anQty);
                    }
                } else if (response.data.response.acMsg) {
                    toast(<MessageInfoBox message={response.data.response.acMsg} type={response.data.response.isOk} />, {
                        className: response.data.response.isOk == 1 ? 'green-background' : 'red-background',
                    });
                } else if (response.data.isOk == 0) {
                    toast(<MessageInfoBox message={response.data.acMsg} type={response.data.isOk} />, {
                        className: response.data.isOk == 1 ? 'green-background' : 'red-background',
                    });
                    setQtyVal(product.anQty); //setujemo prethodnu vrednost za qty , posto nije prosla funkcija
                }

                dispatch(getCartFunction()); //osvezavanje korpe
                setLoaderQty(0); //uklanjamo loader za proizvod kojem se menja kolicina

            })
            .catch(function (error) {
                toast(<MessageInfoBox message="globalError" type="0" />, {
                    className: 'red-background',
                });
            });
    };

    useEffect(() => {
        setQtyVal(product.anQty);
    }, [product.anQty])

    return (
        <div className="button-div-item-qty">
            {loaderQty == 1 ? <i className="fas fa-spinner fa-pulse"></i> : (
                <>
                    <button className="minus-item-qty" onClick={(e) => setQtyMinus(parseFloat(qtyVal) - 1)}>-</button>
                    <input
                        onChange={(e) => setQtyVal(e.target.value)}
                        onClick={(e) => e.target.select()}
                        onKeyPress={(e) => setQtyFunction(e)}
                        className="input-qty-add-item"
                        value={qtyVal}
                    />
                    <button className="plus-item-qty" onClick={(e) => setQtyPlus(parseFloat(qtyVal) + 1)}>+</button>
                </>
            )}
        </div>
    )
}

export default InputQtyPlusMinusCart;