import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import InputQtyPlusMinus from '../other/InputQtyPlusMinus';
import AddToCartButton from '../cart/AddToCartButton';
import CurrencyFormat from '../other/CurrencyFormat';
import ModalForRegistration from '../modal/ModalForRegistration';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ProductDetailPriceBlock = ({ productItem, getUserInfo }) => {
    const getActiveCartFunction = useSelector((state) => state.getActiveCartFunction[0]);
    const [qtyProduct, setQtyProduct] = useState(1);
    const [modalOpen, setModalOpen] = useState(false);
    const { t } = useTranslation(['product', 'routes']);

    const activeCurrency = useSelector((state) => state.activeCurrency.item);
    const activeVat = JSON.parse(localStorage.getItem('activeVat'));
    const isVat = activeVat == 1 ? true : false;
    const iconPath = process.env.PUBLIC_URL + '/media/';
    const getB2bSettings = useSelector((state) => state.getB2bSettings);

    const functionQtyValue = (qty) => {
        setQtyProduct(qty);
    }

    const openModal = () => {
        setModalOpen(true);
    }

    const closeModal = () => {
        setModalOpen(false);
    }
    // console.log(productItem, "productItem");

    return (
        <div className="product-detail-price-block">
            <div className="product-table-content">
                <table className="table table-borderless">
                    <tbody>
                        {getUserInfo.userInfo &&
                            productItem.anRecommendedRetailPrice != 0 && (
                                <tr>
                                    <td>{t('product:preporucenaMPcena')}</td>
                                    <td className="td-product-value"><CurrencyFormat num={productItem.anRecommendedRetailPrice} /> {productItem.anRecommendedRetailPrice != 0 && (getB2bSettings?.items?.acLogOutUserCurrency || t('product:rsd'))}</td>
                                </tr>
                            )}
                        {getUserInfo.userInfo ? (
                            <tr>
                                <td>{t('product:cena')} <small>({isVat ? t('saPdv') : t("bezPdv")})</small>:</td>
                                <td className="td-product-value"><CurrencyFormat num={getUserInfo.userInfo ? productItem.anPrice : productItem.anRetailPrice} /> {getUserInfo.userInfo ? (productItem.anPrice != 0 && (getUserInfo.userInfo ? activeCurrency.acShortName : getB2bSettings?.items?.acLogOutUserCurrency || t('product:rsd'))) : productItem.anRetailPrice != 0 && (getUserInfo.userInfo ? activeCurrency.acShortName : getB2bSettings?.items?.acLogOutUserCurrency || t('product:rsd'))}</td>
                            </tr>
                        ) : (
                            <tr>
                                <td>{t('product:preporucenaMpCena')}</td>
                                <td className="td-product-value"><CurrencyFormat num={getUserInfo.userInfo ? productItem.anPrice : productItem.anRetailPrice} /> {getUserInfo.userInfo ? (productItem.anPrice != 0 && (getUserInfo.userInfo ? activeCurrency.acShortName : getB2bSettings?.items?.acLogOutUserCurrency || t('product:rsd'))) : productItem.anRetailPrice != 0 && (getUserInfo.userInfo ? activeCurrency.acShortName : getB2bSettings?.items?.acLogOutUserCurrency || t('product:rsd'))}</td>
                            </tr>
                        )}
                        {getUserInfo.userInfo && (
                            <>
                                <tr>
                                    <td>{t('product:lager')}</td>
                                    <td className="td-product-value">{productItem.anStock}</td>
                                </tr>
                                <tr>
                                    <td>{t('product:rezervisano')}</td>
                                    <td className="td-product-value">{productItem.anReserved}</td>
                                </tr>
                                {/* <tr style={{ backgroundColor: '#e9e9e9' }}>
                                    <td>Kolicina u dolasku:</td>
                                    <td className="td-product-value">50</td>
                                </tr> */}
                            </>
                        )}
                    </tbody>
                </table>
                {getUserInfo.userInfo && productItem.anOldPrice && productItem.anOldPrice > 0 ? (
                    <div className='product-total-old-price'><CurrencyFormat num={productItem.anOldPrice} /> {productItem.anOldPrice != 0 && activeCurrency.acShortName}</div>
                ) : ''
                }
                <div className="row product-add-to-cart-price">
                    <div className="product-add-cart-price-items">
                        {getUserInfo.userInfo ? (
                            <>
                                <div className="product-add-cart-price small-item">
                                    <div className="product-total-price"><CurrencyFormat num={productItem.anPrice} /> {productItem.anPrice != 0 && activeCurrency.acShortName}</div>
                                </div>
                                {productItem.anStock == productItem.anReserved ? (
                                    <button className="btn btn-sm btn-call w-50 product-item-call call-us-button"><img src={`${iconPath}icon-kontakt-telefon.svg`} alt="kontakt-telefon" />&nbsp; {t('product:pozovite')}</button>
                                ) : (
                                    <>
                                        <div className="product-add-cart-qty">
                                            <InputQtyPlusMinus functionSetQty={functionQtyValue} qty={productItem.anStock} />
                                        </div>
                                        <div className="product-add-cart-btn">
                                            <div className="btn-add-product-detail">
                                                {/* <i className="fas fa-shopping-cart"></i>&nbsp; dodaj u korpu */}
                                                <AddToCartButton qtyValue={qtyProduct} product={productItem} name={t('dodajUKorpu')} />
                                            </div>
                                        </div>
                                    </>
                                )}
                                <div className="product-add-cart-price large-item">
                                    <div className="product-total-price"><CurrencyFormat num={productItem.anPrice} /> {productItem.anPrice != 0 && activeCurrency.acShortName}</div>
                                </div>
                            </>
                        ) : (
                            <Link className="w-100" to={`/registracija`}>
                                <button className="btn btn-info w-100"><i className="fas fa-sign-in-alt"></i> {t('product:regSeZaKupovinu')}</button>
                            </Link>
                            // <div className='register-holder' style={{ padding: '2px 10px' }}>
                            //     <p>Ukoliko ste zainteresovani,<br />kontaktirajte nas putem telefona ili imejla.</p>
                            //     <div className='buttons-register-holder' style={{ display: 'flex' }}>
                            //         <div className="call-us" style={{ marginRight: '15px' }}>
                            //             <a href="tel:+381113535557" className="btn btn-info w-100">
                            //                 &nbsp;Pozovite nas
                            //             </a>
                            //         </div>
                            //         <div className="send-message">
                            //             <div className="btn btn-info w-100" onClick={openModal}>
                            //                 &nbsp;Pošaljite nam poruku
                            //             </div>
                            //         </div>
                            //     </div>
                            // </div>
                        )}
                    </div>
                </div>
                {getUserInfo.userInfo && (
                    <>
                        {productItem.anStock > 0 && productItem.anStock != productItem.anReserved && (
                            <div className='go-to-cart-link'>
                                <Link to={`/${t('routes:korpa')}/${getActiveCartFunction._id}`}>
                                    <span className="active-cart-link">
                                        <span>{t('product:idiNaKorpu')}</span>
                                    </span>
                                </Link>
                            </div>
                        )}

                    </>
                )}
            </div>
            {modalOpen && <ModalForRegistration show={modalOpen} handleClose={() => setModalOpen(false)} />}
        </div >
    )
}

export default ProductDetailPriceBlock;