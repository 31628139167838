import React, { useState } from 'react';
import urlFileSize from 'url-file-size';

import ModalNewsletter from '../modal/ModalNewsletter'
import globalVariable from '../../global';
import { useTranslation } from 'react-i18next';

const NewsletterItem = ({ item }) => {

    const filesList = item.tempMaterialForDownloadItem

    const [showNewsletter, setShowNewsletter] = useState(false)
    const [showNewsletterList, setShowNewsletterList] = useState(false)
    const [modalContent, setModalContent] = useState("")
    const { t } = useTranslation('other');


    filesList.map(img => {
        let fileURL = img.acMaterialForDownloadItemType != "acLink" ? `${globalVariable.domainName}/media/${img.acMaterialForDownloadItemLink}` : ""

        if (fileURL != "") {
            urlFileSize(fileURL).then(function (res) {
                let sizeInMB = (res / Math.pow(1024, 1)).toFixed(2);
                img.fileSize = sizeInMB
            }).catch(console.error);
            let fileExt = img.acMaterialForDownloadItemLink.split(".")[1]
            img.fileExt = fileExt
        }
    })

    const handleClose = (val) => {
        setShowNewsletter(val);
        // setShowNewsletterList(val);
    }

    const showModal = (content) => {
        setModalContent(content)
        handleClose(true)
    }

    return (
        <>
            <div className="newsletter-item">
                <img src={`${globalVariable.domainName}/media/${item.acMaterialForDownloadCoverImage}`} alt="newsletter" className="newsletter-image img-fluid" />
                <div className="newsletter-buttons">
                    <div className="btn btn-sm btn-success text-center" onClick={() => showModal("preview")}><i className="fas fa-eye" aria-hidden="true"></i>&nbsp; {t('pogledaj')}</div>
                    <div className="btn btn-sm btn-newsletter text-center" onClick={() => showModal("list")}><i className="fas fa-angle-double-down"></i>&nbsp; {t('preuzmite')}</div>
                </div>
            </div>
            {showNewsletter &&
                <ModalNewsletter handleClose={handleClose} show={showNewsletter} newsletter={item} modalContent={modalContent} filesList={filesList} />
            }
        </>
    )
}

export default NewsletterItem