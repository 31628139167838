import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField, InputAdornment, Icon, IconButton, TableSortLabel, Select, MenuItem, Typography } from '@material-ui/core';
import OrderStatusCustomDatePicker from '../components/other/OrderStatusCustomDatePicker';
import debounce from 'lodash/debounce'; // Import debounce from lodash
import CurrencyFormat from '../components/other/CurrencyFormat';

const CustomTableOrderStatus = ({ data, showUploadModalFunction, notEditableAttachment, setFunctionAgainOrderAction, gatherStatisticCloneCart, setOrderDetailFunction, handleCargoId, deliveryTypeSettings }) => {
    const { t } = useTranslation('orderStatusPage');
    const [filter, setFilter] = useState('');
    const [columnFilters, setColumnFilters] = useState({
        acKey: '',
        acDocKey: '',
        adDate: null,
        anValue: '',
        anVAT: '',
        anForPay: '',
        acCurrency: '',
        acDelivery: '',
        acStatus: '',
        acOutsourceShipment: ''
    });
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    // console.log(deliveryTypeSettings, 'deliveryTypeSettings');
    const [isLoading, setIsLoading] = useState(false);

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
    };

    const handleColumnFilterChange = (e, column) => {
        setColumnFilters({
            ...columnFilters,
            [column]: e.target.value
        });
    };

    // Debounce funkcija za filtriranje po datumu
    const handleDateChangeDebounced = useCallback(
        debounce((id, date) => {
            setColumnFilters((prevFilters) => ({
                ...prevFilters,
                [id]: date
            }));
        }, 300),
        []
    );

    const handleDateChange = (id, date) => {
        handleDateChangeDebounced(id, date);
    };

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const handleClearFilter = () => {
        setFilter('');
        setColumnFilters({
            acKey: '',
            acDocKey: '',
            adDate: null,
            anValue: '',
            anVAT: '',
            anForPay: '',
            acCurrency: '',
            acDelivery: '',
            acStatus: '',
            acOutsourceShipment: ''
        });
    };
    const formatDate = useCallback((dateString) => {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('sr-RS').format(date);
    }, []);

    const mapData = useMemo(() => {
        return (data) => {
            return data.map(item => ({
                _id: item._id,
                acKey: item.acKey,
                acDocKey: item.acDocKey,
                adDate: formatDate(item.adDate),
                anValue: item.anValue,
                anVAT: item.anVAT,
                anForPay: item.anForPay,
                acCurrency: item.acCurrency,
                acDelivery: (deliveryTypeSettings && deliveryTypeSettings.find(type => type.acType === item.acDelivery)) ? deliveryTypeSettings.find(type => type.acType === item.acDelivery).acName : item.acDelivery,
                acStatus: item.acStatus,
                acOutsourceShipment: item.acOutsourceShipment,
                isEditable: item.isEditable,
                itemList: item.itemList,
                cargoId: item.cargoId,
                acType: item.acType
            }));
        };
    }, [formatDate, deliveryTypeSettings]);

    const parseDate = (date) => {
        // Ako je već Date objekat, vrati ga
        if (date instanceof Date) {
            return date;
        }
        // Ako nije, parsiraj string u Date objekat
        const [day, month, year] = date.split('.');
        return new Date(`${year}-${month}-${day}`);
    };

    const sortedData = useMemo(() => {
        if (!sortConfig.key) return [...mapData(data)];

        const key = sortConfig.key;
        const directionMultiplier = sortConfig.direction === 'asc' ? 1 : -1;

        return [...mapData(data)].sort((a, b) => {
            const valueA = a[key];
            const valueB = b[key];

            // Poređenje za različite tipove podataka (stringovi, brojevi, datumi)
            if (typeof valueA === 'number' && typeof valueB === 'number') {
                return directionMultiplier * (valueA - valueB);
            }

            if (valueA < valueB) return -1 * directionMultiplier;
            if (valueA > valueB) return 1 * directionMultiplier;

            return 0;
        });
    }, [data, sortConfig, mapData]);

    const filteredData  = sortedData.filter((row) => {
        return (!columnFilters.adDate ||
            parseDate(row.adDate).toDateString() === parseDate(columnFilters.adDate).toDateString()) &&
            Object.keys(columnFilters).every(key => {
                if (key === 'adDate') return true;
                const filterValue = columnFilters[key];
                if (filterValue === null || filterValue === '') return true;
                const cellValue = row[key];
                if (typeof cellValue === 'string') {
                    return cellValue.toLowerCase().includes(filterValue.toLowerCase());
                }
                return cellValue.toString().toLowerCase().includes(filterValue.toLowerCase());
            }) &&
            Object.values(row).some((value) =>
                value ? value.toString().toLowerCase().includes(filter.toLowerCase()) : false
            );
    });

    const paginatedData = useMemo(() => {
        return filteredData.slice(
            (currentPage - 1) * itemsPerPage,
            currentPage * itemsPerPage
        );
    }, [filteredData, currentPage, itemsPerPage]);

    const totalPages = useMemo(() => {
        return Math.ceil(filteredData.length / itemsPerPage);
    }, [filteredData.length, itemsPerPage]);

    // Reset trenutne strane na prvu kada se promene filteri
    useEffect(() => {
        setCurrentPage(1);
    }, [columnFilters, filter]);

    useEffect(() => {
        setIsLoading(true); // Pokreni loader kada se filter menja
    }, [columnFilters, sortConfig]);

    return (
        <div className="custom-table-container">
            {/* {isLoading && <div className="loader-mask"></div>} */}
            <div className="search-container">
                <TextField
                    type="text"
                    placeholder={t('Pretraga')}
                    value={filter}
                    onChange={handleFilterChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Icon>search</Icon>
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton disabled>
                                    <Icon>clear</Icon>
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                {filter && <i className="fas fa-times clear-icon" onClick={handleClearFilter}></i>}
            </div>
            <div className="table-wrapper">
                <table className="custom-table">
                    <thead>
                        <tr>
                            {Object.keys(columnFilters).map((key) => (
                                <th key={key} className='order-status-table-column'>
                                    <TableSortLabel
                                        active={sortConfig.key === key}
                                        direction={sortConfig.key === key ? sortConfig.direction : 'asc'}
                                        onClick={() => handleSort(key)}
                                    >
                                        {t(key)}
                                        <i className="fas fa-sort mx-1"></i>
                                    </TableSortLabel>
                                </th>
                            ))}
                        </tr>
                        <tr>
                            {Object.keys(columnFilters).map((key) => (
                                <th key={key} className='order-status-filter-column'>
                                    {key === 'adDate' ? (
                                        <OrderStatusCustomDatePicker
                                            value={columnFilters.adDate}
                                            onFilterChanged={handleDateChange}
                                            id={key}
                                            placeholder={t(key)}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <i className="fas fa-search search-icon-order-status"></i>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    ) : (<TextField
                                        type="search"
                                        placeholder={t(key)}
                                        value={columnFilters[key]}
                                        onChange={(e) => handleColumnFilterChange(e, key)}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <i className="fas fa-search search-icon-order-status"></i>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    )}
                                </th>
                            ))}
                            <th>
                                <div className="filter-reset-button" title={t('obrisiSveFiltere')} onClick={handleClearFilter}><i className="far fa-times-circle"></i></div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedData.map((row) => (
                            <tr key={row._id} className='order-status-row' onClick={() => setOrderDetailFunction(row)}>
                                {Object.keys(columnFilters).map((key) => (
                                        
                                    <td key={key}>
                                        {key === 'acOutsourceShipment' && row[key] ? (
                                            <div>
                                                {row[key].split(',').map((cargoId, index) => (
                                                    <div key={index} className="cargo-id-link" onClick={(e) => { e.stopPropagation(); handleCargoId(cargoId.trim()); }}>{cargoId.trim()}</div>
                                                ))}
                                            </div>
                                        ) : (key === 'anValue' || key === 'anVAT' || key === 'anForPay')  ? (
                                                <div style={{textAlign: 'right'}}><CurrencyFormat num={row[key]} /></div>
                                        ) : row[key]}
                                    </td>
                                ))}
                                <td className="action-buttons">
                                    <i className="fas fa-redo repeat-icon-order-status" onClick={(e) => { e.stopPropagation(); setFunctionAgainOrderAction(row, 'C'); }}></i>
                                    <i className="fas fa-shopping-cart repeat-icon-order-status" onClick={(e) => { e.stopPropagation(); setFunctionAgainOrderAction(row, 'I'); }}></i>
                                    {row.isEditable == 2 ? (
                                        <i className="fas fa-paperclip repeat-icon-order-status" title={t('dodajDokument1')} onClick={(e) => { e.stopPropagation(); showUploadModalFunction(row); }}></i>
                                    ) : row.isEditable == 3 ? (
                                        <i className="fas fa-paperclip repeat-icon-order-status-disabled" title={t('dodajDokument2')} onClick={(e) => { e.stopPropagation(); notEditableAttachment(); }}></i>
                                    ) : null}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            
            <div className="pagination">
                <Select
                    value={itemsPerPage}
                    onChange={(e) => setItemsPerPage(Number(e.target.value))}
                    className="items-per-page-select"
                >
                    {[10, 20, 50, 100].map((size) => (
                        <MenuItem key={size} value={size}>
                            {size}
                        </MenuItem>
                    ))}
                </Select>
                <div className='order-status-pagination-button' title={t('prvaStrana')} onClick={() => setCurrentPage(1)} disabled={currentPage === 1}>
                    <i className="fas fa-grip-lines-vertical"></i>
                    <i className="fas fa-chevron-left"></i>
                </div>
                <div className='order-status-pagination-button' title={t('prethodnaStrana')} onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))} disabled={currentPage === 1}>
                    <i className="fas fa-chevron-left"></i>
                </div>
                <Typography variant="caption">
                    {currentPage} / {totalPages}
                </Typography>
                <div className='order-status-pagination-button' title={t('sledecaStrana')} onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))} disabled={currentPage === totalPages}>
                    <i className="fas fa-chevron-right"></i>
                </div>
                <div className='order-status-pagination-button' title={t('poslednjaStrana')} onClick={() => setCurrentPage(totalPages)} disabled={currentPage === totalPages}>
                    <i className="fas fa-chevron-right"></i>
                    <i className="fas fa-grip-lines-vertical"></i>
                </div>
                {/* <button className='btn btn-primary btn-sm' onClick={() => setCurrentPage(1)} disabled={currentPage === 1}>
                    {t('prvaStrana')}
                </button>
                <button className='btn btn-primary btn-sm' onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))} disabled={currentPage === 1}>
                    {t('prethodnaStrana')}
                </button>
                <span>
                    {currentPage} / {totalPages}
                </span>
                <button className='btn btn-primary btn-sm' onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))} disabled={currentPage === totalPages}>
                    {t('sledecaStrana')}
                </button>
                <button className='btn btn-primary btn-sm' onClick={() => setCurrentPage(totalPages)} disabled={currentPage === totalPages}>
                    {t('poslednjaStrana')}
                </button> */}
            </div>

        </div>
    );
};

export default CustomTableOrderStatus;
