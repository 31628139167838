// import 'bootstrap/dist/css/bootstrap.min.css';
import captchaImg from '../../img/captcha.jpg';
import studentIMG from '../../img/2.png';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { getLoyaltyProducts, getLoyaltySettings } from '../../redux/actions/productActions';
import { createLoyaltyOrder } from '../../redux/actions/loyaltyActions';

import { axiosinstance } from '../../configAxios';
import { useTranslation } from 'react-i18next';

import { toast } from 'react-toastify';
import MessageInfoBox from '../other/MessageInfoBox';
import SecureLS from 'secure-ls';

const LoyaltyFinishModalSpecialAction = ({ acProduct, anQty, anProductKey, anProductId, acProductName, anPrice, handleClose, values, setFinishCartFunction, finishCart, setEmptyValues, type, start, end }) => {

    const { t } = useTranslation('other', 'cartPage');
    const userInfoData = useSelector((state) => state.userInfo.userInfo);
    const dispatch = useDispatch();
    const [delivery, setDelivery] = useState('I');

    const inputFeilds = document.querySelectorAll(".loyalty-input-value");
    const validInputs = Array.from(inputFeilds).filter(input => input.value !== "");
    let items = [];

    validInputs.forEach(element => {
        let item = {
            anQty: element.value,
            acProductName: element.getAttribute('data-product-name'),
            acProduct: element.getAttribute('data-product-ident'),
            anPrice: element.getAttribute('data-product-price'),
            anProductId: element.getAttribute('data-product-id'),
        };
        items.push(item);
    });
    let totalPrice = 0;
    totalPrice = anQty * anPrice;
    totalPrice = totalPrice.toFixed(2);
    totalPrice = totalPrice.replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');

    const onSubmit = e => {

        e.preventDefault();

        if (anQty === 0) {
            toast(<MessageInfoBox message='LoyProductSold' />, {
                className: 'red-background'
            });
            handleClose();
            return;
        }

        var myFunctions = function () {

            // Podaci o kupovini
            var objArr = [];
            var objArrMail = [];
            var obj = {};
            var objMail = {};
            obj['anQty'] = anQty;
            obj['anProductKey'] = anProductKey;
            obj['anProductId'] = anProductId;
            objArr.push(obj);

            objMail['anQty'] = anQty;
            objMail['anProductKey'] = anProductKey;
            objMail['acProductName'] = acProductName;
            objMail['anPrice'] = anPrice;
            objMail['acProduct'] = acProduct;
            objMail['anPriceTotal'] = anQty * anPrice;
            objArrMail.push(objMail);

            var totalSum = 0;
            objArrMail.forEach(element => {
                totalSum += element.anPriceTotal;
            });

            // Formatiranje brojeva za mail
            objArrMail.map(item => {
                item.anPrice = Math.round(item.anPrice * 100) / 100
                item.anPriceTotal = Math.round(item.anPriceTotal * 100) / 100
            })

            totalSum = Math.round(totalSum * 100) / 100

            const attr = {};
            attr['anUserId'] = userInfoData ? userInfoData.company.department[0].user[0].anUserKey : 0;
            const axiosConfig = axiosinstance(attr, '8224', 'getCurrentTime');

            axios(axiosConfig)
                .then(function (response) {
                    let trenutniDatum = new Date(response.data.response.acTime);
                    let krajnjiDatum = new Date(end);
                    let pocetniDatum = new Date(start);

                    if (trenutniDatum < pocetniDatum || trenutniDatum > krajnjiDatum) {
                        toast(<MessageInfoBox message={'Dogodila se greška'} type={0} />, {
                            className: 'red-background'
                        });
                    } else {
                        const attributes = {};
                        attributes['anUserKey'] = userInfoData ? userInfoData.company.department[0].user[0].anUserKey : 0;
                        attributes["anCompanyKey"] = userInfoData ? userInfoData.company.anCompanyKey : 0;
                        attributes["acDelivery"] = delivery;
                        attributes['acLoyalityTable'] = objArr;
                        attributes['anLoyaltyTypeId'] = type;

                        const configRes = axiosinstance(attributes, "8225", "postLoyalityFinishCart");

                        axios(configRes)
                            .then(function (response) {
                                if (response.data.response.isOk == 1) {

                                    dispatch({
                                        type: 'SET_MESSAGE_INFO',
                                        payload: response.data
                                    });

                                    toast(<MessageInfoBox message={response.data.response.acMsg} type={response.data.response.isOk} />, {
                                        className: response.data.response.isOk == 1 ? 'green-background' : 'red-background',
                                    });

                                    dispatch(getLoyaltyProducts(2));
                                    dispatch(getLoyaltySettings(2));
                                    document.getElementById("form-loyalty-shop").reset();
                                    setEmptyValues([])
                                    handleClose();

                                    axios({
                                        method: "POST",
                                        url: "https://newb2b.ewe.rs:5000/send-mail-loyalty",
                                        data: {
                                            objArrMail: objArrMail,
                                            totalSum: totalSum,
                                            delivery: delivery,
                                            userInfoData: userInfoData,
                                            cartId: response.data.response.b2bCart._id,
                                            type: type
                                        }
                                    }).then((response) => {
                                        window.location.reload();
                                        // if (response.data.msg === 'success') {
                                        //     // alert("Email sent, awesome!");
                                        // } else if (response.data.msg === 'fail') {
                                        //     // alert("Oops, something went wrong. Try again")
                                        // }
                                    })

                                } else {
                                    toast(<MessageInfoBox message={response.data.response.acMsg} type={response.data.response.isOk} />, {
                                        className: response.data.response.isOk == 1 ? 'green-background' : 'red-background',
                                    });

                                    setFinishCartFunction(!finishCart);
                                    document.getElementById("form-loyalty-shop").reset();
                                    setEmptyValues([])
                                    handleClose();
                                    window.location.reload();
                                }
                            })
                            .catch(function (error) {
                                console.log(error);
                            });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
        setTimeout(myFunctions, 500);
    };

    return (
        <div className="container">
            <div className="row">

                <div className="col-md-12">
                    <div className="main-cart-items-table-custom">
                        <table className="table main-cart-table table-striped table-hover">
                            <thead>
                                <tr>
                                    <th className="width-150-td" style={{ border: "none", padding: ".5rem" }}>Sifra</th>
                                    <th className="" style={{ border: "none", padding: ".5rem" }}>Naziv</th>
                                    <th className="width-150-td" style={{ border: "none", padding: ".5rem" }}>Cena</th>
                                    <th className="width-150-td" style={{ border: "none", padding: ".5rem" }}>Količina</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr >
                                    <td>{acProduct}</td>
                                    <td>{acProductName}</td>
                                    <td>{anPrice} EUR</td>
                                    <td style={{ textAlign: "center" }}>{anQty}</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                    <div className='holder-summary' style={{ display: "flex", marginTop: "30px", marginBottom: "30px", justifyContent: "space-between" }}>
                        <div className='col-sm-5'>
                            <div className="form-group  loyalty-captcha-image-code" style={{ display: "flex", alignItems: "center" }}>
                                <label className="mr-3" style={{ textWrap: "nowrap" }}>{t('nacinDostave')}</label>
                                <select name="" className="form-control" onChange={(e) => setDelivery(e.target.value)}>
                                    <option value="I">{t('isporukaNaAdrKupca')}</option>
                                    <option value="Z">{t('licnoUzemunu')}</option>
                                    <option value="L">{t('licnoUlestanima')}</option>
                                </select>
                            </div>
                        </div>
                        <div className='col-sm-4'></div>
                        <div className='col-sm-3' style={{ display: "flex" }}>
                            <label className='span-info-cart-box'>UKUPNO :</label>
                            <span><b>{totalPrice} EUR </b></span>
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className='col-sm-10'>
                        </div>
                        <button type="button" id="succesBTN" onClick={onSubmit} className="btn btn-success col-sm-2">{t('potvrdiKupovinu')}</button>
                    </div>

                </div>
            </div >
        </div >
    );
}
export default LoyaltyFinishModalSpecialAction;