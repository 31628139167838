import React, { useEffect, useState } from 'react';
import CatalogFilterSelectedItem from './CatalogFilterSelectedItem';
import { useLocation } from "react-router-dom";
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

function useQuery() {
  return new URLSearchParams(useLocation().search);
};

const CatalogFilterSelectedContent = () => {

  const filtersData = useSelector((state) => state.catalogFilters);
  const filtersDataBrand = useSelector((state) => state.catalogFiltersBrand);
  const filtersDataSpecialAction = useSelector((state) => state.catalogFiltersSpecialAction);
  let query = useQuery();
  const history = useHistory();
  const [filtersList, setFiltersList] = useState([]);
  const { t } = useTranslation('catalog');


  useEffect(() => {
    var kvp = history.location.search.substr(1).split('&');
    var objArr = [];
    if (kvp != '' && filtersData.length > 0) {
      kvp.forEach(item => {
        var itemFilType = item.split('=')[0].split('_')[0];

        if (itemFilType == 'brend') {

          var itemFil = item.split('=')[0].split('_')[1];
          var itemSubFilter = item.split('=')[1].split('_')[1];
          var filterItem = filtersDataBrand.filter(x => x.anId == itemSubFilter);
          var acAttributeName = t('Brend');
          var acAttributeURLName = t('brend');
          var anAttributeId = 0;
          var acAttributeValue = filterItem.length > 0 ? filterItem[0].acString : '';
          var anAttributeValue = filterItem.length > 0 ? filterItem[0].anId : 0;

          var acUrlValueTmp = filterItem.length > 0 ? filterItem[0].acString.toLowerCase() : '';
          var acUrlValue = acUrlValueTmp.replace(' ', '-');

        } else if (itemFilType == 'akcija') {

          var itemFil = item.split('=')[0].split('_')[1];
          var itemSubFilter = item.split('=')[1].split('_')[1];
          var filterItem = filtersDataSpecialAction.filter(x => x.anId == itemSubFilter);
          // console.log(filterItem, 'filterItem-------filterItem');
          var acAttributeName = t('Akcija');
          var acAttributeURLName = t('akcija');
          var anAttributeId = 1;
          var acAttributeValue = filterItem.length > 0 ? filterItem[0].acString : '';
          var anAttributeValue = filterItem.length > 0 ? filterItem[0].anId : 0;

          var acUrlValueTmp = filterItem.length > 0 ? filterItem[0].acString.toLowerCase() : '';
          var acUrlValue = acUrlValueTmp.replace(' ', '-');

        } else if (itemFilType == 'cena') {

          var priceMin = item.split('=')[1].split('_')[0];
          var priceMax = item.split('=')[1].split('_')[1];

          var acAttributeName = t('Cena');
          var acAttributeURLName = t('cena');
          var anAttributeId = '14';
          var acAttributeValue = priceMin + ' - ' + priceMax;
          var anAttributeValue = '62';
          var acUrlValue = '133';

        } else {

          var itemFil = item.split('=')[0].split('_')[1];
          var itemSubFilter = item.split('=')[1].split('_')[1];
          var filterItem = filtersData && filtersData.filter(x => x.anAttributeId == itemFil);
          var filterSubItem = filterItem.length > 0 ? filterItem[0].attributeValueList.filter(x => x.anAttributeValue == itemSubFilter) : "";
          var acAttributeName = filterItem.length > 0 ? filterItem[0].acAttributeName : "";
          var acAttributeURLName = filterItem.length > 0 ? filterItem[0].acAttributeURLName : "";
          var anAttributeId = filterItem.length > 0 ? filterItem[0].anAttributeId : 0;

          var acAttributeValue = filterSubItem.length > 0 ? filterSubItem[0].acAttributeValue : '';
          var anAttributeValue = filterSubItem.length > 0 ? filterSubItem[0].anAttributeValue : '';
          var acUrlValue = filterSubItem.length > 0 ? filterSubItem[0].acUrlValue : '';

        }

        if (filterItem !== undefined) {
          if (filterItem.length > 0) {
            objArr.push(acAttributeName + ';' + acAttributeURLName + ';' + anAttributeId + ';' + acAttributeValue + ';' + anAttributeValue + ';' + acUrlValue);
          }
        }

      });
      setFiltersList(objArr);
    } else {
      setFiltersList([]);
    }
  }, [query.toString(), filtersDataSpecialAction, filtersDataBrand, filtersData]);


  return (
    <>
      {filtersList.length > 0 && filtersList.map((item, key) => (
        <CatalogFilterSelectedItem key={key} isActive="1" item={item} />
      ))}
    </>
  );
};

export default CatalogFilterSelectedContent;