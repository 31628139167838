import React, { useEffect, useState } from 'react';
import PageHelmet from '../components/helmet/PageHelmet';
import { useDispatch } from 'react-redux';

import globalVariable from '../global';
import { globalStatistic } from '../redux/actions/otherActions';
import { getPageSeo } from '../redux/actions/pageActions';
import { useTranslation } from 'react-i18next';

import styles from "../css/Humanity.module.css";

const scrollToTop = () => {
    window.scrollTo(0, 0)
}

const HumanityTreePage = () => {

    const dispatch = useDispatch();
    const [pageSeoData, setPageSeoData] = useState({});
    const { t } = useTranslation('humanityTreePage');

    useEffect(() => {
        scrollToTop();
        dispatch(globalStatistic(760, 'PAGE'));
        dispatch(getPageSeo(760)).then(res => {
            if (res) {
                setPageSeoData(res);
            }
        })
    }, [])

    return (
        <>
            {Object.keys(pageSeoData).length !== 0 &&
                <PageHelmet
                    title={pageSeoData ? `${pageSeoData.acSeoTitle}` : "Ewe Comp"}
                    description={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    keywords={pageSeoData ? `${pageSeoData.acSeoKeywords}` : ""}
                    ogDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    ogTitle={pageSeoData ? `${pageSeoData.acSeoTitle}` : ""}
                    ogUrl={pageSeoData ? `https://ewe.rs/${pageSeoData.acSeoUrl}` : ""}
                    ogImg="https://www.ewe.rs/media/ewe-og-img.jpg"
                    ogType="article"
                    twitterDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                />
            }
            <div className={`container-fluid ${styles['humanity-page']}`}>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="row">
                            <img src={`${globalVariable.domainName}/gui/humanity/main11.png`} className="img-fluid" style={{ width: "100%" }} />
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className={styles['container-text-block']}>
                                    <p className={`${styles['title-tree']} ${styles['title-block-humanity']}`} style={{ fontSize: "30pt", fontWeight: "bold", lineHeight: "45px", color: "#000", marginTop: "60px" }}>{t('naslov1')}</p>
                                    <p className={styles['body-block-humanity']} style={{ fontSize: "19pt", marginBottom: "30px" }}>{t('paragraf1')}</p>
                                    <p className={styles['body-block-humanity']} style={{ fontSize: "19pt" }}>{t('paragraf2')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="row" style={{ backgroundColor: "#d7e6bd" }}>

                            <div className="container">
                                <div className='row'>
                                    <div className="col-md-6 text-right">
                                        <p className={styles['content-body-block-3']} style={{ paddingTop: "65px", paddingBottom: "75px" }}>{t('paragraf3')}</p>
                                    </div>
                                    <div className="col-md-6 text-center">
                                        <img src={`${globalVariable.domainName}/gui/humanity/slika111.png`} style={{ transform: `rotate(12deg)`, position: "absolute" }} className={`img-fluid ${styles['img-style']}`} />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-sm-12" style={{ marginTop: "40px", marginBottom: "30px" }}>
                        <div className="row">
                            <div className="container">
                                <div className='row'>
                                    <div className="col-md-6 text-center">
                                        <img src={`${globalVariable.domainName}/gui/humanity/slika222.png`} style={{ transform: `rotate(-12deg)`, position: "absolute", marginLeft: "50px" }} className={`img-fluid ${styles['img-style']}`} />
                                    </div>
                                    <div className="col-md-6 text-left">
                                        <p className={styles['content-body-block-4']} style={{ fontSize: "16pt" }}>{t('paragraf4')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="row" style={{ backgroundColor: "#d7e6bd" }}>
                            <div className="container">
                                <div className="col-sm-12 text-center">
                                    <p className={styles['content-body-block-6']} style={{ paddingTop: "60px", fontSize: "19pt" }}>{t('paragraf5')}</p>
                                </div>
                                <div className="col-sm-12 text-center">
                                    <p className={styles['content-body-block-6']} style={{ paddingTop: "20px", fontSize: "19pt" }}>{t('paragraf6')}</p>
                                </div>
                                <div className="col-sm-12 text-center" style={{ marginTop: "40px", paddingBottom: "80px" }}>
                                    <div className="row" style={{ paddingTop: "35px" }}>
                                        <div className="col-md-6" style={{ textAlign: "left" }}>
                                            <img src={`${globalVariable.domainName}/gui/humanity/bottom1.png`} className="img-fluid" />
                                        </div>
                                        <div className={`col-md-6 ${styles['img-top-style']}`} style={{ textAlign: "right" }}>
                                            <img src={`${globalVariable.domainName}/gui/humanity/bottom2.png`} className="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="row">
                            <div className="container">
                                <div className="col-sm-12 text-center">
                                    <div className={styles['content-body-block-6']} style={{ paddingTop: "60px", fontSize: "19pt", textAlign: "center" }}>{t('paragraf7')}</div>
                                </div>
                                <div className="col-sm-12 text-center">
                                    <div className={styles['content-body-block-6']} style={{ paddingTop: "20px", fontSize: "19pt", textAlign: "center", marginTop: "10px", marginBottom: "10px" }}>{t('paragraf8')}</div>
                                </div>
                                <div className="col-sm-12 text-center">
                                    <div className={styles['content-body-block-6']} style={{ paddingTop: "20px", fontSize: "30pt", textAlign: "center", fontWeight: "bold", letterSpacing: "3px", marginTop: "15px" }}>{t('paragraf9')}</div>
                                </div>
                                <div className="col-sm-12 text-center" style={{ marginTop: "40px", paddingBottom: "80px" }}>
                                    <img src={`${globalVariable.domainName}/gui/humanity/footer.png`} className="img-fluid" style={{ margin: "0 auto", marginTop: "20px" }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HumanityTreePage
