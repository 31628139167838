import React, { useState, useEffect } from 'react'
import { Link, useParams, useHistory } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import axios from 'axios';
import { axiosinstance } from '../configAxios';
import { useTranslation } from 'react-i18next';

import MessageInfoBox from '../components/other/MessageInfoBox';

const ForgotPasswordPage = () => {

    const [values, setValues] = useState({
        newPassword: "",
        passRepeat: ""
    });

    const [newPass, setNewPass] = useState(0)
    const [passwordRep, setPasswordRep] = useState(0)
    const { token } = useParams();
    const history = useHistory();
    const { t } = useTranslation(['forgotPasswordPage', 'routes']);

    const resetPassword = (e) => {
        e.preventDefault()

        if (values.newPassword != "") {
            setNewPass(2)
        } else {
            setNewPass(1)
        }

        if (values.passRepeat != "") {
            if (values.passRepeat != values.newPassword) {
                setPasswordRep(1)
            } else {
                setPasswordRep(2)
            }
        } else {
            setPasswordRep(1)
        }

        if (values.passRepeat == values.newPassword && values.newPassword != "") {
            try {
                const attributes = {};
                attributes['acTokken'] = token;
                attributes['acPassword'] = values.newPassword;
                attributes['acNewPassword'] = values.passRepeat;

                const configRes = axiosinstance(attributes, '8224', 'postConfirmResetForgottenPassword');

                axios(configRes)
                    .then(function (response) {
                        toast(<MessageInfoBox message={response.data.response.acMsg} type={response.data.response.isOk} />, {
                            className: response.data.response.isOk == 1 ? 'green-background' : 'red-background',
                        });
                        if (response.data.response.isOk == 1) {
                            setTimeout(() => {
                                history.push(`/${t('routes:prijava')}`);
                            }, 1500);
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        toast(<MessageInfoBox message="globalError" type="0" />, {
                            className: 'red-background',
                        });
                    });
            } catch (error) {
                console.log(error)
                toast(<MessageInfoBox message="globalError" type="0" />, {
                    className: 'red-background',
                });
            }
        } else {
            toast(<MessageInfoBox message='errorReqiuredPasswordInputs' type={0} />, {
                className: 'red-background',
            });
        }

    }

    const onChangeFunction = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    }

    return (
        <div className="login-container-holder forgot-password-block">
            <div className="login-holder-block-form">
                <div className="container-login-form">
                    <form>
                        <div className="logo-login-image">
                            <Link to="/">
                                <img src={process.env.PUBLIC_URL + '/ewe-login.png'} className="img-fluid" alt="ewe" />
                            </Link>
                        </div>
                        <h2>{t('naslov1')}</h2>
                        <div className="form-group">
                            <label htmlFor="newPassword">{t('label1')}</label>
                            <Form.Control
                                type="password"
                                id="newPassword"
                                name="newPassword"
                                isInvalid={newPass == 1}
                                isValid={newPass == 2}
                                onChange={(e) => onChangeFunction(e)}
                            />
                            {newPass == 1 &&
                                <div className="invalid-feedback d-block">
                                    {t('porukaGreske1')}
                                </div>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="passRepeat">{t('label2')}</label>
                            <Form.Control
                                type="password"
                                id="passRepeat"
                                name="passRepeat"
                                isInvalid={passwordRep == 1}
                                isValid={passwordRep == 2}
                                onChange={(e) => onChangeFunction(e)}
                            />
                            {passwordRep == 1 &&
                                <div className="invalid-feedback d-block">
                                    {t('porukaGreske2')}
                                </div>
                            }
                        </div>
                        <div className="button-login-content text-center">
                            <button className="btn btn-success text-center custom-button-login" onClick={resetPassword}>{t('dugme')}</button>
                        </div>
                    </form>
                </div>
            </div>
            <div className="forgot-password-img">
                <img src={process.env.PUBLIC_URL + '/zaboravljena-lozinka.jpg'} className="img-fluid" alt="forgot pass" />
            </div>
        </div>

    )
}

export default ForgotPasswordPage