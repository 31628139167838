import React, { useEffect } from 'react';
import Countdown from "react-countdown";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { currencyFormat, customLogger } from "../../helperFunctions";
import { getProductDetailForPopup } from '../../redux/actions/productActions';
import { globalStatistic } from "../../redux/actions/otherActions";
import { useTranslation } from 'react-i18next';

const BlockActionArticleItem = ({ height, item, checkSeenPopup, show }) => {

    const dispatch = useDispatch();
    const productDetailItem = useSelector((state) => state.productDetail.items[0]);
    const activeCurrency = useSelector((state) => state.activeCurrency.item);
    // const today = new Date();
    const endDate = item.adEnd ? new Date(item.adEnd) : new Date();
    // today.setDate(today.getDate() + 1);
    // today.setHours(0, 0, 0);

    const ttoday = endDate.getTime();
    const { t } = useTranslation('block');

    // Random component
    const Completionist = () => <span>{t('isteklaAkcija')}!</span>;

    // Renderer callback with condition
    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return <Completionist />;
        } else {
            // Render a countdown
            return (
                <>
                    <div>{hours < 10 ? "0" + hours : hours}</div>:<div>{minutes < 10 ? "0" + minutes : minutes}</div>:<div>{seconds < 10 ? "0" + seconds : seconds}</div>
                </>
            );
        }
    };

    useEffect(() => {
        customLogger('other', 'item from BlockActionArticleItem', item);
        if (item.acPopupType == 0) {
            if (item.acPopupProduct) {
                let id = item.acPopupProduct.substring(0, item.acPopupProduct.length - 1);
                dispatch(getProductDetailForPopup(id));
            } else {
                return (
                    // <div className="action-block action-block-no-content">
                    //     <div className="action-block-content action-block-content-no-content">
                    //         <img alt="no items placeholder" src="http://www.ewe.rs/media/banners/2022/08/2022-08-04868740.gif"></img>
                    //     </div>
                    // </div>
                    ""
                )
            }
        }
    }, [])

    // if (!productDetailItem && item.acPopupType == 0) {
    //     return (
    //         // <div className="action-block action-block-no-content">
    //         //     <div className="action-block-content action-block-content-no-content">
    //         //         <img alt="no items placeholder" src="http://www.ewe.rs/media/banners/2022/08/2022-08-04868740.gif"></img>
    //         //     </div>
    //         // </div>
    //         ""
    //     )
    // }

    const handleClick = (anPopupKey, anPopupOrgId) => {
        // console.log('clicked "Detaljnije"')
        if (checkSeenPopup != undefined) {
            // console.log('from popup')
            checkSeenPopup(anPopupKey);
            show(false);
        } else {
            // console.log('from homega')
        }
        dispatch(globalStatistic(anPopupOrgId, 'POPUP_DETAIL'));
    }

    // console.log(item.anPopupKey, 'anPopupKey');
    // console.log(item.anPopupOrgId, 'anPopupOrgId');

    return (
        <div className="action-block" style={item.height ? { 'height': item.height } : {}}>
            <div className="action-block-content">
                {item && item.acPopupType == 2 ? (
                    <div className="action-block-content-image">
                        <Link to={item.acPopupLink} onClick={(e) => handleClick(item.anPopupKey)}>
                            <div className="action-item-image-block" style={{ backgroundImage: `url(https://resource.ewe.rs/media/${item && item.acPopupImage})` }} title={item.acSeoTitle}></div>
                            {/* <div className="action-item-image-block" style={{ backgroundImage: `url(/media/banners/768x440.jpg)` }}></div> */}
                            {/* <img src={`http://www.ewe.rs/media/${item && item.acPopupImage}`} alt="EWE COMP DOO" /> */}
                        </Link>
                    </div>
                ) : (
                    <>
                        <div className="action-block-content-image">
                            <div className="action-item-image-block" style={{ backgroundImage: `url(https://resource.ewe.rs/media/${item && item.acPopupImage})` }} title={item.acSeoTitle}></div>
                            {/* <div className="action-item-image-block" style={{ backgroundImage: `url(/media/banners/768x440.jpg)` }}></div> */}
                            {/* <img src={`http://www.ewe.rs/media/${item && item.acPopupImage}`} alt="EWE COMP DOO" /> */}
                        </div>
                        <div className="action-block-content-body">
                            {item && item.acPopupType == 2 ? (
                                item.acPopupLogo && <img src={`https://resource.ewe.rs/media/${item.acPopupLogo}`} className="img-fluid" alt={`${item.acSeoDescription}`} title={`${item.acSeoTitle}`} />
                            ) : (
                                <>
                                    {item.acPopupLogo && <img src={`https://resource.ewe.rs/media/${item.acPopupLogo}`} className="img-fluid" alt={`${item.acSeoDescription}`} title={`${item.acSeoTitle}`} />}
                                    {/* <img src={`http://resource.ewe.rs/manufacturers/XIAOMI.png`} className="img-fluid" alt="b2b" /> */}
                                    <h3 title={item && item.acPopupTitle}>{item && item.acPopupTitle}</h3>
                                    <p title={item && item.acPopupSubtitle}>{item && item.acPopupSubtitle}</p>
                                    {item && item.acPopupType == 0 ? (
                                        <>
                                            {/* {(productDetailItem !== undefined) &&
                                                (productDetailItem.anPrice &&
                                                    <div className="price-item-action">
                                                        <span>Redovna cena: </span> <span className="price-item-value-action">{currencyFormat(productDetailItem.anPrice)} &euro;</span>
                                                    </div>
                                                )
                                            } */}
                                            <div className="action-block-price action-block-price-action">
                                                <span>{t('AkcijskaCena')}: </span> <span className="price-item-value-action">{item && currencyFormat(item.anPromoPrice)} &euro;</span>
                                            </div>
                                            {item.isPaymentAdvanceIncluded == 1 && (
                                                <div className="action-block-price action-block-price-advance">
                                                    <span>{t('cenaZaAvans')}: </span> <span className="price-item-value-action">{item && currencyFormat(item.anPromoPrice - (item.anPromoPrice * item.anPaymentAdvance / 100))} &euro;</span>
                                                </div>
                                            )}
                                        </>
                                    ) : (
                                        <div className="popup-type-line"></div>
                                    )}
                                    <div className="short-desc-action-block" title={item && item.acPopupContent}>
                                        {item && item.acPopupContent}
                                        {item && item.isPaymentAdvanceIncluded == 1 && (
                                            <>
                                                <br /> {t('avansniRabat')} {productDetailItem ? productDetailItem.anPaymentAdvance : ""}%
                                            </>
                                        )}
                                    </div>
                                    <div className="timer-action-block">
                                        <div className="timer-item">
                                            {item.isTimerIncluded && <Countdown date={ttoday} renderer={renderer} />}
                                            <Link to={item.acPopupLink} onClick={(e) => handleClick(item.anPopupKey, item.anPopupOrgId)}>
                                                <button className="shop-item-btn-action">{t('detaljnije')}</button>
                                            </Link>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default BlockActionArticleItem