import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchPostsProduct } from '../../redux/actions/productActions';
import { getProductDetail } from '../../redux/actions/productActions';

import InputQtyPlusMinus from '../other/InputQtyPlusMinus';
import AddToCartButton from '../cart/AddToCartButton';
import CurrencyFormat from '../other/CurrencyFormat';

import { removeSpecialChar, customLogger } from '../../helperFunctions'
import { withTranslation } from 'react-i18next';

class ProductBanner extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            qtyProduct: 1
        };
    };
    
    componentDidMount() {
        // customLogger('other', 'item iz propsa u komponenti ProductBanner', this.props.item);
        var anProduct = this.props.item.acProducts.split(',');

        this.props.fetchPostsProduct(0, 0, anProduct[0]);
    };

    componentDidUpdate(prevProps) {
        if (prevProps.item !== this.props.item) {
            var anProduct = this.props.item.acProducts.split(',');
            this.props.fetchPostsProduct(0, 0, anProduct[0]);
        }
    }

    // componentWillUnmount() {
    //     // window.removeEventListener('storage', this.checkStorage)
    // }
    
    functionQtyValue(qty) {
        this.setState({
            qtyProduct: qty
        });
    };

    render() {
        const { t } = this.props;
        return (
            <>
                {this.props.catalogBannerProduct.length !== 0 && this.props.catalogBannerProduct[0].anStock > 0 && (
                    <div className="catalog-product-top-action">
                        <div className="catalog-banner-product-content">
                            <div className="catalog-product-image">
                                <img className="img-fluid img-logo-brand-product" src={`https://resource.ewe.rs/manufacturers/${this.props.catalogBannerProduct[0].acDept}.png`} alt={`${this.props.catalogBannerProduct[0].acDept}`} title={`${this.props.catalogBannerProduct[0].acDept}`} />
                                <img className="img-fluid img-product-action" src={`https://resource.ewe.rs/products/${this.props.catalogBannerProduct[0].acProduct}_v.jpg`} alt={`${this.props.item.acSeoDescription}`} title={`${this.props.item.acSeoTitle}`} />
                            </div>
                            <div className="catalog-product-body">
                                <div className="content-span-product-badges">
                                    <div className="badge-item-product-action ident-badge">{t('banner:šifra')}: {this.props.catalogBannerProduct[0].acProduct}</div>
                                    {this.props.catalogBannerProduct[0].specialList != null && this.props.catalogBannerProduct[0].specialList.length > 0 && (
                                        this.props.catalogBannerProduct[0].specialList.map((item, key) => (
                                            <div className="badge-item-product-action new-badge" title={item.acText} style={{ backgroundColor: `${item.acColor}` }}><i className={item.acIconClass}></i>&nbsp; {item.acSpecialName}</div>
                                        ))
                                    )}
                                </div>
                                <div className="catalog-product-body-content-text">
                                    <div className="catalog-product-body-text">
                                        <Link to={`/${t('routes:proizvod')}/${this.props.item.acProducts.split(',')[0]}/${removeSpecialChar(this.props.catalogBannerProduct[0].acName)}`} className="title-catalog-product-action">
                                            {this.props.catalogBannerProduct[0].acDept} {this.props.catalogBannerProduct[0].acName}
                                        </Link>
                                        <div className="specification-catalog-product-action">{this.props.catalogBannerProduct[0].acInlineSpecification}</div>
                                    </div>
                                    <div className="catalog-product-body-price">
                                        <table className="table-spec-product-action">
                                            <tbody>
                                                {this.props.catalogBannerProduct[0].anOldPrice > 0 &&
                                                    <tr>
                                                        <td>{t('banner:staraCena')}:</td>
                                                        <td className="price-table-row-product-action price-row-old"><CurrencyFormat num={this.props.catalogBannerProduct[0].anOldPrice} /> {this.props.catalogBannerProduct[0].anOldPrice != 0 && this.props.activeCurrency.acShortName}</td>
                                                    </tr>
                                                }
                                                <tr style={{borderBottom: '1px solid #cecece'}}>
                                                    <td>{t('banner:cena')}:</td>
                                                    <td className="price-table-row-product-action price-row-action"><CurrencyFormat num={this.props.catalogBannerProduct[0].anPrice} /> {this.props.catalogBannerProduct[0].anOldPrice != 0 && this.props.activeCurrency.acShortName}</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td className="qty-bottom-price-product-action">{t('banner:lager')}: <span >{this.props.catalogBannerProduct[0].anStock}</span></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table className="table-spec-product-action">
                                            <tbody>
                                                <tr>
                                                    <td style={{width: '50%'}}>
                                                        <InputQtyPlusMinus functionSetQty={this.functionQtyValue} qty="10" />
                                                    </td>
                                                    <td>
                                                        <div className="btn-add-special-product-action">
                                                            <AddToCartButton product={this.props.catalogBannerProduct[0]} qtyValue={this.state.qtyProduct} name={t('dodajUKorpu')} ident={this.props.catalogBannerProduct[0].anProductKey} />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </>
        );
    };

};

const mapStateToProps = (state) => {
    return { 
        catalogBannerProduct: state.catalogBannerProduct,
        productDetail: state.productDetail.items[0],
        activeCurrency: state.activeCurrency.item
    };
};

export default withTranslation(['banner', 'routes'])(connect(
    mapStateToProps,
    { fetchPostsProduct, getProductDetail }
)(ProductBanner));