import { useEffect } from "react";
import { useLocation } from "react-router-dom";

// Ivan Žarković, Jun 2022. Oblak Tehnologije
// funkcija vraća vertikalni scroll na početak stranice
// poziva se u BrowserRouter-u na index.js strani kako bi se skrolovalo prilikom svake promene stranice

export default function ScrollToTopHelper() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}