import React from 'react'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import globalVariable from '../../global';

const OutletCategoryItem = ({ item }) => {
    const { t } = useTranslation('outletCategoryItem');
    return (
        <>
            {item.anParentKey == 13 ? (
                <div className="content-outlet-item-holder">
                    <div className="outlet-page-products-info">
                        <h2>{item.acName}</h2>
                    </div>
                    <div className="outlet-page-image-holder">
                        {!item.acImage ?
                            <i className="fas fa-laptop logout-page-no-img"></i>
                            :
                            <img className="img-fluid" src={`${globalVariable.domainName}/media/${item.acImage}`} alt='outlet' />
                            // <img className="img-fluid" src="/images/out1.png" />
                        }
                    </div>
                </div>
            ) : (
                <div className="content-products-item-holder">
                    {!item.acImage ?
                        <i className="fas fa-laptop logout-page-no-img"></i>
                        :
                        <img className="img-fluid" src={`${globalVariable.domainName}/media/${item.acImage}`} />
                    }
                    <div className="logout-page-products-info">
                        <h2>{item.acName}</h2>
                        {item.anParentKey != 13 && (
                            <p>{item.anProductCount} {item.anProductCount === 1 ? t('proizvod') : t('proizvoda')}</p>
                        )}
                    </div>
                </div>
            )}
        </>
    )
}

export default OutletCategoryItem