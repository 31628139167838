import React, { useEffect, useState } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';


const ModalAdminUsers = ({ show, handleClose, item, sendData, modalContent, getCompanyDepartmentList, getRolesList, check, loggedUser }) => {

    const [values, setValues] = useState({
        acFirstName: item.acFirstName,
        acLastName: item.acLastName,
        acEmail: item.acEmail,
        anDepartmentKey: getCompanyDepartmentList.filter(dept => dept.acDepartment == item.acDepartment)[0].anDepartmentKey,
        anRoleKey: item.anRoleKey,
        acJobPosition: item.acJobPosition,
        newPassword: "",
        passRep: "",
    })
    const { t } = useTranslation('modal');

    const [validPassword, setValidPassword] = useState(0)

    const onChangeFunction = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value })

        if ([e.target.name] == "passRep") {
            if (e.target.value !== values.newPassword) {
                setValidPassword(1)
            } else {
                setValidPassword(2)
            }

            if (e.target.value == "") {
                setValidPassword(0);
            }
        }
    }

    const sendDataInput = (modalContent, values) => {
        if (modalContent == "input") {
            if (values.newPassword != "" && validPassword == 2) {
                sendData(values, item)
            } else if (values.newPassword == "" && validPassword == 0) {
                sendData(values, item)
            }
        } else {
            if (check.checked == true) {
                sendData({ ...values, "isActive": 0 }, item)
            } else {
                sendData({ ...values, "isActive": 1 }, item)
            }
        }
        handleClose(false);
    }

    return (
        <Modal
            show={show}
            onHide={() => handleClose(false)}
            keyboard={false}
            dialogClassName={modalContent == "input" ? 'modal-xl' : 'modal-md'}
            aria-labelledby=""
            centered
        >
            <div className="close-action-modal" variant="secondary" onClick={() => handleClose(false)}>
                <i className="far fa-times-circle"></i>
            </div>
            <Modal.Header>
                <Modal.Title>
                    {modalContent == "input" ?
                        <h3 className="title-block-profile-data">{t('izmenaPodataka')} {item.acFirstName} {item.acLastName}</h3>
                        :
                        <h3 className="title-block-profile-data">{check.checked == true ? t('deaktivacija') : t('aktivacija')} {t('korisnika')} {item.acFirstName} {item.acLastName}</h3>
                    }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {modalContent == "input" ?
                    <div className="block-profile-data">
                        <div className="block-profile-data-content">
                            <form>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="acFirstName">{t('ime')}:</label>
                                            <input
                                                id='acFirstName'
                                                name="acFirstName"
                                                type="text"
                                                className="form-control custom-input-check form-control-sm"
                                                value={values.acFirstName}
                                                onChange={(e) => onChangeFunction(e)}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="acLastName">{t('prezime')}:</label>
                                            <input
                                                id='acLastName'
                                                name="acLastName"
                                                type="text"
                                                className="form-control custom-input-check form-control-sm"
                                                value={values.acLastName}
                                                onChange={(e) => onChangeFunction(e)}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="acEmail">{t('mail')}:</label>
                                            <input
                                                disabled={true}
                                                id='acEmail'
                                                name="acEmail"
                                                type="text"
                                                className="form-control custom-input-check form-control-sm"
                                                value={values.acEmail}
                                            // onChange={(e) => onChangeFunction(e)}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="anDepartmentKey">{t('radnja')}:</label>
                                            <select
                                                className="form-control form-control-sm"
                                                name="anDepartmentKey"
                                                id="anDepartmentKey"
                                                onChange={(e) => onChangeFunction(e)}
                                                value={values.anDepartmentKey}
                                                disabled={item.anRoleKey != 1 || item.anUserKey == loggedUser.anUserKey && true}
                                            >
                                                {getCompanyDepartmentList.map((item2, key) => (
                                                    <option key={key} value={item2.anDepartmentKey}>{item2.acDepartment}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="anRoleKey">{t('rola')}:</label>
                                            <select
                                                className="form-control form-control-sm"
                                                name="anRoleKey"
                                                id="anRoleKey"
                                                onChange={(e) => onChangeFunction(e)}
                                                value={values.anRoleKey}
                                            >
                                                {getRolesList.map((item2, key) => (
                                                    item2.acRole != "Neulogovani korisnik" && ( // hotfix, treba iz baze da se isključi 22.09.2022.
                                                        <option key={key} value={item2._id}>{item2.acRole}</option>
                                                    )
                                                ))}
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="acEmail">{t('pozicija')}:</label>
                                            <input
                                                id='acJobPosition'
                                                type="text"
                                                name="acJobPosition"
                                                className="form-control custom-input-check form-control-sm"
                                                value={values.acJobPosition}
                                                onChange={(e) => onChangeFunction(e)}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="newPassword">{t('novaLozinka')}:</label>
                                            <input
                                                id='newPassword'
                                                name="newPassword"
                                                type="password"
                                                className="form-control custom-input-check form-control-sm"
                                                value={values.newPassword}
                                                autoComplete="new-password"
                                                onChange={(e) => onChangeFunction(e)}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="passRep">{t('ponovoUnesiteNovu')}:</label>
                                            <Form.Control
                                                size="sm"
                                                type="password"
                                                id="passRep"
                                                name="passRep"
                                                isInvalid={validPassword == 1}
                                                isValid={validPassword == 2}
                                                // autoComplete="off"
                                                onChange={(e) => onChangeFunction(e)}
                                            />
                                            {validPassword == 1 &&
                                                <div className="invalid-feedback d-block">
                                                    {t('lozNijeIsta')}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    :
                    <span>{t('daLiSteSigurni')} {check.checked == true ? t('deaktivirate') : t('aktivirate')} {t('korisnika')} <b>{item.acFirstName} {item.acLastName}</b>?</span>
                }
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-success" onClick={() => sendDataInput(modalContent, values)}>{t('potvrdi')}</button>
                <button className="btn btn-secondary" onClick={() => handleClose(false)}>{t('zatvori')}</button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalAdminUsers;