export const getLoyaltyProducts = (state = [], action) => {
    switch (action.type) {
        case "SET_LOYALTY_PRODUCTS":
            return action.payload;
        default:
            return state;
    }
};

export const getLoyaltySettings = (state = [], action) => {
    switch (action.type) {
        case "SET_LOYALTY_SETTINGS":
            return action.payload;
        default:
            return state;
    }
};