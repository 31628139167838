import React, { createRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import { axiosinstance } from '../../configAxios';
import { toast } from 'react-toastify';
import MessageInfoBox from '../../components/other/MessageInfoBox';
import { useTranslation } from 'react-i18next';

const ModalOrderStatus = ({ show, handleClose, item, setOrderList }) => {
    const uploadPath = "endUser";
    const userInfoData = useSelector((state) => state.userInfo.userInfo);
    const [imgName, setImgName] = useState('');
    const [acAccount, setAcAccount] = useState(item.acAccount);
    const [oldImgPath, setOldImgPath] = useState(item.acFilePath);
    const { t } = useTranslation('modal');

    const onChangeFiscalClippingFunction = (e) => {
        setAcAccount(e.target.value);
    };
    const sendData = (attributes) => {
        const configRes = axiosinstance(attributes, '8224', 'postEndUserDeliveryFile');

        axios(configRes)
            .then(function (response) {
                toast(<MessageInfoBox message={response.data.response.acMsg} type={response.data.response.isOk} />, {
                    className: response.data.response.isOk == 1 ? 'green-background' : 'red-background',
                });
                if (response.data.response.isOk == 1) {
                    attributes['anCompanyKey'] = userInfoData ? userInfoData.company.anCompanyKey : 0;
                    const configRes = axiosinstance(attributes, '8224', 'postSaleHistory');
                    axios(configRes)
                        .then(function (response) {
                            setOrderList(response.data.response.saleHistoryList);
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                    handleClose();
                }
            })
            .catch(function (error) {
                toast(<MessageInfoBox message="globalError" type="0" />, {
                    className: 'red-background',
                });
            });
    };
    const handleFileInput = (e) => {
        e.preventDefault();
        setImgName(e.target.files[0])
    }
    const resetFileValue = (e) => {
        e.target.value = null
    }
    const removeAttachment = (e) => {
        setOldImgPath('');
        setImgName('');
    }
    const saveData = (id) => {
        if (imgName) {
            const formData = new FormData();
            formData.append('document', imgName);
            try {
                axios.post('https://newb2b.ewe.rs:5000/image-upload/' + uploadPath + '', formData)
                    .then(res => {
                        // console.log(res);
                        if (res.data.isOk == 1) {
                            var attributes = {};
                            try {
                                attributes['anSaleHistoryKey'] = id;
                                attributes['acAccount'] = acAccount;
                                attributes['acFilePath'] = `/media/${uploadPath}/${res.data.fileName}`;
                                sendData(attributes);
                            } catch (error) {
                                console.log(error);
                                toast(<MessageInfoBox message="globalError" type="0" />, {
                                    className: 'red-background',
                                });
                            }

                        } else {
                            toast(<MessageInfoBox message={res.data.msg} type={res.data.isOk} />, {
                                className: res.data.isOk == 1 ? 'green-background' : 'red-background',
                            });
                        }
                    })
            } catch (error) {
                console.log(error);
                toast(<MessageInfoBox message="globalError" type="0" />, {
                    className: 'red-background',
                });
            }
        } else {
            var attributes = {};
            attributes['anSaleHistoryKey'] = id;
            attributes['acAccount'] = acAccount;
            attributes['acFilePath'] = oldImgPath ? oldImgPath : '';
            sendData(attributes);
        }

    }

    return (
        <Modal
            show={show}
            onHide={handleClose}
            keyboard={false}
            dialogClassName='modal-xl'
            aria-labelledby=""
            centered
        >
            <div className="close-action-modal" variant="secondary" onClick={handleClose}>
                <i className="far fa-times-circle"></i>
            </div>
            <Modal.Header>
                <Modal.Title>
                    {t('isporukaKrajnjemKorisniku')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="alert alert-primary" role="alert">{t('neophodniPodaciUformi')}</div>
                <label htmlFor="acAccount">{t('brFisIsecka')}</label>
                <input type="text" name="acAccount" id='acAccount' className="form-control" placeholder="Unesite broj fiskalnog isečka" onChange={(e) => onChangeFiscalClippingFunction(e)} value={acAccount} />
                <div className="delivery-attachment-content">
                    <label className='delivery-attachment-label'>
                        <span className="delivery-file-label">{t('fisIsecak')}<i className="fas fa-file-upload"></i></span>
                        <input type="file" name="acFilePath" onChange={handleFileInput} onClick={resetFileValue} className="form-control delivery-attachment-input" />
                    </label>
                    {imgName != "" ?
                        <div className="delivery-img-name">{imgName.name} <span className='remove-attachment' onClick={removeAttachment}><i className="fas fa-trash"></i></span></div> :
                        oldImgPath ?
                            <div className="delivery-img-name"><a className='delivery-img-link' href={`https://resource.ewe.rs/${oldImgPath}`} rel='noreferrer' target='_blank'>{t('dokument')}</a> <span className='remove-attachment' onClick={removeAttachment}><i className="fas fa-trash"></i></span></div> :
                            <div className='delivery-img-name'>{t('nijePrilozenDokument')}</div>}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-info" onClick={() => saveData(item._id)}>{t('sacuvajte')}</button>
                <button className="btn btn-secondary" onClick={handleClose}>{t('zatvorite')}</button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalOrderStatus;