import React from 'react';
import { Page, Text, View, Image, StyleSheet, Link } from "@react-pdf/renderer";
import CurrencyFormat from '../../other/CurrencyFormat';
import globalVariable from '../../../global';

const CustomCatalogPdfItem = (props) => {

    const { item, currency } = props;

    const styles = StyleSheet.create({
        tableRow: { flexDirection: "row" },
        tableCol1: {
            width: "80px",
            // backgroundColor: "#fafafa",
            borderBottom: 1,
            borderBottomStyle: "dotted",
            paddingVertical: 15,
            paddingHorizontal: 10,
            borderColor: "#e6e6e6",
            justifyContent: "center",
            textAlign: "left",
        },
        tableCol2: {
            width: "70%",
            paddingHorizontal: 5,
            borderBottom: 1,
            borderBottomStyle: "dotted",
            paddingVertical: 15,
            paddingHorizontal: 10,
            borderColor: "#e6e6e6",

            alignItems: "left",
        },
        tableCol3: {
            width: "20%",
            borderBottom: 1,
            borderBottomStyle: "dotted",
            paddingVertical: 30,
            paddingHorizontal: 10,
            borderColor: "#e6e6e6",
            textAlign: "right",
            justifyContent: "bottom",
            alignItems: "bottom",
        },
    });

    return (
        <View style={styles.tableRow} wrap={false}>
            <View style={styles.tableCol1}>
                {/* <Image style={{width: "80%"}} src={"https://resource.ewe.rs/products/" + item.acProduct.trim() + "_v.jpg"} /> */}
                {/* <Link src={"/proizvod/" + item.anProductkey + "/" + item.acName.replace(/\s|[,\/]/g, "_")}> */}
                <Image style={{ width: "100%" }} src={`${globalVariable.imagesPath}products/${item.acProduct.trim()}_v.jpg`} />
                {/* </Link> */}
            </View>
            <View style={styles.tableCol2}>
                <Text style={{ paddingVertical: 3 }}>{item.acProduct}</Text>
                <Text>
                    {item.acDept.replace(" 1****", "")} {item.acName}
                </Text>
            </View>
            <View style={styles.tableCol3}>
                <Text>
                    <CurrencyFormat num={item.anPricePdf ? item.anPricePdf : item.anPrice} /> {currency}
                </Text>
            </View>
        </View>
    );
}

export default CustomCatalogPdfItem;
