import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    addProductWishList,
    removeProductWishList
} from '../../redux/actions/productActions';
import { useTranslation } from 'react-i18next';

const WishListButton = ({ title, product }) => {
    const { t } = useTranslation(['wishListButton']);
    const iconPath = process.env.PUBLIC_URL + '/media/';

    const dispatch = useDispatch();
    const [checkBox, setCheckBox] = useState(product.isWish);

    const addWishListItem = (item, e) => {
        var isChecked = e.target.checked;

        if (isChecked === true) {
            setCheckBox(1);
            dispatch(addProductWishList(product));
        } else {
            setCheckBox(0);
            dispatch(removeProductWishList(product, 0));
        }
    }

    const setCheckBoxFunction = () => { }

    var checked = '';

    if (product.isWish == 1) {
        if (checkBox == 1) {
            checked = 'checked';
        } else {
            checked = '';
        }
    } else {
        if (checkBox == 1) {
            checked = 'checked';
        } else {
            checked = '';
        }
    };

    return (
        <div className="pretty pretty-custom-global pretty-wishlist p-icon p-toggle" onClick={(e) => addWishListItem(product, e)} title={t('listaZeljaTitle')}>
            <input
                type="checkbox"
                checked={checked}
                onChange={() => setCheckBoxFunction()}
            />
            <div className="state p-on">
                {<img
                    src={`${iconPath}icon-lista-zelja-on.svg`}
                    alt=""
                />}
                <label>{t('listaZelja')}</label>
            </div>
            <div className="state p-off">
                {<img
                    src={`${iconPath}icon-lista-zelja.svg`}
                    alt=""
                />}
                <label>{t('listaZelja')}</label>
            </div>
        </div >
    );
};

export default WishListButton;