import React from 'react';
import { Link } from 'react-router-dom';
import { useState } from 'react';

const CatalogProductBadgeImage = ({ item, key }) => {

    const [showActionInfo, setShowActionInfo] = useState(false);
    const [showActionKey, setShowActionKey] = useState();

    const showText = (key) => {
        setShowActionInfo(true)
        setShowActionKey(key)
    }

    const hideText = () => {
        setShowActionInfo(false)
    }

    return (
        <Link to={item.acLink.includes("http") ? { pathname: item.acLink, } : item.acLink} target="_blank">
            <div className="product-catalog-badge-image" title={item.acAdditionalInfoName}
                onMouseEnter={() => showText(key)}
                onMouseLeave={hideText}
            ><img src={`https://resource.ewe.rs/media/${item.acImage}`} alt="" className="img-fluid" /></div>
        </Link>
    )
}

export default CatalogProductBadgeImage;
