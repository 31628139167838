import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { getLastProductsList } from '../redux/actions/productActions';
import { globalStatistic } from '../redux/actions/otherActions';
import { getPageSeo } from '../redux/actions/pageActions';

import CatalogPreviewBlock from '../components/catalog/CatalogPreviewBlock';
import PageBanner from '../components/banner/PageBanner';
import PageHelmet from '../components/helmet/PageHelmet';
import { useTranslation } from 'react-i18next';

const LastProductListPage = () => {
    const dispatch = useDispatch();

    const getLastProducts = useSelector((state) => state.getLastProducts);
    const [pageSeoData, setPageSeoData] = useState({});
    const { t } = useTranslation('lastProductListPage');

    useEffect(() => {
        dispatch(getPageSeo(541)).then(res => {
            if (res) {
                setPageSeoData(res);
            }
        });
        dispatch(getLastProductsList());
        dispatch(globalStatistic(541, 'PAGE'));
    }, []);

    if (!getLastProducts) {
        return null;
    }

    return (
        <>
            {Object.keys(pageSeoData).length !== 0 &&
                <PageHelmet
                    title={pageSeoData ? `${pageSeoData.acSeoTitle}` : "Ewe Comp"}
                    description={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    keywords={pageSeoData ? `${pageSeoData.acSeoKeywords}` : ""}
                    ogDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    ogTitle={pageSeoData ? `${pageSeoData.acSeoTitle}` : ""}
                    ogUrl={pageSeoData ? `https://ewe.rs/${pageSeoData.acSeoUrl}` : ""}
                    ogImg="https://www.ewe.rs/media/ewe-og-img.jpg"
                    twitterDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                />
            }
            <div className="container-content container-catalog-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="text-center banner-image-on-top-page">
                            <PageBanner categoryType="" categoryId="" pageId={369} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="weekly-block-page">
                            <h1 className="weekly-title">{pageSeoData.acSeoHeader ? pageSeoData.acSeoHeader : t('naslov')}</h1>
                            {pageSeoData.acSeoShortPageDescription && <p>{pageSeoData.acSeoShortPageDescription}</p>}
                            <CatalogPreviewBlock itemsList={getLastProducts} sortable="0" preview="1" export="0" itemPreview="catalog" pdfType="nedeljna" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LastProductListPage;