import React, { useState, useEffect } from 'react';
//import BlockActionSliderItem from '../blocks/BlockActionSliderItem';
import LoyaltyBlockHomeItem from './LoyaltyBlockHomeItem';
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getLoyaltySettings, getLoyaltyProducts } from "../../redux/actions/productActions";
import "../../css/Loyalty.css";
import globalVariable from '../../global';
import { useTranslation } from 'react-i18next';

function SampleArrow(props) {
    return null;
}

const LoyaltyBlockHome = (props) => {

    const dispatch = useDispatch();
    const getUserBanner = useSelector((state) => state.getUserBanner.items);
    const bannerListItems = getUserBanner.filter((x) => x.anPageKey === 251);
    const bannerList = bannerListItems.filter((x) => x.anBannerPosition === 4);

    const loyaltyProductsList = useSelector((state) => state.getLoyaltyProducts);
    const loyaltySettings = useSelector((state) => state.getLoyaltySettings);
    const getUserInfo = useSelector((state) => state.userInfo);
    const { t } = useTranslation('loyalty');

    // const [loyaltyArticles, setLoyaltyArticles] = useState([]);

    const [activeShop, setActiveShop] = useState(0);

    // const bennerListItems = getUserBanner.filter((x) => x.anPageKey === 251);
    // const bennerList = bennerListItems.filter((x) => x.anBannerPosition === 3);

    // var startDaySettings = new Date(loyaltySettings["adLoyaltyStartDate"]);
    // var endDaySettings = new Date(loyaltySettings["adLoyaltyEndDate"]);
    // var openedTime = "11:00:00";

    var startDate = loyaltySettings['adLoyaltyStartDate'];
    var endDate = loyaltySettings['adLoyaltyEndDate'];

    const checkLoyaltyShopOpened = () => {

        var d = new Date();

        //minuti
        if (d.getMinutes() < 10) {
            var minutes = '0' + d.getMinutes();
        } else {
            var minutes = d.getMinutes();
        }

        var month = d.getMonth() + 1;
        //dani
        if (d.getMonth() + 1 < 10) {
            let sumMonth = d.getMonth() + 1;
            month = '0' + sumMonth;
        }

        var fullCurrentDate = d.getFullYear() + '-' + month + '-' + d.getDate() + ' ' + d.getHours() + ":" + minutes + ":" + d.getSeconds();


        if (fullCurrentDate <= startDate && fullCurrentDate >= endDate) {
            setActiveShop(0);
        } else if (fullCurrentDate >= startDate && fullCurrentDate <= endDate) {
            setActiveShop(1);
        }
    }

    useEffect(() => {
        dispatch(getLoyaltySettings());
        dispatch(getLoyaltyProducts());
    }, []);

    useEffect(() => {
        checkLoyaltyShopOpened();
        // console.log(loyaltySettings, 'loyaltySettings');
    }, [loyaltySettings]);

    var responsive = [
        {
            breakpoint: 1921,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            },
        },
        {
            breakpoint: 1680,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2,
            },
        },
        {
            breakpoint: 1440,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            },
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1,
            },
        },
    ];

    const settingsSlider = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        responsive: responsive,
        // nextArrow: <SampleArrow />,
        // prevArrow: <SampleArrow />
    };

    // console.log(loyaltyProductsList, 'loyaltyProductsList')
    // loyaltyProductsList.forEach(element => {
    //     // console.log(element.loyaltyShopItemList, 'element')
    //     setLoyaltyArticles([...loyaltyArticles, element.loyaltyShopItemList]);
    // });

    // useEffect(() => {
    //     loyaltyProductsList.forEach(element => {
    //         console.log(element.loyaltyShopItemList, 'element')
    //         // setLoyaltyArticles([...loyaltyArticles, element.loyaltyShopItemList]);
    //     });
    // }, [loyaltyProductsList]);

    // console.log(loyaltyArticles, 'loyaltyArticles')

    // console.log(loyaltyProductsList, 'loyaltyProductsList')

    if (!loyaltyProductsList) {
        return null;
    }

    if (!getUserInfo) {
        return null;
    }

    // console.log(loyaltyProductsList, 'loyaltyProductsList')

    return (
        <>
            {loyaltySettings.anCompanyTarget > 0 && (
                <div className="action-block-special loyalty-slider-content">
                    <div className="action-block-special-content">
                        <div className="action-block-special-banner">
                            <Link to={bannerList[0] && bannerList[0].acBannerLink}>
                                <div className="action-block-special-banner-background-wrapper" style={{ overflow: 'hidden' }}>

                                    <div className="action-block-special-banner-bacground" style={{ background: `url(akcija-baner-2.jpg)`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>

                                    </div>
                                    {/* <img src="akcija-baner-2.jpg" className="img-fluid" alt="" /> */}
                                    {bannerList[0] && (<img src={`${globalVariable.imagesPath}/media/${bannerList[0].acBannerImage}`} alt={`${bannerList[0].acSeoDescription}`} title={`${bannerList[0].acSeoTitle}`} />)}
                                </div>

                                {/* <div className="action-block-special-banner-bacground">

                            </div>
                            <img src="akcija-baner-2.jpg" className="img-fluid" alt="" /> */}
                                <div className="special-block-image-mask">
                                    <div className="special-block-image-mask-content">
                                        <div className="special-block-image-mask-name">{bannerList[0] && bannerList[0].acBannerTitle}</div>
                                        <div className="special-block-image-mask-btn">
                                            <div className="special-block-image-mask-btn-item">
                                                <Link to={bannerList[0] && bannerList[0].acBannerLink}>{t('detaljnije')}</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="action-block-special-slider">
                            <Link to="/loyalty-artikli" className='link'>
                                <div className="action-block-special-slider-title-main">
                                    <h2>{t('n')}<span>{t('EWE')}</span>{t('rovatanShop')}</h2>
                                </div>
                            </Link>

                            {loyaltySettings.anCompanyTarget > 0 ? (
                                loyaltyProductsList.companyLoyalityShop ? (
                                    <div className="action-block-special-slider-main-slider">
                                        {loyaltyProductsList.companyLoyalityShop.length > 0 && (
                                            <Slider {...settingsSlider}>
                                                {loyaltyProductsList.companyLoyalityShop.map((item2, key2) => {
                                                    return (
                                                        item2.loyaltyShopItemList.map((item, key) => (
                                                            <LoyaltyBlockHomeItem
                                                                item={item}
                                                                key={key}
                                                                isMaxWidth={loyaltyProductsList.companyLoyalityShop.length}
                                                                shopIsActive={activeShop}
                                                                getUserInfo={getUserInfo.userInfo.companyAdditionalInfo}
                                                                loyaltySettings={loyaltySettings}
                                                            />
                                                        ))
                                                    );
                                                })}
                                            </Slider>
                                        )}
                                    </div>
                                ) : (
                                    // 22
                                    <div className="loyalty-msg-holder">
                                        <div className="alert alert-primary" role="alert">
                                            {t('nisuSetovaniArtikli')}
                                        </div>
                                    </div>
                                )
                            ) : (
                                <div className="loyalty-msg-holder">
                                    <div className="alert alert-danger w-100" role="alert">
                                        {t('nijeDefinisanTarget')}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default LoyaltyBlockHome;
