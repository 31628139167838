import React from 'react';
import { Helmet } from 'react-helmet-async';

const PageHelmet = ({ title, description, ogDescription, ogTitle, ogUrl, ogImg, twitterDescription, keywords, ogType }) => {
    const currentUrl = window.location.href;
    return (
        <Helmet>
            {description && <meta name="description" content={description} />}
            {keywords && <meta name="keywords" content={keywords} />}
            <link rel="canonical" href={currentUrl} />

            {description && <meta property="og:description" content={description} />}
            {title && <meta property="og:title" content={title} />}
            {ogUrl && <meta property="og:url" content={ogUrl} />}
            {ogImg && <meta property="og:image" content={ogImg} />}
            {ogImg && <meta property="og:image:width" content="1200" />}
            {ogImg && <meta property="og:image:height" content="630" />}
            {ogType && <meta property="og:type" content={ogType} />}

            {/* {title && <meta property="twitter:title" content={title} />}
            {description && <meta property="twitter:description" content={description} />}
            {ogUrl && <meta property="twitter:url" content={ogUrl} />}
            {ogImg && <meta property="twitter:image" content={ogImg} />}
            {keywords && <meta name="twitter:keywords" content={keywords} />}
            <meta name="twitter:creator" content="@korisnickoime" />
            <meta name="twitter:site" content="https://ewe.rs/" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:lang" content="sr" />
            {ogImg && <meta name="twitter:image:alt" content="Opis slike" /> } */}
            <title>{title}</title>
            {/* Dodajte ostale tagove po potrebi */}
        </Helmet>
    );
};

export default PageHelmet;