import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import imageExists  from 'image-exists';
import { useTranslation } from 'react-i18next';

const LogoItem = ({ logo }) => {

    const [currentImage, setCurrentImage] = useState(`https://resource.ewe.rs/manufacturers/${logo}.png`);
    const [srcImage, setSrcImage] = useState('');
    const { t } = useTranslation('routes');

    useEffect(() => {
        imageExists(currentImage, img => {
            if(img) {
                setSrcImage(currentImage);
            };
        });
    }, []);

    return (
        <>
            {srcImage && (
                <div className="logo-item-logout-page">
                    <Link to={`/${t('pretraga')}?q=${logo.toLowerCase()}&brand=${logo}`}>
                        <img src={srcImage} alt="" />
                    </Link>
                </div>
            )}
        </>
    );
};

export default LogoItem;