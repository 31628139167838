import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import useMediaQuery from '@mui/material/useMediaQuery';

import FormInput from '../components/form/FormInput';
import MessageInfoBox from '../components/other/MessageInfoBox';
import PageBanner from '../components/banner/PageBanner';
import PageHelmet from '../components/helmet/PageHelmet';

import globalVariable from '../global';
import { globalStatistic } from '../redux/actions/otherActions';
import { getPageSeo } from '../redux/actions/pageActions';
import { useTranslation, Trans } from 'react-i18next';

const CustomerCarePage = () => {

    const dispatch = useDispatch()

    const queries = useMediaQuery('(max-width: 1024px)');
    const userInfo = useSelector((state) => state.userInfo);
    const userEmails = userInfo.userInfo ? userInfo.userInfo.company.acCompanyMail1.split(';') : '';
    const [honeyPot, setHoneyPot] = useState(0);
    const [pageSeoData, setPageSeoData] = useState({});
    const { t } = useTranslation('customerCare');

    const [values, setValues] = useState({
        emailContact: userEmails[0],
        nameContact: "",
        textContact: ""
    });

    const [errors, setErrors] = useState({
        emailContact: 1,
        nameContact: 1,
        textContact: 1
    });

    const [validInput, setValidInput] = useState({
        emailContact: 0,
        nameContact: 0,
        textContact: 0
    })

    useEffect(() => {
        dispatch(globalStatistic(55, 'PAGE'));
        dispatch(getPageSeo(55)).then(res => {
            if (res) {
                setPageSeoData(res);
            }
        });
    }, []);

    const onChangeFunction = (e) => {
        // honeypot provera
        if (e.target.name == 'contact_us_via_pigeon') {
            if (e.target.value != '') {
                setHoneyPot(1)
            } else {
                setHoneyPot(0)
            }
        }
        setValues({ ...values, [e.target.name]: e.target.value });
    };

    const setErrorsFunction = (name, val) => {
        setErrors({ ...errors, [name]: val });
    };

    const handleSubmit = (e) => {
        if (honeyPot == 0) {
            setValidInput({
                ...validInput,
                emailContact: values.emailContact == '' && 1,
                nameContact: values.nameContact == '' && 1,
                textContact: values.textContact == '' && 1,

            });

            e.preventDefault();

            if (errors.emailContact == 0 && errors.nameContact == 0 && errors.textContact == 0) {

                //slanje email-a
                axios({
                    method: "POST",
                    url: "https://newb2b.ewe.rs:5000/contact",
                    data: {
                        values: values,
                    }
                }).then((response) => {

                    if (response.data.isOk == 1) {

                        toast(<MessageInfoBox message={response.data.acMsg} type={response.data.isOk} />, {
                            className: response.data.isOk == 1 ? 'green-background' : 'red-background',
                        });

                        //resetovanje inputa i provera obaveznih polja
                        setValidInput({
                            emailContact: 0,
                            nameContact: 0,
                            textContact: 0
                        });

                        setErrors({
                            emailContact: 1,
                            nameContact: 1,
                            textContact: 1
                        })

                        setValues({
                            emailContact: "",
                            nameContact: "",
                            textContact: ""
                        });

                    }
                });

            } else {
                toast(<MessageInfoBox message='errorReqiuredFormInputs' type={0} />, {
                    className: 'red-background',
                });
            }
        }

    };
    return (
        <>
            {Object.keys(pageSeoData).length !== 0 &&
                <PageHelmet
                    title={pageSeoData ? `${pageSeoData.acSeoTitle}` : "Ewe Comp"}
                    description={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    keywords={pageSeoData ? `${pageSeoData.acSeoKeywords}` : ""}
                    ogDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    ogTitle={pageSeoData ? `${pageSeoData.acSeoTitle}` : ""}
                    ogUrl={pageSeoData ? `https://ewe.rs/${pageSeoData.acSeoUrl}` : ""}
                    ogImg="https://www.ewe.rs/media/ewe-og-img.jpg"
                    ogType="website"
                    twitterDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                />
            }
            <div className="container-content container-catalog-content customer-care-page">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="banner-image-on-top-page">
                                <PageBanner categoryType="" categoryId="" pageId="55" />
                            </div>
                            <div className="stats-order-content-holder">
                                <h1>{pageSeoData.acSeoHeader ? pageSeoData.acSeoHeader : t('naslov1')}</h1>
                                {pageSeoData.acSeoShortPageDescription && <p>{pageSeoData.acSeoShortPageDescription}</p>}
                            </div>

                            <div className="contact-desc">
                                <p>
                                    <Trans i18nKey="customerCare:paragraf1" components={{ br: <br /> }} />
                                </p>
                            </div>
                            <div className="row">
                                <div className={queries ? "col-sm-12 customer-care-info" : "col-sm-5"}>
                                    <div className="customer-care-left">
                                        <img alt="ar glasses" className="img-responsive" src={`${globalVariable.domainName}/media/ar-glasses.png`} />
                                    </div>
                                    {/* <br /> */}
                                    <h4>{t('naslov2')}</h4>
                                    {/* <br /> */}
                                    {t('paragraf2')}<br />
                                    <a href={`tel:${t('tel1')}`} target="_self">{t('tel2')}</a><br />
                                    <br />
                                    {t('paragraf3')}<br />
                                    <a href={`mailto:${t('email')}`} target="_self">{t('email')}</a>
                                    <br />
                                </div>
                                <div className={queries ? "col-sm-12" : "col-sm-7"}>
                                    <form name="contactFormLogout" className="contactFormLogout">
                                        <div className="form-group">
                                            <FormInput
                                                type="text"
                                                name="nameContact"
                                                placeholder={t('placeholder1')}
                                                isRequired="1"
                                                nameClass="contactName"
                                                messageError={t('porukaGreske1')}
                                                label={t('inputLabel1')}
                                                value={values['nameContact']}
                                                onChange={(e) => onChangeFunction(e)}
                                                validInputValue={validInput.nameContact}
                                                setErrorsFunc={setErrorsFunction}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <FormInput
                                                type="email"
                                                name="emailContact"
                                                placeholder={t('placeholder2')}
                                                isRequired="1"
                                                nameClass="contactEmail"
                                                messageError={t('porukaGreske2')}
                                                label={t('inputLabel2')}
                                                value={values['emailContact']}
                                                onChange={(e) => onChangeFunction(e)}
                                                validInputValue={validInput.emailContact}
                                                setErrorsFunc={setErrorsFunction}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <FormInput
                                                type="textarea"
                                                name="textContact"
                                                isRequired="1"
                                                nameClass="contactDesc"
                                                placeholder={t('placeholder3')}
                                                messageError={t('porukaGreske1')}
                                                label={t('inputLabel3')}
                                                value={values['textContact']}
                                                onChange={(e) => onChangeFunction(e)}
                                                validInputValue={validInput.textContact}
                                                setErrorsFunc={setErrorsFunction}
                                            />
                                        </div>
                                        {/* HONEY POT POLJE */}
                                        <input type="text" name="contact_us_via_pigeon" value="" style={{ display: "none" }} autoComplete="off" onChange={(e) => onChangeFunction(e)} />
                                        <div className="button-add-holder-article">
                                            <button type="submit" className="btn btn-info btn-add-article-pickupreturn" onClick={handleSubmit}>{t('dugmePosalji')}</button>
                                        </div>

                                    </form>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div >
        </>
    );
};

export default CustomerCarePage;