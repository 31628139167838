const globalVariable = {
  'domainName': 'https://resource.ewe.rs',
  'imagesPath': 'https://resource.ewe.rs/',
  // 'url': 'http://b2b-front.ewe.rs/',
  'url': 'https://www.ewe.rs/',
  'sessionToken': '44881e1070b14f60b0fdaef35cc18fef09aeb6db60a89cdbe6a08661d2eb3b53',
  '3dPath': 'https://resource3d.ewe.rs/',
  // 'work3Path': 'http://work3.ewe.rs/',
};

export default globalVariable;