import React from 'react';

const CompareProductsBlock = (props) => {


    return (

        <tbody>
            <tr>
                <th colSpan={props.colspan + 1}><span className="compare-product-row">{props.name}</span></th>
            </tr>
            {props.children}
        </tbody>
    );
}

export default CompareProductsBlock;
