import React, { useState } from 'react';

const FilterCheckboxBrandInput = ({ checkedBox, onClickCheckboxFilterFunction, item }) => {

    return (
        <>
            <input 
            type="checkbox" 
            className="form-check-input" 
            onChange={(e) => onClickCheckboxFilterFunction(e, item, item.acString, 'brand')} 
            id={`checbox${item.acString}`} 
            />
        </>
    )
};

export default FilterCheckboxBrandInput;