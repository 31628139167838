import React, { useState, useEffect } from 'react';
import LoyaltyItem from '../components/loyalty/LoyaltyItem';
import '../css/Loyalty.css';
import { useDispatch, useSelector } from 'react-redux';
import { getLoyaltyProducts, getLoyaltySettings } from '../redux/actions/productActions';
import { Modal, Image, Container, Row, Col } from 'react-bootstrap';
import CurrencyFormat from '../components/other/CurrencyFormat';
import { globalStatistic, getCurrentTime } from '../redux/actions/otherActions';
// import CountdownLoyalty from '../components/loyalty/CountdownLoyalty';
import ScrollToTop from '../components/other/ScrollToTop';
import PageHelmet from '../components/helmet/PageHelmet';
import { getPageSeo } from '../redux/actions/pageActions';
import EweGoCartLoyaltyModal from '../components/other/EweGoCartLoyaltyModal';
import LoyaltyCounterBlock from '../components/loyalty/LoyaltyCounterBlock';

// import FlipClock from "x-react-flipclock";
import Countdown, { zeroPad } from 'react-countdown';

const Tehnovacije2023LoyaltyShopPage = () => {

    const dispatch = useDispatch();
    const loyaltyProductsList = useSelector((state) => state.getLoyaltyProducts);
    const loyaltySettings = useSelector((state) => state.getLoyaltySettings);
    const getUserInfo = useSelector((state) => state.userInfo.userInfo.companyAdditionalInfo);
    const getCurrentServerTime = useSelector((state) => state.getCurrentServerTime.items)
    // var objArr = {};
    const [show, setShow] = useState(false);
    const [counter, setCounter] = useState(0);
    // const [activeShop, setActiveShop] = useState(0);

    const [finishCart, setFinishCart] = useState(false);
    const [emptyValues, setEmptyValues] = useState([]);
    const [now, setNow] = useState(Date.parse(getCurrentServerTime));
    const [pageSeoData, setPageSeoData] = useState({});
    const activeShop = loyaltySettings['isActive'];
    const isDesktop = window.innerWidth > 1020;

    // const now = Date.now();
    // const dummyNow = new Date(2022, 9, 26, 11, 23);
    const adLoyaltyStartDate = Date.parse(loyaltySettings.adLoyaltyStartDate)
    const adLoyaltyEndDate = Date.parse(loyaltySettings.adLoyaltyEndDate)


    // const [timeOpenedShop, setTimeOpenedShop] = useState(0);

    // console.log(loyaltySettings, 'loyaltySettings');

    // var startDate = loyaltySettings['adLoyaltyStartDate'];
    // var endDate = loyaltySettings['adLoyaltyEndDate'];

    const handleClose = () => {
        setShow(false);
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        setShow(true);
    }

    useEffect(() => {
        dispatch(getCurrentTime());
        dispatch(getLoyaltyProducts(2));
        dispatch(getLoyaltySettings(2));
        dispatch(globalStatistic(858, 'PAGE'));
        dispatch(getPageSeo(858)).then(res => {
            if (res) {
                setPageSeoData(res);
            }
        })
    }, []);

    useEffect(() => {
        setNow(Date.parse(getCurrentServerTime))
    }, [getCurrentServerTime]);

    /*useEffect(() => {
        const timer = setInterval(() => {
          setCounter(prevCount => prevCount + 1);
          checkLoyaltyShopOpened();
        }, 1000);
        return () => {
          clearInterval(timer);
        };
    }, [loyaltySettings]);
    

    const checkLoyaltyShopOpened = () => {

        var d = new Date();

        //minuti
        if(d.getMinutes() < 10) {
            var minutes = '0'+d.getMinutes();
        } else {
            var minutes = d.getMinutes();
        }

        var month = d.getMonth()+1;
        //dani
        if(d.getMonth()+1 < 10) {
            let sumMonth = d.getMonth()+1;
            month = '0'+sumMonth;
        }

        var fullCurrentDate = d.getFullYear()+'-'+month+'-'+d.getDate()+' '+d.getHours() + ":" + minutes + ":" + d.getSeconds();


        if(fullCurrentDate <= startDate && fullCurrentDate >= endDate) {
            setActiveShop(0);
        } else if(fullCurrentDate >= startDate && fullCurrentDate <= endDate) {
            setActiveShop(1);
        }
    }*/
    // console.log(getUserInfo, "getUserInfo.acCompanyLoyaltyStatus");
    // console.log(activeShop, "activeShop");
    const renderButtonSubmitLoyalty = () => {
        if (activeShop == 1) {
            // console.log("usao u if");

            return (
                <div className={`${emptyValues.length == 0 || emptyValues.filter(i => i.value == 0).length > 0 ? "button-submit-ly-shop-disabled" : ""} button-submit-ly-shop`}>
                    <button type="submit" className="btn btn-success" disabled={emptyValues.length == 0 || emptyValues.filter(i => i.value == 0).length > 0 ? true : false} onClick={(e) => handleSubmit(e)} title={emptyValues.length == 0 || emptyValues.filter(i => i.value == 0).length > 0 ? "Molimo izaberite validnu količinu artikala" : ""}><i className="fa fa-shopping-cart" aria-hidden="true"></i>&nbsp; Potvrdi kupovinu</button>
                </div>
            );
        }
    };

    // Renderer callback with condition
    /*const renderer = ({ days, hours, minutes, seconds, completed }) => {

            return (
                <>
                    <h2 className="title-counter-banner-main">Počnite sa kupovinom za</h2>
                    <div className="timer-counter-banner-holder">
                        <div className="timer-holder-item">
                            <div className="timer-item-number">{zeroPad(days)}</div>
                            <div className="timer-item-text">dana</div>
                        </div>
                        <div className="timer-holder-item timer-holder-item-dots">:</div>
                        <div className="timer-holder-item">
                            <div className="timer-item-number">{zeroPad(hours)}</div>
                            <div className="timer-item-text">sati</div>
                        </div>
                        <div className="timer-holder-item timer-holder-item-dots">:</div>
                        <div className="timer-holder-item">
                            <div className="timer-item-number">{zeroPad(minutes)}</div>
                            <div className="timer-item-text">minuta</div>
                        </div>
                        <div className="timer-holder-item timer-holder-item-dots">:</div>
                        <div className="timer-holder-item">
                            <div className="timer-item-number">{zeroPad(seconds)}</div>
                            <div className="timer-item-text">sekundi</div>
                        </div>
                    </div>
                </>
            )
    };
    // Renderer callback with condition
    const renderer2 = ({ days, hours, minutes, seconds, completed }) => {
            return (
                <>
                    <h2 className="title-counter-banner-main">Za kupovinu imate još</h2>
                    <div className="timer-counter-banner-holder">
                        <div className="timer-holder-item">
                            <div className="timer-item-number">{zeroPad(days)}</div>
                            <div className="timer-item-text">dana</div>
                        </div>
                        <div className="timer-holder-item timer-holder-item-dots">:</div>
                        <div className="timer-holder-item">
                            <div className="timer-item-number">{zeroPad(hours)}</div>
                            <div className="timer-item-text">sati</div>
                        </div>
                        <div className="timer-holder-item timer-holder-item-dots">:</div>
                        <div className="timer-holder-item">
                            <div className="timer-item-number">{zeroPad(minutes)}</div>
                            <div className="timer-item-text">minuta</div>
                        </div>
                        <div className="timer-holder-item timer-holder-item-dots">:</div>
                        <div className="timer-holder-item">
                            <div className="timer-item-number">{zeroPad(seconds)}</div>
                            <div className="timer-item-text">sekundi</div>
                        </div>
                    </div>
                </>
            )
    };*/

    // console.log(activeShop, 'activeShop')
    // console.log(startDate, '--------startDate')
    // console.log(endDate, '--------endDate')

    // const setActiveShopFunction = (val) => {
    //     setActiveShop(val);
    // };
    // console.log(loyaltySettings, "loyaltySettings");
    return (
        <>
            {Object.keys(pageSeoData).length !== 0 &&
                <PageHelmet
                    title={"Ewe Go Cart Loyalty - Ewe Comp"}
                    description={"Ewe Go Cart Loyalty - Ewe Comp"}
                    keywords={"Ewe Comp Go Cart Loyalty"}
                    ogDescription={"Ewe Go Cart Loyalty - Ewe Comp"}
                    ogTitle={"Ewe Go Cart Loyalty - Ewe Comp"}
                    ogUrl={`https://ewe.rs/ewe-go-cart-loyalty`}
                    ogImg="https://www.ewe.rs/media/ewe-og-img.jpg"
                    twitterDescription={"Ewe Go Cart Loyalty - Ewe Comp"}
                />
            }
            {loyaltySettings.length != 0 && (
                <div className="container-content container-main-content target-content loyalty-shop-content-container">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                {isDesktop ?
                                    <img src="https://resource.ewe.rs/media/loyalty/go_cart_loyalty_banner.jpg" className="img-responsive page-banner-image-slider page-banner-image-slider-desktop" alt="Ewe Go Cart Loyalty" title="Ewe Go Cart Loyalty" style={{ width: '100%', display: "inline-block", marginBottom: "20px" }}></img>
                                    : <img src="https://resource.ewe.rs/media/loyalty/go_cart_loyalty_banner_500x500.jpg" className="img-responsive page-banner-image-slider page-banner-image-slider-desktop" alt="Ewe Go Cart Loyalty" title="Ewe Go Cart Loyalty" style={{ width: '100%', display: "inline-block", marginBottom: "20px" }}></img>}
                            </div>

                            {loyaltySettings.isActive == 1 ? (
                                <>
                                    {loyaltyProductsList.isSaleConnected == 1 && (
                                        <div className="col-lg-12">
                                            <div className="info-icons-action">
                                                <span>* Kupovinom artikala obeleženih</span> <img className="smallBadge" src="/images/loyalty/badges-povezana-prodaja.png" /> <span>otvara se mogućnost kupovine iste količine artikala obeleženih</span> <img className="smallBadge" src="/images/loyalty/badges-povezana-prodaja-artikal.png" /> <span>po specijalnoj ceni.</span>
                                            </div>
                                        </div>
                                    )}
                                    {/* {getUserInfo.acCompanyLoyaltyStatus.trim() == 'CRVEN' &&
                                        <div className="col-sm-12">
                                            <div className="alert alert-danger w-100 alert-info-loyalty" role="alert">
                                                Onemogućena kupovina zbog dugovanja van valute. Molimo Vas kontaktirajte Vašeg komercijalistu.
                                            </div>
                                        </div>
                                    } */}
                                    <div className="col-sm-12">
                                        &nbsp;
                                        <br />
                                    </div>

                                    <div className="col-sm-12">
                                        <div className="loyalty-table-content">
                                            <form id="form-loyalty-shop">
                                                <table className="table table-loyalty">
                                                    <thead>
                                                        <tr>
                                                            <th className="text-align-center" style={{ width: '100px' }}>Foto</th>
                                                            <th>Šifra</th>
                                                            <th>Akcija</th>
                                                            <th>Naziv</th>
                                                            <th className="text-align-right">Na lageru</th>
                                                            <th className="text-align-right">Dnevni limit</th>
                                                            <th className="text-align-right">Redovna cena</th>
                                                            <th className="text-align-right">Cena</th>
                                                            <th>Ušteda</th>
                                                            <th style={{ width: '100px' }}>Kupi</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {loyaltyProductsList.companyLoyalityShop && loyaltyProductsList.companyLoyalityShop.map((item, key) => (
                                                            <>
                                                                <tr className="custom-name-title" key={key}>
                                                                    <td colSpan={10}>
                                                                        {item.acLoyaltyGroup == '' ? (
                                                                            <div className="loyalty-header-info-products">Loyalty artikli</div>
                                                                        ) :
                                                                            <div className="loyalty-header-info-products">{item.acLoyaltyGroup}</div>
                                                                        }
                                                                    </td>
                                                                </tr>

                                                                {item.loyaltyShopItemList.map((item2, key2) => (
                                                                    <LoyaltyItem finishCart={finishCart} key={key2} keyItem={key2} shopIsActive={activeShop} getUserInfo={getUserInfo} loyaltySettings={loyaltySettings} item={item2} setEmptyValues={setEmptyValues} emptyValues={emptyValues} />
                                                                ))}
                                                            </>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </form>
                                            {renderButtonSubmitLoyalty()}
                                        </div>
                                    </div>
                                </>
                            ) : (loyaltySettings.isActive == 2 ?
                                <div className="col-sm-12">
                                    <div className="alert alert-danger w-100 alert-info-loyalty" role="alert">
                                        Nemate pravo pristupa Ewe Go Cart akciji
                                    </div>
                                </div>
                                : <div className="col-sm-12">
                                    <div className="alert alert-danger w-100 alert-info-loyalty" role="alert">
                                        Ewe Go Cart akcija nije aktivna
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div >
            )}
            <ScrollToTop />
            <Modal
                show={show}
                onHide={handleClose}
                keyboard={false}
                dialogClassName='modal-xl'
                aria-labelledby=""
                centered
            >
                <div className="close-action-modal" variant="secondary" onClick={handleClose}>
                    <i className="far fa-times-circle"></i>
                </div>
                <Modal.Header>
                    <h4>Potvrda kupovine</h4>
                </Modal.Header>
                <Modal.Body>
                    <EweGoCartLoyaltyModal setFinishCartFunction={setFinishCart} finishCart={finishCart} handleClose={handleClose} setEmptyValues={setEmptyValues} type={2} />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Tehnovacije2023LoyaltyShopPage;