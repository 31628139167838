import React, { useState } from 'react';

import axios from 'axios';
import { axiosinstance } from '../../configAxios';
import { getCompanyPromoMaterial } from "../../redux/actions/companyPromoMaterialActions";
import { globalStatistic } from '../../redux/actions/otherActions';
import { useDispatch } from 'react-redux';

import { useSelector } from "react-redux";
import Countdown from "react-countdown";
import { Link } from "react-router-dom";
import SecureLS from 'secure-ls';

const BlockActionImage = (props) => {

    // const { item } = props;

    const [show, setShow] = useState(props.isShown);
    const dispatch = useDispatch()

    const checkSeenPopup = (popupKey) => {
        let ls = new SecureLS({ encodingType: 'aes' });
        var userInfoData = ls.get('userInfo');
        const attributes = {};
        attributes["anPopupKey"] = popupKey;
        attributes["anUserKey"] = userInfoData.company.department[0].user[0].anUserKey;
        attributes["isVisitedLink"] = 1;

        const configRes = axiosinstance(attributes, "8224", "seenPopup");

        axios(configRes)
            .then(function (response) {
                dispatch(getCompanyPromoMaterial(4));
            })
            .catch(function (error) {
                console.log(error, "error");
            });
    };

    const handleClick = () => {
        dispatch(globalStatistic(props.item.anPopupOrgId, 'POPUP'));
        props.handleCloseClick();
    };

    return (
        <a href={props.item.acPopupLink} target='_blank' rel="noreferrer noopener" onClick={handleClick}>
            <div className="action-block" style={{ height: "auto" }}>
                <div className="action-block-content">
                    <div className="action-block-content-image">
                        <img src={"https://resource.ewe.rs/media/" + props.item.acPopupImage} alt="" style={{ width: "100%", height: "auto" }} />
                    </div>
                </div>
            </div>
        </a>
    );
};

export default BlockActionImage;
