import React, { useEffect, createRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MaterialTable, { MTableToolbar } from "material-table";
import { Paper } from '@material-ui/core';

import { getReservations } from '../redux/actions/otherActions';
import { globalStatistic } from '../redux/actions/otherActions';
import { getPageSeo } from '../redux/actions/pageActions';

import ReservationItem from '../components/other/ReservationItem';
import PatchedPagination from '../components/other/PatchedPagination';
import CustomDatePicker from '../components/other/CustomDatePicker';
import { Link } from 'react-router-dom';
import PageHelmet from '../components/helmet/PageHelmet';
import { useTranslation } from 'react-i18next';
import { removeSpecialChar } from '../helperFunctions';

const ReservationPage = () => {

    const dispatch = useDispatch();
    const tableRef = createRef();
    const getReservationItems = useSelector((state) => state.getReservationItems.items);
    const [pageSeoData, setPageSeoData] = useState({});

    const { t } = useTranslation(['reservationPage', 'routes']);

    useEffect(() => {
        dispatch(getPageSeo(544)).then(res => {
            if (res) {
                setPageSeoData(res);
            }
        });
        dispatch(getReservations());
        dispatch(globalStatistic(544, 'PAGE'));
    }, []);

    return (
        <>
            {Object.keys(pageSeoData).length !== 0 &&
                <PageHelmet
                    title={pageSeoData ? `${pageSeoData.acSeoTitle}` : "Ewe Comp"}
                    description={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    keywords={pageSeoData ? `${pageSeoData.acSeoKeywords}` : ""}
                    ogDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    ogTitle={pageSeoData ? `${pageSeoData.acSeoTitle}` : ""}
                    ogUrl={pageSeoData ? `https://ewe.rs/${pageSeoData.acSeoUrl}` : ""}
                    ogImg="https://www.ewe.rs/media/ewe-og-img.jpg"
                    twitterDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                />
            }
            <div className="container-content container-main-content reservation-content top-margin-navbar-escape">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="stats-order-content-holder">
                                <h1>{pageSeoData.acSeoHeader ? pageSeoData.acSeoHeader : t('reservationPage:naslov')}</h1>
                                {pageSeoData.acSeoShortPageDescription && <p>{pageSeoData.acSeoShortPageDescription}</p>}
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="status-order-table">
                                <div className="editing-cart-items-table">
                                    <MaterialTable
                                        components={{
                                            Pagination: PatchedPagination,
                                            Container: props => <Paper {...props} elevation={0} />,
                                            Toolbar: (props) => (
                                                <div
                                                    style={{
                                                        height: "0px",
                                                    }}
                                                >
                                                    <MTableToolbar {...props} />
                                                </div>
                                            ),
                                        }}
                                        data={getReservationItems}
                                        localization={{
                                            toolbar: {
                                                searchPlaceholder: '',
                                                searchTooltip: '',
                                            },
                                            pagination: {
                                                labelRowsSelect: '',
                                                firstAriaLabel: t('reservationPage:prvaStrana'),
                                                firstTooltip: t('reservationPage:prvaStrana'),
                                                previousAriaLabel: t('reservationPage:prethodnaStrana'),
                                                previousTooltip: t('reservationPage:prethodnaStrana'),
                                                nextAriaLabel: t('reservationPage:sledecaStrana'),
                                                nextTooltip: t('reservationPage:sledecaStrana'),
                                                lastAriaLabel: t('reservationPage:poslednjaStrana'),
                                                lastTooltip: t('reservationPage:poslednjaStrana'),
                                            },
                                            header:
                                            {
                                                firstAriaLabel: t('reservationPage:prvaStranaAriaLabel'),
                                                firstTooltip: 'Prva strana',
                                                previousAriaLabel: 'Prethodna strana',
                                                previousTooltip: 'Prethodna strana',
                                                nextAriaLabel: 'Sledeća strana',
                                                nextTooltip: t('reservationPage:sledecaStranaTooltip'),
                                                lastAriaLabel: 'Poslednja strana',
                                                lastTooltip: 'Poslednja strana',
                                            },
                                            header: {
                                                actions: 'Actions'

                                            },
                                            body: {
                                                emptyDataSourceMessage: (
                                                    <div className="loyalty-msg-holder">
                                                        <div className="alert alert-primary" role="alert">
                                                            {t('reservationPage:ostvariteBodove')}
                                                        </div>
                                                    </div>
                                                ),
                                            },
                                        }}
                                        tableRef={tableRef}
                                        columns={[
                                            {
                                                title: <>{t('reservationPage:nalog')} <i className="fas fa-sort mx-1"></i></>,
                                                field: "acOrderKey",
                                            },
                                            {
                                                title: <>{t('reservationPage:kompanija')} <i className="fas fa-sort mx-1"></i></>,
                                                field: "acCompany",
                                            },
                                            {
                                                title: <>{t('reservationPage:sifra')} <i className="fas fa-sort mx-1"></i></>,
                                                field: "acProduct",
                                                render: item => {
                                                    return <Link to={`/${t('routes:proizvod')}/${item.anProductKey}/${removeSpecialChar(item.acProductName)}`}>{item.acProduct}</Link>
                                                }
                                            },
                                            {
                                                title: <>{t('reservationPage:naizvArtikla')} <i className="fas fa-sort mx-1"></i></>,
                                                field: "acProductName",
                                                render: item => {
                                                    return <Link to={`/${t('routes:proizvod')}/${item.anProductKey}/${removeSpecialChar(item.acProductName)}`}>{item.acProductName}</Link>
                                                }
                                            },
                                            {
                                                title: <>{t('reservationPage:brend')} <i className="fas fa-sort mx-1"></i></>,
                                                field: "acBrand",
                                            },
                                            {
                                                title: <>{t('reservationPage:grupa')} <i className="fas fa-sort mx-1"></i></>,
                                                field: "acCategory",
                                            },
                                            {
                                                title: <>{t('reservationPage:podgrupa')} <i className="fas fa-sort mx-1"></i></>,
                                                field: "acSubCategory",
                                            },
                                            {
                                                title: <>{t('reservationPage:kolicina')} <i className="fas fa-sort mx-1"></i></>,
                                                field: "anQty",
                                                cellStyle: { textAlign: "center", padding: "8px" },
                                                filterCellStyle: { textAlign: "center" }
                                            },
                                            {
                                                title: <>{t('reservationPage:tipRez')} <i className="fas fa-sort mx-1"></i></>,
                                                field: "acReservationDescription",
                                                render: item => {
                                                    return <div><span className="reservation-box-color" style={{ backgroundColor: item.acReservationColor }}></span>{item.acReservationLevel}</div>;
                                                }
                                            },
                                            {
                                                title: <>{t('reservationPage:opisTipaRez')} <i className="fas fa-sort mx-1"></i></>,
                                                field: "acReservationDescription",
                                            },
                                            {
                                                title: <>{t('reservationPage:maksimalnoTrajanjeRez')} <i className="fas fa-sort mx-1"></i></>,
                                                field: "anReservationMaxDuration",
                                                cellStyle: { textAlign: "center", padding: "8px" },
                                                filterCellStyle: { textAlign: "center" },
                                                render: item => {
                                                    return item.anReservationMaxDuration + " dana";
                                                }
                                            },
                                            {
                                                title: <>{t('reservationPage:datumRezervisanja')} <i className="fas fa-sort mx-1"></i></>,
                                                field: "adReservationDateCreate",
                                                type: "date",
                                                dateSetting: { locale: "sr-RS" },
                                                filterComponent: (props) => <CustomDatePicker {...props} />,
                                                cellStyle: { textAlign: "center", padding: "8px" },
                                                filterCellStyle: { textAlign: "center" }
                                            },
                                            {
                                                title: <>{t('reservationPage:datumIsticanjaRezervaicje')}<i className="fas fa-sort mx-1"></i></>,
                                                field: "adReservationDateExpiration",
                                                type: 'date',
                                                dateSetting: { locale: "sr-RS" },
                                                filterComponent: (props) => <CustomDatePicker {...props} />,
                                                cellStyle: { textAlign: "center", padding: "8px" },
                                                filterCellStyle: { textAlign: "center" }
                                            },
                                        ]}
                                        options={{
                                            filtering: true,
                                            draggable: false,
                                            thirdSortClick: false,
                                            pageSize: 20,
                                            pageSizeOptions: [20, 50, 100],
                                            cellStyle: {
                                                whiteSpace: "nowrap",
                                                padding: "8px"
                                            },
                                            headerStyle: {
                                                padding: "8px"
                                            },
                                            filterCellStyle: {
                                                padding: "8px"
                                            },
                                        }}
                                        icons={{
                                            Filter: () => <i className="fas fa-search"></i>,
                                        }}
                                        title=""
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ReservationPage;