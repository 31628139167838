import React from 'react';
import {
    Page,
    Text,
    View,
    Document,
    Image,
    Font,
    StyleSheet,
} from "@react-pdf/renderer";

const CompareProductsItemsSpec = (props) => {

    const styles = StyleSheet.create({
        tableCol: { width: "20%" },
        tableCell: { paddingHorizontal: 5, fontSize: 10, paddingVertical: 2 }
    });

    return (
        <>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{props.name}</Text>
            </View>
            {props.children}
        </>
    );
};

export default CompareProductsItemsSpec;
