import React from 'react';

import DefaultModalMenu from './DefaultModalMenu';

const SidebarModalPopup = ({ modalMenuSettings, modalValue, modalFunctionPopup, sidebar }) => {

    // console.log(modalValue, 'modalValue')
    // console.log(modalMenuSettings, 'modalMenuSettings')
    // console.log(modalFunctionPopup, 'modalFunctionPopup')

    const setModalMenuFunction = (idModal) => {
        // console.log(idModal, '------idModal------1');
        modalFunctionPopup(idModal);
    };
    return (
        <>
            {
                Object.keys(modalValue).length !== 0 &&
                    Object.keys(modalValue.subMenu).length === 0 &&
                    Object.keys(modalValue.tempMenuList).length === 0 ? '' : <DefaultModalMenu sidebar={sidebar} modalMenuFunction={setModalMenuFunction} modalMenuSettings={modalMenuSettings} modalValue={modalValue} />}
            {/* (
                <DefaultModalMenu modalMenuFunction={setModalMenuFunction} modalMenuSettings={modalMenuSettings} modalValue={modalValue} />
            )} */}
        </>
    )
};

export default SidebarModalPopup;