import React, { useEffect, useState, createRef } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import axios from 'axios';
import { axiosinstance } from '../configAxios';
import { useSelector, useDispatch } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';
import { getPageSeo } from '../redux/actions/pageActions';
import { getSaleStatistics, globalStatistic } from '../redux/actions/otherActions'
import { setCartClone } from '../redux/actions/cartActions'
import PageHelmet from '../components/helmet/PageHelmet';
import CustomDatePicker from '../components/other/CustomDatePicker';
import PatchedPagination from '../components/other/PatchedPagination';
import ModalOrderStatus from '../components/modal/ModalOrderStatus';
import CurrencyFormat from '../components/other/CurrencyFormat';
import { useTranslation } from 'react-i18next';
import styles from '../css/OrderStatus.module.css'

// import OrderStatusItem from '../components/other/OrderStatusItem';

const TrendStatusPage = () => {

    const dispatch = useDispatch()
    const [pageSeoData, setPageSeoData] = useState({});
    const userInfoData = useSelector((state) => state.userInfo.userInfo);
    const getSaleStatisticsItems = useSelector((state) => state.getSaleStatisticsItems);
    const getCurrencyItems = useSelector((state) => state.getCurrencyItems);
    const { t } = useTranslation('trendStatusPage');
    const [orderList, setOrderList] = useState([]);
    const [show, setShow] = useState(false);
    const [orderDetailList, setOrderDetailList] = useState([]);

    const tableRef = createRef();

    var attributes = {};

    useEffect(() => {

        dispatch(getPageSeo(765)).then(res => {
            if (res) {
                setPageSeoData(res);
            }
        });
        attributes['anCompanyKey'] = userInfoData ? userInfoData.company.anCompanyKey : 0;
        const configRes = axiosinstance(attributes, '8224', 'postSaleHistory');
        axios(configRes)
            .then(function (response) {
                setOrderList(response.data.response.saleHistoryList);
            })
            .catch(function (error) {
                console.log(error);
            });

        dispatch(getSaleStatistics());
        dispatch(globalStatistic(765, 'PAGE'));
    }, []);


    const handleClose = () => {
        setShow(false);
    };

    const setFunctionAgainOrderAction = (item, val) => {
        dispatch(setCartClone(item, getCurrencyItems, val))
    };

    const queries = useMediaQuery('(max-width: 450px)')
    const queries1 = useMediaQuery('(max-width: 1366px)')
    const queries2 = useMediaQuery('(min-width: 450px) and (max-width: 1100px)')

    return (
        <>
            {Object.keys(pageSeoData).length !== 0 &&
                <PageHelmet
                    title={pageSeoData ? `${pageSeoData.acSeoTitle}` : "Ewe Comp"}
                    description={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    keywords={pageSeoData ? `${pageSeoData.acSeoKeywords}` : ""}
                    ogDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    ogTitle={pageSeoData ? `${pageSeoData.acSeoTitle}` : ""}
                    ogUrl={pageSeoData ? `https://ewe.rs/${pageSeoData.acSeoUrl}` : ""}
                    ogImg="https://www.ewe.rs/media/ewe-og-img.jpg"
                    twitterDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                />
            }
            <div className={`container-content ${styles["stats-order-content"]} container-catalog-content`}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className={styles["stats-order-content-holder"]}>
                                <h1>{pageSeoData.acSeoHeader ? pageSeoData.acSeoHeader : t('naslov1')}</h1>
                                {pageSeoData.acSeoShortPageDescription && <p>{pageSeoData.acSeoShortPageDescription}</p>}
                                {Object.keys(getSaleStatisticsItems.items).length != 0 ?
                                    getSaleStatisticsItems.loading == false ? (
                                        <div className="row">
                                            <div className={queries1 ? "col-sm-12" : "col-sm-4"}>
                                                <h3 className="status-order-box-title">{t('naslov2')}</h3>
                                                <div className="status-holder-box-trend">
                                                    <div className="status-box-trend">
                                                        <div className="status-box-trend-value">{getSaleStatisticsItems.items.UKUPANTREND[0].anSaleStatistcsValue}%</div>
                                                        <div className="status-box-trend-desc">{t('paragraf1')}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={queries1 ? "col-sm-6" : "col-sm-4"}>
                                                <h3 className={`status-order-box-title ${queries1 && "mt-4"}`}>{t('naslov3')}</h3>
                                                <div className="status-holder-box">
                                                    <div className="status-box-diagram">
                                                        <div className="status-box-diagram-item"></div>
                                                        <div className="status-box-diagram-item"></div>
                                                        <div className="status-box-diagram-item"></div>
                                                        <div className="status-box-diagram-item"></div>
                                                        <div className="status-box-diagram-item"></div>
                                                        <div className="status-diagram-lines">
                                                            {getSaleStatisticsItems.items.KATEGORIJA.map(item => {
                                                                return <OverlayTrigger placement="top"
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-top`}>
                                                                            {item.acSaleStatisticsValue} ({item.anSaleStatistcsValue}%)
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <div className="status-diagram-line" style={{ width: Math.abs(item.anSaleStatistcsValue) + '%' }}><span style={item.anSaleStatistcsValue > -40 ? { color: '#fe285c' } : { color: '#fff' }}>{item.acSaleStatisticsValue}</span></div>
                                                                </OverlayTrigger>
                                                            })}
                                                        </div>
                                                    </div>
                                                    <div className="status-box-diagram-value">
                                                        <div className="status-box-diagram-item-value">0%</div>
                                                        <div className="status-box-diagram-item-value">-20%</div>
                                                        <div className="status-box-diagram-item-value">-40%</div>
                                                        <div className="status-box-diagram-item-value">-60%</div>
                                                        <div className="status-box-diagram-item-value">-80% <span>-100%</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={queries1 ? "col-sm-6" : "col-sm-4"}>
                                                <h3 className={`status-order-box-title ${queries1 && "mt-4"}`}>{t('naslov4')}</h3>
                                                <div className="status-holder-box">
                                                    <div className="status-box-diagram">
                                                        <div className="status-box-diagram-item"></div>
                                                        <div className="status-box-diagram-item"></div>
                                                        <div className="status-box-diagram-item"></div>
                                                        <div className="status-box-diagram-item"></div>
                                                        <div className="status-box-diagram-item"></div>
                                                        <div className="status-diagram-lines">
                                                            {getSaleStatisticsItems.items.BREND.map(item => {
                                                                return <OverlayTrigger placement="top"
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-top`}>
                                                                            {item.acSaleStatisticsValue} ({item.anSaleStatistcsValue}%)
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <div className="status-diagram-line" data-toggle="tooltip" data-placement="top" style={{ width: Math.abs(item.anSaleStatistcsValue) + '%' }}><span style={item.anSaleStatistcsValue > -40 ? { color: '#fe285c' } : { color: '#fff' }}>{item.acSaleStatisticsValue}</span></div>
                                                                </OverlayTrigger>

                                                            })}
                                                        </div>
                                                    </div>
                                                    <div className="status-box-diagram-value">
                                                        <div className="status-box-diagram-item-value">0%</div>
                                                        <div className="status-box-diagram-item-value">-20%</div>
                                                        <div className="status-box-diagram-item-value">-40%</div>
                                                        <div className="status-box-diagram-item-value">-60%</div>
                                                        <div className="status-box-diagram-item-value">-80% <span>-100%</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        ""
                                    ) :
                                    <div className="loyalty-msg-holder">
                                        <div className="alert alert-primary" role="alert">
                                            {t('paragraf2')}
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {show && <ModalOrderStatus item={orderDetailList} handleClose={handleClose} getCurrencyItems={getCurrencyItems} show={show} />}
        </>
    )
}

export default TrendStatusPage;