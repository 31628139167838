import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import UserProfile from '../components/profile/UserProfile';
import GlobalUserInfo from '../components/profile/GlobalUserInfo';
import CompanyProfile from '../components/profile/CompanyProfile';
import WishListPage from './WishListPage';
import CompanyVoucherList from '../components/profile/CompanyVoucherList';
import AdminTab from '../components/profile/AdminTab';
import PageHelmet from '../components/helmet/PageHelmet';

import { globalStatistic } from '../redux/actions/otherActions';
import { endPageListener } from '../helperFunctions';
import { getPageSeo } from '../redux/actions/pageActions';
import { useTranslation } from 'react-i18next';

const ProfilePage = () => {

    const dispatch = useDispatch();

    const getUserInfo = useSelector((state) => state.userInfo.userInfo.company);
    const user = getUserInfo.department[0].user[0];
    // const user1 = ls.get('userInfo');
    // const user = user1.company.department[0].user[0];
    const [pageSeoData, setPageSeoData] = useState({});

    const [tab, setTab] = useState("user");
    const { t } = useTranslation('profiePage');

    const changeTab = (val) => {
        setTab(val)
    }

    // f-ja gleda da li je skrol stigao do kraja stranice
    const handleScroll = () => {
        const bottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight
        if (bottom) {
            dispatch(globalStatistic('547', 'END_PAGE'));
        }
    }

    useEffect(() => {
        dispatch(getPageSeo(547)).then(res => {
            if (res) {
                setPageSeoData(res);
            }
        });
        dispatch(globalStatistic('547', 'PAGE'));
        window.addEventListener('scroll', handleScroll, {
            passive: true
        });
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [])

    return (
        <>
            {Object.keys(pageSeoData).length !== 0 &&
                <PageHelmet
                    title={pageSeoData ? `${pageSeoData.acSeoTitle}` : "Ewe Comp"}
                    description={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    keywords={pageSeoData ? `${pageSeoData.acSeoKeywords}` : ""}
                    ogDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    ogTitle={pageSeoData ? `${pageSeoData.acSeoTitle}` : ""}
                    ogUrl={pageSeoData ? `https://ewe.rs/${pageSeoData.acSeoUrl}` : ""}
                    ogImg="https://www.ewe.rs/media/ewe-og-img.jpg"
                    twitterDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                />
            }
            <div className="container-content container-main-content profile-page">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="profile-page-title">
                                <i className="far fa-user"></i>
                                <div className="profile-page-title-section">
                                    {pageSeoData.acSeoHeader ? <h3>{pageSeoData.acSeoHeader}</h3> : <h3>{t('mojNalog')}</h3>}
                                    {pageSeoData.acSeoShortPageDescription ? <span>{pageSeoData.acSeoShortPageDescription}</span> : <span>{t('pregledInfoNaloga')}</span>}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <GlobalUserInfo getUserInfo={getUserInfo} user={user} changeTab={changeTab} tab={tab} />

                            {tab == "user" &&
                                <UserProfile user={user} />
                            }
                            {tab == "company" &&
                                <CompanyProfile getUserInfo={getUserInfo} user={user} />
                            }
                            {tab == "wishList" &&
                                <WishListPage isProfilePage={'profile'} />
                            }
                            {tab == "promo" &&
                                <CompanyVoucherList />
                            }
                            {tab == "admin" &&
                                <AdminTab loggedUser={user} />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProfilePage;