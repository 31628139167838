import React, { useState } from 'react';
import CatalogFilterTopItem from './CatalogFilterTopItem';
import CatalogFilterTopBrandItem from './CatalogFilterTopBrandItem';
// import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { globalStatistic } from '../../redux/actions/otherActions';
import { useTranslation } from 'react-i18next';


const CatalogTopFilters = () => {


  const params = useParams();
  const dispatch = useDispatch();
  const [showFilters, setShowFilters] = useState(false);
  const [activeElement, setActiveElement] = useState('0');

  const filters = useSelector((state) => state.catalogFilters);
  const catalogFiltersBrand = useSelector((state) => state.catalogFiltersBrand);
  const catalogFiltersSpecialAction = useSelector((state) => state.catalogFiltersSpecialAction);
  const { t } = useTranslation('catalog');

  const { categoryId } = params;
  const gatherStatisticTopFilterCategory = () => {
    dispatch(globalStatistic(categoryId, 'TOP_FILTER_CATEGORY'));
  }
  // console.log(catalogFiltersSpecialAction, 'catalogFiltersSpecialAction')
  // console.log(catalogFiltersBrand, 'catalogFiltersBrand')
  return (
    <div className="catalog-filters-container-box">
      <div className="catalog-filters-top-title" onClick={(e) => { setShowFilters(!showFilters); gatherStatisticTopFilterCategory() }}>{t('filteri')} <i className={`fas fa-chevron-${showFilters ? 'up' : 'down'}`}></i></div>
      <div className={`catalog-filters-top-items ${showFilters && 'active'}`}>
        {catalogFiltersSpecialAction.length > 0 && (
          <div className="top-filter-item-content">
            <CatalogFilterTopBrandItem typeValue="akcija" toggleIndex={index => setActiveElement(index)} elementActive={activeElement} isActive="0" valueItem={catalogFiltersSpecialAction} />
          </div>
        )}
        {catalogFiltersBrand.length > 0 && (
          <div className="top-filter-item-content">
            <CatalogFilterTopBrandItem typeValue="brand" toggleIndex={index => setActiveElement(index)} elementActive={activeElement} isActive="0" valueItem={catalogFiltersBrand} />
          </div>
        )}
        {filters.length > 0 ? (
          <>
            {filters.map((item, key) => (
              <div className="top-filter-item-content" key={key}>
                <CatalogFilterTopItem toggleIndex={index => setActiveElement(index)} elementActive={activeElement} isActive="0" valueItem={item} />
              </div>
            ))}
          </>
        ) : (
          <div>{t('nemaFiltera')}</div>
        )}
      </div>

    </div>
  );
};

export default CatalogTopFilters;