import React, { useState } from 'react';

const CatalogProductBadgeAction = ({ item, view, key }) => {
    
    const [showActionInfo, setShowActionInfo] = useState(false);
    const [showActionKey, setShowActionKey] = useState();

    const showText = (key) => {
        setShowActionInfo(true)
        setShowActionKey(key)
    }

    const hideText = () => {
        setShowActionInfo(false)
    }

    return (
        <>
            { view == 'classicItem' && (
                <>
                {item.acText != 'Vaucher' && 
                    <div className="product-catalog-badge" title={item.acText} style={{ backgroundColor: `${item.acColor}` }}>
                        { item.acIconType == 1 ? (
                            <>
                                <i className={item.acIconClass}></i>&nbsp;{item.acSpecialName}
                            </>
                        )
                        : 
                            <>
                                <i className="fas fa-star"></i>&nbsp;{item.acSpecialName}
                            </>
                        }
                    
                    </div>
                }
                </>

            )}
            { view == 'tableItem' && (
                <>
                {item.acText != 'Vaucher' && 
                    <div 
                        className="product-table-view-badge text-center" 
                        style={{ backgroundColor: `${item.acColor}` }} 
                        onMouseEnter={() => showText(key)} 
                        onMouseLeave={hideText}
                    >
                        { item.acIconType == 1 ? <i className={item.acIconClass} title={item.acSpecialName}></i> : <i className="fas fa-star" title={item.acSpecialName}></i> }
                        { showActionInfo && showActionKey == key && item.acSpecialName && <span className="table-action-info" title={item.acSpecialName} style={{ backgroundColor: item.acColor }}>&nbsp;{item.acSpecialName}</span> }
                    </div>
                }
                </>
            )}
        </>
    )
}

export default CatalogProductBadgeAction;
