import React, { useEffect, useState } from 'react';
import PageHelmet from '../components/helmet/PageHelmet';
import { useDispatch } from 'react-redux';
import { globalStatistic } from '../redux/actions/otherActions';
import { getPageSeo } from '../redux/actions/pageActions';
import { useTranslation } from 'react-i18next';

const Terms = () => {

    const dispatch = useDispatch();
    const [pageSeoData, setPageSeoData] = useState({});
    const { t } = useTranslation('privacyPolicy');

    useEffect(() => {
        dispatch(getPageSeo(79)).then(res => {
            if (res) {
                setPageSeoData(res);
            }
        });
        dispatch(globalStatistic(79, 'PAGE'));
    }, [])

    return (
        <>
            {Object.keys(pageSeoData).length !== 0 &&
                <PageHelmet
                    title={pageSeoData ? `${pageSeoData.acSeoTitle}` : "Ewe Comp"}
                    description={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    keywords={pageSeoData ? `${pageSeoData.acSeoKeywords}` : ""}
                    ogDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    ogTitle={pageSeoData ? `${pageSeoData.acSeoTitle}` : ""}
                    ogUrl={pageSeoData ? `https://ewe.rs/${pageSeoData.acSeoUrl}` : ""}
                    ogImg="https://www.ewe.rs/media/ewe-og-img.jpg"
                    twitterDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                />
            }
            <div className='container-fluid container-terms-content'>
                <div className='row'>
                    <div className='col-sm-12 text-center banner-image-on-top-page'>
                        <img className='img-fluid' src='https://resource.ewe.rs/media/blocks/2018/02/2018-02-04866670.png' alt={t('laptopNaStolu')} style={{ maxWidth: "100%", borderRadius: "10px" }} />
                    </div>
                </div>
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <h3 style={{ color: "#212529", fontSize: "1.75rem", fontWeight: "500", marginTop: "0", marginBottom: "0.5rem", textAlign: "left", textTransform: "none", fontFamily: "inherit" }}>{t('politikaPrivatnosti')}</h3>
                            <p style={{ marginTop: "auto", fontSize: "1rem", fontWeight: "400", letterSpacing: "inherit" }}>{t('pasus1')}</p>
                        </div>
                    </div>
                    <br />
                    <div className='row'>
                        <div className='col-sm-12'>
                            <h3 style={{ color: "#212529", fontSize: "1.75rem", fontWeight: "500", marginTop: "0", marginBottom: "0.5rem", textAlign: "left", textTransform: "none", fontFamily: "inherit" }}>{t('zaboraviliSteLozinku')}</h3>
                            <p style={{ marginTop: "auto", fontSize: "1rem", fontWeight: "400", letterSpacing: "inherit" }}>{t('pasus2')}</p>
                        </div>
                    </div>
                    <br />
                    <div className='row'>
                        <div className='col-sm-12'>
                            <h3 style={{ color: "#212529", fontSize: "1.75rem", fontWeight: "500", marginTop: "0", marginBottom: "0.5rem", textAlign: "left", textTransform: "none", fontFamily: "inherit" }}>{t('politikaZastite')}</h3>
                            <p style={{ marginTop: "auto", fontSize: "1rem", fontWeight: "400", letterSpacing: "inherit" }}>{t('pasus3')}</p>
                            <br />
                            <p style={{ marginTop: "auto", fontSize: "1rem", fontWeight: "400", letterSpacing: "inherit" }}>{t('pasus4')}</p>
                        </div>
                    </div>
                    <br />
                    <div className='row'>
                        <div className='col-sm-12'>
                            <h3 style={{ color: "#212529", fontSize: "1.75rem", fontWeight: "500", marginTop: "0", marginBottom: "0.5rem", textAlign: "left", textTransform: "none", fontFamily: "inherit" }}>{t('prikupljanjeInfo')}</h3>
                            <p style={{ marginTop: "auto", fontSize: "1rem", fontWeight: "400", letterSpacing: "inherit" }}>{t('pasus5')}</p>
                        </div>
                    </div>
                    <br />
                    <div className='row'>
                        <div className='col-sm-12'>
                            <h3 style={{ color: "#212529", fontSize: "1.75rem", fontWeight: "500", marginTop: "0", marginBottom: "0.5rem", textAlign: "left", textTransform: "none", fontFamily: "inherit" }}>{t('upotrebaInfo')}</h3>
                            <p style={{ marginTop: "auto", fontSize: "1rem", fontWeight: "400", letterSpacing: "inherit" }}>{t('pasus6')}</p>
                            <br />
                            <p style={{ marginTop: "auto", fontSize: "1rem", fontWeight: "400", letterSpacing: "inherit" }}>{t('pasus7')}</p>
                        </div>
                    </div>
                    <br />
                    <div className='row'>
                        <div className='col-sm-12'>
                            <h3 style={{ color: "#212529", fontSize: "1.75rem", fontWeight: "500", marginTop: "0", marginBottom: "0.5rem", textAlign: "left", textTransform: "none", fontFamily: "inherit" }}>{t('otkrivanjeInfo')}</h3>
                            <p style={{ marginTop: "auto", fontSize: "1rem", fontWeight: "400", letterSpacing: "inherit" }}>{t('pasus8')}</p>
                        </div>
                    </div>
                    <br />
                    <div className='row'>
                        <div className='col-sm-12'>
                            <h3 style={{ color: "#212529", fontSize: "1.75rem", fontWeight: "500", marginTop: "0", marginBottom: "0.5rem", textAlign: "left", textTransform: "none", fontFamily: "inherit" }}>{t('bezbednost')}</h3>
                            <p style={{ marginTop: "auto", fontSize: "1rem", fontWeight: "400", letterSpacing: "inherit" }}>{t('pasus9')}</p>
                        </div>
                    </div>
                    <br />
                    <div className='row'>
                        <div className='col-sm-12'>
                            <h3 style={{ color: "#212529", fontSize: "1.75rem", fontWeight: "500", marginTop: "0", marginBottom: "0.5rem", textAlign: "left", textTransform: "none", fontFamily: "inherit" }}>{t('deca')}</h3>
                            <p style={{ marginTop: "auto", fontSize: "1rem", fontWeight: "400", letterSpacing: "inherit" }}>{t('pasus10')}</p>
                        </div>
                    </div>
                    <br />
                    <div className='row'>
                        <div className='col-sm-12'>
                            <h3 style={{ color: "#212529", fontSize: "1.75rem", fontWeight: "500", marginTop: "0", marginBottom: "0.5rem", textAlign: "left", textTransform: "none", fontFamily: "inherit" }}>{t('drugiWebsajtovi')}</h3>
                            <p style={{ marginTop: "auto", fontSize: "1rem", fontWeight: "400", letterSpacing: "inherit" }}>{t('pasus11')}</p>
                        </div>
                    </div>
                    <br />
                    <div className='row'>
                        <div className='col-sm-12'>
                            <h3 style={{ color: "#212529", fontSize: "1.75rem", fontWeight: "500", marginTop: "0", marginBottom: "0.5rem", textAlign: "left", textTransform: "none", fontFamily: "inherit" }}>{t('promenaPodataka')}</h3>
                            <p style={{ marginTop: "auto", fontSize: "1rem", fontWeight: "400", letterSpacing: "inherit" }}>{t('pasus12')}</p>
                        </div>
                    </div>
                    <br />
                    <div className='row'>
                        <div className='col-sm-12'>
                            <h3 style={{ color: "#212529", fontSize: "1.75rem", fontWeight: "500", marginTop: "0", marginBottom: "0.5rem", textAlign: "left", textTransform: "none", fontFamily: "inherit" }}>{t('izmenaPolitike')}</h3>
                            <p style={{ marginTop: "auto", fontSize: "1rem", fontWeight: "400", letterSpacing: "inherit" }}>{t('pasus13')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Terms