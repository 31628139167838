import React from 'react';
import { useDispatch } from 'react-redux';
import styles from '../../css/Wishlist.module.css';

import {
    removeProductWishListAll
} from '../../redux/actions/productActions';
import { useTranslation } from 'react-i18next';

const WishListButtonRemoveAll = () => {

    const dispatch = useDispatch();
    const { t } = useTranslation('other');

    const removeWishListItemFunction = () => {
        dispatch(removeProductWishListAll('', 1));
    }

    return (
        <div className={styles['wish-list-remove-all']}>
            <button className="btn btn-warning" onClick={(e) => removeWishListItemFunction()}>{t('obrisiIzListeZelja')}</button>
        </div>
    )
}

export default WishListButtonRemoveAll;