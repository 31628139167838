import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Slider from "react-slick";

import ProductBanner from './ProductBanner';
import ImageBanner from './ImageBanner';
import ElementBanner from './ElementBanner';

import { getCompanyPromoMaterial } from '../../redux/actions/companyPromoMaterialActions';

const PageBanner = (props) => {

    var bannerList = [];
    // var userInfoData = ls.get('userInfo');
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCompanyPromoMaterial());
    }, []);

    const getUserBanner = useSelector((state) => state.getUserBanner.items);


    // console.log(getUserBanner, 'getuserbanner')

    if (props.categoryType == 'brend') {
        bannerList = getUserBanner.filter(x => x.acDept == props.categoryId);
    } else if (props.pageId != 0) {
        bannerList = getUserBanner.filter(x => x.anPageKey == props.pageId);
    } else {
        bannerList = getUserBanner.filter(x => x.anCategoryKey == props.categoryId);
    }

    // const settingsSlider = {
    //     dots: true,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 1,
    //     adaptiveHeight: false,
    //     slidesToScroll: 1,
    //     prevArrow: '',
    //     nextArrow: '',
    //     autoplay: false,
    //     autoplaySpeed: 4000,
    // };

    // if (!userInfoData && bannerList[0].isForLogedUser == 1) {
    //     console.log('usao')
    //     return null;
    // }

    return (
        <>
            {Object.keys(bannerList).length != 0 && (
                <>
                    {bannerList[0].acBannerType == 0 && <ImageBanner pageId={props.pageId} categoryId={props.categoryId} />}
                    {bannerList[0].acBannerType == 1 && <ProductBanner item={bannerList[0]} />}
                    {bannerList[0].acBannerType == 2 && <ElementBanner pageId={props.pageId} item={bannerList[0]} />}
                </>
            )}
        </>
        // {bannerList.length != 0 &&
        //     //     bannerList.length > 1 ? (
        //     //     <Slider {...settingsSlider}>
        //     //         {bannerList.map((item, key) => (
        //     //             item.acBannerType == 0 ? <ImageBanner pageId={props.pageId} categoryId={props.categoryId} />
        //     //                 : item.acBannerType == 1 ? <ProductBanner item={item} />
        //     //                     : item.acBannerType == 2 && <ElementBanner pageId={props.pageId} item={item} />
        //     //             // <BlockBannerItem item={item} key={key} />
        //     //             // <div className="banner-block-home-item" key={key}>
        //     //             //     <img src={process.env.PUBLIC_URL + '/media/banners/1438x500.jpg'} className="slider-image-home-block" alt="b2b" />
        //     //             // </div>
        //     //         ))}
        //     //     </Slider>
        //     // ) : (
        //     <>
        //         {bannerList[0].acBannerType == 0 && <ImageBanner pageId={props.pageId} categoryId={props.categoryId} />}
        //         {bannerList[0].acBannerType == 1 && <ProductBanner item={bannerList[0]} />}
        //         {bannerList[0].acBannerType == 2 && <ElementBanner pageId={props.pageId} item={bannerList[0]} />}
        //     </>
        // }
    )
};

export default PageBanner;
