import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import PageBanner from '../components/banner/PageBanner';
import LoaderMaskLine from '../components/other/LoaderMaskLine';
import LoaderMask from '../components/other/LoaderMask';
import CatalogTopFilters from '../components/catalog/CatalogTopFilters';
import CatalogFilterSelectedContent from '../components/catalog/CatalogFilterSelectedContent';
import CatalogPreviewBlock from '../components/catalog/CatalogPreviewBlock';
import FilterSidebarModal from '../components/filtersMobile/FilterSidebarModal';
import PageHelmet from '../components/helmet/PageHelmet';

import { globalStatistic } from '../redux/actions/otherActions';
import { getCatalogMenuList } from '../redux/actions/menuActions';

import { removeSpecialChar } from '../helperFunctions'
import { useTranslation } from 'react-i18next';

const CatalogPage = () => {

    const { categoryId, catagoryType } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    let pathname = history.location.pathname;
    const getProductsList = useSelector((state) => state.catalogProducts);
    const isLoading = useSelector((state) => state.catalogProducts.loading);
    const catalogDefaultFilters = useSelector((state) => state.catalogDefaultFilters);
    const userInfo = useSelector((state) => state.userInfo);
    const getCatalogMenuListItems = useSelector((state) => state.getCatalogMenuList);
    const isLoggedIn = userInfo.userInfo;

    var kvp = history.location.search.substr(1).split('&')[0];
    var kvp1 = history.location.search.substr(1).split('&')[1];
    var itemFilType = kvp.split('=')[0].split('_')[0];

    const isDesktop = window.innerWidth > 1020;
    const [filterModal, setFilterModal] = useState(false);
    const [currentCategoryItem, setCurrentCategoryItem] = useState({});
    const { t } = useTranslation(['catalogPage', 'routes']);

    const setDeafultFilters = () => {
        dispatch({
            type: 'SET_DEFAULT_URL_FILTERS',
            payload: ""
        });
    }

    // f-ja gleda da li je skrol stigao do kraja stranice
    const handleScroll = () => {
        const bottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight
        if (bottom) {
            dispatch(globalStatistic(categoryId, 'END_CATEGORY'));
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, {
            passive: true
        });
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        dispatch(globalStatistic(categoryId, 'CATEGORY'));
        dispatch(getCatalogMenuList());
    }, [categoryId])

    useEffect(() => {
        setCurrentCategoryItem(findObjectById(getCatalogMenuListItems, categoryId));
        // console.log(getCatalogMenuList, 'getCatalogMenuList u CatalogPage');
        // console.log(categoryId, 'categoryId u CatalogPage');
        // console.log(currentCategoryItem, 'currentCategoryItem u CatalogPage');
    }, [getCatalogMenuListItems])

    const functionClearAllFilters = () => {
        history.push(pathname);
        setDeafultFilters();
    };

    const findObjectById = (objArray, targetId) => {
        for (let obj of objArray) {
            if (obj._id == targetId) {
                return obj;
            }

            if (obj.subCategories) {
                const result = findObjectById(obj.subCategories, targetId);
                if (result) {
                    return result;
                }
            }
        }

        return null;
    };

    return (
        <>
            {currentCategoryItem &&
                <PageHelmet
                    title={currentCategoryItem ? `${currentCategoryItem.acSeoTitle}` : "Ewe Comp"}
                    description={currentCategoryItem ? `${currentCategoryItem.acSeoDescription}` : ""}
                    keywords={currentCategoryItem ? `${currentCategoryItem.acSeoKeywords}` : ""}
                    ogDescription={currentCategoryItem ? `${currentCategoryItem.acSeoDescription}` : ""}
                    ogTitle={currentCategoryItem ? `${currentCategoryItem.acSeoTitle}` : ""}
                    ogUrl={currentCategoryItem ? `https://ewe.rs/${currentCategoryItem.acSeoUrl}` : ""}
                    ogImg="https://www.ewe.rs/media/ewe-og-img.jpg"
                    ogType="website"
                    twitterDescription={currentCategoryItem ? `${currentCategoryItem.acSeoDescription}` : ""}
                />
            }
            <div className="container-content container-catalog-content">
                <div className="container-fluid">
                    <div className="row">
                        {isLoading ? (
                            <div className='products-loader-container'>
                                <LoaderMask />
                            </div>
                        ) : (
                            <div className="main-title-categroy-item">
                                {getProductsList.items.length > 0 ? (
                                    <>
                                        {catagoryType === "brend" ? (
                                            categoryId
                                        ) : catagoryType === "kategorija" ? (
                                            // getProductsList.items[0].category[0].acCategory
                                            < span className="catalog-breadcrumbs-item">{getProductsList.items[0].acMainCategory} </span>
                                        ) : catagoryType === "podkategorija" ? (
                                            <>
                                                {catalogDefaultFilters != "" ? (
                                                    <>
                                                        <span className="catalog-breadcrumbs-item">{getProductsList.items[0].acMainCategory} / </span>
                                                        <span className='catalog-breadcrumbs-item'><b>{catalogDefaultFilters.name}</b></span>
                                                    </>
                                                ) : (
                                                    <>
                                                        <span className="catalog-breadcrumbs-item">{getProductsList.items[0].acMainCategory} / </span>
                                                        <span className='catalog-breadcrumbs-item'><b>{getProductsList.items[0].acCategory}</b></span>
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            catagoryType === "podpodkategorija" && (
                                                <>
                                                    {catalogDefaultFilters != "" ? (
                                                        <>
                                                            <span className="catalog-breadcrumbs-item">{getProductsList.items[0].acMainCategory} / </span>
                                                            <span className="catalog-breadcrumbs-item">
                                                                <Link to={`/${t('routes:katalog')}/podkategorija/${getProductsList.items[0].anCategoryKey}/${removeSpecialChar(getProductsList.items[0].acCategory)}`} onClick={setDeafultFilters}>{getProductsList.items[0].acCategory} </Link> /{" "}
                                                            </span>
                                                            <span className='catalog-breadcrumbs-item'><b>{catalogDefaultFilters.name}</b></span>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <span className="catalog-breadcrumbs-item">{getProductsList.items[0].acMainCategory} / </span>
                                                            <span className="catalog-breadcrumbs-item">
                                                                <Link to={`/${t('routes:katalog')}/podkategorija/${getProductsList.items[0].anCategoryKey}/${removeSpecialChar(getProductsList.items[0].acCategory)}`} onClick={setDeafultFilters}>{getProductsList.items[0].acCategory} </Link> /{" "}
                                                            </span>
                                                            <span className='catalog-breadcrumbs-item'><b>{getProductsList.items[0].acSubCategory}</b></span>
                                                        </>
                                                    )}

                                                </>
                                            )
                                        )}
                                        <span className='catalog-breadcrumbs-item'> <b>({getProductsList.items.length})</b></span>
                                    </>
                                ) : (
                                    // <LoaderMaskLine />
                                    null
                                )}
                                {currentCategoryItem && <h1>{currentCategoryItem.acSeoHeader}</h1>}
                                {currentCategoryItem && <h4>{currentCategoryItem.acSeoShortPageDescription}</h4>}
                            </div>
                        )}
                        <div className="col-12 catalog-banner-padding">
                            <PageBanner categoryType={catagoryType} categoryId={categoryId} pageId="0" />
                        </div>
                        {isDesktop && getProductsList.items.length > 0 && (
                            <div className="catalog-filters-content">
                                <CatalogTopFilters />
                            </div>
                        )}
                        {itemFilType == "stanje" && kvp1 != undefined ?
                            history.location.search && getProductsList.items.length > 0 && (
                                <div className="catalog-selected-filters-content">
                                    <div className="catalog-selected-filters-title">{t('catalogPage:odabraniFilteri')}</div>
                                    <div className="catalog-selected-filters-items">
                                        <CatalogFilterSelectedContent />
                                    </div>
                                    <div className="catalog-reset-filters-btn" onClick={(e) => functionClearAllFilters()}>
                                        <div className="reset-btn-filters-catalog">
                                            {t('catalogPage:resetFiltera')} <i className="fas fa-times-circle"></i>
                                        </div>
                                    </div>
                                </div>
                            )
                            : itemFilType == "stanje" && kvp1 == undefined ?
                                ("")
                                : history.location.search && getProductsList.items.length > 0 && (
                                    <div className="catalog-selected-filters-content">
                                        <div className="catalog-selected-filters-title">{t('catalogPage:odabraniFilteri')}</div>
                                        <div className="catalog-selected-filters-items">
                                            <CatalogFilterSelectedContent />
                                        </div>
                                        <div className="catalog-reset-filters-btn" onClick={(e) => functionClearAllFilters()}>
                                            <div className="reset-btn-filters-catalog">
                                                {t('catalogPage:resetFiltera')} <i className="fas fa-times-circle"></i>
                                            </div>
                                        </div>
                                    </div>
                                )}
                        {!isDesktop && (
                            <div className="mobile-filters-button">
                                <button className="btn btn-info w-100" onClick={() => setFilterModal(true)}><i className="fas fa-filter"></i> {t('catalogPage:filteri')}</button>
                            </div>
                        )}
                        <CatalogPreviewBlock itemsList={getProductsList} sortable="1" preview="1" export="1" itemPreview="catalog" pdfType="katalog" />
                    </div>
                </div>
            </div>
            {!isDesktop && <FilterSidebarModal setFilterModal={setFilterModal} filterModal={filterModal} />}
        </>
    );
}

export default CatalogPage;