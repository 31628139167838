import React, { useState } from 'react';
import Slider from "react-slick";
import { useHistory, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const SliderLogoutPage = ({ items }) => {
    const [mouseMoved, setMouseMoved] = useState(false);
    const history = useHistory();
    const { t } = useTranslation(['block', 'routes']);

    if (!Array.isArray(items)) {
        return null;
    }

    const reversedItems = items.slice().reverse();
    
    const handleClick = (linkUrl) => {
        if (!mouseMoved) {
            history.push(linkUrl);
        }
    };

    var responsive = [
        {
            breakpoint: 1921,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 1750,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                initialSlide: 2,
            },
        },
        {
            breakpoint: 1400,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 1,
            },
        },
        {
            breakpoint: 1000,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2,
            },
        },
        {
            breakpoint: 700,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 450,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ];

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: responsive
    };

    return (
        <Slider {...settings}>
            {reversedItems.map((item, key) => (
                <div className="slider-logout-page-item">
                    <div className="slider-logout-page-item-image">
                        <img src={`https://resource.ewe.rs/media/${item.acImage}`} className="img-fluid" alt="ewe" />
                        {/* <img src={`/images/materijali.jpg`} className="img-fluid" alt="ewe" /> */}
                    </div>
                    <div className="slider-logout-page-item-body">
                        <h3>{item.acTitle}</h3>
                        <div className="slider-logout-page-item-link">
                            <Link
                                onMouseMove={() => setMouseMoved(true)}
                                onMouseDown={() => setMouseMoved(false)}
                                onMouseUp={() => handleClick(`/${t('routes:stranica')}/${item.anPageId}/${item.acTitle.replace(/\s+/g, '-').toLowerCase()}`)}
                            >
                                {t('saznajVise')}
                            </Link>
                        </div>
                    </div>
                </div>
            ))}
        </Slider>
    )
}

export default SliderLogoutPage;