import React from 'react';

const DebitDiagramItemMobileRight = (props) => {
    return (
        <div className="debit-content-info-row">
            {/* <div className="debit-content-info-row-item debit-content-info-row-item-left-side flex-2">
                <div className="debit-content-line-box debit-content-line-box-left">
                    {props.left !== '' && (
                        <>
                            <div className="debit-box-value">{props.left}</div>
                            <div className="debit-box-red debit-box-item-diagram" style={{ width: props.percentageLeft }}></div>
                        </>
                    )}
                </div>
            </div> */}
            <div className="debit-content-info-row-item debit-content-info-row-item-center-side flex-05">{props.title}</div>
            <div className="debit-content-info-row-item debit-content-info-row-item-right-side flex-2">
                <div className="debit-content-line-box debit-content-line-box-right">
                    {props.right !== '' && (
                        <>
                            <div className="debit-box-green debit-box-item-diagram" style={{ width: props.percentageRight }}></div>
                            <div className="debit-box-value">{props.right}</div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default DebitDiagramItemMobileRight;