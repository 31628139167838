import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';


const ModalConfirm = ({ show, handleClose, functionActionConfirm }) => {
    const { t } = useTranslation('modal');

    return (
        <Modal
            show={show}
            onHide={handleClose}
            keyboard={false}
            dialogClassName='modal-l'
            aria-labelledby=""
            centered
        >
            <div className="close-action-modal" variant="secondary" onClick={handleClose}>
                <i className="far fa-times-circle"></i>
            </div>
            <Modal.Header>
                <Modal.Title>
                    <div>{t('resetovanjeForme')}</div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <p><b>{t('daLiSteSigurni')}</b></p>
                    <p>{t('molimoDaBudetePazljivi')}</p>
                    <p>{t('kliknite')} <b>{t('potvrdi')}</b> {t('akoSteSigurni')}<b>{t('otkazi')}</b>{t('akoZeliteDaZadrzite')}</p>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-success" onClick={(e) => functionActionConfirm()}>{t('potvrdi')}</button>
                <button className="btn btn-secondary" onClick={handleClose}>{t('otkazi')}</button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalConfirm;