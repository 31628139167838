import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import Moment from 'react-moment';
import { useHistory } from 'react-router-dom';

import CurrencyFormat from '../other/CurrencyFormat';
import { useTranslation } from 'react-i18next';

const ModalDelivery = ({ show, handleClose, item, images, sendData }) => {

    const history = useHistory();
    const { t } = useTranslation('modal');

    const escFunction = (event) => {
        if (event.keyCode === 27) {
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);

        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, []);

    const handleConfirm = (e) => {
        e.preventDefault();
        sendData(e);
    };

    return (
        <Modal
            show={show}
            onHide={handleClose}
            keyboard={false}
            dialogClassName='modal-xl'
            aria-labelledby=""
            centered
        >
            <div className="close-action-modal" variant="secondary" onClick={handleClose}>
                <i className="far fa-times-circle"></i>
            </div>
            <Modal.Header>
                <Modal.Title>
                    <h3>{t('prilikomZahtevaUneliSte')}</h3>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="modal-detail-user-info">
                    <h5>{t('podaciOisporuci')}</h5>
                    <table className="modal-table-detail-user">
                        <tbody>
                            <tr>
                                <td className="modal-left-side-info-detail">{t('faktura')}</td>
                                <td>{item.acDocKey}</td>
                            </tr>
                            <tr>
                                <td className="modal-left-side-info-detail">{('imeIprezime')}</td>
                                <td>{item.acEndUser}</td>
                            </tr>
                            <tr>
                                <td className="modal-left-side-info-detail">{t('adresa')}</td>
                                <td>{item.acEndUserAddress}</td>
                            </tr>
                            <tr>
                                <td className="modal-left-side-info-detail">{t('grad')}</td>
                                <td>{item.acPostCode}</td>
                            </tr>
                            <tr>
                                <td className="modal-left-side-info-detail">{t('telefon')}</td>
                                <td>{item.acPhone1}</td>
                            </tr>
                            <tr>
                                <td className="modal-left-side-info-detail">{t('telefon2')}</td>
                                <td>{item.acPhone2}</td>
                            </tr>
                            <tr>
                                <td className="modal-left-side-info-detail">{t('skenIsecakIliFaktura')}</td>
                                <td>{images[0].name}</td>
                            </tr>
                            <tr>
                                <td className="modal-left-side-info-detail">{t('eweOtpremnica')}</td>
                                <td>{images[1].name}</td>
                            </tr>
                            <tr>
                                <td className="modal-left-side-info-detail">{t('naplataNaAdresi')}</td>
                                <td>{item.isRansomDelivery == 1 ? "Da" : "Ne"}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {item.isRansomDelivery == 1 &&
                    <div className="modal-detail-order-info">
                        <table className="modal-table-order">
                            <thead>
                                <tr>
                                    <th>{t('naplataCekovima')}</th>
                                    <th>{t('iznosOtkupa')}</th>
                                    <th>{t('brRacZaUplatu')}</th>
                                    <th>{t('brFisIseckaIliFakture')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{item.acPayMethod == true ? "Da" : "Ne"}</td>
                                    <td>{item.anRansomAmount}</td>
                                    <td>{item.acCompanyBankAccount}</td>
                                    <td>{item.acCompanyReceipt}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                }
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-success" onClick={handleConfirm}>{t('potvrdi')}</button>
                <button className="btn btn-secondary" onClick={handleClose}>{t('zatvori')}</button>
            </Modal.Footer>
        </Modal >
    );
};

export default ModalDelivery;