import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Countdown from 'react-countdown';

import CurrencyFormat from '../other/CurrencyFormat';
import AuctionBidItem from '../other/AuctionBidItem';
import { setAuctionItemBidFunction } from '../../redux/actions/productActions';
import { useTranslation } from 'react-i18next';

const AuctionDetailItem = ({ item }) => {
    // console.log(item, 'item')
    const dispatch = useDispatch();
    const { t } = useTranslation('other');

    const getAuctionProductsList = useSelector((state) => state.getAuctionProducts);
    // const getAuctionBidList = getAuctionProductsList.filter((x) => x.acAuctionKey == getAuctionBidList[0].acAuctionKey);
    const getAuctionBidList = item;
    // const [dateTo, setDateTo] = useState(getAuctionBidList[0].adDateTo);
    const [bidValue, setBidValue] = useState('');


    const today = new Date();
    // today.setDate(today.getDate() + 1);
    // today.setHours(0, 0, 0);

    // const ttoday = today.getTime();

    //minuti
    if (today.getMinutes() < 10) {
        var minutes = '0' + today.getMinutes();
    } else {
        var minutes = today.getMinutes();
    }
    var month = today.getMonth() + 1;
    //dani
    if (today.getMonth() + 1 < 10) {
        let sumMonth = today.getMonth() + 1;
        month = '0' + sumMonth;
    }


    var currentDate = today.getFullYear() + '-' + month + '-' + today.getDate() + ' ' + today.getHours() + ":" + minutes + ":" + today.getSeconds();
    var startDate = item[0].adDateFrom
    var endDate = item[0].adDateTo

    currentDate = new Date(currentDate)
    startDate = new Date(startDate)
    endDate = new Date(endDate)

    // R  andom component
    // const Completionist = () => <span>Vreme za licitaciju je isteklo!</span>;

    // Renderer callback with condition
    // const renderer = ({ days, hours, minutes, seconds, completed }) => {
    //     if (completed) {
    //         // Render a completed state
    //         return <Completionist />;
    //     } else {
    //         // Render a countdown
    //         return (
    //             <>
    //                 <div>{days < 10 ? '0' + days : days}</div>:<div>{hours < 10 ? '0' + hours : hours}</div>:<div>{minutes < 10 ? '0' + minutes : minutes}</div>:<div>{seconds < 10 ? '0' + seconds : seconds}</div>
    //             </>
    //         )
    //     }
    // };

    // useEffect(() => {
    //     setDateTo(getAuctionBidList[0].adDateTo);
    // }, [item]);

    const setAuctionItemBidFunctionMain = (item, bidValue) => {
        dispatch(setAuctionItemBidFunction(item, bidValue));
        setBidValue('');
    }

    const setAuctionItemBidFunctionMainEnter = (item, bidValue, e) => {
        if (e.key == 'Enter') {
            dispatch(setAuctionItemBidFunction(item, bidValue));
            setBidValue('');
        }
    }

    let fastBidValue = item[0].anCurentPrice + item[0].anPace;
    const fastBid = () => {
        dispatch(setAuctionItemBidFunction(item[0], fastBidValue));
    }

    return (
        <div className="auction-detail-holder">
            <div className="auction-body-detail">
                <div className="auction-big-image-block">
                    <div className="auction-image-block-title">
                        {getAuctionBidList[0].acDept} {getAuctionBidList[0].acProductName}
                        <p>{getAuctionBidList[0].acDescription}</p>
                    </div>
                    <div className="auction-image-block-image">
                        <img alt="" src={`${getAuctionBidList[0].acImage}`} />
                    </div>
                    <div className="auction-image-block-price">
                        <div className="auction-image-block-price-title">{t('pocetnaCena')}</div>
                        <div className="auction-price-value"><CurrencyFormat num={getAuctionBidList[0].anStartPrice} />€</div>
                    </div>
                    <div className="auction-image-block-time">
                        <div className="auction-image-block-time-title">{t('preostaloZaLicitiranje')}</div>
                        <div className="auction-image-block-time-value">
                            <div className="timer-item">
                                {/* <Countdown
                                    date={dateTo}
                                    renderer={renderer}
                                /> */}

                                {currentDate <= endDate && currentDate >= startDate && (
                                    <Countdown
                                        date={item[0].adDateTo}
                                    />
                                )}
                                {currentDate <= startDate && (
                                    <Countdown
                                        date={item[0].adDateFrom}
                                    />
                                )}
                                {currentDate >= startDate && currentDate >= endDate && (
                                    <span>{t('vremeZaLicitacijuIsteklo')}</span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="auction-list-block">
                    <div className="auction-list-block-title">{t('trenutnaPonuda')} <span><CurrencyFormat num={getAuctionBidList[0].anCurentPrice} />€</span></div>
                    <div className="auction-list-block-table">
                        {getAuctionBidList[0].auctionBid.length > 0 ? (
                            <ul>
                                {getAuctionBidList[0].auctionBid.map((item, key) => {
                                    // console.log(key, 'key')
                                    // console.log(item, 'item')
                                    return (
                                        <AuctionBidItem key={key} item={item} />
                                    )
                                })}
                            </ul>
                        ) : (
                            <ul>
                                <li>{t('nemaPonuda')}</li>
                            </ul>
                        )}
                    </div>
                    {currentDate <= endDate && currentDate >= startDate &&
                        <>
                            <div className="auticon-item-buttons">
                                <div className="button-bid-list" title={`Izborom brze licitacije dajete prvu sledeću minimalnu ponudu. Sledeća minimalna ponuda je: ${fastBidValue.toFixed(2).replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}€`} onClick={fastBid}>{t('brzoLicitiraj')}</div>
                                <div className="auction-list-block-bid-input-box">
                                    <div className="auction-list-block-bid-input">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={t('unesSvojuPonudu')}
                                            value={bidValue}
                                            onChange={(e) => setBidValue(e.target.value)}
                                            onKeyPress={(e) => setAuctionItemBidFunctionMainEnter(getAuctionBidList[0], bidValue, e)}
                                        />
                                    </div>
                                    <div className="auction-list-block-bid-button-holder">
                                        <button
                                            className="auction-list-block-bid-button"
                                            onClick={(e) => setAuctionItemBidFunctionMain(getAuctionBidList[0], bidValue)}
                                        >
                                            {t('licitiraj')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="auction-list-info">
                                {t('sledecaMinimalna')} <CurrencyFormat num={getAuctionBidList[0].anCurentPrice + getAuctionBidList[0].anPace} />€
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default AuctionDetailItem;