import React from 'react';
import { useHistory } from 'react-router';
import { filtersDataCheck } from '../../functions/filters';
import CatalogFilterTopItemCheckbox from './CatalogFilterTopItemCheckbox';
import OutsideClickHandler from 'react-outside-click-handler';

const CatalogFilterTopItemModal = ({ items, functionCloseModal, functionItemClickCheckbox }) => {

  const functionCloseModalFunc = () => {
    functionCloseModal(false);
  }

  const clickFunctionItem = (e, item2, valueItem, type, key) => {
    functionItemClickCheckbox(e, item2, valueItem, type, key);
  }


  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        functionCloseModalFunc()
      }}
    >
      <div className={`subfilters-list-content-items active`}>
        <div className="conent-subfilters-holder">
          <ul>
            { items.attributeValueList.map((item2, key) => (
              <CatalogFilterTopItemCheckbox items={items} item2={item2} clickFunctionItem={clickFunctionItem} key={key} />
            ))}
          </ul>
          <div className="close-top-filter-modal" onClick={(e) => functionCloseModalFunc()}><i className="fas fa-times"></i></div>
        </div>
      </div>
    </OutsideClickHandler>
    
  );
};

export default CatalogFilterTopItemModal;