import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';

import CategorySidebarSubItem from './CategorySidebarSubItem';
import CategorySidebarVirtualSubItem from './CategorySidebarVirtualSubItem';

import { removeSpecialChar } from '../../helperFunctions'
import { useTranslation } from 'react-i18next';

const CategorySidebarItem = ({ item, setMenuItem }) => {

    const dispatch = useDispatch();
    const [subnav, setSubnav] = useState(false);
    const { t } = useTranslation('routes');

    const showSubnav = () => {
        setSubnav(!subnav);
    };

    const setDeafultFilters = () => {
        dispatch({
            type: 'SET_DEFAULT_URL_FILTERS',
            payload: ""
        });
    }

    return (
        <div className="item-menu-category">

            {item.subCategories.length > 0 ? (
                <li className={`category-menu-item ${subnav ? 'active' : ''}`} onClick={item.subCategories && showSubnav}>{item.acName}
                    <span className="icon-expand-menu-items">
                        {
                            item.subCategories.length > 0 && subnav
                                ? <FontAwesomeIcon className="category-expanded-li" icon={faChevronDown} />
                                : item.subCategories.length > 0
                                    ? <FontAwesomeIcon icon={faChevronRight} color="#eae8e9" />
                                    : null
                        }
                    </span>
                </li>
            ) : (
                <Link className="category-main-no-sub" to={`/${t('routes:katalog')}/kategorija/${item._id}/${item.acSeoUrl ? removeSpecialChar(item.acSeoUrl) : removeSpecialChar(item.acName)}`} onClick={setDeafultFilters}>
                    <li className="category-menu-item" >{item.acName}</li>
                </Link>
            )}


            {subnav && item.subCategories.length > 0 ? (
                <ul className="submenu-items">
                    {item.subCategories.map((item2, key2) => (
                        <CategorySidebarSubItem setMenuItem={setMenuItem} item={item2} key={key2} />
                    ))}
                </ul>
            ) : null}
            {subnav && item.virtualCategories.length > 0 ? (
                <ul className="submenu-items submenu-items-virtual">
                    {item.virtualCategories.map((item2, key) => (
                        <CategorySidebarVirtualSubItem setMenuItem={setMenuItem} item={item2} key={key} />
                    ))}
                </ul>
            ) : null}
        </div>
    )
};

export default CategorySidebarItem;