import React from 'react';

const DebitDiagramItemMobileLeft = (props) => {
    return (
        <div className="debit-content-info-row">
            <div className="debit-content-info-row-item debit-content-info-row-item-left-side flex-2">
                <div className="debit-content-line-box debit-content-line-box-left">
                    {props.left !== '' && (
                        <>
                            <div className="debit-box-value">{props.left}</div>
                            <div className="debit-box-red debit-box-item-diagram" style={{ width: props.percentageLeft }}></div>
                        </>
                    )}
                </div>
            </div>
            <div className="debit-content-info-row-item debit-content-info-row-item-center-side flex-05">{props.title}</div>
        </div>
    );
};

export default DebitDiagramItemMobileLeft;