import React from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";

const CartItemModal = ({ item2, item, checkedFunctionCount }) => {
    const { t } = useTranslation('cart');
    const getB2bSettings = useSelector((state) => state.getB2bSettings);

    const checkedFunctionCountFunc = (item2) => {
        checkedFunctionCount(item2);
    }

    return (
        <tr>
            <td>{item2.anPromoCodeId}</td>
            <td>{item2.acPromoCode}</td>
            <td>{item2.anPromoCodeValue} {getB2bSettings?.items?.acLogOutUserCurrency || t('rsd')}</td>
            {/* <td>{item2.anPromoCodeUsage}</td> */}
            <td>{item2.anDaysLeft} {t('dana')}</td>
            <td>
                {item.acPromoCodeList.includes(item2) ?
                    <button className='btn btn-danger' onClick={() => checkedFunctionCountFunc(item2)}>
                        {t('obrisiVaucer')}
                    </button>
                    :
                    <button className='btn btn-success' onClick={() => checkedFunctionCountFunc(item2)}>
                        {t('dodajVaucer')}
                    </button>
                }

                {/* <input 
                    type="checkbox" 
                    className="" 
                    onChange={(e) => checkedFunctionCountFunc(e, item2)}    
                /> */}
            </td>
        </tr>
    )
};

export default CartItemModal;