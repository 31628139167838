import React, { useState, useRef } from 'react'
import { useDispatch } from 'react-redux';
import { CSVLink } from "react-csv";

import { b2bNewsletterSignup, exportCatalog } from '../../redux/actions/otherActions';

import ExcelExportCatalog from './ExcelExportCatalog';
import LoaderMaskLine from '../other/LoaderMaskLine';
import { useTranslation } from 'react-i18next';

const HomePageExports = () => {

    const dispatch = useDispatch();
    const dataRef = useRef();

    const [newsletterEmail, setNewsletterEmail] = useState('');
    const [errors, setErrors] = useState(1);
    const [validInput, setValidInput] = useState(0);
    const [exportCatalogState, setExportCatalogState] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const { t } = useTranslation('customExportCatalog');

    const headers = [
        { label: t('kategorija'), key: "acCategory" },
        { label: t('podkategorija'), key: "acSubCategory" },
        { label: t('ident'), key: "acProduct" },
        { label: t('proizvodjac'), key: "acDept" },
        { label: t('naziv'), key: "acName" },
        { label: t('cenaSaPopustom'), key: "anPrice" },
        { label: t('kolicina'), key: "anStock" },
        { label: t('preporucenaMpCena'), key: "anRecommendedRetailPrice" },
        { label: t('ean'), key: "acEan" },
        { label: t('uvoznik'), key: "acSupplier" },
        { label: t('zPorekla'), key: "acCountry" },
        { label: t('opis'), key: "acInlineSpecification" },
        { label: t('tezina'), key: "anDimWeightBrutto" },
        { label: t('visina'), key: "anDimHeight" },
        { label: t('sirina'), key: "anDimWidth" },
        { label: t("duzina"), key: "anDimDepth" },
        { label: t("brKomada"), key: "anProductTransport" }
    ]

    const onChangeFunction = (e) => {
        setNewsletterEmail(e.target.value);

        if (!e.target.value || !e.target.value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
            setValidInput(1);
            setErrors(1);
        } else {
            setValidInput(2);
            setErrors(0);
        }
    }

    const handleInput = (e) => {
        e.preventDefault();

        if (errors == 0) {
            dispatch(b2bNewsletterSignup(newsletterEmail))
        }
    }

    const onExportCatalog = (val) => {
        setShowLoader(true)
        dispatch(exportCatalog(val)).then(res => {
            for (var i = 0; i < res.length; i++) {
                res[i].acDept = res[i].acDept.replace(" 1****", "");
            }
            setExportCatalogState(res); dataRef.current.click(); setShowLoader(false)
        })
    }

    return (
        <div className="row home-page-exports">
            {/* <div className="form-group col-sm-6"> */}
            {/* <div className="input-group input-group-sm">
                    <input type="email" name="acEmail" className="form-control" id="acEmail" value={newsletterEmail} onChange={(e) => onChangeFunction(e)} placeholder="Prijavite se na naš newsletter..." />
                    <div className="input-group-append">
                        <button className="btn btn-submit-email homepage-newsletter text-center" onClick={handleInput}><i className="far fa-envelope"></i></button>
                    </div>
                </div>
                {validInput == 1 &&
                    <div className="invalid-feedback d-block">
                        Email nije u dobrom formatu!
                    </div>
                } */}
            {/* </div> */}
            <div className="col-sm-12 export-buttons">
                <ExcelExportCatalog />
                {!showLoader ?
                    <div className="btn btn-sm btn-newsletter text-center" onClick={() => onExportCatalog("csv")}><i className="fas fa-angle-double-down"></i>&nbsp; {t('exportCsv')}</div>
                    :
                    <LoaderMaskLine />
                }
                <CSVLink filename={t('proizvodi') + Date.now() + ".csv"} data={exportCatalogState} headers={headers} target="_parent">
                    <span ref={dataRef}></span>
                </CSVLink>
            </div>
        </div>
    )
}

export default HomePageExports