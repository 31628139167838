import React, { useRef } from 'react';
import Slider from "react-slick";
import BlockActionSliderItem from './BlockActionSliderItem';

import { useDispatch } from 'react-redux';

import { globalStatistic } from '../../redux/actions/otherActions';

const BlockActionSliderContent = (props) => {
    const dispatch = useDispatch()
    const slider = useRef(null);
    let responsive = []
    if (props.type == "relatedProducts" || props.type == "viewedProducts") {
        responsive = [
            {
                breakpoint: 2300,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 6,
                },
            },
            {
                breakpoint: 2000,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                },
            },
            {
                breakpoint: 1800,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                },
            },
            {
                breakpoint: 1537,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 1475,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 1150,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 1020,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 850,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 599,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ]

    } else {
        responsive = [

            {
                breakpoint: 2560,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                },
            },
            {
                breakpoint: 2450,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                },
            },

            {
                breakpoint: 1921,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 1680,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ];
    }

    const handleClickNext = () => {
        slider.current.slickNext();
        dispatch(globalStatistic(0, 'SLIDER_PRODUCT'));
    }

    const handleClickPrev = () => {
        slider.current.slickPrev();
        dispatch(globalStatistic(0, 'SLIDER_PRODUCT'));
    }

    function SampleNextArrow(props) {
        const { className, style } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block", borderRadius: '10px', right: "-10px", zIndex: 2 }}
                onClick={(e) => handleClickNext()}
            />
        );
    };

    function SamplePrevArrow(props) {
        const { className, style } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block", borderRadius: '10px', right: "-10px", zIndex: 2 }}
                onClick={(e) => handleClickPrev()}
            />
        );
    };

    const settingsSlider = props.type == "relatedProducts" || props.type == "viewedProducts" ? {
        dots: false,
        infinite: false,
        speed: 500,
        // slidesToShow: props.slidesToShow,
        // slidesToScroll: props.slidesToScroll,
        responsive: responsive,
        slidesToShow: 7,
        slidesToScroll: 7,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
    } : {
        dots: false,
        infinite: false,
        speed: 500,
        // slidesToShow: props.slidesToShow,
        // slidesToScroll: props.slidesToScroll,
        responsive: responsive,
        slidesToShow: 5,
        slidesToScroll: 5,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
    };

    // const settingsSlider = {
    //     dots: false,
    //     infinite: false,
    //     speed: 500,
    //     slidesToShow: props.slidesToShow,
    //     slidesToScroll: props.slidesToScroll,
    //     responsive: responsive,
    //     slidesToShow: 5,
    //     slidesToScroll: 5,
    // };

    return (

        <Slider ref={slider} {...settingsSlider}>
            {props.items.map((item, key) => (
                props.productId != item.anProductKey &&
                <BlockActionSliderItem isHomepage={props.isHomepage} item={item} key={key} isMaxWidth={props.items.length} type={props.type} />
            ))}
        </Slider>
    );
};

export default BlockActionSliderContent;