import React from 'react';

const CompareEmptyItemBox = ({ num }) => {
  return (
    <div className="compare-item-body compare-content-item">
        <div className="compare-item-detail">
            <div className="comapre-item-number">{num}.</div>
            <div className="compare-item-image"></div>
            <div className="compare-item-name"></div>
            <div className="close-compare-item-detail hidden"><i className="fas fa-times"></i></div>
        </div>
    </div>
  )
}

export default CompareEmptyItemBox;