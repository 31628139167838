import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const SearchBox = ({ searchText, setSearchNameFunction, history, searchBoxFunction, searchFunctionClick, closeSearchModalFunction, setShowSearchBox }) => {

    // const [name, setName] = useState(searchText);
    const { t } = useTranslation(['search', 'routes']);
    const onSubmit = (e) => {
        e.preventDefault();
        
        history.push(`/${t('routes:pretraga')}?q=${searchText.toLowerCase()}`);
        // document.getElementById('search-input').value = '';
        // setName('');
        setSearchNameFunction('');
        setShowSearchBox(false)
        closeSearchModalFunction();
    }

    const searchFunction = (valItem) => {
        // setName(valItem);
        setSearchNameFunction(valItem);
        searchBoxFunction(valItem.length, valItem);
    };

    return (
        <form className="form" onSubmit={(e) => onSubmit(e)}>
            <div className="search-box">
                <input 
                    type="text" 
                    id="search-input" 
                    className="form-control" 
                    placeholder={t('placeholder1')}
                    autoComplete="off" 
                    onChange={(e) => searchFunction(e.target.value)} 
                    onClick={(e) => searchFunctionClick()}
                    value={searchText}
                />
                <span className="search-icon"> { searchText === '' ? <i className="fas fa-search"></i> : <i className="fas fa-times clear-search-box" onClick={(e) => searchFunction('')}></i> }</span>
            </div>
        </form>
    )
}

export default SearchBox;