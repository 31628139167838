import React, { useState, useEffect } from 'react';
import Countdown, { zeroPad } from 'react-countdown';
import { useTranslation } from 'react-i18next';


const LoyaltyCounterBlock = ({ loyaltySettings, setActiveShopFunction, activeShop, now }) => {

    const [counter, setCounter] = useState(0);
    var startDate = new Date(loyaltySettings['adLoyaltyStartDate']);
    var endDate = new Date(loyaltySettings['adLoyaltyEndDate']);
    var fullCurrentDate = new Date();

    // const [activeShop, setActiveShop] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setCounter(prevCount => prevCount + 1);
            checkLoyaltyShopOpened();
        }, 1000);
        return () => {
            clearInterval(timer);
        };
    }, [loyaltySettings]);

    const { t } = useTranslation('loyalty');

    const checkLoyaltyShopOpened = () => {

        var d = new Date();

        //minuti
        if (d.getMinutes() < 10) {
            var minutes = '0' + d.getMinutes();
        } else {
            var minutes = d.getMinutes();
        }

        var month = d.getMonth() + 1;
        //dani
        if (d.getMonth() + 1 < 10) {
            let sumMonth = d.getMonth() + 1;
            month = '0' + sumMonth;
        }

        // var fullCurrentDate = d.getFullYear() + '-' + month + '-' + d.getDate() + ' ' + d.getHours() + ":" + minutes + ":" + d.getSeconds();

        if (fullCurrentDate <= startDate && fullCurrentDate >= endDate) {
            setActiveShopFunction(0);
        } else if (fullCurrentDate >= startDate && fullCurrentDate <= endDate) {
            setActiveShopFunction(1);
        }
    }

    // Renderer callback with condition
    const renderer = ({ days, hours, minutes, seconds, completed }) => {

        return (
            <>
                <h2 className="title-counter-banner-main">{t('pocniteSaKupovinom')}</h2>
                <div className="timer-counter-banner-holder">
                    <div className="timer-holder-item">
                        <div className="timer-item-number">{zeroPad(days)}</div>
                        <div className="timer-item-text">{t('dana')}</div>
                    </div>
                    <div className="timer-holder-item timer-holder-item-dots">:</div>
                    <div className="timer-holder-item">
                        <div className="timer-item-number">{zeroPad(hours)}</div>
                        <div className="timer-item-text">{t('sati')}</div>
                    </div>
                    <div className="timer-holder-item timer-holder-item-dots">:</div>
                    <div className="timer-holder-item">
                        <div className="timer-item-number">{zeroPad(minutes)}</div>
                        <div className="timer-item-text">{t('minuta')}</div>
                    </div>
                    <div className="timer-holder-item timer-holder-item-dots">:</div>
                    <div className="timer-holder-item">
                        <div className="timer-item-number">{zeroPad(seconds)}</div>
                        <div className="timer-item-text">{t('sekundi')}</div>
                    </div>
                </div>
            </>
        )
    };
    // Renderer callback with condition
    const renderer2 = ({ days, hours, minutes, seconds, completed }) => {
        return (
            <>
                <h2 className="title-counter-banner-main">{t('zaKupovinuImateJos')}</h2>
                <div className="timer-counter-banner-holder">
                    <div className="timer-holder-item">
                        <div className="timer-item-number">{zeroPad(days)}</div>
                        <div className="timer-item-text">{t('dana')}</div>
                    </div>
                    <div className="timer-holder-item timer-holder-item-dots">:</div>
                    <div className="timer-holder-item">
                        <div className="timer-item-number">{zeroPad(hours)}</div>
                        <div className="timer-item-text">{t('sati')}</div>
                    </div>
                    <div className="timer-holder-item timer-holder-item-dots">:</div>
                    <div className="timer-holder-item">
                        <div className="timer-item-number">{zeroPad(minutes)}</div>
                        <div className="timer-item-text">{t('minuta')}</div>
                    </div>
                    <div className="timer-holder-item timer-holder-item-dots">:</div>
                    <div className="timer-holder-item">
                        <div className="timer-item-number">{zeroPad(seconds)}</div>
                        <div className="timer-item-text">{t('sekundi')}</div>
                    </div>
                </div>
            </>
        )
    };

    return (
        <div className="banner-loyalty-image-conter" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/images/loyalty/loyalty-timer-banner.jpg'})` }}>
            <div className="banner-counter-holder-loyaty">
                <div className="banner-counter-holder-main">
                    {activeShop == 0 && <Countdown date={startDate} renderer={renderer} />}
                    {activeShop == 1 && <Countdown date={endDate} renderer={renderer2} />}
                </div>
            </div>
            <div className="banner-logo-holder-loyaty">
                <div className="logo-image-loyalty-banner">
                    <img src={process.env.PUBLIC_URL + '/images/loyalty/logo-loyalty.png'} alt="" className="loyalty-image-img" />
                </div>
            </div>
        </div>
    )
};

export default LoyaltyCounterBlock;