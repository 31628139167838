import React, { useEffect, useState } from 'react';
import styles from "../css/Humanity.module.css";
import globalVariable from '../global';
import PageHelmet from '../components/helmet/PageHelmet';
import { useDispatch } from 'react-redux';
import { globalStatistic } from '../redux/actions/otherActions';
import { getPageSeo } from '../redux/actions/pageActions';
import { useTranslation, Trans } from 'react-i18next';

const scrollToTop = () => {
    window.scrollTo(0, 0)
}

const HumanitySolidarity = () => {

    const dispatch = useDispatch();
    const [pageSeoData, setPageSeoData] = useState({});
    const { t } = useTranslation('humanitySolidarity');

    useEffect(() => {
        dispatch(getPageSeo(767)).then(res => {
            if (res) {
                setPageSeoData(res);
            }
        });
        scrollToTop();
        dispatch(globalStatistic(767, 'PAGE'));
    }, [])

    return (
        <>
            {Object.keys(pageSeoData).length !== 0 &&
                <PageHelmet
                    title={pageSeoData ? `${pageSeoData.acSeoTitle}` : "Ewe Comp"}
                    description={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    keywords={pageSeoData ? `${pageSeoData.acSeoKeywords}` : ""}
                    ogDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    ogTitle={pageSeoData ? `${pageSeoData.acSeoTitle}` : ""}
                    ogUrl={pageSeoData ? `https://ewe.rs/${pageSeoData.acSeoUrl}` : ""}
                    ogImg="https://www.ewe.rs/media/ewe-og-img.jpg"
                    ogType="article"
                    twitterDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                />
            }
            <div className={`container-fluid ${styles['humanity-page']}`}>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="row">
                            <img alt={t('alt1')} src={`${globalVariable.domainName}/gui/humanity/header-slika.png`} className="img-fluid" style={{ width: "100%" }} />
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className={`text-center ${styles['container-text-block']}`} style={{ marginTop: "-64px" }}>
                                    <p className={styles['title-block-humanity2']} style={{ fontWeight: "normal" }}><Trans i18nKey="humanitySolidarity:naslov1" components={{ b: <b></b>, br: <br /> }} /></p>
                                    <p className={styles['body-block-humanity']} style={{ marginBottom: "35px" }}>{t('podnaslov1')}</p>
                                    <p className={styles['body-block-humanity']}>{t('paragraf1')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="row" style={{ backgroundColor: "#efefef" }}>
                            <div className="container" style={{ position: "relative" }}>
                                <div className='row'>
                                    <div className="col-md-6">
                                        <p className={`text-right ${styles['content-body-block-3']}`} style={{ marginRight: "0" }}><Trans i18nKey="humanitySolidarity:paragraf2" components={{ br: <br /> }} /></p>
                                    </div>
                                    <div className="col-md-6 text-left">
                                        <img alt={t('alt2')} src={`${globalVariable.domainName}/gui/humanity/solidarity1.png`} className={`img-fluid ${styles['img-margin']}`} style={{ marginTop: "-50px" }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row' style={{ backgroundColor: "#efefef" }}>
                            <div className="container" style={{ position: "relative" }}>
                                <div className='row'>
                                    <div className="col-md-6 text-left">
                                        <img alt={t('alt3')} src={`${globalVariable.domainName}/gui/humanity/solidarity2.png`} className="img-fluid" style={{ marginBottom: "-80px" }} />
                                    </div>
                                    <div className="col-md-6 text-right">
                                        <p className={styles['content3']} style={{ textAlign: "left", paddingBottom: "20px", marginRight: "50px" }}>{t('paragraf3')}</p>
                                        <p className={styles['content3']} style={{ textAlign: "left", paddingTop: "0", paddingBottom: "35px", marginRight: "50px" }}>{t('paragraf4')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="row">
                            <div className="container">
                                <div className="col-sm-12 text-center">
                                    <p className={styles['content-body-block-6']} style={{ marginTop: "30px" }}>{t('paragraf5')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12" style={{ backgroundColor: "#efefef", marginTop: "80px" }}>
                        <div className="row">
                            <div className="container">
                                <div className="col-sm-12">
                                    <img alt={t('alt4')} src={`${globalVariable.domainName}/gui/humanity/solidarity3.png`} className="img-fluid" style={{ marginTop: "-20px" }} />
                                </div>
                                <div className="col-sm-12">
                                    <p className={`text-justify ${styles['content-body-block-4']}`} style={{ paddingTop: "20px", marginBottom: "40px", paddingBottom: "0" }}>{t('paragraf6')}</p>
                                    <p className={`text-center ${styles['content-body-block-4']}`} style={{ paddingTop: "0", paddingBottom: "40px" }}>{t('paragraf7')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </>
    )
}

export default HumanitySolidarity
