import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
// import { useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';

import {
    removeCartFunction,
    removeCartItemFunction
} from '../../redux/actions/cartActions';
// import Moment from 'react-moment';
import { useTranslation } from 'react-i18next';


// import CurrencyFormat from '../other/CurrencyFormat';
const ModalConfirmation = ({ show, handleClose, item, sendData, title, body, values, action }) => {

    const dispatch = useDispatch();
    const { t } = useTranslation('modal');

    const functionActionConfirm = () => {
        if (action == 'removeCartFunction') {
            var id = values[0];
            dispatch(removeCartFunction(id));
            handleClose();
        } else if (action == 'removeCartItemFunction') {
            var anCartItemKey = values[1];
            var getActiveCartFunction = values;
            dispatch(removeCartItemFunction(anCartItemKey, getActiveCartFunction));
            handleClose();
        }
    }

    const escFunction = (event) => {
        if (event.keyCode === 27) {
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);

        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, []);

    return (
        <Modal
            show={show}
            onHide={handleClose}
            keyboard={false}
            dialogClassName='modal-l'
            aria-labelledby=""
            centered
        >
            <div className="close-action-modal" variant="secondary" onClick={handleClose}>
                <i className="far fa-times-circle"></i>
            </div>
            <Modal.Header>
                <Modal.Title>
                    <div dangerouslySetInnerHTML={{ __html: title }} />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div dangerouslySetInnerHTML={{ __html: body }} />
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-success" onClick={(e) => functionActionConfirm()}>{t('potvrdi')}</button>
                <button className="btn btn-secondary" onClick={handleClose}>{t('zatvori')}</button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalConfirmation;