import React from 'react';
import CartItemModal from './CartItemModal';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CurrencyFormat from '../other/CurrencyFormat';
import CurrencyMenuItem from '../other/CurrencyMenuItem';
import VatMenuItem from '../other/VatMenuItem';
import { globalStatistic } from '../../redux/actions/otherActions';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SidebarCartModal = ({ shoppingCartView, cartModalFunction, sidebar }) => {

    const dispatch = useDispatch();
    const cartItems = useSelector((state) => state.getCartItemsList.items);
    const getActiveCartFunction = useSelector((state) => state.getActiveCartFunction[0]);
    const activeCurrency = useSelector((state) => state.activeCurrency.item);
    const productItem = useSelector((state) => state.productDetail.items[0]);
    const getCurrencyItems = useSelector((state) => state.getCurrencyItems);
    const activeVat = useSelector((state) => state.activeVat.item);
    const iconPath = process.env.PUBLIC_URL + '/media/';
    const { t } = useTranslation(['menuModal', 'translation', 'routes']);

    const setModalCartFunction = (idModal) => {
        cartModalFunction(idModal);
    };
    const gatherStatistic3DProduct = () => {
        dispatch(globalStatistic(543, 'PAGE'));
    }
    // console.log(getActiveCartFunction)
    return (
        <div
            className={`modal-root modal-cart-root ${shoppingCartView === 1 ? 'menu-modal-show' : 'menu-modal-hide'} ${sidebar && 'collapsed-sidebar-modal'}`}
            onMouseEnter={(e) => setModalCartFunction(1)}
        >
            <i
                className="close-menu-modal-popup fas fa-times"
                onClick={(e) => setModalCartFunction(0)}
            ></i>
            <div className="menu-modal-item-header d-flex">
                <div>
                    <img src="/images/korpa.png" alt="ikonica korpe" />
                </div>
                <div className="cart-name-modal-menu">{getActiveCartFunction.acCartName == 'Default' ? t('translation:yourCard') : getActiveCartFunction.acCartName}</div>
                <Link to="/uredjivanje-korpi" onClick={(e) => { { setModalCartFunction(0) } gatherStatistic3DProduct() }}>
                    {/* <span className="settings-cart-button">Uredite vaše korpe <i className="fas fa-sliders-h"></i></span> */}
                    <button className="btn btn-outline-light menu-modal-item-header-button">{t('translation:editYourCard')}&nbsp;
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 53.84 43.78"><defs></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path className="cls-1" d="M9.31,3.52A5.79,5.79,0,0,1,15.1,9.31h3.52A9.31,9.31,0,0,0,9.31,0ZM3.52,9.31A5.79,5.79,0,0,1,9.31,3.52V0A9.31,9.31,0,0,0,0,9.31ZM9.31,15.1A5.79,5.79,0,0,1,3.52,9.31H0a9.31,9.31,0,0,0,9.31,9.31ZM15.1,9.31A5.79,5.79,0,0,1,9.31,15.1v3.52a9.31,9.31,0,0,0,9.31-9.31Z" /><path className="cls-1" d="M44.54,28.68a5.78,5.78,0,0,1,5.78,5.79h3.52a9.3,9.3,0,0,0-9.3-9.31Zm-5.79,5.79a5.79,5.79,0,0,1,5.79-5.79V25.16a9.31,9.31,0,0,0-9.31,9.31Zm5.79,5.79a5.79,5.79,0,0,1-5.79-5.79H35.23a9.31,9.31,0,0,0,9.31,9.31Zm5.78-5.79a5.78,5.78,0,0,1-5.78,5.79v3.52a9.3,9.3,0,0,0,9.3-9.31Z" /><path className="cls-1" d="M52.08,7.55H26.92v3.52H52.08Zm-25.16,0a1.76,1.76,0,0,0,0,3.52Zm25.16,3.52a1.76,1.76,0,1,0,0-3.52Z" /><path className="cls-1" d="M26.92,32.71H1.76v3.52H26.92Zm-25.16,0a1.76,1.76,0,1,0,0,3.52Zm25.16,3.52a1.76,1.76,0,0,0,0-3.52Z" /></g></g></svg>
                    </button>
                </Link>
                <div className="dashboard-value-item">
                    <div className="cart-modal-item-radio-name">
                        {t('valuta')}:
                    </div>
                    <div className="dashboard-item-radio-buttons">
                        {getCurrencyItems.map((item, key) => (
                            <CurrencyMenuItem key={key} item={item} isPriceInEur={activeCurrency._id} productItem={productItem} inputName={'2'} />
                        ))}
                    </div>
                </div>
                <div className="dashboard-value-price">
                    <div className="cart-modal-item-radio-name">
                        {t('cene')} + {t('pdv')}:
                    </div>
                    <div className="dashboard-item-radio-buttons">
                        <VatMenuItem productItem={productItem} activeVat={activeVat} inputName={'2'} />
                    </div>
                </div>
            </div>
            <div className="shopping-cart-items-list">
                {cartItems.length === 0 ?
                    // <div className="empty-cart-modal">Vaša korpa je trenutno prazna</div> 
                    <div className="empty-cart text-center">
                        <img src="/images/korpa.png" alt="ikonica korpe" />
                        <h5>{t('korpaJePrazna')}</h5>
                        <small>{t('nemaArtUKorpi')}</small>
                    </div>
                    :
                    <ul>
                        {cartItems.map((item, key) => (
                            <CartItemModal key={key} item={item} />
                        ))}
                    </ul>
                }
            </div>
            {cartItems.length > 0 &&
                <div className="shopping-cart-modal-total-sum">
                    <div className="total-sum-cart-items-modal">
                        <div className="price-cart-modal">{t('iznos')}: <span><CurrencyFormat num={getActiveCartFunction.anValueNoPdv} /> {activeCurrency.acShortName}</span></div>
                        <div className="total-price-cart-modal">{t('ukupnoZaUplatu')}: <span><CurrencyFormat num={getActiveCartFunction.anValueWithPdv} /> {activeCurrency.acShortName}</span></div>
                    </div>
                    <div className="button-finish-cart-modal">
                        <Link to={`/${t('routes:korpa')}/${getActiveCartFunction._id}`} onClick={(e) => setModalCartFunction(0)}>
                            <span className="btn-cart-modal-finish small-cart-finish-icon">
                                <img src={`${iconPath}icon-vasa-korpa.svg`} alt="vasa-korpa" /><span>{t('zavrsiKupovinu')}</span>
                            </span>
                        </Link>
                    </div>
                </div>
            }
        </div>
    )
};


export default SidebarCartModal;