import React from 'react';
import { useLocation } from 'react-router-dom';
import FilterCheckboxBrandInput from '../filters/FilterCheckboxBrandInput';
import FilterCheckboxBrandInputChecked from '../filters/FilterCheckboxBrandInputChecked';
import { globalStatistic } from '../../redux/actions/otherActions';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

const CatalogSpecialActionFilterItem = ({ checkFilterFunction, item, index }) => {

  const dispatch = useDispatch();
  const params = useParams();
  const { categoryId } = params;

  const location = useLocation();
  var checkedBox = '';

  const onClickCheckboxFilterFunction = (e, item2, item, type) => {
    checkFilterFunction(e, item2, item, 'akcija');
    dispatch(globalStatistic(categoryId + item2.anId, 'FILTER_CATEGORY'));
  };

  var selectedFilters = [];
  var checkboxItems = location.search.split('?');
  if(checkboxItems.slice(0, -1).length > 0) {
      checkboxItems.forEach(item => {
          if(item != '') {
              var checboxItem = item.split('&');
              checboxItem.forEach(item => {
                  selectedFilters.push(item.split('=')[1].split('_')[1]);
              });
          }
      });
  }
  if(item.anId) {
    checkedBox = selectedFilters.includes(item.anId.toString());
  }
   
  return (
    <div key={index} className={`filter-item-detail filter-show-item`}>
      <div className="form-group form-check">
          { checkedBox ? (
            <FilterCheckboxBrandInputChecked item={item} onClickCheckboxFilterFunction={onClickCheckboxFilterFunction} />
          ) : (
            <FilterCheckboxBrandInput item={item} onClickCheckboxFilterFunction={onClickCheckboxFilterFunction} />
          )}
          <label className="form-check-label" htmlFor={`checbox${item.acString}`}>{item.acString} <span className="total-count-filter">({item.anNumber})</span></label>
      </div>
  </div>
  );
};

export default CatalogSpecialActionFilterItem;