import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Link } from "react-router-dom";
import { fetchWeeklyPosts, fetchInStockAgainProducts, fetchRecommendedForYouProducts, getActionProductsList, getLastProductsList } from '../../redux/actions/productActions';

import BlockActionSliderContent from "./BlockActionSliderContent";
import globalVariable from '../../global';
import { useTranslation } from 'react-i18next';

const BlockActionSliderImage = (props) => {

    const params = useParams();
    const dispatch = useDispatch();
    const { productId } = params;

    const getRelatedSale = useSelector((state) => state.getProductRelatedSale);
    const getRelatedProducts = useSelector((state) => state.getRelatedCategoryProducts);
    const weeklyProducts = useSelector((state) => state.weeklyProducts.items);
    const inStockAgainProducts = useSelector((state) => state.inStockAgainProducts.items);
    const recommendedProducts = useSelector((state) => state.recommendedProducts.items);
    const getLastProducts = useSelector((state) => state.getLastProducts);
    const { t } = useTranslation('block');

    var items = [];
    var link = "";

    const [isBannerImage, setIsBannerImage] = useState(0);
    const [slidesToShow, setSlidesToShow] = useState(4);
    const [slidesToScroll, setSlidesToScroll] = useState(4);
    const getUserBanner = useSelector((state) => state.getUserBanner.items);

    const bennerListItems = getUserBanner.filter((x) => x.anPageKey === 251);
    const bennerList = bennerListItems.filter((x) => x.anBannerPosition === props.position);

    // Uvedena provera jer se struktura podatka za meni razlikuje u zavisnosti od jezika - Ivan 10.10.2024.
    const currentLanguage = useMemo(() => document.documentElement.lang ? document.documentElement.lang : 'en', []);
    const getMenuItems = useSelector((state) =>
        currentLanguage === 'mk' ? state.menuItems[0] : state.menuItems[1]
    );
    const subMenu = getMenuItems ? getMenuItems.menu[1].subMenu.filter(x => x.acMenu === props.title) : [];

    useEffect(() => {
        if (bennerList[0]) {
            setIsBannerImage(1);
            setSlidesToShow(5);
            setSlidesToScroll(5);
        } else {
            setIsBannerImage(0);
            setSlidesToShow(7);
            setSlidesToScroll(7);
        }
        if (props.type == 'weekAction') {
            dispatch(getActionProductsList(4, 'weekAction'));
        } else if (props.type == 'inStockAgain') {
            dispatch(getActionProductsList(2, 'inStockAgain'));
        } else if (props.type == 'recommendedForYou') {
            dispatch(getActionProductsList(5, 'recommendedForYou'));
        } else if (props.type == 'viewedProducts') {
            dispatch(getLastProductsList());
        }
    }, []);

    if (props.type == "relatedSale") {
        items = getRelatedSale;

    } else if (props.type == "relatedProducts") {
        const productsArray = getRelatedProducts.slice(0, 10);
        items = productsArray.filter((el) => el.anProductKey !== productId);
        // link = getMenuItems ? getMenuItems.menu[1].subMenu[11].acLink : "";

    } else if (props.type == 'weekAction') {
        items = weeklyProducts;
    } else if (props.type == 'inStockAgain') {
        items = inStockAgainProducts;
    } else if (props.type == 'recommendedForYou') {
        items = recommendedProducts;
    } else if (props.type == 'viewedProducts') {
        items = getLastProducts.items;
        // link = getMenuItems ? getMenuItems.menu[1].subMenu[11].acLink : "";

    }
    // console.log(subMenu, 'subMenu');

    var imageClassName = '';
    var blockItemsClassName = '';
    if (props.isHomepage == 'true') {
        imageClassName = 'homepage-content-special-image-block';
        blockItemsClassName = 'homepage-special-block-items';

    } else {
        imageClassName = 'content-special-image-block';
        blockItemsClassName = 'special-block-items';
    }

    return (
        <>
            {bennerList[0] ? (
                props.type == 'recommendedForYou'
                    ? <a href={bennerList[0].acBannerLink && bennerList[0].acBannerLink} target='_blank' rel='noopener noreferrer'>
                        <div className={imageClassName}>
                            <div className="action-block-special-banner-background-wrapper" style={{ overflow: 'hidden' }}>
                                <div className="action-block-special-banner-bacground" title={bennerList[0].acSeoTitle} style={{ background: `url(${globalVariable.imagesPath}/media/${bennerList[0].acBannerImage})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}></div>
                                <img src={`${globalVariable.imagesPath}/media/${bennerList[0].acBannerImage}`} className="img-fluid action-block-special-banner-image" alt={`${bennerList[0].acSeoDescription}`} title={`${bennerList[0].acSeoTitle}`} />
                            </div>
                            <div className="special-block-image-mask">
                                <div className="special-block-image-mask-content">
                                    <div className="special-block-image-mask-name">{bennerList[0].acBannerTitle}</div>
                                    <div className="special-block-image-mask-btn">
                                        <div className="special-block-image-mask-btn-item">
                                            {
                                                props.type == 'recommendedForYou'
                                                    ? <a href={bennerList[0].acBannerLink && bennerList[0].acBannerLink} target='_blank' rel='noopener noreferrer'>{t('prijaviSe')}</a>
                                                    : <Link to={bennerList[0].acBannerLink && bennerList[0].acBannerLink}>{t('detaljnije')}</Link>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </a>
                    : <Link to={bennerList[0].acBannerLink && bennerList[0].acBannerLink}>
                        <div className={imageClassName}>
                            <div className="action-block-special-banner-background-wrapper" style={{ overflow: 'hidden' }}>
                                <div className="action-block-special-banner-bacground" title={bennerList[0].acSeoTitle} style={{ background: `url(${globalVariable.imagesPath}/media/${bennerList[0].acBannerImage})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}></div>
                                <img src={`${globalVariable.imagesPath}/media/${bennerList[0].acBannerImage}`} className="img-fluid action-block-special-banner-image" alt={`${bennerList[0].acSeoDescription}`} title={`${bennerList[0].acSeoTitle}`} />
                            </div>
                            <div className="special-block-image-mask">
                                <div className="special-block-image-mask-content">
                                    <div className="special-block-image-mask-name">{bennerList[0].acBannerTitle}</div>
                                    <div className="special-block-image-mask-btn">
                                        <div className="special-block-image-mask-btn-item">
                                            {
                                                props.type == 'recommendedForYou'
                                                    ? <a href='http://eepurl.com/ibMt3r' target='_blank' rel='noopener noreferrer'>{t('prijaviSe')}</a>
                                                    : <Link to={bennerList[0].acBannerLink && bennerList[0].acBannerLink}>{t('detaljnije')}</Link>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>


            ) : (
                props.isHomepage == 'true' ?
                    <div className={imageClassName}>
                        <div className="action-block-special-banner-background-wrapper" style={{ overflow: 'hidden' }}>
                            <div className="action-block-special-banner-bacground" title="Specijalna akcija" style={{ background: "url(./no-image-actions-banners.png)", backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}></div>
                            <img src="/no-image-actions-banners.png" alt="Placeholder" className="img-fluid action-block-special-banner-image" />
                        </div>
                    </div>
                    :
                    ""
            )}

            <div className={blockItemsClassName}>
                <div className="special-block-title-main">
                    <Link to={subMenu.length > 0 ? subMenu[0].acLink : ""} className="link">
                        <h2>{props.title}</h2>
                    </Link>

                </div>
                <div className="content-slider-special-action">
                    {items.length > 0
                        ? <BlockActionSliderContent isHomepage={props.isHomepage} items={items} isBannerImage={isBannerImage} slidesToShow={slidesToShow} slidesToScroll={slidesToScroll} type={props.type} productId={productId} />
                        : <div className="alert alert-danger w-100" role="alert">{t('nemaAktivnihProizvoda')}.</div>
                    }
                </div>
            </div>
        </>
    );
};

export default BlockActionSliderImage;
