import React from 'react';
import { Page, Text, View, Image, StyleSheet, Link } from "@react-pdf/renderer";
import CurrencyFormat from '../../other/CurrencyFormat';
import globalVariable from '../../../global';
import { useTranslation } from 'react-i18next';


const CatalogPdfItem = (props) => {

    const { item, currency, getUserInfo } = props;
    const { t } = useTranslation('block');

    const styles = StyleSheet.create({
        tableRow: { flexDirection: "row" },
        tableCol1: {
            width: "18%",
            backgroundColor: "#fafafa",
            borderBottom: 1,
            borderBottomStyle: "dotted",
            paddingVertical: 15,
            paddingHorizontal: 10,
            borderColor: "#e6e6e6",
            justifyContent: "center",
            textAlign: "left",
        },
        tableCol2: {
            width: "70%",
            paddingHorizontal: 5,
            borderBottom: 1,
            borderBottomStyle: "dotted",
            paddingVertical: 15,
            paddingHorizontal: 10,
            borderColor: "#e6e6e6",

            alignItems: "left",
        },
        tableCol3: {
            width: "20%",
            paddingHorizontal: 5,
            borderBottom: 1,
            borderBottomStyle: "dotted",
            paddingVertical: 15,
            paddingHorizontal: 10,
            borderColor: "#e6e6e6",
            textAlign: "left",
            justifyContent: "bottom",
            alignItems: "bottom",
        },
    });

    const renderOldPrice = () => {

        if (getUserInfo && item.anOldPrice > 0) {
            return (
                <Text style={{ paddingVertical: 3, textDecoration: "line-through" }}>
                    <CurrencyFormat num={item.anOldPrice} /> {currency}
                </Text>
            )
        }
    };

    return (
        <View style={styles.tableRow} wrap={false}>
            <View style={styles.tableCol1}>
                {/* <Image style={{width: "80%"}} src={"https://resource.ewe.rs/products/" + item.acProduct.trim() + "_v.jpg"} /> */}
                {/* <Link src={"/proizvod/" + item.anProductkey + "/" + item.acName.replace(/\s|[,\/]/g, "_")}> */}
                <Image style={{ width: "100%" }} src={`${globalVariable.imagesPath}products/${item.acProduct.trim()}_v.jpg`} />
                {/* </Link> */}
            </View>
            <View style={styles.tableCol2}>
                <Text style={{ paddingVertical: 3 }}>{item.acProduct}</Text>
                <Text>
                    {item.acDept.replace(" 1****", "")} {item.acName}
                </Text>
            </View>
            {getUserInfo &&
                <View style={styles.tableCol3}>
                    <Text style={{ paddingVertical: 3 }}>{t('lager')}: {item.anStock}</Text>
                    <Text>{t('rezervisano')}: {item.anReserved}</Text>
                </View>
            }
            <View style={styles.tableCol3}>
                {renderOldPrice()}
                <Text style={!getUserInfo && { marginTop: "20px" }}>
                    <CurrencyFormat num={getUserInfo ? item.anPrice : item.anRetailPrice} /> {getUserInfo ? currency : 'RSD'}
                </Text>
            </View>
        </View>
    );
}

export default CatalogPdfItem;
