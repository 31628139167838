import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { addProductCompare, removeProductCompare } from '../../redux/actions/productActions';

import MessageInfoBox from './MessageInfoBox';

const CompareButton = ({ product, title }) => {

    const dispatch = useDispatch();
    const [checkBox, setCheckBox] = useState(0);
    const compareItems = useSelector((state) => state.compareItems.compareItems);
    
    const addCompareItemFunction = (e) => {
        var isChecked = e.target.checked;  

        if (isChecked === true) {
            setCheckBox(1);
            if (compareItems.length > 3) {
                setCheckBox(0);
                
                toast( <MessageInfoBox message="compareErrorMaxNumber" type="0" />, {
                    className: 'red-background',
                });
            } else {
                dispatch(addProductCompare(product));
            }
        } else {
            setCheckBox(0);
            dispatch(removeProductCompare(product));
        }
    }

    useEffect(() => {
        setCheckBox(0);
        compareItems.map((d) => {
            if (d.acProduct.trim() === product.acProduct.trim()) {
                setCheckBox(1);
            }
        });
    });

    const setCheckBoxFunction = () => {}

    return (
        <div className="pretty pretty-custom-global p-default">
            <input
                type="checkbox"
                onClick={(e) => addCompareItemFunction(e)}
                checked={checkBox == 1 ? "checked" : ""}
                onChange={() => setCheckBoxFunction()}
            />
            <div className="state p-primary">
                <label>{title}</label>
            </div>
        </div>
    );
};

export default CompareButton;