import React, { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from "react-router-dom";
import { getMenuItems, functionChangeCollapsedMenu } from '../../redux/actions/menuActions';
import { ToastContainer } from 'react-toastify';

//kopmonente
import SidebarMenuItem from './SidebarMenuItem';
import SidebarModalPopup from '../../components/menuModal/SidebarModalPopup';
import CartModal from '../../components/menuModal/CartModal';
import CartMenuItem from '../../components/sidebarMenu/CartMenuItem';
import MenuLoginButton from '../../components/menu/MenuLoginButton';
import MenuLoggedUserInfo from '../../components/menu/MenuLoggedUserInfo';
import MenuLogo from '../../components/menu/MenuLogo';
import Header from '../Header';
import { useDispatch, useSelector } from 'react-redux';

import { globalStatistic } from '../../redux/actions/otherActions';
import { useTranslation } from 'react-i18next';



const Sidebar = (props) => {

    const dispatch = useDispatch();
    const menuItems = useSelector((state) => state.menuItems);
    const userInfo = useSelector((state) => state.userInfo);
    const getMenuSettingsRedux = useSelector((state) => state.menuSettings);
    var getMenuSettings = JSON.parse(localStorage.getItem('menuSettings'));
    if (!getMenuSettings) {
        getMenuSettings = getMenuSettingsRedux;
    }
    // console.log(getMenuSettings, 'getMenuSettings-2')
    const isDesktop = window.innerWidth > 1020;
    const { t } = useTranslation(['sidebarCategory', 'routes']);

    const [shoppingCartView, setShoppingCartView] = useState(0);
    const [modalMenu, setModalMenu] = useState(0);
    const [hoverCompanyInfo, setHoverCompanyInfo] = useState(false);
    const [sidebar, setSidebar] = useState(false);
    const [modalValue, setModalValue] = useState({});
    const { categoryId, productId, pageId } = useParams();

    useEffect(() => {
        dispatch(getMenuItems());
    }, []);

    // console.log(getMenuSettings, 'getMenuSettings')

    useEffect(() => {
        if (getMenuSettings) {
            if (window.location.pathname == '/') {
                setSidebar(getMenuSettings.homePage)
            } else {
                setSidebar(getMenuSettings.otherPage)
            }
        }

    }, [window.location.pathname])

    useEffect(() => {
        if (getMenuSettings) {
            if (window.location.pathname == '/') {
                setSidebar(getMenuSettings.homePage)
            } else {
                setSidebar(getMenuSettings.otherPage)
            }
        }
    }, []);

    const setCloseModalFunction = () => {
        setModalMenu(0);
        setShoppingCartView(0);
    }
    const setCloseModalFunctionMenu = () => {
        setModalMenu(0);
    }

    //funkcija koja radi nakon izlaska misem preko menija kako bi prikazao popup menu stavke
    const callbackFunction = (showModalId, itemValue) => {
        setModalMenu(showModalId);
        setModalValue(itemValue);
        setShoppingCartView(0);
    }

    //funkcija koja prikazuje ili zatvara modal osim cart modala prelaskom misem van modala ili setanjem kroz sam modal
    const callbackFunctionModal = (idModal) => {
        setModalMenu(idModal);
        setShoppingCartView(0);
    }

    //funkcija koja prikazuje ili zatvara cart modal prelaskom misem van modala ili setanjem kroz sam modal
    const callbackCartFunctionModal = (idModal) => {
        setShoppingCartView(idModal);
    }

    //funkcija koja pikazuje cart modal
    const callbackCartItemFuncion = (shoppingCartViewVal, modalMenuVal) => {
        setModalMenu(modalMenuVal);
        setShoppingCartView(shoppingCartViewVal);
    }

    const renderList = () => {
        return menuItems.map((item, key) => (
            <div key={key}>
                {item.position === 1 && (
                    <>
                        {item.menu.map((value, index) => (
                            <div key={index}>
                                <SidebarMenuItem functionShowModalMenu={callbackFunction} sidebar={sidebar} item={value} />
                            </div>
                        ))}
                    </>
                )}
            </div>
        ));
    };

    const functionCollapsedMenu = (val) => {
        let urlPath = window.location.pathname;
        if (getMenuSettings) {
            if (urlPath == '/') {
                dispatch(functionChangeCollapsedMenu(val, 'homePage', getMenuSettings.otherPage));
                dispatch(globalStatistic(251, 'MENU_PAGE'));
            } else {
                dispatch(functionChangeCollapsedMenu(val, 'otherPage', getMenuSettings.homePage));

                if (urlPath.includes(t('routes:katalog'))) {
                    dispatch(globalStatistic(categoryId, 'MENU_CATEGORY'));
                } else if (urlPath.includes('/' + t('routes:proizvod'))) {
                    dispatch(globalStatistic(productId, 'MENU_PRODUCT'));
                } else if (urlPath.includes('/' + t('routes:specijalnaAkcija'))) {
                    dispatch(globalStatistic(pageId, 'MENU_PAGE'));
                } else if (urlPath.includes('/loyalty-pocetna')) {
                    dispatch(globalStatistic('363', 'MENU_PAGE'));
                } else if (urlPath.includes('/aukcija')) {
                    dispatch(globalStatistic('540', 'MENU_PAGE'));
                } else if (urlPath.includes('/pregledani-proizvodi')) {
                    dispatch(globalStatistic('541', 'MENU_PAGE'));
                } else if (urlPath.includes('/outlet')) {
                    dispatch(globalStatistic('355', 'MENU_PAGE'));
                } else if (urlPath.includes('/lista-zelja')) {
                    dispatch(globalStatistic('542', 'MENU_PAGE'));
                } else if (urlPath.includes('/rezervacije')) {
                    dispatch(globalStatistic('544', 'MENU_PAGE'));
                } else if (urlPath.includes('/raspored-dostave')) {
                    dispatch(globalStatistic('351', 'MENU_PAGE'));
                } else if (urlPath.includes('/isporuka-krajnjem-kupcu')) {
                    dispatch(globalStatistic('350', 'MENU_PAGE'));
                } else if (urlPath.includes('/preuzimanje-robe')) {
                    dispatch(globalStatistic('352', 'MENU_PAGE'));
                } else if (urlPath.includes('/valutacija')) {
                    dispatch(globalStatistic('545', 'MENU_PAGE'));
                } else if (urlPath.includes('/status-porudzbine')) {
                    dispatch(globalStatistic('302', 'MENU_PAGE'));
                } else if (urlPath.includes('/humanost-ewe')) {
                    dispatch(globalStatistic('546', 'MENU_PAGE'));
                } else if (urlPath.includes('/profil')) {
                    dispatch(globalStatistic('547', 'MENU_PAGE'));
                } else if (urlPath.includes('/briga-o-klijentima')) {
                    dispatch(globalStatistic('55', 'MENU_PAGE'));
                } else if (urlPath.includes('/kontakt')) {
                    dispatch(globalStatistic('378', 'MENU_PAGE'));
                } else if (urlPath.includes('/pregled-newslettera')) {
                    dispatch(globalStatistic('394', 'MENU_PAGE'));
                }
            }
            setSidebar(val);

        }
    }

    const setOnHoverEffectCompanyInfo = () => {
        setHoverCompanyInfo(!hoverCompanyInfo);
    }

    // const userEmails2 = userInfo.userInfo && userInfo.userInfo.company && userInfo.userInfo.company.department[0].user[0].acEmail ? userInfo.userInfo.company.acCompanyMail1.split(';') : '';
    var userEmail = '';
    if (userInfo.userInfo != null) {
        userEmail = userInfo.userInfo.company != null && userInfo.userInfo.company.department[0].user[0].acEmail != '' ? userInfo.userInfo.company.department[0].user[0].acEmail : '';
    }

    const isLaptop = window.innerWidth < 1367;

    let ref = useRef(null);

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            props.onClickOutside && props.onClickOutside();
            setCloseModalFunction()
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    });

    return (
        <>
            <div
                className={`sidebar-content d-none d-lg-block ${sidebar ? 'active' : ''}`}
                // onMouseLeave={() => setCloseModalFunction()}
                ref={ref}
            >
                <SidebarModalPopup
                    modalFunctionPopup={callbackFunctionModal}
                    modalMenuSettings={modalMenu}
                    modalValue={modalValue}
                    sidebar={sidebar}
                />
                {hoverCompanyInfo && (
                    sidebar && (
                        <div className="user-info-hover">
                            <div className="user-info-hover-label">{t('sidebarCategory:firma')}</div>
                            <div className="user-info-hover-text">{userInfo.userInfo.company.acCompany}</div>
                            <div className="info-user-detail-label user-info-hover-label">{t('sidebarCategory:mail')}</div>
                            <div className="user-info-hover-text">{userEmail}</div>
                            <div className="info-user-detail-label user-info-hover-label">{t('sidebarCategory:komercijalista')}</div>
                            <div className="user-info-hover-text">{userInfo.userInfo.company.clerkResponsible['0'].acFirstName} {userInfo.userInfo.company.clerkResponsible['0'].acLastName}</div>

                        </div>
                    )
                )}

                <CartModal sidebar={sidebar} shoppingCartView={shoppingCartView} cartModalFunction={callbackCartFunctionModal} />

                <div className="sidebar-fixed-menu">
                    <nav id="sidebar" className={`d-none d-lg-block ${sidebar ? 'active' : ''}`}>

                        <MenuLogo sidebar={sidebar} />
                        <div
                            className="sidebar-items-list"
                        // onMouseLeave={() => setCloseModalFunctionMenu()}
                        >
                            <div className="sidebar-items-first-line">
                                {userInfo.userInfo && <CartMenuItem sidebar={sidebar} shoppingCartViewFunction={callbackCartItemFuncion} />}
                                {renderList()}
                            </div>
                        </div>
                        <button
                            className={`btn ${isLaptop ? "btn-sm" : ""} btn-info collapse-button-sidebar`}
                            onClick={(e) => functionCollapsedMenu(!sidebar)}
                        >
                            {!sidebar ? <i className="fas fa-chevron-left"></i> : <i className="fas fa-chevron-right"></i>}
                        </button>
                        <MenuLoggedUserInfo effectCompanyInfoFun={setOnHoverEffectCompanyInfo} userInfo={userInfo} sidebar={sidebar} />
                        <MenuLoginButton userInfo={userInfo} sidebar={sidebar} />
                    </nav>
                </div>
            </div>
            <ToastContainer autoClose={2500} />
        </>
    );
};

export default Sidebar;