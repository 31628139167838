import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';

import { getNewsletterMaterial, globalStatistic } from '../redux/actions/otherActions';

import PageBanner from '../components/banner/PageBanner';
import NewsletterItem from '../components/other/NewsletterItem';
import PageHelmet from '../components/helmet/PageHelmet';
import { getPageSeo } from '../redux/actions/pageActions';
import { useTranslation } from 'react-i18next';

const NewsletterPage = () => {

    const dispatch = useDispatch();
    const getNewsletterMaterialItems = useSelector((state) => state.getNewsletterMaterialItems.items);
    const queries = useMediaQuery('(min-width: 2000px)');
    const queries1 = useMediaQuery('(max-width: 1024px)');
    const [pageSeoData, setPageSeoData] = useState({});
    const { t } = useTranslation('newsletterPage');

    useEffect(() => {
        dispatch(getPageSeo(394)).then(res => {
            if (res) {
                setPageSeoData(res);
            }
        });
        dispatch(getNewsletterMaterial())
        dispatch(globalStatistic(394, 'PAGE'));
    }, [])

    return (
        <>
            {Object.keys(pageSeoData).length !== 0 &&
                <PageHelmet
                    title={pageSeoData ? `${pageSeoData.acSeoTitle}` : "Ewe Comp"}
                    description={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    keywords={pageSeoData ? `${pageSeoData.acSeoKeywords}` : ""}
                    ogDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    ogTitle={pageSeoData ? `${pageSeoData.acSeoTitle}` : ""}
                    ogUrl={pageSeoData ? `https://ewe.rs/${pageSeoData.acSeoUrl}` : ""}
                    ogImg="https://www.ewe.rs/media/ewe-og-img.jpg"
                    twitterDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                />
            }
            <div className="container-content container-main-content newsletter-content top-margin-navbar-escape">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="banner-image-on-top-page">
                                <PageBanner categoryType="" categoryId="" pageId="394" />
                            </div>
                            <div className="stats-order-content-holder">
                                <h1>{pageSeoData.acSeoHeader ? pageSeoData.acSeoHeader : 'Unapredite svoje poslovanje, budite bliži kupcima'}</h1>
                                {pageSeoData.acSeoShortPageDescription && <p>{pageSeoData.acSeoShortPageDescription}</p>}
                            </div>
                            <div className="contact-desc">{t('nudimoVam')}</div>
                        </div>
                        <div className="col-sm-12">
                            <div className="row">
                                {getNewsletterMaterialItems.map((item, key) => (
                                    <div className={queries ? "col-sm-2" : queries1 ? "col-sm-4" : "col-sm-3"}>
                                        <NewsletterItem item={item} key={key} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </>
    )
}

export default NewsletterPage