import React from 'react';

const LoaderMask = () => {
    return (
        <div className="loader-mask">
            <div className="loader-mask-item">
                {/* <img src="/images/loader.gif" alt="" className="img-fluid" /> */}
            </div>
        </div>
    );
};

export default LoaderMask;