import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Page, Text, View, Document, Image, Font, StyleSheet } from "@react-pdf/renderer";

import CurrencyFormat from "../../other/CurrencyFormat";
import CompareProductsItemsSpec from "./CompareProductsItemsSpec";
import CompareProductsPdfItem from "./CompareProductsPdfItem";
import globalVariable from "../../../global";
import { useTranslation } from 'react-i18next';
import SecureLS from 'secure-ls';

const CompareProductsPdfItems = ({ items, currency, filters, acLogOutUserCurrency }) => {

    const [lastPage, setLastPage] = useState(1)
    const { t } = useTranslation('compareProductsPdfItems');
    let ls = new SecureLS({ encodingType: 'aes' });
    const userInfo = ls.get('userInfo');
    Font.register({
        family: "Roboto",
        src: "/css/fonts/roboto/RobotoCondensed-Light.ttf",
    });
    Font.register({
        family: "Roboto-Bold",
        src: "/css/fonts/roboto/RobotoCondensed-Bold.ttf",
    });

    const styles = StyleSheet.create({
        body: {
            paddingTop: 10,
            paddingBottom: 30,
            paddingHorizontal: 10,
            width: 100,
            fontFamily: "Roboto",
            fontSize: 11,
            fontWeight: 200,
        },
        header: {
            paddingHorizontal: "0.5cm",
            marginBottom: 30,
        },
        table: { display: "table", width: "auto" },
        tableRow: { flexDirection: "row" },
        tableCol: { width: "20%" },
        tableCell: { marginTop: 5, fontSize: 10 }
    });

    return (
        <Document debug="true">
            <Page size="A4" style={styles.body} wrap>
                <Image src={t('src')} style={{ marginBottom: 15 }} />
                <View style={styles.table}>
                    <View style={[styles.tableRow, { marginVertical: 10 }]}>
                        <View style={styles.tableCol}>

                        </View>
                        {items.map((item, key) => (
                            <View key={key} style={[styles.tableCol, { padding: 5, display: "flex", flexDirection: "column", justifyContent: "space-between" }]}>
                                <View style={styles.tableCell}>
                                    <Image src={`${globalVariable.imagesPath}products/${item.acProduct.trim()}_v.jpg`} />
                                </View>
                                <Text style={styles.tableCell}>
                                    {item.acDept.replace(" 1****", "")} {item.acName}
                                </Text>
                                <View>
                                    <Text style={[styles.tableCell, { fontFamily: "Roboto-Bold" }]}>
                                        {item.acProduct}
                                    </Text>
                                    <Text style={[styles.tableCell, { fontFamily: "Roboto-Bold" }]}>
                                        {/* <CurrencyFormat num={item.anPrice}></CurrencyFormat> {currency} */}
                                        {userInfo && userInfo.isOk ? (
                                            <>
                                                <CurrencyFormat num={item.anPrice} /> {currency}
                                            </>
                                        ) : (
                                            <>
                                                <CurrencyFormat num={item.anRetailPrice} /> {acLogOutUserCurrency ? acLogOutUserCurrency : t('RSD')}
                                            </>
                                        )}
                                    </Text>
                                </View>
                            </View>
                        ))}
                    </View>
                    {filters.map((item, key) => (
                        item.filters.length > 0 ? (
                            <>
                                <View style={{ width: "100%" }}>
                                    <View style={[styles.tableRow, { backgroundColor: "rgb(239,239,239)" }]}>
                                        <Text style={styles.tableCell}>{item.acGroupName}</Text>
                                    </View>
                                    {item.filters.map((item2, key2) => {
                                        return (
                                            <View style={styles.tableRow}>
                                                <CompareProductsItemsSpec key={key2} name={item2.acFilterSet}>
                                                    {items.map(function (specs, key3) {
                                                        let set = specs.spec.list.find((sp) => sp.anGroupId === item.anGroupId);
                                                        set = set ? set : null;
                                                        return <CompareProductsPdfItem specs={set} filterId={item2.anFilterId} />
                                                    })}
                                                </CompareProductsItemsSpec>
                                            </View>
                                        )
                                    })}
                                </View>
                            </>
                        ) : (
                            ""
                        )
                    ))}
                </View>
            </Page>
        </Document>
    );
};

export default CompareProductsPdfItems;
