import React, { useEffect, createRef } from 'react'
import { Modal, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import MaterialTable from "material-table";
import { Paper } from '@material-ui/core';

import PatchedPagination from '../../components/other/PatchedPagination'
import globalVariable from '../../global';
import { useTranslation } from 'react-i18next';

const ModalNewsletter = ({ handleClose, show, newsletter, modalContent, filesList }) => {
    const tableRef = createRef();
    const { t } = useTranslation('modal');

    return (
        <Modal
            show={show}
            onHide={() => handleClose(false)}
            keyboard={false}
            dialogClassName="modal-lg"
            aria-labelledby=""
            centered
        >
            <div className="close-action-modal" variant="secondary" onClick={() => handleClose(false)}>
                <i className="far fa-times-circle"></i>
            </div>
            {modalContent == "list" &&
                <Modal.Header>
                    <Modal.Title>
                        {t('listaFajlova')}
                    </Modal.Title>
                </Modal.Header>
            }
            <Modal.Body>
                {modalContent == "list" ?
                    <div className="newsletter-img-preview">
                        <MaterialTable
                            components={{
                                Pagination: PatchedPagination,
                                Container: props => <Paper {...props} elevation={0} />
                            }}
                            data={filesList}
                            tableRef={tableRef}
                            columns={[
                                {
                                    title: "Naziv fajla",
                                    field: "acMaterialForDownloadItem",
                                    render: item => {
                                        return <Link to={{ pathname: item.acMaterialForDownloadItemType != "acLink" ? `${globalVariable.domainName}/media/${item.acMaterialForDownloadItemLink}` : item.acMaterialForDownloadItemLink }} target="_blank">{item.acMaterialForDownloadItem}</Link>
                                    }
                                },
                                {
                                    title: "Veličina",
                                    field: "fileSize",
                                    render: item => {
                                        return item.fileSize && item.fileSize + "KB"
                                    }
                                },
                                {
                                    title: "Format",
                                    field: "fileExt",
                                },
                            ]}
                            options={{
                                headerStyle: {
                                    whiteSpace: "nowrap",
                                    borderBottom: "2px solid #dee2e6",
                                    fontWeight: "bold",
                                    padding: "8px"
                                },
                                cellStyle: {
                                    padding: "8px",
                                    whiteSpace: "nowrap",
                                },
                                search: false,
                                showTitle: false,
                                toolbar: false,
                                paging: false,
                                filtering: false,
                            }}
                            title=""
                        />
                    </div>
                    :
                    <div className="newsletter-img-preview text-center">
                        {filesList[0].acMaterialForDownloadItemType != "acLink" ?
                            <img src={`${globalVariable.domainName}/media/${filesList[0].acMaterialForDownloadItemLink}`} alt="newsletter" className="newsletter-image img-fluid" />
                            :
                            <img src={`${globalVariable.domainName}/media/${newsletter.acMaterialForDownloadCoverImage}`} alt="newsletter" className="newsletter-image img-fluid" />
                        }
                    </div>
                }

            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-secondary" onClick={() => handleClose(false)}>{t('zatvori')}</button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalNewsletter