import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const FormInput = (props) => {

    const {
        label,
        onChange,
        nameClass,
        placeholder,
        name,
        type,
        isRequired,
        messageError,
        value,
        validInputValue,
        setErrorsFunc,
        options,
        disable,
        maxlength
    } = props;

    const [validInput, setValidInput] = useState(0); //0-nista se ne desava, 1-greska, 2-sve ok
    const [messagesErrorValue, setMessagesErrorValue] = useState('');
    const { t } = useTranslation('form');

    const timeoutFunction = () => {
        setTimeout(() => {
            setValidInput(0);
        }, 1000);
    }

    const handleFocus = (e) => {

        switch (type) {
            case "email":
                if (!value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
                    setValidInput(1);
                    setErrorsFunc(name, 1);
                } else {
                    setValidInput(2);
                    timeoutFunction();
                    setErrorsFunc(name, 0);
                }
                break;
            case "text":
                if (!value || !value.replace(/\s/g, '').length) {
                    setValidInput(1);
                    setErrorsFunc(name, 1);
                } else {
                    setValidInput(2);
                    timeoutFunction();
                    setErrorsFunc(name, 0);
                }
                break;
            case "textarea":
                if (!value) {
                    setValidInput(1);
                    setErrorsFunc(name, 1);
                } else {
                    setValidInput(2);
                    timeoutFunction();
                    setErrorsFunc(name, 0);
                }
                break;
            case "select":
                if (!value) {
                    setValidInput(1);
                    setErrorsFunc(name, 1);
                } else {
                    setValidInput(2);
                    timeoutFunction();
                    setErrorsFunc(name, 0);
                }
                break;
            case "number":
                if (!value) {
                    setValidInput(1);
                    setErrorsFunc(name, 1);
                } else if (name == "taxId" && value.length != 9) {
                    setValidInput(1);
                    setMessagesErrorValue(t('potrebnoUneti9Cifara'));
                    setErrorsFunc(name, 1);
                } else if (name == "companyId" && value.length != 8) {
                    setValidInput(1);
                    setMessagesErrorValue(t('potrebnoUneti8Cifara'));
                    setErrorsFunc(name, 1);
                } else {
                    setValidInput(2);
                    timeoutFunction();
                    setErrorsFunc(name, 0);
                }
                break;
            default:
                if (value != '') {
                    setValidInput(2);
                    timeoutFunction();
                }
                break;
        }
    };

    useEffect(() => {
        setValidInput(validInputValue);
        setMessagesErrorValue('');
    }, [validInputValue])

    return (
        <>
            {label &&
                <label htmlFor={name}>{label}</label>
            }
            {type != "textarea" && type != "select" &&
                <input
                    type={type}
                    value={value}
                    placeholder={placeholder}
                    name={name}
                    className={`form-control ${nameClass} ${validInput == 1 && !disable ? 'is-invalid' : validInput == 2 && 'is-valid'}`}
                    onChange={onChange}
                    onBlur={handleFocus}
                    disabled={disable}
                    maxLength={maxlength}
                    onKeyDown={(evt) => evt.key === 'e' && type == "number" && evt.preventDefault()}
                />
            }
            {type == "textarea" &&
                <textarea
                    value={value}
                    name={name}
                    placeholder={placeholder}
                    className={`form-control ${nameClass} ${validInput == 1 ? 'is-invalid' : validInput == 2 && 'is-valid'}`}
                    onChange={onChange}
                    onBlur={handleFocus}
                    rows="6"></textarea>
            }
            {type == "select" &&
                <select
                    className={`form-control ${nameClass} ${validInput == 1 && !disable ? 'is-invalid' : validInput == 2 && 'is-valid'}`}
                    name={name}
                    onChange={onChange}
                    onBlur={handleFocus}
                    value={value}
                    disabled={disable}
                >
                    <option value="">{t('izaberite')}</option>
                    {options.map((item) => (
                        <option key={item._id} value={item.acName}>{item.acName}</option>
                    ))}
                    {name == "acProductBrand" &&
                        <option value="drugo">{t('drugo')}</option>
                    }
                    {name == "acCompanyBankAccount" &&
                        <option value="novi racun">{t('noviRacun')}</option>
                    }
                </select>
            }
            <div className="invalid-feedback invalid-feedback-absolute1">
                {messagesErrorValue != '' ? messagesErrorValue : messageError}
            </div>
        </>
    )
};

export default FormInput;