import React from 'react';
import CurrencyFormat from "../other/CurrencyFormat";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const LoyaltyBlockHomeItem = ({ item, isMaxWidth, loyaltySettings, getUserInfo, shopIsActive }) => {

    const percentProduct = 100 - (parseFloat(item.anPrice) * 100) / parseFloat(item.anOldPrice);

    const renderPriceElement = () => {
        if (shopIsActive == 0 && item.isPriceVisible == 1) {
            return (
                <>
                    <CurrencyFormat num={item.anPrice} /> <small>EUR</small>
                </>
            );
        } else if (shopIsActive == 1) {
            return (
                <>
                    <CurrencyFormat num={item.anPrice} /> <small>EUR</small>
                </>
            )
        } else {
            return <b>Uskoro</b>
        }
    };
    const { t } = useTranslation('loyalty');

    const renderPercentElement = () => {
        if (shopIsActive == 0 && item.isPriceVisible == 1) {
            return (
                <div className="loyalty-discount-holder">
                    <div className="percetange-loyatly-product">
                        {parseInt(percentProduct)}
                        <small>%</small>
                    </div>
                </div>
            );
        } else if (shopIsActive == 1 && item.isPriceVisible == 1) {
            return (
                <div className="loyalty-discount-holder">
                    <div className="percetange-loyatly-product">
                        {parseInt(percentProduct)}
                        <small>%</small>
                    </div>
                </div>
            )
        } else {
            return <></>
        }
    };
    return (
        <div className="action-block-special-slider-main-slider-item">
            <div className="action-block-special-slider-item-content">
                <div className="loyalty-bages-holder">
                    {item.isSpecialForCompany == 1 && <img src="/images/loyalty/special-for-company.png" className="special-icon-loyalty-for-company" alt={`${item.acProductName.replace(" 1****", "")}`} title={`${item.acProductName.replace(" 1****", "").split(" ").slice(0, 5).join(" ")}`} />}
                    {item.isSaleConnected == 1 && <img className="smallBadge" src="/images/loyalty/badges-povezana-prodaja.png" alt={`${item.acProductName.replace(" 1****", "")}`} title={`${item.acProductName.replace(" 1****", "").split(" ").slice(0, 5).join(" ")}`} />}
                    {item.acConditionType == "PovezanaProdaja" && <img className="smallBadge" src="/images/loyalty/badges-povezana-prodaja-artikal.png" alt={`${item.acProductName.replace(" 1****", "")}`} title={`${item.acProductName.replace(" 1****", "").split(" ").slice(0, 5).join(" ")}`} />}
                </div>
                {renderPercentElement()}
                <div className="action-block-special-slider-item-image">
                    <Link to={{ pathname: "/loyalty-artikli" }}>
                        <img src={`https://resource.ewe.rs/products/${item.acProduct}_v.jpg`} alt={`${item.acProductName.replace(" 1****", "")}`} title={`${item.acProductName.replace(" 1****", "").split(" ").slice(0, 5).join(" ")}`} />
                    </Link>
                </div>
                <div className="action-block-special-slider-item-title"><Link to={{ pathname: "/loyalty-artikli" }}>{item.acProductName.replace(" 1****", "")}</Link></div>
                <div className="action-block-special-slider-item-price-content">
                    <div className="action-block-special-slider-item-price-content-left">
                        <div className="price-content-item">{item.acProduct}</div>
                        <div className="price-content-item oldprice-value"><CurrencyFormat num={item.anOldPrice} /> {t('EUR')}</div>
                    </div>
                    <div className="action-block-special-slider-item-price-content-right">
                        <div className="price-content-item">{t('lager')}: {item.anQtyVirtual}</div>
                        {/* <div className="price-content-item price-value"><CurrencyFormat num={item.anPrice} /> EUR</div> */}
                        {item.isPriceVisible ? renderPriceElement() : 'Uskoro'}

                    </div>
                </div>
                <div className="action-block-special-slider-item-button">
                    <Link to={{ pathname: "/loyalty-artikli" }}>
                        <div className="button-slider-item-detail">
                            {t('pogledajPonudu')}
                        </div>
                    </Link>
                </div>
            </div>
        </div>
        /*<div className="slider-item-spec" style={isMaxWidth < 5 ? {maxWidth: "300px"} : {}}>
            <div className="item-slider-special-action">
                <div className="loyalty-bages-holder">
                    {item.isSpecialForCompany == 1 && <img src="/images/loyalty/special-for-company.png" className="special-icon-loyalty-for-company" alt="Loyalty Shop" />}
                    {item.isSaleConnected == 1 && <img className="smallBadge" src="/images/loyalty/badges-povezana-prodaja.png" />}
                    {item.acConditionType == "PovezanaProdaja" && <img className="smallBadge" src="/images/loyalty/badges-povezana-prodaja-artikal.png" />}
                </div>
                <div className="loyalty-discount-holder">
                    <div className="percetange-loyatly-product">
                        {parseInt(percentProduct)}
                        <small>%</small>
                    </div>
                </div>

                <div className="slider-special-action-image">
                    <img src={`http://resource.ewe.rs/products/${item.acProduct}_v.jpg`} alt="b2b" />
                </div>
                <div className="slider-special-action-title">
                    <div className="action-title-name">
                        <Link to={{pathname: "/loyalty-artikli"}}>{item.acProductName}</Link>
                    </div>
                </div>
                <div className="slider-special-action-detail-res">
                    <div className="spec-action-qty">{item.acProduct}</div>
                </div>
                <div className="slider-special-action-detail-res">
                    <div className="spec-action-qty">Dnevni limit: {shopIsActive == 1 ? item.anQtyAvailableForCompany : "Računa se"}</div>
                    <div className="spec-action-qty text-right">Lager: {shopIsActive == 1 ? item.anQty : item.anQtyVirtual}</div>
                </div>
                {/* <div className="slider-special-action-detail-price">
                    <div className="spec-action-qty">Dnevni limit: {shopIsActive == 1 ? item.anQtyAvailableForCompany : "Računa se"}</div>
                </div>
                <div className="slider-special-action-detail-price">
                </div>
                <div className="slider-special-action-detail-price">
                    <div className="spec-action-price-old text-left">
                        <CurrencyFormat num={item.anOldPrice} /> EUR
                    </div>
                    <div className="spec-action-price">{renderElement()}</div>
                </div>
                <div className="loyalty-btn-holder">
                    <Link to={{pathname: "/loyalty-artikli"}} className="btn loyalty-block-btn">
                        Pogledaj ponudu
                    </Link>
                </div>
            </div>
        </div>*/
    );
};
export default LoyaltyBlockHomeItem;