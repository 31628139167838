import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TargetBox from '../components/other/TargetBox';
import { getSaleTarget } from '../redux/actions/userActions'
import { globalStatistic } from '../redux/actions/otherActions';
import { getPageSeo } from '../redux/actions/pageActions';
import PageHelmet from '../components/helmet/PageHelmet';
import { useTranslation } from 'react-i18next';

const TargetPage = () => {

    const dispatch = useDispatch();
    const getSaleTargetList = useSelector((state) => state.getSaleTargetList.items)
    const [pageSeoData, setPageSeoData] = useState({});
    const { t } = useTranslation('targetPage');
    useEffect(() => {
        dispatch(getPageSeo(774)).then(res => {
            if (res) {
                setPageSeoData(res);
            }
        });
        dispatch(getSaleTarget())
        dispatch(globalStatistic(774, 'PAGE'));
    }, [])

    return (
        <>
            {Object.keys(pageSeoData).length !== 0 &&
                <PageHelmet
                    title={pageSeoData ? `${pageSeoData.acSeoTitle}` : "Ewe Comp"}
                    description={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    keywords={pageSeoData ? `${pageSeoData.acSeoKeywords}` : ""}
                    ogDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    ogTitle={pageSeoData ? `${pageSeoData.acSeoTitle}` : ""}
                    ogUrl={pageSeoData ? `https://ewe.rs/${pageSeoData.acSeoUrl}` : ""}
                    ogImg="https://www.ewe.rs/media/ewe-og-img.jpg"
                    twitterDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                />
            }
            <div className="container-content container-main-content target-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="stats-order-content-holder">
                                <h1>{pageSeoData.acSeoHeader ? pageSeoData.acSeoHeader : t('pregledTargeta')}</h1>
                                {pageSeoData.acSeoShortPageDescription && <p>{pageSeoData.acSeoShortPageDescription}</p>}
                            </div>
                        </div>
                        <div className="col-sm-12">
                            {getSaleTargetList.map((item, key) => {
                                return <TargetBox item={item} key={key} />
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TargetPage;