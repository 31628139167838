import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { globalStatistic } from '../../redux/actions/otherActions';

const ProductDetailSliderItem = ({ imgNo, product, image, modalIcon, handleShow, row, youtubeImage }) => {

    const dispatch = useDispatch();
    const params = useParams();
    const { productId } = params;

    // const [youtubeImage, setYoutubeImage] = useState('');
    const [mouseMoved, setMouseMoved] = useState(false);
    // var youtubeCode = image.acImage;
    // var youtubeKey = 'AIzaSyCcp3vQueKPj0OfU12LmKGF_xRaeJUl66E';
    // var youtubeApi = 'https://www.googleapis.com/youtube/v3/videos?key=' + youtubeKey + '&part=snippet&id=' + youtubeCode + '';

    // function youtubeApiFunction() {
    //     axios.get(youtubeApi).then((response) => {
    //         if (response.data.items.length > 0) {
    //             setYoutubeImage(response.data.items[0].snippet.thumbnails.high.url);
    //         }
    //     });
    // }

    // useEffect(() => {
    //     if (image.acType == "video") {
    //         youtubeApiFunction();
    //     }
    // }, []);

    const handleClickFunction = (e, row) => {
        if (!mouseMoved) {
            if (e.button === 1) {
            } else {
                handleShow(row);
            }
        }
    };
    const gatherStatisticVideoProduct = () => {
        dispatch(globalStatistic(productId, 'VIDEO_PRODUCT'));
    }
    const gatherStatisticImageProduct = () => {
        dispatch(globalStatistic(productId, 'IMAGE_PRODUCT'));
    }

    const gatherStatistic3DProduct = () => {
        dispatch(globalStatistic(productId, '3D_PRODUCT'));
    }


    return (
        <div
            className={`h3-cust ${imgNo < 4 && 'h3-cust-small'}`}
            onClick={(e) => handleClickFunction(e, row)}
            onMouseMove={() => setMouseMoved(true)}
            onMouseDown={() => setMouseMoved(false)}
        >
            {image.acType == "video" &&
                // <div className="embeded-video" key={row}>
                //     <iframe src="https://www.youtube.com/embed/FB4cWOUHAkU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                // </div>
                <>
                    <img className={`img-fluid`} src={youtubeImage} alt={product.acName} />
                    <div className="product-detail-item-3d">
                        <div className="mask-div-image-360">
                            <img alt="Play button" src={process.env.PUBLIC_URL + `/player-btn.png`} className="img-logo-360" />
                        </div>
                    </div>
                </>
            }
            {image.acType == "image" &&
                <img className={`img-fluid ${imgNo < 3 && 'content-slider-image-small'} ${imgNo == 1 && 'content-slider-image-product-one-item'}`} src={image.acImage} alt={`${image.acSeoDescription}`} title={`${image.acSeoTitle}`} />
            }
            {
                modalIcon == 1 & row == 0 &&
                <div className="product-detail-item-3d">
                    <div className="mask-div-image-360">
                        <img alt="3D logo" src={process.env.PUBLIC_URL + `/360logo.png`} className="img-logo-360" />
                    </div>
                </div>
            }
        </div>
    )
}

export default ProductDetailSliderItem;