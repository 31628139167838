import React from 'react';

const ReclamationStatusItem = ({ status, statusColor }) => {
    return (
        <tr>
            <td>
                <div className="table-row-editing-cart-item-name">210CM000000234</div>
            </td>
            <td className="col-width-300">
                <div className="table-row-editing-cart-item table-padding-item-border-both text-left-padding text-overflow-custom-1">XIAOMI Yunmai Otporna traka (15pound) narandžasta YMTB-T301</div>
            </td>
            <td>
                <div className="table-row-editing-cart-item table-padding-item-border-right text-center">TNE14WP1BOX7364</div>
            </td>
            <td>
                <div className="table-row-editing-cart-item table-padding-item-border-right text-center">29/10/2021</div>
            </td>
            <td>
                <div className="table-row-editing-cart-item table-padding-item-border-right text-center">Servis</div>
            </td>
            <td>
                <div className="table-row-editing-cart-item table-padding-item-border-right text-center">29/10/2021</div>
            </td>
            <td className="text-center">
                <div className="buttons-editing-cart-all">
                    <div className={`btn-custom-editing-cart ${statusColor}-btn-editing-cart`}>
                        <div className={`btn-custom-editing-cart-text order-status-text-status`}>{status}</div>
                    </div>
                </div>
            </td>
        </tr>
    );
};

export default ReclamationStatusItem;