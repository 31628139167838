import { applyMiddleware, createStore, compose } from 'redux';
import reducers from './reducers';
import thunk from 'redux-thunk';
import SecureLS from 'secure-ls';

let ls = new SecureLS({ encodingType: 'aes' });

const initialState = {
    userInfo: {
        userInfo: ls.get('userInfo') ? ls.get('userInfo') : null,
    },
    compareItems: {
        compareItems: localStorage.getItem('compareItems') ? JSON.parse(localStorage.getItem('compareItems')) : [],
    },
    cart: {
        cartItems: localStorage.getItem('cartItems') ? JSON.parse(localStorage.getItem('cartItems')) : [],
    },
    getUserNews: {
        items: localStorage.getItem('getUserNews') ? JSON.parse(localStorage.getItem('getUserNews')) : [],
    },
    getUserPopup: {
        items: localStorage.getItem('getUserPopup') ? JSON.parse(localStorage.getItem('getUserPopup')) : [],
    },
    getUserBanner: {
        items: localStorage.getItem('getUserBanner') ? JSON.parse(localStorage.getItem('getUserBanner')) : [],
    },
    getUserSurvey: {
        items: localStorage.getItem('getUserSurvey') ? JSON.parse(localStorage.getItem('getUserSurvey')) : [],
    },
    getUserSettings: {
        items: localStorage.getItem('getUserSettings') ? JSON.parse(localStorage.getItem('getUserSettings')) : [],
    },
    getActiveCartFunction: [
        ls.get('getActiveCartFunction') ? ls.get('getActiveCartFunction') : [],
    ],
    getCartItemsList: {
        items: ls.get('getCartItemsList') ? ls.get('getCartItemsList') : [],
    },
    getCartListFunction: {
        items: ls.get('getCartListFunction') ? ls.get('getCartListFunction') : [],
    },
    activeCurrency: {
        item: localStorage.getItem('activeCurrency') ? JSON.parse(localStorage.getItem('activeCurrency')) : [],
    },
    activeVat: {
        item: localStorage.getItem('activeVat') ? JSON.parse(localStorage.getItem('activeVat')) : [],
    },
    activeProductSort: {
        item: localStorage.getItem('activeVat') ? JSON.parse(localStorage.getItem('activeProductSort')) : [],
    },
    activeProductView: {
        item: localStorage.getItem('activeVat') ? JSON.parse(localStorage.getItem('activeProductView')) : [],
    },
    menuSettings: {
        homePage: localStorage.getItem('menuSettings') ? (JSON.parse(localStorage.getItem('menuSettings'))).homePage ? JSON.parse(localStorage.getItem('menuSettings')).homePage : false : false,
        otherPage: localStorage.getItem('menuSettings') ? (JSON.parse(localStorage.getItem('menuSettings'))).otherPage ? JSON.parse(localStorage.getItem('menuSettings')).otherPage : false : false,
    }
};

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    reducers,
    initialState,
    composeEnhancer(applyMiddleware(thunk))
);

export default store;