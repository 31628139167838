import React, { useState, useEffect } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import imageExists from 'image-exists';
import { useTranslation } from 'react-i18next';


const GlobalUserInfo = ({ getUserInfo, user, changeTab, tab }) => {

    const clerk = getUserInfo.clerkResponsible[0];
    const queries1 = useMediaQuery('(max-width: 1730px)');


    const [currentImage, setCurrentImage] = useState("https://resource.ewe.rs/images/hr/avatars/" + clerk.acPicture);
    const [srcImage, setSrcImage] = useState('/no-user-image.png');
    const { t } = useTranslation('profile');

    useEffect(() => {
        imageExists(currentImage, img => {
            if (img) {
                setSrcImage(currentImage);
            };
        });
    }, []);

    return (
        <>
            <div className="row user-section">
                <div className="col-sm-8">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="global-info-user">
                                <h5>{t('imeKompanije')}</h5>
                                <div className="global-info-user-data">{getUserInfo.acCompany}</div>
                                <h5>{t('radnja')}</h5>
                                <div className="global-info-user-data">{getUserInfo.department[0].acDepartment}</div>
                                <h5>{t('adresa')}</h5>
                                <div className="global-info-user-data">{getUserInfo.acAddress}, {getUserInfo.acCity}</div>
                                <h5>{t('telefon')}</h5>
                                <div className="global-info-user-data">{getUserInfo.acPhone1}</div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="global-info-user">
                                <h5>{t('pib')}</h5>
                                <div className="global-info-user-data">{getUserInfo.acCode}</div>
                                <h5>{t('maticniBroj')}</h5>
                                <div className="global-info-user-data">{getUserInfo.acRegNo}</div>
                                {/* <h5>Klasifikacija kupaca</h5>
                                <div className="global-info-user-data">{getUserInfo.acCompanyClassifName}</div>
                                <h5>Rabatna šema</h5>
                                <div className="global-info-user-data">{getUserInfo.acCompanyRebate}</div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="login-holder-block-form">
                        <div className="clerk-info text-center">
                            <div className="logo-login-image">
                                {/* <img className="img-fluid" src={"http://work3.ewe.rs/images/hr/avatars/" + clerk.acPicture} /> */}
                                <img src={srcImage} alt="" />
                            </div>
                            <h5>{clerk.acFirstName} {clerk.acLastName}</h5>
                            <div className="clerk-info-position">{clerk.acJobPosition}</div><br />
                            <div className="clerk-info-data">{clerk.acPhone1}</div>
                            <div className="clerk-info-data">{clerk.acPhone2}</div>
                            {clerk.acSkype != "" && <div className="clerk-info-social" title={clerk.acSkype}><i className="fab fa-skype" style={{ color: "rgb(0, 120, 212)" }}></i></div>}
                        </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className={`${queries1 ? "col-md-4" : "col-lg-2"} ${tab == "user" ? "user-links-active" : "user-links-not-active"} col-6 user-links`}>
                    <div className="global-info-user-data" onClick={() => changeTab("user")}>
                        {t('mojNalog')}
                    </div>
                </div>
                <div className={`${queries1 ? "col-md-4" : "col-lg-2"} ${tab == "company" ? "user-links-active" : "user-links-not-active"} col-6 user-links`}>
                    <div className="global-info-user-data" onClick={() => changeTab("company")}>
                        {t('kompanija')}
                    </div>
                </div>
                <div className={`${queries1 ? "col-md-4" : "col-lg-2"} ${tab == "wishList" ? "user-links-active" : "user-links-not-active"} col-6 user-links`}>
                    <div className="global-info-user-data" onClick={() => changeTab("wishList")}>
                        {t('listaZelja')}
                    </div>
                </div>
                <div className={`${queries1 ? "col-md-4" : "col-lg-2"} ${tab == "promo" ? "user-links-active" : "user-links-not-active"} col-6 user-links`}>
                    <div className="global-info-user-data" onClick={() => changeTab("promo")}>
                        {t('pregledVaucera')}
                    </div>
                </div>
                {user.anRoleKey == 1 &&
                    <div className={`${queries1 ? "col-md-4" : "col-lg-2"} ${tab == "admin" ? "user-links-active" : "user-links-not-active"} col-6 user-links`}>
                        <div className="global-info-user-data" onClick={() => changeTab("admin")}>
                            {t('admin')}
                        </div>
                    </div>
                }
            </div>
        </>
    )
}

export default GlobalUserInfo