import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { createNewCartFunction, getCartFunction } from '../redux/actions/cartActions';
import { globalStatistic } from '../redux/actions/otherActions';
import { getPageSeo } from '../redux/actions/pageActions';

import CartEditingItem from '../components/cart/CartEditingItem';
import MessageInfoBox from '../components/other/MessageInfoBox';
import PageHelmet from '../components/helmet/PageHelmet';
import { useTranslation } from 'react-i18next';

const CartEditingPage = () => {

    const dispatch = useDispatch();
    const getCartListFunction = useSelector((state) => state.getCartListFunction);
    const [cartName, setCartName] = useState('');
    const [pageSeoData, setPageSeoData] = useState({});
    const { t } = useTranslation('cartEditingPage');

    const cartCreateNew = (e) => {
        if (cartName != '') {
            dispatch(createNewCartFunction(cartName));
            setCartName('');
        } else {
            toast(<MessageInfoBox message="errorCreateNewCartEmptyName" type="0" />, {
                className: 'red-background',
            });
        }
    }

    const cartCreateNewEnter = (e) => {
        if (e.key == 'Enter') {
            if (cartName != '') {
                dispatch(createNewCartFunction(cartName));
                setCartName('');
            } else {
                toast(<MessageInfoBox message="errorCreateNewCartEmptyName" type="0" />, {
                    className: 'red-background',
                });
            }
        }
    }

    useEffect(() => {
        dispatch(getPageSeo(543)).then(res => {
            if (res) {
                setPageSeoData(res);
            }
        });
        dispatch(getCartFunction());
        dispatch(globalStatistic(543, 'PAGE'));
    }, []);

    const gatherStatisticCreateCart = () => {
        dispatch(globalStatistic(0, 'CREATE_CART'));
    }

    return (
        <>
            {Object.keys(pageSeoData).length !== 0 &&
                <PageHelmet
                    title={pageSeoData ? `${pageSeoData.acSeoTitle}` : "Ewe Comp"}
                    description={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    keywords={pageSeoData ? `${pageSeoData.acSeoKeywords}` : ""}
                    ogDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    ogTitle={pageSeoData ? `${pageSeoData.acSeoTitle}` : ""}
                    ogUrl={pageSeoData ? `https://ewe.rs/${pageSeoData.acSeoUrl}` : ""}
                    ogImg="https://www.ewe.rs/media/ewe-og-img.jpg"
                    ogType="website"
                    twitterDescription={`${pageSeoData.acSeoDescription}`}
                />
            }
            <div className="container-content container-editing-cart-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="editing-cart-item-content">
                            {pageSeoData.acSeoShortPageDescription ? <h4>{pageSeoData.acSeoShortPageDescription}</h4> : <h4>{t('naslov')}</h4>}
                            <div className="editing-cart-items-table">
                                <div className="editing-cart-items-table-custom">
                                    <table className="table editing-cart-table">
                                        <thead>
                                            <tr>
                                                <th>{t('kolona1')}</th>
                                                <th className="editing-cart-table-header-qty">{t('kolona2')}</th>
                                                <th className="editing-cart-table-header-price">{t('kolona3')}</th>
                                                <th className="editing-cart-table-header-buttons"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {getCartListFunction.items.map((item, key) => (
                                                <CartEditingItem key={key} item={item} />
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="box-crete-new-order">
                                    <div className="box-create-item box-create-new-order-title">{t('novaKorpa')}</div>
                                    <div className="box-create-item box-create-new-order-input">
                                        <input
                                            type="text"
                                            value={cartName}
                                            className="form-control form-control-sm"
                                            placeholder={t('placeholder')}
                                            onChange={(e) => setCartName(e.target.value)}
                                            onKeyPress={(e) => cartCreateNewEnter(e)}
                                        />
                                    </div>
                                    <div className="box-create-item box-create-new-order-button blue-btn-editing-cart">
                                        <button
                                            className="btn-custom-editing-cart-text"
                                            onClick={(e) => { cartCreateNew(e); gatherStatisticCreateCart() }}
                                        >
                                            <i className="fas fa-plus"></i> {t('kreirajKorpu')}
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default CartEditingPage