import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import NewsItem from '../components/news/NewsItem';
import PageHelmet from '../components/helmet/PageHelmet';

import { useDispatch } from 'react-redux';
import { globalStatistic } from '../redux/actions/otherActions';
import { getPageSeo } from '../redux/actions/pageActions';
import { useTranslation } from 'react-i18next';

const NewsPage = () => {
    const [scrollEnabled, setScrollEnabled] = useState(true); // Dodavanje scrollEnabled stanja
    const { t } = useTranslation('newsPage');

    const getUserNews = useSelector((state) => state.getUserNews.items);
    getUserNews.sort((a, b) => {
        return new Date(b.adTimeIns) - new Date(a.adTimeIns);
    });

    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    let today = new Date().toLocaleDateString(t('kodZaDatum'), options) // za makedoniju mk-MK

    const dispatch = useDispatch();
    const [pageSeoData, setPageSeoData] = useState({});
    const itemsPerPage = 10; // Broj vesti po stranici
    const [totalDisplayed, setTotalDisplayed] = useState(10); // Ukupan broj trenutno prikazanih vesti
    const handleShowMoreClick = () => {
        setScrollEnabled(false); // Onemogucavanje scroll-a prilikom klika na "Prikazi vise"
        setTotalDisplayed(totalDisplayed + itemsPerPage);
    };
    useEffect(() => {
        dispatch(getPageSeo(764)).then(res => {
            if (res) {
                setPageSeoData(res);
            }
        });
        dispatch(globalStatistic(764, 'PAGE'));
    }, [])


    return (
        <>
            {Object.keys(pageSeoData).length !== 0 &&
                <PageHelmet
                    title={pageSeoData ? `${pageSeoData.acSeoTitle}` : "Ewe Comp"}
                    description={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    keywords={pageSeoData ? `${pageSeoData.acSeoKeywords}` : ""}
                    ogDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    ogTitle={pageSeoData ? `${pageSeoData.acSeoTitle}` : ""}
                    ogUrl={pageSeoData ? `https://ewe.rs/${pageSeoData.acSeoUrl}` : ""}
                    ogImg="https://www.ewe.rs/media/ewe-og-img.jpg"
                    ogType="article"
                    twitterDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                />
            }
            <div className="container-content news-container-content">
                <div className="container-fluid-custom">
                    <div className="row">
                        <div className="stats-order-content-holder w-100">
                            <h1>{Object.keys(pageSeoData).length !== 0 && pageSeoData.acSeoHeader}</h1>
                            {pageSeoData.acSeoShortPageDescription && <h5>{pageSeoData.acSeoShortPageDescription}</h5>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="news-content-box">
                            {getUserNews.length == 0 ? (
                                <div className="alert alert-primary w-100" role="alert">
                                    {t('paragraf1')}
                                </div>
                            ) : (
                                <>
                                    {getUserNews.slice(0, totalDisplayed).map((item, key) => (
                                        <NewsItem data={item} today={today} options={options} key={key} scrollEnabled={scrollEnabled} />
                                    ))}

                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div className="show-more-container">
                    <span
                        onClick={handleShowMoreClick}
                        className="show-more"
                        id="show-more-button"
                    >
                        {t('paragraf2')}<i className="fas fa-chevron-down"></i>
                    </span>
                </div>
            </div>
        </>
    );
};

export default NewsPage;
