import React, { useState, useEffect } from "react";
import {
    Page,
    Text,
    View,
    Document,
    Image,
    Font,
    StyleSheet,
} from "@react-pdf/renderer";

const CompareProductsPdfItem = ({ specs, filterId }) => {

    const [filterValue, setFilterValue] = useState('');

    const styles = StyleSheet.create({
        tableCol: { width: "20%" },
        tableCell: { paddingHorizontal: 5, fontSize: 10, paddingVertical: 2 }
    });

    useEffect(() => {
        let val = specs ? specs.filterSet.find((fv) => fv.anFilterId === filterId) : null;
        if (val) {
            setFilterValue(val.filters);
        }
    }, []);

    return (
        <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{filterValue}</Text>
        </View>
    )
}

export default CompareProductsPdfItem