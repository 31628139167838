import React, { useRef, useCallback } from 'react';
import Slider from "react-slick";
import axios from 'axios';
import { Button, Modal } from 'react-bootstrap';
import YouTube from 'react-youtube';
import ProductDetailSliderItemSmallImage from '../product/ProductDetailSliderItemSmallImage';

import globalVariable from '../../global';
import { useTranslation } from 'react-i18next';

function SampleNextArrow(props) {

    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", borderRadius: '10px', right: "-10px", zIndex: 2 }}
            onClick={onClick}
        />
    );
};

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", borderRadius: '10px', left: "-10px", zIndex: 2 }}
            onClick={onClick}
        />
    );
};

function useHookWithRefCallback() {
    const slider = useRef(null)
    const setRef = useCallback(node => {
        if (node) {
            setTimeout(function () {
                node.innerSlider.list.setAttribute('tabindex', 0);
                node.innerSlider.list.focus();
            }, 100);
        }
        slider.current = node
    }, [])

    return [setRef]
}

const Modal3D = ({ show, handleClose, item, modalIcon, slideIndex, sliderImages, youtubeImage }) => {
    let productImages = sliderImages
    // console.log(productImages, 'productImages')
    const [slider] = useHookWithRefCallback();

    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    };

    const settingsSlider = {
        customPaging: function (i) {

            // var youtubeCode = 'oARedhwTUio';
            // var youtubeKey = 'AIzaSyCcp3vQueKPj0OfU12LmKGF_xRaeJUl66E';
            // var youtubeApi = 'https://www.googleapis.com/youtube/v3/videos?key='+youtubeKey+'&part=snippet&id='+youtubeCode+'';
            // var tmpImage = '';
            // axios.get(youtubeApi).then((response) => {
            //     tmpImage = response.data.items[0].snippet.thumbnails.high.url;
            // });

            return (
                <div className="item-list-slider-product">
                    <div className="item-mini-images-product">
                        <ProductDetailSliderItemSmallImage image={productImages[i]} modalIcon={modalIcon} keyVal={i} youtubeImage={youtubeImage} />
                        {/* <img className="mini-images-slider-product" src={`${productImages[i].acType == 'images' ? productImages[i].acThumbnail: tmpImage}`} alt="b2b" /> */}
                    </div>
                </div>
            );
        },
        dots: true,
        infinite: true,
        speed: 0,
        focusOnSelect: true,
        initialSlide: slideIndex,
        slidesToScroll: 1,
        slidesToShow: 1,
        swipeToSlide: false,
        draggable: false,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    initialSlide: slideIndex
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    initialSlide: slideIndex
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: slideIndex
                }
            }
        ]
    };
    const { t } = useTranslation('product');

    return (
        <Modal
            show={show}
            onHide={handleClose}
            dialogClassName="custom-modal-preview-image" //"modal-custom-product-3d" - prethodna klasa u slucaju da zatreba
        >
            <Modal.Body> {/*"body-3d-modal-product-detail" - prethodna klasa u slucaju da zatreba */}
                <div className="content-slider-image-product">
                    <Slider {...settingsSlider} ref={slider}>
                        {modalIcon == 1 &&
                            productImages.map((thumb, index) => {
                                if (index == 0) {
                                    return (
                                        <div className="h3-cust" key={index}>
                                            {/* <iframe src={`https://resource.ewe.rs/3d/${item.acProduct.trim()}/${item.acProduct.trim()}/`} width="100%" height="100%" title="description"></iframe> */}
                                            <iframe src={`${globalVariable['3dPath']}${item.acProduct.trim()}/${item.acProduct.trim()}/`} width="100%" height="100%" title="description"></iframe>
                                        </div>

                                    )
                                } else if (thumb.acType == "image") {
                                    return (
                                        <div className="h3-cust" key={index}>
                                            <img className="img-fluid" src={thumb.acImage} alt={item.acSeoDescription} title={item.acSeoTitle} />
                                        </div>
                                    )
                                } else if (thumb.acType == "video" && thumb.acImage != "") {
                                    return (
                                        <div className="embeded-video" key={index}>
                                            {/* <iframe src={thumb.acImage} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> */}
                                            <YouTube videoId={thumb.acImage} opts={opts} />
                                        </div>
                                    )
                                }
                            })
                        }
                        {modalIcon == 0 &&
                            productImages.map((thumb, index) => {
                                if (thumb.acType == "image") {
                                    return (
                                        <div className="h3-cust" key={index}>
                                            <img className="img-fluid" src={thumb.acImage} alt={item.acSeoDescription} title={item.acSeoTitle} />
                                        </div>
                                    )
                                } else if (thumb.acType == "video" && thumb.acImage != "") {
                                    return (
                                        <div className="embeded-video" key={index}>
                                            {/* <iframe src={thumb.acImage} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> */}
                                            <YouTube videoId={thumb.acImage} opts={opts} />
                                        </div>
                                    )
                                }
                            })
                        }
                    </Slider>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t('zatvoriPregled')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default Modal3D;