import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Image, Container, Row, Col } from 'react-bootstrap';

import axios from 'axios';
import { axiosinstance } from '../../configAxios';
import { getCompanyPromoMaterial } from "../../redux/actions/companyPromoMaterialActions";

import BlockActionArticle from '../blocks/BlockActionArticle';
import BlockActionImage from '../blocks/BlockActionImage';
import SecureLS from 'secure-ls';

const ModalActionPopup = (props) => {

    const [show, setShow] = useState(props.isShown);
    const { popups } = props;

    const dispatch = useDispatch()

    const checkSeenPopup = (popupKey) => {
        let ls = new SecureLS({ encodingType: 'aes' });
        var userInfoData = ls.get('userInfo');
        const attributes = {};
        attributes["anPopupKey"] = popupKey;
        attributes["anUserKey"] = userInfoData.company.department[0].user[0].anUserKey;
        attributes["isVisitedLink"] = 1;

        const configRes = axiosinstance(attributes, "8224", "seenPopup");

        axios(configRes)
            .then(function (response) {
                dispatch(getCompanyPromoMaterial(4));
            })
            .catch(function (error) {
                console.log(error, "error");
            });
    };

    const handleClose = () => {
        setShow(false);
        popups.forEach((item, index) => {
            checkSeenPopup(item.anPopupKey);
        });
    };

    const escFunction = (event) => {
        if (event.keyCode === 27) {
            handleClose();
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);

        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, []);

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            dialogClassName={props.classModal}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="action-modal-popup"
        >
            <div className="close-action-modal" variant="secondary" onClick={handleClose}>
                <i className="far fa-times-circle"></i>
            </div>
            <Modal.Body>
                {popups.length > 1 && (
                    <div className="modal-double-pupup-holder">
                        {popups.map((item, index) => {
                            // console.log(index, 'u modalu')
                            return (
                                // <>
                                //     {index == 0 ? (
                                //         <>
                                //             {item.acPopupType == 0 &&
                                //                 <div className="no-padding-custom modal-popup-item-holder">
                                //                     <BlockActionArticle item={item} index={index} />
                                //                 </div>
                                //             }
                                //             {item.acPopupType == 1 &&
                                //                 <div className="no-padding-custom modal-popup-item-holder">
                                //                     <BlockActionArticle item={item} index={index} />
                                //                 </div>
                                //             }
                                //             {item.acPopupType == 2 &&
                                //                 <div className="no-padding-custom modal-popup-item-holder-left">
                                //                     <BlockActionImage handleCloseClick={handleClose} index={index} isShown={show} item={item} />
                                //                 </div>
                                //             }
                                //         </>
                                //     ) : (
                                <>
                                    {item.acPopupType == 0 &&
                                        <div className="no-padding-custom modal-popup-item-holder">
                                            <BlockActionArticle item={popups} show={setShow} checkSeenPopup={checkSeenPopup} index={index} />
                                        </div>
                                    }
                                    {item.acPopupType == 1 &&
                                        <div className="no-padding-custom modal-popup-item-holder">
                                            <BlockActionArticle item={popups} show={setShow} checkSeenPopup={checkSeenPopup} index={index} />
                                        </div>
                                    }
                                    {item.acPopupType == 2 &&
                                        <div className="no-padding-custom modal-popup-item-holder-left">
                                            <BlockActionImage show={setShow} checkSeenPopup={checkSeenPopup} index={index} isShown={show} item={item} />
                                        </div>
                                    }
                                </>
                                //     )}
                                // </>
                            )
                        })}
                    </div>
                    // <Container fluid>
                    //     <Row>
                    // {popups.map((item, index) => {
                    //     return (
                    //         <>
                    //             {index == 0 ? (

                    //                 <Col sm={12} md={12} lg={12} xl={5} className="no-padding-custom">
                    //                     {item.acPopupType == 0 && <BlockActionArticle height={"100%"} item={item} />}
                    //                     {item.acPopupType == 1 && <BlockActionArticle height={"100%"} item={item} />}
                    //                     {item.acPopupType == 2 && <BlockActionImage handleCloseClick={handleClose} isShown={show} item={item} />}
                    //                 </Col>
                    //             ) : (
                    //                 <Col sm={12} md={12} lg={12} xl={7} className="no-padding-custom">
                    //                     {item.acPopupType == 0 && <BlockActionArticle height={"100%"} item={item} />}
                    //                     {item.acPopupType == 1 && <BlockActionArticle height={"100%"} item={item} />}
                    //                     {item.acPopupType == 2 && <BlockActionImage handleCloseClick={handleClose} isShown={show} item={item} />}
                    //                 </Col>
                    //             )}
                    //         </>
                    //     )
                    // })}

                    //     </Row>
                    // </Container>
                )}
                {popups.length == 1 && (
                    popups.map((item, index) => {
                        if (item.acPopupType == "0") {
                            return <BlockActionArticle item={item} index={index} show={setShow} checkSeenPopup={checkSeenPopup} />;
                        } else if (item.acPopupType == "1") {
                            return <BlockActionArticle item={item} index={index} show={setShow} checkSeenPopup={checkSeenPopup} />;
                        } else if (item.acPopupType == "2") {
                            return <BlockActionImage handleCloseClick={handleClose} index={index} item={item} show={setShow} checkSeenPopup={checkSeenPopup} />;
                        }
                    })
                )}

            </Modal.Body>
        </Modal>
    );
};

export default ModalActionPopup;