import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";

import styles from '../../css/Wishlist.module.css';

import {
    removeProductWishList
} from '../../redux/actions/productActions';

import InputQtyPlusMinus from '../other/InputQtyPlusMinus';
import CurrencyFormat from '../other/CurrencyFormat';
import RemoveCartItem from '../other/RemoveCartItem';
import AddToCartButton from '../cart/AddToCartButton';
import { useTranslation } from 'react-i18next';

import { removeSpecialChar } from '../../helperFunctions'

const WishListItem = ({ item }) => {

    const dispatch = useDispatch();
    const activeCurrency = useSelector((state) => state.activeCurrency.item);
    const [qtyProduct, setQtyProduct] = useState(1);
    const { t } = useTranslation(['other', 'routes']);
    const functionQtyValue = (qty) => {
        setQtyProduct(qty);
    }

    const removeWishListItemFunction = () => {
        dispatch(removeProductWishList(item, 0));
    }

    return (
        <tr className={styles['wish-list-row-table']}>
            <td className={`main-cart-item-image ${styles['main-cart-item-value']}`}>
                <Link to={`/${t('proizvod')}/${item.anProductKey}/${removeSpecialChar(item.acName)}`}>
                    <img src={`https://resource.ewe.rs/products/${item.acProduct}_v.jpg`} className={styles['wishlist-img']} alt="img" />
                </Link>
            </td>
            <td className={`${styles['main-cart-item-name']} ${styles['main-cart-item-value']}`}>
                <div className="item-cart-td">
                    {item.acProduct}
                </div>
            </td>
            <td className={`${styles['main-cart-item-name']} ${styles['main-cart-item-value']}`}>
                <div className={`item-cart-td ${styles['product-name']}`}>
                    <Link to={`/${t('proizvod')}/${item.anProductKey}/${removeSpecialChar(item.acName)}`}>
                        {item.acDept.replace(" 1****", "")} {item.acName}
                    </Link>
                </div>
            </td>
            <td className={`${styles['main-cart-item-price']} ${styles['main-cart-item-value']}`}>
                <div className={`item-cart-td ${styles['price-value']}`}>
                    <CurrencyFormat num={item.anPrice} /> {activeCurrency.acShortName}
                </div>
            </td>
            <td className={`${styles['main-cart-item-name']} ${styles['main-cart-item-value']} text-center`}>
                <div className="item-cart-td">
                    {item.anStock}
                </div>
            </td>
            <td className={styles['wishlist-buttons']}>
                <div className="item-cart-td">
                    <div className={styles['catalog-item-buttons']}>
                        <InputQtyPlusMinus functionSetQty={functionQtyValue} qty={item.anStock} />
                        <div className={styles['catalog-item-add-button']}>
                            <div className="text-center btn btn-success w-100 btn-sm">
                                <AddToCartButton qtyValue={qtyProduct} product={item} name={t('dodajUKorpu')} />
                            </div>
                        </div>
                    </div>
                </div>
            </td>
            <td>
                <div className={styles['remove-wish-list-item']} onClick={(e) => removeWishListItemFunction()}>
                    <i className="far fa-times-circle"></i>
                </div>
            </td>
        </tr>
    )
}

export default WishListItem;