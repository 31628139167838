import React from 'react';
import Slider from "react-slick";
import { useSelector } from 'react-redux';

import BannerSliderItem from './BannerSliderItem';

const BlockSlider = () => {

  const getUserBanner = useSelector((state) => state.getUserBanner.items);
  const bennerListItems = getUserBanner.filter(x => x.anBannerPosition === 2);
  const bennerList = bennerListItems.filter(x => x.anPageKey === 251);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    adaptiveHeight: false,
    slidesToScroll: 1,
    prevArrow: '',
    nextArrow: '',
    autoplay: true,
    autoplaySpeed: 4000,
  };

  return (
    <div className="slider-banner">
      <div className="block-slider-home-banner">
        <Slider {...settings}>
          {bennerList.map((item, key) => (
            <BannerSliderItem itemData={item} key={key} />
            // <div key={key}>
            //   <img src="/media/banners/500x500.jpg" alt="" />
            // </div>
          ))}
        </Slider>
        {bennerList.length == 0 && <img src={'https://resource.ewe.rs/media/banners/no-image-placeholder-500x500.jpg'} className="img-fluid" alt="Placeholder" title="Image placeholder" />}
      </div>
    </div>
  )
};

export default BlockSlider;