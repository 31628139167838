import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import CatalogSpecialActionFilterItem from '../catalog/CatalogSpecialActionFilterItem';
import { useTranslation } from 'react-i18next';


const FilterSpecialActionItem = ({ checkFilterFunction }) => {

    const [showFilter, setShowFilter] = useState(true);
    const catalogFiltersSpecialAction = useSelector((state) => state.catalogFiltersSpecialAction);
    const { t } = useTranslation('filters');


    const checkFilterFunctionFunc = (e, item2, item, type) => {
        checkFilterFunction(e, item2, item, type);
    };

    return (
        <div style={{ marginBottom: '10px' }}>
            {catalogFiltersSpecialAction.length > 0 && (
                <>
                    <div>
                        <h5 onClick={(e) => setShowFilter(!showFilter)}><span className="filter-title-holder" title="Specijalne akcije">{t('specijalneAkcije')}</span> <span className="toggle-icon-filters-show">
                            {showFilter ? <i className="fas fa-chevron-down"></i> : <i className="fas fa-chevron-right"></i>}
                        </span>
                        </h5>
                        <div className="line-h5-filter"></div>
                    </div>
                    <div className={`filter-list-items ${showFilter ? 'show-filters' : 'hide-filters'}`}>
                        {catalogFiltersSpecialAction.map((item, index) => (
                            <CatalogSpecialActionFilterItem key={index} item={item} checkFilterFunction={checkFilterFunctionFunc} index={index} />
                        ))}
                    </div>
                </>
            )}
        </div>
    )
};

export default FilterSpecialActionItem;