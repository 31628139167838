import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { useDispatch } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';

import { globalStatistic } from "../../redux/actions/otherActions";
import { customLogger } from "../../helperFunctions";

const BlockBannerItem = ({ item, key }) => {

    const [mouseMoved, setMouseMoved] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();
    const minWidth820 = useMediaQuery('(min-width: 820px)');
    // customLogger('other', 'item from BlockBannerItem', item);

    const handleClick = () => {
        if (!mouseMoved) {
            history.push(item.acBannerLink);
            // console.log(item, 'banner clicked')
            dispatch(globalStatistic(item.anBannerOrgId, 'BANNER'));
        }
    };
    var slide = minWidth820 ? item.acBannerImage : item.acFilenameMobile;

    return (
        <div className="banner-block-home-item" key={key}>
            <Link
                onMouseMove={() => setMouseMoved(true)}
                onMouseDown={() => setMouseMoved(false)}
                onMouseUp={() => handleClick()}
            >
                <img src={`https://resource.ewe.rs/media/${slide}`} className="slider-image-home-block" alt={`${item.acSeoDescription}`} title={`${item.acSeoTitle}`} />
                {/* <div className="banner-item-block-image" style={{backgroundImage: `url(http://www.ewe.rs/media/${item.acBannerImage})`}}></div> */}
            </Link>
        </div>
    );

};

export default BlockBannerItem;
