import React from 'react';
import { useHistory } from 'react-router';
import { filtersDataCheck } from '../../functions/filters';
import CatalogFilterTopItemBrandCheckbox from './CatalogFilterTopItemBrandCheckbox';
import OutsideClickHandler from 'react-outside-click-handler';

const CatalogFilterTopItemBrandModal = ({ items, functionCloseModal, functionItemClickCheckbox, typeValue }) => {

  const functionCloseModalFunc = () => {
    functionCloseModal(false);
  }

  const clickFunctionItem = (e, item2, valueItem, type, key) => {
    functionItemClickCheckbox(e, item2, valueItem, type, key);
  }

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        functionCloseModalFunc()
      }}
    >
      <div className={`subfilters-list-content-items active`}>
        <div className="conent-subfilters-holder">
          <ul>
            { items.map((item2, key) => (
              // <li>{item2.acString}</li>
              <CatalogFilterTopItemBrandCheckbox items={items} item2={item2} clickFunctionItem={clickFunctionItem} key={key} typeValue={typeValue} />
            ))}
          </ul>
          <div className="close-top-filter-modal" onClick={(e) => functionCloseModalFunc()}><i className="fas fa-times"></i></div>
        </div>
      </div>
    </OutsideClickHandler>
    
  );
};

export default CatalogFilterTopItemBrandModal;