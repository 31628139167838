export const getDeliveryItems = (state = { items: [] }, action) => {
    switch (action.type) {
        case "SET_DELIVERY_ITEMS":
            return {
                loading: false,
                items: action.payload,
            };
        default:
            return state;
    }
};
export const getReclamationsItems = (state = { items: [] }, action) => {
    switch (action.type) {
        case "SET_RECLAMATION_ITEMS":
            return {
                loading: false,
                items: action.payload,
            };
        default:
            return state;
    }
};
export const getBrendsItems = (state = { items: [] }, action) => {
    switch (action.type) {
        case "SET_BRENDS_ITEMS":
            return {
                loading: false,
                items: action.payload,
            };
        default:
            return state;
    }
};
export const getInvoiceItems = (state = { items: [] }, action) => {
    switch (action.type) {
        case "SET_INVOICE_ITEMS":
            return {
                loading: false,
                items: action.payload,
            };
        default:
            return state;
    }
};
export const getBankAccountItems = (state = { items: [] }, action) => {
    switch (action.type) {
        case "SET_BANK_ACCOUNT_ITEMS":
            return {
                loading: false,
                items: action.payload,
            };
        default:
            return state;
    }
};
export const getReservationItems = (state = { items: [] }, action) => {
    switch (action.type) {
        case "SET_RESERVATION_ITEMS":
            return {
                loading: false,
                items: action.payload,
            };
        default:
            return state;
    }
};
export const getSaleStatisticsItems = (state = { items: [] }, action) => {
    switch (action.type) {
        case "SET_SALE_STATISTICS_ITEMS":
            return {
                loading: false,
                items: action.payload,
            };
        default:
            return state;
    }
};
export const getTransactionItems = (state = { items: [] }, action) => {
    switch (action.type) {
        case "SET_TRANSACTION_ITEMS":
            return {
                loading: false,
                items: action.payload,
            };
        default:
            return state;
    }
};
export const getNewsletterMaterialItems = (state = { items: [] }, action) => {
    switch (action.type) {
        case "SET_NEWSLETTER_MATERIAL_ITEMS":
            return {
                loading: false,
                items: action.payload,
            };
        default:
            return state;
    }
};
export const getExportCatalogItems = (state = { items: [] }, action) => {
    switch (action.type) {
        case "SET_CATALOG_EXPORT_ITEMS_REQUEST":
            return {
                loading: true,
                items: [],
            };
        case "SET_CATALOG_EXPORT_ITEMS":
            return {
                loading: false,
                items: action.payload,
            };
        default:
            return state;
    }
};
export const getBenefitList = (state = { items: [] }, action) => {
    switch (action.type) {
        case "SET_BENEFIT_RANG_LIST":
            return {
                loading: false,
                items: action.payload,
            };
        default:
            return state;
    }
};
export const getBenefitPoinsCustomAlias = (state = { items: [] }, action) => {
    switch (action.type) {
        case "SET_SENCOR_RANG_LIST":
            return {
                loading: false,
                items: action.payload,
            };
        default:
            return state;
    }
};
export const getBenefitOverview = (state = { items: [] }, action) => {
    switch (action.type) {
        case "SET_BENEFIT_OVERVIEW":
            return {
                loading: false,
                items: action.payload,
            };
        default:
            return state;
    }
};
export const getNewsKey = (state = { items: [] }, action) => {
    switch (action.type) {
        case "SET_NEWS_KEY":
            return {
                loading: false,
                items: action.payload,
            };
        default:
            return state;
    }
};
export const getCurrentServerTime = (state = { items: [] }, action) => {
    switch (action.type) {
        case "SET_CURRENT_SERVER_TIME":
            return {
                loading: false,
                items: action.payload,
            };
        default:
            return state;
    }
};
export const postBenefitPoints = (state = { items: [] }, action) => {
    switch (action.type) {
        case "SET_BENEFIT_POINTS":
            return {
                loading: false,
                items: action.payload,
            };
        default:
            return state;
    }
};