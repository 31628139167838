import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { menuMobileShow } from '../../redux/actions/userActions';

import CategorySidebarVirtualItems from './CategorySidebarVirtualItems';

import { removeSpecialChar } from '../../helperFunctions'
import { useTranslation } from 'react-i18next';

const CategorySidebarSubItem = ({ item, setMenuItem }) => {

    const [subnav, setSubnav] = useState(false);
    const dispatch = useDispatch();
    const isDesktop = window.innerWidth > 1020;
    const { t } = useTranslation(['sidebarCategory', 'routes']);

    const showSubnav = () => {
        setSubnav(!subnav);
    }

    const setMobileFunction = () => {
        dispatch(menuMobileShow(false));
        if (!isDesktop) {
            setMenuItem(false)
        }
        dispatch({
            type: 'SET_DEFAULT_URL_FILTERS',
            payload: ""
        });

    }

    // za generisanje sitemap.xml-a - okida se na klik u listi kategorije
    // item.subCategories.map((item2, key2) => {

    //     console.log(`<url>
    //                     <loc>https://www.ewe.rs/katalog/podkategorija/${item2._id}/${removeSpecialChar(item2.acName)}/</loc>
    //                     <changefreq>weekly</changefreq>
    //                     <priority>0.80</priority>
    //                   </url>`);
    // })
    return (
        <>
            {item.subCategories.length > 0 ? (
                <>
                    {item.subCategories.length == 1 && item.virtualCategories.length == 0 ? (
                        <Link className="category-main-no-sub" to={`/${t('routes:katalog')}/podkategorija/${item._id}/${item.acSeoUrl ? removeSpecialChar(item.acSeoUrl) : removeSpecialChar(item.acName)}`} onClick={(e) => setMobileFunction()}>
                            <li className="subcategory-menu-item" key={item.anCategory}>{item.acName}</li>
                        </Link>
                    ) : item.subCategories.length == 1 && item.virtualCategories.length > 0 ? (
                        <li className={`subcategory-menu-item ${subnav ? 'active' : ''}`} onClick={item.virtualCategories && showSubnav} key={item.anCategory}>
                            <div className="sidebar-submenu-holder">
                                {item.acName}
                                <span className="icon-expand-menu-items">
                                    {
                                        item.virtualCategories.length > 0 && subnav
                                            ? <FontAwesomeIcon className="category-expanded-li" icon={faChevronDown} />
                                            : item.virtualCategories.length > 0
                                                ? <FontAwesomeIcon icon={faChevronRight} color="#eae8e9" />
                                                : null
                                    }
                                </span>
                            </div>
                        </li>
                    ) : (
                        <li className={`subcategory-menu-item ${subnav ? 'active' : ''}`} onClick={item.subCategories && showSubnav} key={item.anCategory}>
                            <div className="sidebar-submenu-holder">
                                {item.acName}
                                <span className="icon-expand-menu-items">
                                    {
                                        item.subCategories.length > 0 && subnav
                                            ? <FontAwesomeIcon className="category-expanded-li" icon={faChevronDown} />
                                            : item.subCategories.length > 0
                                                ? <FontAwesomeIcon icon={faChevronRight} color="#eae8e9" />
                                                : null
                                    }
                                </span>
                            </div>
                        </li>
                    )}
                </>
            ) : (
                    <Link className="category-main-no-sub" to={`/${t('routes:katalog')}/podkategorija/${item._id}/${item.acSeoUrl ? removeSpecialChar(item.acSeoUrl) : removeSpecialChar(item.acName)}`} onClick={(e) => setMobileFunction()}>
                    <li className="subcategory-menu-item" key={item.anCategory}>{item.acName}</li>
                </Link>
            )}

            {subnav && item.subCategories.length > 0 ? (
                <>
                    {item.subCategories.length > 1 && item.virtualCategories.length == 0 ? (
                        <ul className="subsubmenu-items">
                            {item.subCategories.map((item2, key2) => (
                                <Link to={`/${t('routes:katalog')}/podpodkategorija/${item2._id}/${item2.acSeoUrl ? removeSpecialChar(item2.acSeoUrl) : removeSpecialChar(item2.acName)}`} key={key2} onClick={(e) => setMobileFunction()}>
                                    <li key={item2.anCategory}>{item2.acName}</li>
                                </Link>
                            ))}
                            <Link to={`/${t('routes:katalog')}/podkategorija/${item._id}/${item.acSeoUrl ? removeSpecialChar(item.acSeoUrl) : removeSpecialChar(item.acName)}`} key={item._id} onClick={(e) => setMobileFunction()}>
                                <li style={{ color: 'green' }} key={item.anCategory}>{t('sidebarCategory:prikaziSve')}</li>
                            </Link>
                        </ul>
                    ) : item.subCategories.length == 1 && item.virtualCategories.length > 0 ? (
                        <ul className="subsubmenu-items">
                            {item.virtualCategories.map((item2, key2) => (
                                <CategorySidebarVirtualItems item={item2} key={key2} setMenuItem={setMenuItem} />
                            ))}
                                <Link to={`/${t('routes:katalog')}/podkategorija/${item._id}/${item.acSeoUrl ? removeSpecialChar(item.acSeoUrl) : removeSpecialChar(item.acName)}`} key={item._id} onClick={(e) => setMobileFunction()}>
                                <li style={{ color: 'green' }} key={item.anCategory}>{t('sidebarCategory:prikaziSve')}</li>
                            </Link>
                        </ul>
                    ) : (
                        <ul className="subsubmenu-items">
                            {item.subCategories.map((item2, key2) => (
                                <Link to={`/${t('routes:katalog')}/podpodkategorija/${item2._id}/${item2.acSeoUrl ? removeSpecialChar(item2.acSeoUrl) : removeSpecialChar(item2.acName)}`} key={key2} onClick={(e) => setMobileFunction()}>
                                    <li key={item2.anCategory}>{item2.acName}</li>
                                </Link>
                            ))}
                            {item.virtualCategories.map((item2, key2) => (
                                <CategorySidebarVirtualItems item={item2} key={key2} setMenuItem={setMenuItem} />
                            ))}
                            <Link to={`/${t('routes:katalog')}/podkategorija/${item._id}/${item.acSeoUrl ? removeSpecialChar(item.acSeoUrl) : removeSpecialChar(item.acName)}`} key={item._id} onClick={(e) => setMobileFunction()}>
                                <li style={{ color: 'green' }} key={item.anCategory}>{t('sidebarCategory:prikaziSve')}</li>
                            </Link>
                        </ul>
                    )}
                </>
            ) :
                null
            }

            {/* {subnav && item.subCategories[0].virtualCategories.length > 0 ? (
                <ul className="submenu-items submenu-items-virtual">
                    {item.subCategories[0].virtualCategories.map((item2, key) => (
                        <CategorySidebarVirtualSubItem setMenuItem={setMenuItem} item={item2} key={key} />
                    ))}
                </ul>
            ) : null} */}
        </>
    )
};

export default CategorySidebarSubItem;