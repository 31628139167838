export function filtersDataCheck(checked, item2, item, type, history, searchHistory, slider=false){

  const obj = {};
  var anAttribute = item.anAttributeId;
  var acAttributeURLName = item.acAttributeURLName;
  var anValue = item2.anAttributeValue;
  var acUrlValue = item2.acUrlValue;
  
  if(type === 'brand') {
      anAttribute = 0;
      anValue = item2.anId;
      acAttributeURLName = 'brend';
      var acUrlValueTmp = item2.acString.toLowerCase();
      acUrlValue = acUrlValueTmp.replace(' ', '-');
  }
  if(type === 'akcija') {
      anAttribute = 1;
      anValue = item2.anId;
      acAttributeURLName = 'akcija';
      var acUrlValueTmp = item2.acString.toLowerCase();
      acUrlValue = acUrlValueTmp.replace(' ', '-');
  }

  
  if(checked === true) {
      var kvp = searchHistory.substr(1).split('&');
      if (kvp == '') {
          history.push('?' + acAttributeURLName+'_'+anAttribute+'='+acUrlValue+'_'+anValue);
      }else {
          var i = kvp.length;
          var x;
          while (i--) {
              x = kvp[i].split('=');
              if (x[0] == acAttributeURLName+'_'+anAttribute) {
                  if(x[1] == acUrlValue+'_'+anValue){
                      break;
                  }else{
                      kvp[kvp.length] = [acAttributeURLName+'_'+anAttribute, acUrlValue+'_'+anValue].join('=');

                      break;
                  }
              }
          }
          if (i < 0) { 
              kvp[kvp.length] = [acAttributeURLName+'_'+anAttribute, acUrlValue+'_'+anValue].join('='); 
          }
          kvp = kvp.join('&');
          history.push('?'+kvp);
      }
      
  } else {
      var kvp = searchHistory.substr(1).split('&');

      if (kvp != '') {
          var i = kvp.length;
          var x;
          while (i--) {
                x = kvp[i].split('=');

                if(x[0] == 'cena_0' && slider == true) {
                    var acAttributeURLName = 'cena_0';
                    var acUrlValue = item2.acAttributeValue.split(' - ')[0];
                    var anValue = item2.acAttributeValue.split(' - ')[1];

                    if (x[0] == acAttributeURLName) {
                        var index = kvp.indexOf(kvp[i]);
                        if (index > -1) {
                            kvp.splice(index, 1);
                        }
                        break;
                    }
                } else {

                    if (x[0] == acAttributeURLName+'_'+anAttribute) {
                        if(x[1] == acUrlValue+'_'+anValue){
                            var index = kvp.indexOf(kvp[i]);
                            if (index > -1) {
                                kvp.splice(index, 1);
                            }
                            break;
                        }
                    }
                }


          }
          
          if (i < 0) { 
              kvp[kvp.length] = [acAttributeURLName+'_'+anAttribute, acUrlValue+'_'+anValue].join('='); 
          }

          kvp = kvp.join('&');
          history.push('?'+kvp);
      }
  }
};