export const cartReducer = (state = { cartItems: [] }, action) => {
    switch (action.type) {
        case 'CARD_ADD_ITEM':
            const item = action.payload;
            const existItem = state.cartItems.find((x) => x._anId === item._anId);

            if (existItem) {
                return {
                    ...state,
                    cartItems: state.cartItems.map(x => x.product === existItem.product ? item : x)
                }
            } else {
                return {
                    ...state, cartItems: [...state.cartItems, item]
                }
            }
        case 'CARD_REMOVE_ITEM':
            return { ...state, cartItems: state.cartItems.filter(x => x._anId !== action.payload) };
        case 'SET_CARD_ITEMS':
            return { ...state, cartItems: action.payload }
        default:
            return state;
    };
};

export const getCartListFunction = (state = { items: [] }, action) => {
    switch (action.type) {
        case 'SET_CART_LIST':
            return {
                items: action.payload
            };
        default:
            return state;
    };
};

export const getActiveCartFunction = (state = [[]], action) => {
    switch (action.type) {
        case 'SET_ACTIVE_CART':
            return action.payload;
        default:
            return state;
    };
};

export const getCartItemsList = (state = { items: [] }, action) => {
    switch (action.type) {
        case 'SET_CART_ITEMS_LIST':
            return {
                items: action.payload
            };
        default:
            return state;
    };
};

export const getDeliveryCity = (state = { items: [] }, action) => {
    switch (action.type) {
        case 'SET_DELIVERY_CITY':
            return {
                items: action.payload
            };
        default:
            return state;
    };
};

export const getDeliveryTime = (state = { items: [] }, action) => {
    switch (action.type) {
        case 'SET_DELIVERY_TIME':
            return {
                items: action.payload
            };
        default:
            return state;
    };
};

export const getCartComplete = (state = 0, action) => {
    switch (action.type) {
        case 'SET_CART_COMPLETE':
            return action.payload;
        default:
            return state;
    };
};

export const getVoucherListsFunction = (state = { items: [] }, action) => {
    switch (action.type) {
        case 'SET_VOUCHER_LIST':
            return {
                items: action.payload.items,
                loading: action.payload.loading
            };
        default:
            return state;
    };
};