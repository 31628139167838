import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import IframeResizer from 'iframe-resizer-react';

import PageHelmet from '../components/helmet/PageHelmet';
import { useDispatch } from 'react-redux';
import { globalStatistic } from '../redux/actions/otherActions';
import { getPageSeo } from '../redux/actions/pageActions';

const CustomPages = () => {

    const { id } = useParams();

    const dispatch = useDispatch();
    const [pageSeoData, setPageSeoData] = useState({});
    const currentUrl = window.location.href;

    useEffect(() => {
        dispatch(getPageSeo(parseInt(id))).then(res => {
            if (res) {
                setPageSeoData(res);
            }
        });
        dispatch(globalStatistic(id, 'PAGE'));
    }, [])

    return (
        <>
            {Object.keys(pageSeoData).length !== 0 &&
                <PageHelmet
                    title={pageSeoData ? `${pageSeoData.acSeoTitle}` : "Ewe Comp"}
                    description={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    keywords={pageSeoData ? `${pageSeoData.acSeoKeywords}` : ""}
                    ogDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    ogTitle={pageSeoData ? `${pageSeoData.acSeoTitle}` : ""}
                    ogUrl={currentUrl}
                    ogImg="https://www.ewe.rs/media/ewe-og-img.jpg"
                    ogType="product"
                    twitterDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                />
            }
            <IframeResizer
                src={`https://resource.ewe.rs/media/pages/${id}/index.php`}
                style={{ minWidth: '1px', width: "100%" }}
            />
        </>
    )
};

export default CustomPages;