import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import FilterItemCheckbox from './FilterItemCheckbox';
import { useTranslation } from 'react-i18next';


const FilterItem = ({ item, numRow, checkFilterFunction, filters }) => {

    const dispatch = useDispatch();
    const [showFilter, setShowFilter] = useState(false);
    const [subFilter, setSubFilter] = useState(false);
    const { t } = useTranslation('filters');

    useEffect(() => {
        if (numRow < 4) {
            setShowFilter(true);
        }
    }, []);

    const onClickCheckboxFilterFunction = (e, item2, item, type, idx) => {
        checkFilterFunction(e, item2, item, type, idx);
    };

    return (
        <div style={{ marginBottom: '10px' }}>
            <div>
                <h5 onClick={(e) => setShowFilter(!showFilter)}><span className="filter-title-holder" title={item.acAttributeName}>{item.acAttributeName}</span> <span className="toggle-icon-filters-show">
                    {showFilter ? <i className="fas fa-chevron-down"></i> : <i className="fas fa-chevron-right"></i>}
                </span>
                </h5>
                <div className="line-h5-filter"></div>
            </div>
            <div className={`filter-list-items ${showFilter ? 'show-filters' : 'hide-filters'}`}>
                {item.attributeValueList.map((item2, index) => {
                    return (
                        <div key={index}>
                            {item2.anProductCnt > 0 &&
                                <FilterItemCheckbox
                                    filters={filters}
                                    index={index}
                                    subFilter={subFilter}
                                    item={item}
                                    item2={item2}
                                    onClickCheckboxFilter={onClickCheckboxFilterFunction}
                                />
                            }
                        </div>
                    )
                })}
                {item.attributeValueList.length > 7 && (
                    <div className="more-filter-load" onClick={(e) => setSubFilter(!subFilter)}>
                        {subFilter ? <span><i className="fas fa-chevron-up" style={{ fontSize: '11px' }}></i> {t('prikaziteManje')}</span> : <span><i className="fas fa-chevron-down" style={{ fontSize: '11px' }}></i> {t('prikaziteVise')}</span>}
                    </div>
                )}
            </div>
        </div>
    )
};

export default FilterItem;