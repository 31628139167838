import React from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { SetVatFunction } from '../redux/actions/userActions';

import CurrencyMenuItem from '../components/other/CurrencyMenuItem';
import DashboardBoxInfoItem from '../components/menuModal/DashboardBoxInfoItem';
import DashboardBoxDeliveryItem from '../components/menuModal/DashboardBoxDeliveryItem';
import LoyaltyBox from '../components/menuModal/LoyaltyBox';
import { useTranslation } from 'react-i18next';

const CustomMenuPage = () => {

    const { id } = useParams();
    const dispatch = useDispatch();
    const menuItems = useSelector((state) => state.menuItems);
    const menuListItems = menuItems.filter(x => x.position == 1);
    var menuListItemsMain = [];
    if (menuListItems.length > 0) {
        menuListItemsMain = menuListItems[0].menu.filter(x => x.anMenuKey == id);
    }

    const getCurrencyItems = useSelector((state) => state.getCurrencyItems);
    const activeVat = useSelector((state) => state.activeVat.item);
    const activeCurrency = useSelector((state) => state.activeCurrency);
    const getUserInfo = useSelector((state) => state.userInfo.userInfo);

    const location = useLocation();
    const { t } = useTranslation('customMenuPage');

    const onChangeFunction = (e, type) => {
        dispatch(SetVatFunction(e.target.value, location));
    };

    return (
        <div className="container-content container-custom-page-content">
            <div className="container-fluid">
                <div className={`row dashboard-mobile-background`}>
                    {menuListItemsMain.length > 0 && (
                        <div className="col-12">
                            <div className="dashboard-menu-header menu-modal-item-header">
                                <div className="dashboard-title-menu">
                                    <i className={menuListItemsMain[0].acIcon} style={{ color: menuListItemsMain[0].acIconColor }}></i> <span>{menuListItemsMain[0].acMenu}</span>
                                </div>
                            </div>
                            {menuListItemsMain[0].acMenu === 'DASHBOARD' && (
                                <>
                                    <div className="dashboard-value-item-2">
                                        <div className="dashboard-item-radio-name">
                                            {t('VALUTA')}
                                        </div>
                                        <div className="dashboard-item-radio-buttons">
                                            {getCurrencyItems.map((item, key) => (
                                                <CurrencyMenuItem key={key} item={item} isPriceInEur={activeCurrency._id} />
                                            ))}
                                        </div>
                                    </div>
                                    <div className="dashboard-value-price">
                                        <div className="dashboard-item-radio-name">
                                            {t('ceneIpdv')}
                                        </div>
                                        <div className="dashboard-item-radio-buttons">
                                            <div className="form-check form-check-inline">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="priceVat"
                                                    id="priceVat1"
                                                    defaultValue="1"
                                                    defaultChecked={activeVat == 1 && 'checked'}
                                                    onChange={(e) => onChangeFunction(e)}
                                                />
                                                <label className="form-check-label" htmlFor="priceVat1">DA</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="priceVat"
                                                    id="priceVat2"
                                                    defaultValue="0"
                                                    defaultChecked={activeVat == 0 && 'checked'}
                                                    onChange={(e) => onChangeFunction(e)}
                                                />
                                                <label className="form-check-label" htmlFor="priceVat2">NE</label>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}

                            {Object.keys(menuListItemsMain[0]).length !== 0 && menuListItemsMain[0].tempMenuList.map((item, key) => (
                                <>
                                    {item.anMenuBlockGroupId === 1 ?
                                        <div className="dashboard-item-content" key={key}>
                                            <div className="dashboard-block-content">
                                                {item.tmbiList.map((item2) => (
                                                    <>
                                                        {item2.anMenuBlockTypeId === 1 && <DashboardBoxInfoItem data={item2} user={getUserInfo} />}
                                                        {item2.anMenuBlockTypeId === 2 && <DashboardBoxInfoItem data={item2} user={getUserInfo} />}
                                                        {item2.anMenuBlockTypeId === 3 && <DashboardBoxDeliveryItem data={item2} user={getUserInfo} />}
                                                    </>
                                                ))}
                                            </div>
                                        </div>
                                        : item.anMenuBlockGroupId === 2 &&
                                        <LoyaltyBox data={item} />
                                    }
                                </>
                            ))}

                            {Object.keys(menuListItemsMain[0]).length !== 0 && menuListItemsMain[0].subMenu.length > 0 && (

                                <>
                                    <div className="menu-modal-list-links">
                                        <ul>

                                            {menuListItemsMain[0].subMenu.map((item, key) => (

                                                <Link to={`${item.acLink}`}>
                                                    {item.acIcon ?
                                                        <li>
                                                            <i className={item.acIcon}></i> &nbsp; {item.acMenu}
                                                        </li>
                                                        : <li>
                                                            <i className="fas fa-angle-right"></i> &nbsp; {item.acMenu}
                                                        </li>
                                                    }
                                                </Link>
                                            ))}
                                        </ul>
                                    </div>
                                </>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CustomMenuPage;