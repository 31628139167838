import React from 'react';
import { Link } from 'react-router-dom';
import ReactFullpage from '@fullpage/react-fullpage';
import useMediaQuery from '@mui/material/useMediaQuery';
import { connect } from 'react-redux';

import TopSidebar from '../components/TopSidebar';
import Header from '../components/Header';
import PageHelmet from '../components/helmet/PageHelmet';

import globalVariable from '../global';
import { getPageSeo } from '../redux/actions/pageActions';
import { withTranslation } from 'react-i18next';

import '../intro.css';
class IntroBrandsPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = ({
            coverRed: '',
            coverWhite: '',
            coverGrey: '',
            satoHolder: '',
            satoTextHolder: '',
            pageSeoData: {},
            matches: window.matchMedia("(max-width: 450px)").matches
        });
    }

    componentDidMount() {
        const handler = e => this.setState({ matches: e.matches });
        window.matchMedia("(max-width: 450px)").addEventListener('change', handler);
        const { dispatch } = this.props;
        dispatch(getPageSeo(777)).then(res => {
            if (res) {
                this.setPageSeoData(res);
            }
        });
    }

    setPageSeoData = (data) => {
        this.setState({ pageSeoData: data });
    };

    afterLoad(origin, destination, direction, anchors) {

        if (destination.anchor === 'Friends') {
            this.setState({
                coverRed: 'cover-red-ani',
                coverWhite: 'cover-grey-ani',
                coverGrey: 'cover-white-ani',
                satoHolder: 'sato-holder-ani',
                satoTextHolder: 'sato-text-holder-ani',
            });
        }
        else if (destination.anchor === 'Maps') {

            this.setState({
                mapText: 'map-text-ani',
                mapMap: 'map-map-ani',
            });
        }

    }
    render() {
        const isDesktop = window.innerWidth > 1020;
        const { pageSeoData } = this.state;
        const { t } = this.props;
        return (
            <>
                {Object.keys(pageSeoData).length !== 0 &&
                    <PageHelmet
                        title={pageSeoData ? `${pageSeoData.acSeoTitle}` : "Ewe Comp"}
                        description={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                        keywords={pageSeoData ? `${pageSeoData.acSeoKeywords}` : ""}
                        ogDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                        ogTitle={pageSeoData ? `${pageSeoData.acSeoTitle}` : ""}
                        ogUrl={pageSeoData ? `https://ewe.rs/${pageSeoData.acSeoUrl}` : ""}
                        ogImg="https://www.ewe.rs/media/ewe-og-img.jpg"
                        twitterDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    />
                }
                {!isDesktop ? (
                    <Header />
                ) : (
                    <TopSidebar />
                )}
                <ReactFullpage
                    scrollingSpeed={1000}
                    lockAnchors={false}
                    anchors={['Friends', 'Brands-1', 'Brands-2', 'Brands-3', 'Brands-4', 'Brands-5', 'Brands-6', 'Brands-7', 'Brands-8']}
                    navigation={true}
                    navigationPosition={'right'}
                    navigationTooltips={['Friends', 'Brands-1', 'Brands-2', 'Brands-3', 'Brands-4', 'Brands-5', 'Brands-6', 'Brands-7', 'Brands-8']}
                    showActiveTooltip={false}
                    slidesNavigation={false}
                    slidesNavPosition={'bottom'}
                    sectionsColor={['#ffffff', '#ebebeb', '#ebebeb', '#ebebeb', '#ebebeb', '#ebebeb']}
                    callbacks={["afterLoad"]}
                    scrollOverflow={true}
                    afterLoad={this.afterLoad.bind(this)}
                    render={({ state, fullpageApi }) => {
                        return (
                            <div id="fullpage-wrapper">
                                <div className="section">
                                    <div className="page-holder sato-page">
                                        <div className="page-alignment-holder">
                                            <div className={`sato-holder ${this.state.satoHolder}`}></div>
                                            <div className={`sato-text-holder ${this.state.satoTextHolder}`}>
                                                <h2>{t('naslov1')}</h2>
                                                <p>{t('podnaslov1')}</p>
                                                <div className="partner-signature">
                                                    <div>Roger Oka</div>
                                                    <div>Chieftec Head Of Global Sales</div>
                                                </div>
                                                <div className="clearfix"></div>
                                                <a href={`/${t('routes:brendovi')}#Brands-1`} className="btn btn-sato">
                                                    {t('dugme')}
                                                </a>
                                            </div>
                                        </div>
                                        <div className={`sato-page-cover cover-white ${this.state.coverWhite}`}></div>
                                        <div className={`sato-page-cover cover-grey ${this.state.coverGrey}`}></div>
                                        <div className={`sato-page-cover cover-red ${this.state.coverRed}`}></div>
                                    </div>
                                </div>
                                <div className="section">
                                    <div className="page-holder">
                                        <div className="brands-alignment-holder">
                                            <div className="about-item">
                                                <div className="about-logo-holder">
                                                    <div>
                                                        <img className="img-responsive img-fluid" src={`${globalVariable.domainName}/media/intro/1.-Dahua-Logo.png`} alt='Dahua' />
                                                    </div>
                                                </div>
                                                <div className="about-text-holder">
                                                    <p>{t('citat1')}</p>
                                                    <div className="signature">
                                                        <div>Regional Sales Manager</div>
                                                        <div>Ales Dejak</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="about-item">
                                                <div className="about-logo-holder">
                                                    <div>
                                                        <img className="img-responsive img-fluid" src={`${globalVariable.domainName}/media/intro/2.-infosec-logo.png`} alt="infosec" />
                                                    </div>
                                                </div>
                                                <div className="about-text-holder">
                                                    <p>{t('citat2')}</p>
                                                    <div className="signature">
                                                        <div>Export Manager</div>
                                                        <div>Olivier Poteaux</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="about-item">
                                                <div className="about-logo-holder">
                                                    <div>
                                                        <img className="img-responsive img-fluid" src={`${globalVariable.domainName}/media/intro/3.-spire-logo.png`} alt="spire" />
                                                    </div>
                                                </div>
                                                <div className="about-text-holder">
                                                    <p>{t('citat3')}</p>
                                                    <div className="signature">
                                                        <div>Rolf Borrenbergs</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="about-item">
                                                <div className="about-logo-holder">
                                                    <div>
                                                        <img className="img-responsive img-fluid" src={`${globalVariable.domainName}/media/intro/4.-vega-logo.png`} alt="vega" />
                                                    </div>
                                                </div>
                                                <div className="about-text-holder">
                                                    <p>{t('citat4')}</p>
                                                    <div className="signature">
                                                        <div>Sales Specialist</div>
                                                        <div>Sandi Suvajac</div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="section">
                                    <div className="page-holder">
                                        <div className="brands-alignment-holder">
                                            <div className="about-item about-big-text-item">
                                                <div className="about-logo-holder">
                                                    <div>
                                                        <img className="img-responsive img-fluid" src={`${globalVariable.domainName}/media/intro/6.-AOC-logo.png`} alt="AOC" />
                                                    </div>
                                                </div>
                                                <div className="about-text-holder">
                                                    <p>{t('citat5')}</p>
                                                    <div className="signature">
                                                        <div>Sales Director – South Eastern Europe</div>
                                                        <div>Cristian Bucur</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="about-item about-big-text-item">
                                                <div className="about-logo-holder">
                                                    <div>
                                                        <img className="img-responsive img-fluid" src={`${globalVariable.domainName}/media/intro/7.-Apacer-logo.png`} alt="Apacer" />
                                                    </div>
                                                </div>
                                                <div className="about-text-holder">
                                                    <p>{t('citat6')}</p>
                                                    <div className="signature">
                                                        <div>Sales Department</div>
                                                        <div>Anita Wang</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="about-item about-small-text-item">
                                                <div className="about-logo-holder">
                                                    <div>
                                                        <img className="img-responsive img-fluid" src={`${globalVariable.domainName}/media/intro/8.-Kingston-logo.png`} alt="Kingston" />
                                                    </div>
                                                </div>
                                                <div className="about-text-holder">
                                                    <p>{t('citat7')}</p>
                                                    <div className="signature">
                                                        <div>BDM - Serbia, Croatia, B&H, Macedonia and Montenegro</div>
                                                        <div>Dejan Skuletic</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="about-item about-big-text-item">
                                                <div className="about-logo-holder">
                                                    <div>
                                                        <img className="img-responsive img-fluid" src={`${globalVariable.domainName}/media/intro/9.-Targus-logo.png`} alt="Targus" />
                                                    </div>
                                                </div>
                                                <div className="about-text-holder">
                                                    <p>{t('citat8')}</p>
                                                    <div className="signature">
                                                        <div>Sales Director CEE, Baltics, Israel, Turkey & CIS</div>
                                                        <div>Dainius Urbanavicius</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="section">
                                    <div className="page-holder">
                                        <div className="brands-alignment-holder">
                                            <div className="about-item">
                                                <div className="about-logo-holder">
                                                    <div>
                                                        <img className="img-responsive img-fluid" src={`${globalVariable.domainName}/media/intro/10.-Dell-Logo.png`} alt="Dell" />
                                                    </div>
                                                </div>
                                                <div className="about-text-holder">
                                                    <p>{t('citat9')}</p>
                                                    <div className="signature">
                                                        <div>Regional Sales Director Volume Distribution</div>
                                                        <div>David Spruyt</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="about-item">
                                                <div className="about-logo-holder">
                                                    <div>
                                                        <img className="img-responsive img-fluid" src={`${globalVariable.domainName}/media/intro/11.-Thecus-logo.png`} alt="Thecus" />
                                                    </div>
                                                </div>
                                                <div className="about-text-holder">
                                                    <p>{t('citat10')}</p>
                                                </div>
                                            </div>
                                            <div className="about-item">
                                                <div className="about-logo-holder">
                                                    <div>
                                                        <img className="img-responsive img-fluid" src={`${globalVariable.domainName}/media/intro/13.-msi-Logo.png`} alt="msi" />
                                                    </div>
                                                </div>
                                                <div className="about-text-holder">
                                                    <p>{t('citat11')}</p>
                                                    <div className="signature">
                                                        <div>Regional Manager for Balkans</div>
                                                        <div>Aleksandar Blagojevic</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="about-item">
                                                <div className="about-logo-holder">
                                                    <div>
                                                        <img className="img-responsive img-fluid" src={`${globalVariable.domainName}/media/intro/12.-Njoy-logo.png`} alt="Njoy" />
                                                    </div>
                                                </div>
                                                <div className="about-text-holder">
                                                    <p>{t('citat12')}</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="section">
                                    <div className="page-holder">
                                        <div className="brands-alignment-holder">
                                            <div className="about-item">
                                                <div className="about-logo-holder">
                                                    <div>
                                                        <img className="img-responsive img-fluid" src={`${globalVariable.domainName}/media/intro/15.-d-link-logo.png`} alt="d-link" />
                                                    </div>
                                                </div>
                                                <div className="about-text-holder">
                                                    <p>{t('citat13')}</p>
                                                    <div className="signature">
                                                        <div>Country Manager Adria</div>
                                                        <div>Ivo Pavicic</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="about-item">
                                                <div className="about-logo-holder">
                                                    <div>
                                                        <img className="img-responsive img-fluid" src={`${globalVariable.domainName}/media/intro/18.-EnergySystem-Logo.png`} alt="Energy System" />
                                                    </div>
                                                </div>
                                                <div className="about-text-holder">
                                                    <p>{t('citat14')}</p>
                                                    <div className="signature">
                                                        <div>Sales Marketing Manager</div>
                                                        <div>Vicenta Antón Gregori</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="about-item">
                                                <div className="about-logo-holder">
                                                    <div>
                                                        <img className="img-responsive img-fluid" src={`${globalVariable.domainName}/media/intro/16.-chieftec-logo.png`} alt="chieftec" />
                                                    </div>
                                                </div>
                                                <div className="about-text-holder">
                                                    <p>{t('citat15')}</p>
                                                    <div className="signature">
                                                        <div>Head of Global Sales</div>
                                                        <div>Roger Oka</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="about-item">
                                                <div className="about-logo-holder">
                                                    <div>
                                                        <img className="img-responsive img-fluid" src={`${globalVariable.domainName}/media/intro/20.-BenQ-logo.png`} alt="BenQ" />
                                                    </div>
                                                </div>
                                                <div className="about-text-holder">
                                                    <p>{t('citat16')}</p>
                                                    <div className="signature">
                                                        <div>Sales manager for CEE region</div>
                                                        <div>Peter Oros</div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="section">
                                    <div className="page-holder">
                                        <div className="brands-alignment-holder">
                                            <div className="about-item">
                                                <div className="about-logo-holder">
                                                    <div>
                                                        <img className="img-responsive img-fluid" src={`${globalVariable.domainName}/media/intro/14.-acer-logo.png`} alt="acer" />
                                                    </div>
                                                </div>
                                                <div className="about-text-holder">
                                                    <p>{t('citat17')}</p>
                                                    <div className="signature">
                                                        <div>Business Manager - Notebook</div>
                                                        <div>Milan Radic</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="about-item">
                                                <div className="about-logo-holder">
                                                    <div>
                                                        <img className="img-responsive img-fluid" src={`${globalVariable.domainName}/media/intro/19.-Cooler_Master-Logo.png`} alt="Cooler Master" />
                                                    </div>
                                                </div>
                                                <div className="about-text-holder">
                                                    <p>{t('citat18')}</p>
                                                    <div className="signature">
                                                        <div>Regional Manager</div>
                                                        <div>Tijana Milosavljevic</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="about-item">
                                                <div className="about-logo-holder">
                                                    <div>
                                                        <img className="img-responsive img-fluid" src={`${globalVariable.domainName}/media/intro/17.-Sencor-logo.png`} alt="Sencor" />
                                                    </div>
                                                </div>
                                                <div className="about-text-holder">
                                                    <p>{t('citat19')}</p>
                                                    <div className="signature">
                                                        <div>Fast (Sencor & Yenkee)</div>
                                                        <div>Dejan Stankovic</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="about-item">
                                                <div className="about-logo-holder">
                                                    <div>
                                                        <img className="img-responsive img-fluid" src={`${globalVariable.domainName}/media/intro/21.-Epson-logo.png`} alt="Epson" />
                                                    </div>
                                                </div>
                                                <div className="about-text-holder">
                                                    <p>&quot;{t('citat20')}&quot;</p>
                                                    <div className="signature">
                                                        <div>Area Sales Manager</div>
                                                        <div>Ivana Kokalovic</div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="section">
                                    <div className="page-holder">
                                        <div className="brands-alignment-holder">
                                            <div className="about-item">
                                                <div className="about-logo-holder">
                                                    <div>
                                                        <img className="img-responsive img-fluid" src={`${globalVariable.domainName}/media/intro/22.-amd-logo.png`} alt="amd" />
                                                    </div>
                                                </div>
                                                <div className="about-text-holder">
                                                    <p>&quot;{t('citat21')}&quot;</p>
                                                    <div className="signature">
                                                        <div>Adriatic Sales Manager</div>
                                                        <div>Viktorija Baralic</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="about-item">
                                                <div className="about-logo-holder">
                                                    <div>
                                                        <img className="img-responsive img-fluid" src={`${globalVariable.domainName}/media/intro/5.-A4Tech-logo.png`} alt="A4Tech" />
                                                    </div>
                                                </div>
                                                <div className="about-text-holder">
                                                    <p>&quot;{t('citat22')}&quot;</p>
                                                    <div className="signature">
                                                        <div>Sales Representative</div>
                                                        <div>Allen</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="about-item">
                                                <div className="about-logo-holder">
                                                    <div>
                                                        <img className="img-responsive img-fluid" src={`${globalVariable.domainName}/media/intro/23.-sharp-logo.png`} alt="sharp" />
                                                    </div>
                                                </div>
                                                <div className="about-text-holder">
                                                    <p>&quot;{t('citat23')}&quot;</p>
                                                    <div className="signature">
                                                        <div>Vice President Marketing & Sales</div>
                                                        <div>Sascha Lange</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="about-item">
                                                <div className="about-logo-holder">
                                                    <div>
                                                        <img className="img-responsive img-fluid" src={`${globalVariable.domainName}/media/intro/gigabyte-logo.png`} alt="gigabyte" />
                                                    </div>
                                                </div>
                                                <div className="about-text-holder">
                                                    <p>&quot;{t('citat24')}&quot;</p>
                                                    <div className="signature">
                                                        <div>Direktor prodaje za region Istočne Evrope</div>
                                                        <div>Wu Fan-Pin</div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="section">
                                    <div className="page-holder">
                                        <div className="brands-alignment-holder">
                                            <div className="about-item about-small-text-item">
                                                <div className="about-logo-holder">
                                                    <div>
                                                        <img className="img-responsive img-fluid" src={`${globalVariable.domainName}/media/intro/Beko-01.png`} alt="Beko" />
                                                    </div>
                                                </div>
                                                <div className="about-text-holder">
                                                    <p>&quot;{t('citat25')}&quot;</p>
                                                    <div className="signature">
                                                        <div className="beko-signature">Beko Balkans Team</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="about-item about-big-text-item">
                                                <div className="about-logo-holder">
                                                    <div>
                                                        <img className="img-responsive img-fluid" src={`${globalVariable.domainName}/media/intro/nzxt-logo.png`} alt="nzxt" />
                                                    </div>
                                                </div>
                                                <div className="about-text-holder">
                                                    <p>&quot;{t('citat26')}&quot;</p>
                                                    <div className="signature">
                                                        <div>Regional Manager</div>
                                                        <div>Jovan Milicevic</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="about-item about-big-text-item">
                                                <div className="about-logo-holder">
                                                    <div>
                                                        <img className="img-responsive img-fluid" src={`${globalVariable.domainName}/media/intro/fantom.png`} alt="fantom" />
                                                    </div>
                                                </div>
                                                <div className="about-text-holder">
                                                    <p>&quot;{t('citat27')}&quot;</p>
                                                    <div className="signature">
                                                        <div>Export Area Manager</div>
                                                        <div>Dogukan Bozkurt</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="about-item about-big-text-item">
                                                <div className="about-logo-holder">
                                                    <div>
                                                        <img className="img-responsive img-fluid" src={`${globalVariable.domainName}/media/intro/whirpool.png`} alt="whirpool" />
                                                    </div>
                                                </div>
                                                <div className="about-text-holder">
                                                    <p>&quot;{t('citat28')}&quot;</p>
                                                    <div className="signature">
                                                        <div>COUNTRY MANAGER, SOUTH EAST EUROPE</div>
                                                        <div>Vadim Khoroshun</div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="section">
                                    <div className="page-holder">
                                        <div className="brands-alignment-holder">
                                            <div className="about-item">
                                                <div className="about-logo-holder">
                                                    <div>
                                                        <img className="img-responsive img-fluid" src={`${globalVariable.domainName}/media/intro/adata.png`} alt="adata" />
                                                    </div>
                                                </div>
                                                <div className="about-text-holder">
                                                    <p>&quot;{t('citat29')}&quot;</p>
                                                    <div className="signature">
                                                        <div>Regional Sales Manager</div>
                                                        <div>Kosta Todosijevic</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="about-item">
                                                <div className="about-logo-holder">
                                                    <div>
                                                        <img className="img-responsive img-fluid" src={`${globalVariable.domainName}/media/intro/kyocera.png`} alt="kyocera" />
                                                    </div>
                                                </div>
                                                <div className="about-text-holder">
                                                    <p>&quot;{t('citat30')}&quot;</p>
                                                    <div className="signature">
                                                        <div>General Manager Corporate Sales Division</div>
                                                        <div>Scott Rankin</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="about-item">
                                                <div className="about-logo-holder">
                                                    <div>
                                                        <img className="img-responsive img-fluid" src={`${globalVariable.domainName}/media/intro/hisense.png`} alt="hisense" />
                                                    </div>
                                                </div>
                                                <div className="about-text-holder">
                                                    <p>&quot;{t('citat31')}&quot;</p>
                                                    <div className="signature">
                                                        <div>Managing Director</div>
                                                        <div>Boštjan Vodeb</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="button-holder">
                                                <Link to={"/"} className="btn btn-sato">
                                                    {t('dugme2')}
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        );
                    }}
                />
            </>
        );
    }
}
const mapDispatchToProps = dispatch => ({
    dispatch,
});
export default withTranslation(['introBrandsPage', 'routes'])(connect(null, mapDispatchToProps)(IntroBrandsPage));