import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import imageExists from 'image-exists';
import { Player } from 'video-react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { globalStatistic } from '../../redux/actions/otherActions';

import "../../../node_modules/video-react/dist/video-react.css"; // import css

import ProductDetailSliderItem from './ProductDetailSliderItem';
import ProductDetailSliderItemSmallImage from './ProductDetailSliderItemSmallImage';
import Modal3D from '../other/Modal3D';
import globalVariable from '../../global';

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", borderRadius: '10px' }}
            onClick={onClick}
        />
    );
};

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", borderRadius: '10px' }}
            onClick={onClick}
        />
    );
};

const ProductDetailSlider = ({ product, youtubeImage }) => {
    const [show, setShow] = useState(false);
    const [modalIcon, setModalIcon] = useState(0);
    const [slideIndex, setSlideIndex] = useState(0);
    const dispatch = useDispatch();
    const params = useParams();
    const { productId } = params;

    const handleShow = (i) => {
        setSlideIndex(i)
        setShow(true)
    }
    const handleClose = () => setShow(false);

    // console.log(product.urlImages, 'product.urlImages')

    useEffect(() => {
        imageExists('https://resource3d.ewe.rs/' + product.acProduct.trim() + '/' + product.acProduct.trim() + '/images/up.png', img => {
            if (img) {
                setModalIcon(1);
            };
        });

    }, []);

    const sliderImages = product.urlImages.filter((item) => item.acThumbnail != "" && item.acType != "3D");

    if (modalIcon == 1) {
        // Proverava da li putanja već postoji u nizu sliderImages koristeći metodu some. Ako ne postoji, kreira novi objekat i dodaje ga u niz.
        // Edit: Ivan - 20.06.2024.
        var imagePath = "" + globalVariable.imagesPath + "/products/" + product.acProduct.trim() + "_v.jpg";

        if (!sliderImages.some(image => image.acImage === imagePath && image.acThumbnail === imagePath)) {
            var obj = {
                acImage: imagePath,
                acThumbnail: imagePath,
                acType: "image"
            };
            sliderImages.push(obj);
        }
    }

    const settings = {
        // customPaging: function (i) {
        //     return (
        //         <div className="item-list-slider-product">
        //             <div className="item-mini-images-product">
        //                 <img className="mini-images-slider-product" src={`${product.urlImages[i]}`} alt="b2b" />
        //                 {modalIcon == 1 && i == 0 &&
        //                     <div className="product-detail-item-3d">
        //                         <div className="mask-div-image-360">
        //                             <img alt="" src={process.env.PUBLIC_URL + `/360logo.png`} className="img-logo-360" />
        //                         </div>
        //                     </div>
        //                 }
        //             </div>
        //         </div>
        //     );
        // },
        // dots: true,
        dotsClass: "slick-dots slick-thumb",
        infinite: true,
        speed: 500,
        slidesToScroll: sliderImages.length > 3 ? 3 : sliderImages.length,
        // slidesToScroll: 3,
        className: "center h3-cust-top-product-detail",
        centerMode: true,
        centerPadding: "60px",
        slidesToShow: sliderImages.length > 3 ? 3 : sliderImages.length,
        // slidesToShow: 3,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        initialSlide: sliderImages.length > 3 || sliderImages.length == 2 ? 0 : 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: sliderImages.length == 1 ? 1 : sliderImages.length - 1,
                    slidesToScroll: sliderImages.length == 1 ? 1 : sliderImages.length - 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerPadding: "0px"
                }
            }
        ]
    };

    const settings2 = {
        // nextArrow: <SampleNextArrow />,
        // prevArrow: <SamplePrevArrow />,
        arrows: false,
        initialSlide: sliderImages.length > 3 || sliderImages.length == 2 ? 0 : 1,
    };

    const [nav1, setNav1] = useState();
    const [nav2, setNav2] = useState();

    const openModal3DFunction = () => {
        setShow(true);
        dispatch(globalStatistic(productId, '3D_PRODUCT'));
    }


    return (
        <>
            <Slider
                {...settings}
                asNavFor={nav2}
                ref={(slider1) => setNav1(slider1)}
            >
                {sliderImages.map((item, i) => (
                    <div key={i}>
                        <ProductDetailSliderItem imgNo={sliderImages.length} handleShow={handleShow} row={i} modalIcon={modalIcon} product={product} image={item} youtubeImage={youtubeImage} />
                    </div>
                ))}
            </Slider>
            <Slider
                {...settings2}
                asNavFor={nav1}
                ref={(slider2) => setNav2(slider2)}
                slidesToShow={sliderImages.length < 4 ? sliderImages.length : 4}
                swipeToSlide={true}
                focusOnSelect={true}
                className={`second-mini-slider second-mini-slider-${sliderImages.length}`}
            >
                {sliderImages.map((item, i) => (
                    <div className="item-list-slider-product">
                        <div className="item-mini-images-product">
                            <ProductDetailSliderItemSmallImage openModal3DFunction={openModal3DFunction} image={item} youtubeImage={youtubeImage} />
                        </div>
                        {modalIcon == 1 && i == 0 &&
                            <div className="product-detail-item-3d" onClick={(e) => { openModal3DFunction() }}>
                                <div className="mask-div-image-360">
                                    <img alt="3D logo" src={process.env.PUBLIC_URL + `/360logo.png`} className="img-logo-360" />
                                </div>
                            </div>
                        }
                    </div>
                ))}
            </Slider>
            <Modal3D show={show} item={product} sliderImages={sliderImages} slideIndex={slideIndex} modalIcon={modalIcon} handleClose={handleClose} youtubeImage={youtubeImage} />
        </>
        // <>
        //     <Slider {...settings}>
        //         {product.urlImages.map((item, i) => (
        //             <div key={i}>
        //                 <ProductDetailSliderItem imgNo={product.urlImages.length} handleShow={handleShow} row={i} modalIcon={modalIcon} product={product} image={item} />
        //             </div>
        //         ))}
        //         <div>
        //             <div className="h3-cust-video">
        //                 <Player
        //                     playsInline
        //                     poster="/assets/poster.png"
        //                     src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4"
        //                 />
        //             </div>
        //         </div>
        //     </Slider>

        //     <Modal3D show={show} item={product} slideIndex={slideIndex} modalIcon={modalIcon} handleClose={handleClose} />
        // </>
    )
}

export default ProductDetailSlider;