export const getMenuItems = ( state = [], action ) => {
    switch (action.type) {
        case 'MENU_ITEMS':
            return action.payload.menu;
        default:
            return state;
    };
};

export const getCurrencyItems = ( state = [], action ) => {
    switch (action.type) {
        case 'GET_CURRENCY_ITEMS':
            return action.payload.currency;
        default:
            return state;
    };
};

export const getCatalogMenuItems = ( state = [], action ) => {
    switch (action.type) {
        case 'CATEGORY_MENU_ITEMS_LIST':
            return action.payload;
        default:
            return state;
    };
};

export const getBrandsMenuList = ( state = [], action ) => {
    switch (action.type) {
        case 'BRAND_ITEMS_LIST':
            return action.payload;
        default:
            return state;
    };
};

export const getMenuSettings = ( state = { homePage: false, otherPage: true}, action) => {
    switch (action.type) {
        case 'SET_HOMEPAGE_MENU':
            return {
                homePage: action.payload,
                otherPage: action.pageVal
            }
        case 'SET_OTHERPAGE_MENU':
            return {
                homePage: action.pageVal,
                otherPage: action.payload,
            }
        case 'SET_DEFAULT_COLLAPSED_MENU':
            return {
                homePage: false,
                otherPage: true,
            }
        default:
            return state;
    };
};