import React from 'react'

const CompareProductsRow = (props)=> {
    return (
    <tr>
        <td>{props.name}</td>
        {props.children}    
    </tr>
       
    )
}

export default CompareProductsRow;