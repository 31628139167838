import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { getSearchItemsPage, getWishList, getActionProductsList, getLastProductsList, getPopupProductDetail } from '../../redux/actions/productActions';

//komponente
// import ModalListLinks from './ModalListLinks';
import LoyaltyBox from './LoyaltyBox';

import DashboardBoxInfoItem from './DashboardBoxInfoItem';
import DashboardBoxDeliveryItem from './DashboardBoxDeliveryItem';
import CurrencyMenuItem from '../other/CurrencyMenuItem';
import VatMenuItem from '../other/VatMenuItem';

import { SetVatFunction } from '../../redux/actions/userActions';
import { globalStatistic } from '../../redux/actions/otherActions';
import { useTranslation } from 'react-i18next';

function useQuery() {
    return new URLSearchParams(useLocation().search);
};

const DefaultModalMenu = ({ modalMenuSettings, modalValue, modalMenuFunction, sidebar }) => {

    const dispatch = useDispatch();
    const getCurrencyItems = useSelector((state) => state.getCurrencyItems);
    const activeVat = useSelector((state) => state.activeVat.item);
    const activeCurrency = useSelector((state) => state.activeCurrency);
    const getUserInfo = useSelector((state) => state.userInfo.userInfo);
    const productItem = useSelector((state) => state.productDetail.items[0]);
    const { t } = useTranslation('menuModal');

    const location = useLocation();
    let query = useQuery();

    const onChangeFunction = (e, type) => {
        dispatch(SetVatFunction(e.target.value, location, query.toString()));
        if (location.pathname.includes('pretraga')) {
            dispatch(getSearchItemsPage(query.get("q"), query.get("brand"), query.get("category")));
        } else if (location.pathname.includes('lista-zelja')) {
            dispatch(getWishList());
        } else if (location.pathname.includes('specijalna-akcija')) {
            var filteredUrl = location.pathname.split('/');
            dispatch(getActionProductsList(filteredUrl[3]));
        } else if (location.pathname.includes('pregledani-proizvodi') || location.pathname.includes('proizvod')) {
            dispatch(getLastProductsList());
        } else if (location.pathname == '/') {
            dispatch(getActionProductsList(4, 'weekAction'));
            dispatch(getActionProductsList(2, 'inStockAgain'));
            dispatch(getActionProductsList(5, 'recommendedForYou'));
            dispatch(getPopupProductDetail(productItem.anProductKey));
        }
    };

    const setModalMenuFunction = (id) => {
        modalMenuFunction(id);
    };

    const gatherStatistic = (anMenuKey) => {
        dispatch(globalStatistic(anMenuKey, 'MENU'));
    }

    return (
        <div
            className={`modal-root ${modalMenuSettings == 1 ? 'menu-modal-show' : 'menu-modal-hide'} ${sidebar && 'collapsed-sidebar-modal'}`}
            onMouseEnter={(e) => setModalMenuFunction(1)}
        >
            <i
                className="close-menu-modal-popup fas fa-times"
                onClick={(e) => setModalMenuFunction(0)}
            ></i>
            <div className="dashboard-menu-header menu-modal-item-header">

                <div className="dashboard-title-menu">
                    <i className="fas fa-th-large"></i> <span>{Object.keys(modalValue).length !== 0 && modalValue.acMenu}</span>
                </div>
                {modalValue.acMenu === 'DASHBOARD' && (
                    <>
                        <div className="dashboard-value-item">
                            <div className="dashboard-item-radio-name">
                                {t('VALUTA')}
                            </div>
                            <div className="dashboard-item-radio-buttons">
                                {getCurrencyItems.map((item, key) => (
                                    <CurrencyMenuItem key={key} item={item} isPriceInEur={activeCurrency._id} productItem={productItem} inputName={'0'} />
                                ))}
                            </div>
                        </div>
                        <div className="dashboard-value-price">
                            <div className="dashboard-item-radio-name">
                                {t('cenePdv')}
                            </div>
                            <div className="dashboard-item-radio-buttons">
                                <VatMenuItem productItem={productItem} activeVat={activeVat} inputName={'0'} />
                            </div>
                        </div>
                    </>
                )}

            </div>


            {Object.keys(modalValue).length != 0 && modalValue.tempMenuList.map((item, key) => (
                <>
                    {item.anMenuBlockGroupId != 2 ?
                        <div className="dashboard-item-content" key={key}>
                            <div className="dashboard-block-content">
                                {item.tmbiList.map((item2, key2) => (
                                    <>
                                        {item2.anMenuBlockTypeId == 1 && <DashboardBoxInfoItem user={getUserInfo} key={key2} item={item} data={item2} />}
                                        {item2.anMenuBlockTypeId == 2 && <DashboardBoxInfoItem user={getUserInfo} key={key2} item={item} data={item2} />}
                                        {item2.anMenuBlockTypeId == 3 && <DashboardBoxDeliveryItem key={key2} data={item2} />}
                                    </>
                                ))}
                            </div>
                        </div>
                        : item.anMenuBlockGroupId == 2 &&
                        <LoyaltyBox data={item} />
                    }
                </>
            ))}

            {Object.keys(modalValue).length != 0 && modalValue.subMenu.length > 0 && (

                <>
                    <div className="menu-modal-list-links">
                        <ul>
                            {modalValue.subMenu.map((item, key) => (
                                <Link
                                    to={item.acLink}
                                    key={key}
                                    onClick={(e) => { setModalMenuFunction(0); gatherStatistic(item.anMenuKey) }}
                                >
                                    {item.acIcon ?
                                        <li>
                                            <i className={item.acIcon}></i>&nbsp; {item.acMenu}
                                        </li>
                                        : <li>
                                            <i className="fas fa-angle-right"></i>&nbsp; {item.acMenu}
                                        </li>
                                    }

                                </Link>
                            ))}
                        </ul>
                    </div>
                </>
            )}

        </div>
    );
};

export default DefaultModalMenu;