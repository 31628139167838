import React, { useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ReactExport from "react-data-export";

import { exportCatalog } from '../../redux/actions/otherActions';

import LoaderMaskLine from '../other/LoaderMaskLine';
import { useTranslation } from 'react-i18next';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const ExcelExportCatalog = () => {

    const dataRef = useRef();
    const dispatch = useDispatch();
    const activeCurrency = useSelector((state) => state.activeCurrency.item);
    const [exportCatalogState, setExportCatalogState] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const { t } = useTranslation('customExportCatalog');

    const onExportCatalog = (val) => {
        setShowLoader(true)
        dispatch(exportCatalog(val)).then(res => { setExportCatalogState(res); dataRef.current.click(); setShowLoader(false) })
    }

    const excelData = [
        {
            columns: [
                { title: t('kategorija'), style: { font: { bold: true } }, width: { wpx: 180 } },
                { title: t('podkategorija'), style: { font: { bold: true } }, width: { wpx: 180 } },
                { title: t('ident'), style: { font: { bold: true } } },
                { title: t('proizvodjac'), style: { font: { bold: true } } },
                { title: t('naziv'), style: { font: { bold: true } }, width: { wpx: 450 } },
                { title: t('cenaSaPopustom'), style: { font: { bold: true } }, width: { wpx: 120 } },
                { title: t('kolicina'), style: { font: { bold: true } } },
                { title: t('preporucenaMpCena'), style: { font: { bold: true } }, width: { wpx: 150 } },
                { title: t('ean'), style: { font: { bold: true } }, width: { wpx: 120 } },
                { title: t('uvoznik'), style: { font: { bold: true } }, width: { wpx: 200 } },
                { title: t('zPorekla'), style: { font: { bold: true } }, width: { wpx: 100 } },
                { title: t('opis'), style: { font: { bold: true } }, width: { wpx: 1500 } },
                { title: t('tezina'), style: { font: { bold: true } }, width: { wpx: 180 } },
                { title: t('visina'), style: { font: { bold: true } }, width: { wpx: 180 } },
                { title: t('sirina'), style: { font: { bold: true } }, width: { wpx: 180 } },
                { title: t("duzina"), style: { font: { bold: true } }, width: { wpx: 180 } },
                { title: t("brKomada"), style: { font: { bold: true } }, width: { wpx: 170 } },
            ],
            data: exportCatalogState.map((item, key) => {
                const anPrice = item.anPrice.toFixed(2).replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + " " + activeCurrency.acShortName
                const anRecommendedRetailPrice = item.anRecommendedRetailPrice.toFixed(2).replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + " " + 'RSD'

                return [
                    { value: item.acCategory, style: { font: { sz: "11" } } },
                    { value: item.acSubCategory, style: { font: { sz: "11" } } },
                    { value: item.acProduct, style: { font: { sz: "11" } } },
                    { value: item.acDept.replace(" 1****", ""), style: { font: { sz: "11" } } },
                    { value: item.acName, style: { font: { sz: "11" } } },
                    { value: anPrice, style: { alignment: { horizontal: "right" }, font: { sz: "11" } } },
                    { value: item.anStock, style: { font: { sz: "11" } } },
                    item.anRecommendedRetailPrice != 0 ? { value: anRecommendedRetailPrice, style: { alignment: { horizontal: "right" }, font: { sz: "11" } } } : "",
                    { value: item.acEan, style: { font: { sz: "11" } } },
                    { value: item.acSupplier, style: { font: { sz: "11" } } },
                    { value: item.acCountry, style: { font: { sz: "11" } } },
                    { value: item.acInlineSpecification, style: { font: { sz: "11" }, alignment: { wrapText: true } } },
                    { value: item.anDimWeightBrutto ? item.anDimWeightBrutto : '', style: { alignment: { horizontal: "right" }, font: { sz: "11" } } },
                    { value: item.anDimHeight ? item.anDimHeight : '', style: { alignment: { horizontal: "right" }, font: { sz: "11" } } },
                    { value: item.anDimWidth ? item.anDimWidth : '', style: { alignment: { horizontal: "right" }, font: { sz: "11" } } },
                    { value: item.anDimDepth ? item.anDimDepth : '', style: { alignment: { horizontal: "right" }, font: { sz: "11" } } },
                    { value: item.anProductTransport ? item.anProductTransport : '', style: { alignment: { horizontal: "right" }, font: { sz: "11" } } },
                ]
            })
        }
    ]

    return (
        <>
            {!showLoader ?
                <div className="btn btn-sm btn-newsletter text-center" onClick={() => onExportCatalog("excel")}><i className="fas fa-angle-double-down"></i>&nbsp; {t('exportExcel')}</div>
                :
                <LoaderMaskLine />
            }
            <ExcelFile filename={t('proizvodi') + Date.now()} element={< div className="btn btn-sm btn-newsletter text-center" ref={dataRef} style={{ display: "none" }}> <i className="fas fa-angle-double-down"></i>&nbsp; {t('exportExcel')}</div >}>
                <ExcelSheet dataSet={excelData} name="ewe" />
            </ExcelFile>
        </>
    );
};


export default ExcelExportCatalog;