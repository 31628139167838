import React from 'react';
import { Link } from 'react-router-dom';
import globalVariable from '../../global';

import { removeSpecialChar } from '../../helperFunctions'
import { useTranslation } from 'react-i18next';

const addDefaultSrc = (e) => {
  e.target.src = `${globalVariable.imagesPath}products/noImage.jpg`;
};
const SearchBoxItemModal = ({ itemValue, serachBoxClosedClick }) => {
  const { t } = useTranslation('routes');
  return (
    <div className="search-box-item-result">
      <div className="search-box-item-result-image">
        <img src={`https://resource.ewe.rs/products/${itemValue.acProduct.trim()}.jpg`} alt="b2b" className="img-fluid" onError={(e) => addDefaultSrc(e)} />
      </div>
      <div className="search-box-item-result-body">
        <div className="search-box-item-result-ident">{itemValue.acProduct.trim()}</div>
        <div className="search-box-item-result-name">
          <Link
            to={`/${t('proizvod')}/${itemValue.anProductKey}/${removeSpecialChar(itemValue.acName)}`}
            onClick={(e) => serachBoxClosedClick(false)}
          >
            {itemValue.acSeoHeader ? itemValue.acSeoHeader.replace(" 1****", "") : (itemValue.acDept.replace(" 1****", "") + ' ' + itemValue.acName)}
          </Link>
        </div>
      </div>
    </div>
  )
}

export default SearchBoxItemModal;