import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const SearchCategories = () => {

    let query = useQuery();
    const { t } = useTranslation('search');

    const getSearchCategories = useSelector((state) => state.getSearchCategories);

    return (
        <div>
            {getSearchCategories.length > 0 ? (
                <ul className="menu-items">
                    {getSearchCategories.map((item, key) => (
                        <Link className="brand-main-no-sub" to={`${query.get('brand') ? `?q=${query.get("q")}&brand=${query.get("brand")}` : `?q=${query.get("q")}`}&category=${item.anSubCategoryKey}`}>
                            <li className="brand-menu-item" >{item.acSubCategory}</li>
                        </Link>
                    ))}
                </ul>
            ) : <h5>{t('nemaRezultata')}</h5>}
        </div>
    );
};

export default SearchCategories;