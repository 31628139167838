import React from 'react';
import { useSelector } from 'react-redux';

import ReactExport from "react-data-export";
import { useTranslation } from 'react-i18next';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const ExcelExport = (props) => {
    const getUserInfo = useSelector((state) => state.userInfo.userInfo);
    const activeCurrency = useSelector((state) => state.activeCurrency.item);
    const iconPath = process.env.PUBLIC_URL + '/media/';

    let currency = Object.keys(activeCurrency).length != 0 ? activeCurrency.acShortName : 'RSD';
    const { t } = useTranslation('customExportCatalog');


    const excelData = [
        {
            columns:
                getUserInfo ? [
                    { title: t('sifra') },
                    { title: t('proizvodjac'), style: { font: { bold: true } } },
                    { title: t('naziv'), style: { font: { bold: true } }, width: { wpx: 800 } },
                    { title: t('lager'), style: { font: { bold: true } } },
                    { title: t('cena'), style: { font: { bold: true } }, width: { wpx: 120 } },
                    { title: t('staraCena'), style: { font: { bold: true } }, width: { wpx: 120 } },
                    { title: t("mpCena"), style: { font: { bold: true } }, width: { wpx: 120 } }
                ] : [
                    { title: t('sifra') },
                    { title: t('proizvodjac'), style: { font: { bold: true } } },
                    { title: t('naziv'), style: { font: { bold: true } }, width: { wpx: 800 } },
                    { title: t('cena'), style: { font: { bold: true } }, width: { wpx: 120 } },
                ],
            data: props.data.items.map((item, key) => {
                // const anPrice = new Intl.NumberFormat('sr-RS', { style: 'currency', currency: currency, currencyDisplay: "code" }).format(item.anPrice)
                // const anOldPrice = new Intl.NumberFormat('sr-RS', { style: 'currency', currency: currency, currencyDisplay: "code" }).format(item.anOldPrice)
                // const anRetailPrice = new Intl.NumberFormat('sr-RS', { style: 'currency', currency: 'RSD', currencyDisplay: "code" }).format(item.anRetailPrice)
                const anPrice = item.anPrice.toFixed(2).replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + " " + currency
                const anOldPrice = item.anOldPrice.toFixed(2).replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + " " + currency
                const anRecommendedRetailPrice = item.anRecommendedRetailPrice.toFixed(2).replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + " " + 'RSD'
                const anRetailPrice = item.anRetailPrice.toFixed(2).replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + " " + 'RSD'

                return getUserInfo ? [
                    { value: item.acProduct, style: { font: { sz: "11" } } },
                    { value: item.acDept.replace(" 1****", ""), style: { font: { sz: "11" } } },
                    { value: item.acName, style: { font: { sz: "11" } } },
                    { value: item.anStock, style: { font: { sz: "11" } } },
                    { value: anPrice, style: { alignment: { horizontal: "right" }, font: { sz: "11" } } },
                    item.anOldPrice != 0 ? { value: anOldPrice, style: { alignment: { horizontal: "right" }, font: { sz: "11" } } } : "",
                    item.anRecommendedRetailPrice != 0 ? { value: anRecommendedRetailPrice, style: { alignment: { horizontal: "right" }, font: { sz: "11" } } } : ""
                ] : [
                    { value: item.acProduct, style: { font: { sz: "11" } } },
                    { value: item.acDept.replace(" 1****", ""), style: { font: { sz: "11" } } },
                    { value: item.acName, style: { font: { sz: "11" } } },
                    { value: anRetailPrice, style: { alignment: { horizontal: "right" }, font: { sz: "11" } } },

                ]
            })
        }
    ]

    return (
        <>
            <ExcelFile filename={t('proizvodi') + Date.now()} element={
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47.55 53.84">
                    <g data-name="Layer 2">
                        <g data-name="Layer 1">
                            <path d="M19.32 13.92A8.06 8.06 0 0117 16.21l2 2.93a11.76 11.76 0 003.26-3.26zM4.87 21.14c3.5 0 6.25 0 8.45-.23A11.9 11.9 0 0019 19.14l-2-2.93a8.54 8.54 0 01-4 1.2c-2 .2-4.52.2-8.09.2zM20.72 1.76c0 3.57 0 6.12-.2 8.09a8.62 8.62 0 01-1.2 4.07l2.93 2A12 12 0 0024 10.21c.23-2.2.22-5 .22-8.45zM4.87 17.61H1.76v3.53h3.11zm15.92-2.71l-1.47-1z"
                                className="cls-1">
                            </path>
                            <path d="M1.64 15.11A11.27 11.27 0 00.74 17L4 18.23a8.09 8.09 0 01.63-1.33zm1.88 9.83c0-3.89 0-5.39.52-6.71L.74 17C0 19.06 0 21.32 0 24.94zM7.33 9.53c-2.81 2.28-4.58 3.69-5.69 5.58l3 1.79c.72-1.22 1.87-2.19 4.88-4.64zm12.59-5.32A8 8 0 0121 3.83L20 .44a12.09 12.09 0 00-1.49.56zM26.09 0A22.45 22.45 0 0020 .44l1 3.39a20 20 0 015.1-.31zM15.77 7.2c2.27-1.85 3.15-2.56 4.15-3L18.51 1a23 23 0 00-5 3.48zm24.84-2.09A8.45 8.45 0 0142.45 7l2.85-2.13a12 12 0 00-2.62-2.61zm6.94 14.77a89.39 89.39 0 00-.25-9 12 12 0 00-2-6L42.45 7a8.56 8.56 0 011.35 4.3c.23 2.1.23 4.81.23 8.63zM27.68 3.52c3.81 0 6.53 0 8.63.23a8.55 8.55 0 014.3 1.36l2.07-2.85a11.94 11.94 0 00-6-2C34.35 0 31.41 0 27.68 0zM42.45 46.9a8.91 8.91 0 01-1.84 1.84l2.07 2.85A12.06 12.06 0 0045.3 49zm-14.77 6.94a89.59 89.59 0 009-.25 11.93 11.93 0 006-2l-2.07-2.85a8.56 8.56 0 01-4.3 1.35c-2.1.23-4.82.23-8.63.23zM44 34c0 3.81 0 6.53-.23 8.63a8.56 8.56 0 01-1.35 4.3L45.3 49a11.93 11.93 0 002-6 89.59 89.59 0 00.25-9zM7 48.74a8.45 8.45 0 01-1.89-1.84L2.26 49a12 12 0 002.61 2.62zM0 34c0 3.73 0 6.67.25 9a11.94 11.94 0 002 6l2.86-2.1a8.55 8.55 0 01-1.36-4.3c-.22-2.1-.23-4.82-.23-8.63zm19.88 16.32c-3.82 0-6.53 0-8.63-.23A8.56 8.56 0 017 48.74l-2.13 2.85a12 12 0 006 2 89.39 89.39 0 009 .25zM3.52 34v-9H0v9zm24.16 16.32h-7.8v3.52h7.8zM44 19.88V34h3.52V19.88zM26.09 3.52h1.59V0h-1.59zM9.55 12.26l6.22-5.06-2.23-2.74-6.21 5.07zm-7.16 5.35L.74 17zM20.51 2.14L20 .44zm-1.29.46L18.51 1zm-4.57 3.23l-1.11-1.37zm29.22.08L42.45 7zm-2.23-2.23l-1 1.43zm0 46.48l1 1.43zm2.23-2.22l1.43 1zm-40.19 0L2.26 49zm2.23 2.22l1-1.42z"
                                className="cls-1"></path>
                            <path d="M30.78 40.86a.91.91 0 01-.93.94 1 1 0 01-.75-.4l-4.45-5.59-4.38 5.61a.92.92 0 01-.73.38.88.88 0 01-.87-.88 1 1 0 01.18-.58l4.8-5.9-4.76-5.86a1 1 0 01-.18-.58.9.9 0 01.91-.92 1 1 0 01.77.38L24.78 33l4.38-5.61a.85.85 0 01.69-.33.9.9 0 01.91.89.91.91 0 01-.16.52l-4.81 5.93 4.81 5.9a.82.82 0 01.18.56z"
                                className="cls-2"></path>
                        </g>
                    </g>
                </svg>
            }>
                <ExcelSheet dataSet={excelData} name="ewe" />
            </ExcelFile>
        </>
    );
};


export default ExcelExport;