import React from 'react'

const ProductDetailSpecificationItem = ({ type, show, name, filterSet }) => {
    return (
        <div className={`${type == 'loyalty' ? 'col-md-12' : 'figure'} ${type === 'hidden' && 'show-more-specification-product-detail'} ${show && 'active'}`}>
            <div className="product-detail-specification-item">
                <div className="product-detail-specification-title">{name}</div>
                <div className="product-detail-specification-table">
                    <table className="table table-striped">
                        <tbody>
                            {filterSet.map((item, key) => (
                                <tr key={key}>
                                    <td className="row-spec-td-name">{item.acFilterSet}</td>
                                    <td dangerouslySetInnerHTML={{ __html: item.filters }} ></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default ProductDetailSpecificationItem;