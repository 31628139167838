import React from 'react';
import { useTranslation } from 'react-i18next';

const ProductDetailShorDescription = ({ items }) => {
    const { t } = useTranslation('product');

    if (!items) {
        return null;
    }

    return (
        <div className="table-short-description-items">
            {items.length > 0 ? (
                <table className="table-shor-desc">
                    <tbody>
                        {items.map((item2, key2) => (
                            <>
                                {key2 < 6 && item2.filterSet.map((item3, key3) => (
                                    <>
                                        {key3 == 0 && (
                                            <tr>
                                                <td className="td-name-short-desc">{item3.acFilterSet}</td>
                                                <td className="td-value-short-desc">{item3.filters}</td>
                                            </tr>
                                        )}
                                    </>
                                ))}
                            </>
                        ))}
                    </tbody>
                </table>
            ) : (
                <div className="alert alert-info"><i className="fas fa-info-circle"></i> {t('uskoroDetSpec')}</div>
            )}
        </div>
    );
};

export default ProductDetailShorDescription;