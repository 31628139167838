import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { globalStatistic } from '../redux/actions/otherActions';

import { getActionProductsList } from '../redux/actions/productActions';

import CatalogPreviewBlock from '../components/catalog/CatalogPreviewBlock';
import PageBanner from '../components/banner/PageBanner';

const SpecialActionPage = () => {
    const dispatch = useDispatch();
    const { id, name, pageId } = useParams();
    const history = useHistory();

    var title = name.charAt(0).toUpperCase() + name.slice(1);

    const getActionProducts = useSelector((state) => state.getActionProducts);

    useEffect(() => {
        dispatch(getActionProductsList(id));
        dispatch(globalStatistic(pageId, 'PAGE'));
    }, [id]);

    return (
        <div className="container-content container-catalog-content">
            <div className="container-fluid">
                <div className="row">
                    <div className="text-center banner-image-on-top-page">
                        <PageBanner categoryType="" categoryId="" pageId={pageId} />
                        {/* <img className="img-fluid" src="https://www.ewe.rs/gui/action_banner.jpg" style={{maxWidth: "100%", borderRadius: "10px"}} /> */}
                    </div>
                </div>
                <div className="row">
                    <div className="weekly-block-page">
                        <h2 className="weekly-title">{title.replaceAll('-', ' ')}</h2>
                        <CatalogPreviewBlock itemsList={getActionProducts} sortable="1" preview="1" export="1" itemPreview="catalog" pdfType="specialActionPage" specialId={id} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SpecialActionPage;