import React, { useState } from 'react';

const FilterCheckboxInput = ({ checkedBox, onClickCheckboxFilterFunction, item2, item, idx }) => {

    const [checkedInput, setCheckedInput] = useState(checkedBox);

    return (
        <>
            <input 
                onChange={(e) => onClickCheckboxFilterFunction(e, item2, item, 'filter', idx)} 
                type="checkbox" 
                // onClick={(e) => clickItemCheckbox(e)}
                className="form-check-input" 
                // defaultChecked={checkedBox}
                // checked={checkedBox}
                id={`checbox${item2.anAttributeValue}`} 
            />
        </>
    )
};

export default FilterCheckboxInput;