import React, { useEffect, useState } from 'react';
import PageHelmet from '../components/helmet/PageHelmet';
import { useDispatch } from 'react-redux';
import { globalStatistic } from '../redux/actions/otherActions';
import { getPageSeo } from '../redux/actions/pageActions';
import { useTranslation } from 'react-i18next';

const Terms = () => {

    const dispatch = useDispatch();
    const [pageSeoData, setPageSeoData] = useState({});
    const { t } = useTranslation('terms');
    useEffect(() => {
        dispatch(getPageSeo(78)).then(res => {
            if (res) {
                setPageSeoData(res);
            }
        });
        dispatch(globalStatistic(78, 'PAGE'));
    }, [])

    return (
        <>
            {Object.keys(pageSeoData).length !== 0 &&
                <PageHelmet
                    title={pageSeoData ? `${pageSeoData.acSeoTitle}` : "Ewe Comp"}
                    description={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    keywords={pageSeoData ? `${pageSeoData.acSeoKeywords}` : ""}
                    ogDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    ogTitle={pageSeoData ? `${pageSeoData.acSeoTitle}` : ""}
                    ogUrl={pageSeoData ? `https://ewe.rs/${pageSeoData.acSeoUrl}` : ""}
                    ogImg="https://www.ewe.rs/media/ewe-og-img.jpg"
                    twitterDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                />
            }
            <div className='container-fluid container-terms-content'>
                <div className='row'>
                    <div className='col-sm-12 text-center banner-image-on-top-page'>
                        <img className='img-fluid' src='https://resource.ewe.rs/media/blocks/2018/02/2018-02-04962838.png' alt={pageSeoData ? `${pageSeoData.acSeoTitle}` : 'Ewe'} style={{ maxWidth: "100%", borderRadius: "10px" }} />
                    </div>
                </div>
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <h3 style={{ color: "#212529", fontSize: "1.75rem", fontWeight: "500", marginTop: "0", marginBottom: "0.5rem", textAlign: "left", textTransform: "none", fontFamily: "inherit" }}>{t('naslov1')}</h3>
                            <p style={{ marginTop: "auto", fontSize: "1rem", fontWeight: "400", letterSpacing: "inherit" }}>{t('paragraf1')}</p>
                        </div>
                    </div>
                    <br />
                    <div className='row'>
                        <div className='col-sm-12'>
                            <h3 style={{ color: "#212529", fontSize: "1.75rem", fontWeight: "500", marginTop: "0", marginBottom: "0.5rem", textAlign: "left", textTransform: "none", fontFamily: "inherit" }}>{t('naslov2')}</h3>
                            <p style={{ marginTop: "auto", fontSize: "1rem", fontWeight: "400", letterSpacing: "inherit" }}>{t('paragraf2')}</p>
                            <br />
                            <p style={{ marginTop: "auto", fontSize: "1rem", fontWeight: "400", letterSpacing: "inherit" }}>{t('paragraf3')}</p>
                            <br />
                            <p style={{ marginTop: "auto", fontSize: "1rem", fontWeight: "400", letterSpacing: "inherit" }}>{t('paragraf4')}</p>
                        </div>
                    </div>
                    <br />
                    <div className='row'>
                        <div className='col-sm-12'>
                            <h3 style={{ color: "#212529", fontSize: "1.75rem", fontWeight: "500", marginTop: "0", marginBottom: "0.5rem", textAlign: "left", textTransform: "none", fontFamily: "inherit" }}>{t('naslov3')}</h3>
                            <p style={{ marginTop: "auto", fontSize: "1rem", fontWeight: "400", letterSpacing: "inherit" }}>{t('paragraf5')}</p>
                        </div>
                    </div>
                    <br />
                    <div className='row'>
                        <div className='col-sm-12'>
                            <h3 style={{ color: "#212529", fontSize: "1.75rem", fontWeight: "500", marginTop: "0", marginBottom: "0.5rem", textAlign: "left", textTransform: "none", fontFamily: "inherit" }}>{t('naslov4')}</h3>
                            <p style={{ marginTop: "auto", fontSize: "1rem", fontWeight: "400", letterSpacing: "inherit" }}>{t('paragraf6')}</p>
                            <br />
                            <p style={{ marginTop: "auto", fontSize: "1rem", fontWeight: "400", letterSpacing: "inherit" }}>{t('paragraf7')}</p>
                        </div>
                    </div>
                    <br />
                    <div className='row'>
                        <div className='col-sm-12'>
                            <h3 style={{ color: "#212529", fontSize: "1.75rem", fontWeight: "500", marginTop: "0", marginBottom: "0.5rem", textAlign: "left", textTransform: "none", fontFamily: "inherit" }}>{t('naslov5')}</h3>
                            <p style={{ marginTop: "auto", fontSize: "1rem", fontWeight: "400", letterSpacing: "inherit" }}>{t('paragraf8')}</p>
                        </div>
                    </div>
                    <br />
                    <div className='row'>
                        <div className='col-sm-12'>
                            <h3 style={{ color: "#212529", fontSize: "1.75rem", fontWeight: "500", marginTop: "0", marginBottom: "0.5rem", textAlign: "left", textTransform: "none", fontFamily: "inherit" }}>{t('naslov6')}</h3>
                            <p style={{ marginTop: "auto", fontSize: "1rem", fontWeight: "400", letterSpacing: "inherit" }}>{t('paragraf9')}</p>
                            <br />
                            <p style={{ marginTop: "auto", fontSize: "1rem", fontWeight: "400", letterSpacing: "inherit" }}>{t('paragraf10')}</p>
                        </div>
                    </div>
                    <br />
                    <div className='row'>
                        <div className='col-sm-12'>
                            <h3 style={{ color: "#212529", fontSize: "1.75rem", fontWeight: "500", marginTop: "0", marginBottom: "0.5rem", textAlign: "left", textTransform: "none", fontFamily: "inherit" }}>{t('naslov7')}</h3>
                            <p style={{ marginTop: "auto", fontSize: "1rem", fontWeight: "400", letterSpacing: "inherit" }}>{t('paragraf11')}</p>
                            <br />
                            <p style={{ marginTop: "auto", fontSize: "1rem", fontWeight: "400", letterSpacing: "inherit" }}>{t('paragraf12')}</p>
                            <br />
                            <p style={{ marginTop: "auto", fontSize: "1rem", fontWeight: "400", letterSpacing: "inherit" }}>{t('paragraf13')}</p>
                            <br />
                            <p style={{ marginTop: "auto", fontSize: "1rem", fontWeight: "400", letterSpacing: "inherit" }}>{t('paragraf14')}</p>
                        </div>
                    </div>
                    <br />
                    <div className='row'>
                        <div className='col-sm-12'>
                            <h3 style={{ color: "#212529", fontSize: "1.75rem", fontWeight: "500", marginTop: "0", marginBottom: "0.5rem", textAlign: "left", textTransform: "none", fontFamily: "inherit" }}>{t('naslov8')}</h3>
                            <p style={{ marginTop: "auto", fontSize: "1rem", fontWeight: "400", letterSpacing: "inherit" }}>{t("paragraf15")}</p>
                            <br />
                            <p style={{ marginTop: "auto", fontSize: "1rem", fontWeight: "400", letterSpacing: "inherit" }}>{t('paragraf16')}</p>
                        </div>
                    </div>
                    <br />
                    <div className='row'>
                        <div className='col-sm-12'>
                            <h3 style={{ color: "#212529", fontSize: "1.75rem", fontWeight: "500", marginTop: "0", marginBottom: "0.5rem", textAlign: "left", textTransform: "none", fontFamily: "inherit" }}>{t('naslov9')}</h3>
                            <p style={{ marginTop: "auto", fontSize: "1rem", fontWeight: "400", letterSpacing: "inherit" }}>{t('paragaf17')}</p>
                        </div>
                    </div>
                    <br />
                    <div className='row'>
                        <div className='col-sm-12'>
                            <h3 style={{ color: "#212529", fontSize: "1.75rem", fontWeight: "500", marginTop: "0", marginBottom: "0.5rem", textAlign: "left", textTransform: "none", fontFamily: "inherit" }}>{t('naslov10')}</h3>
                            <p style={{ marginTop: "auto", fontSize: "1rem", fontWeight: "400", letterSpacing: "inherit" }}>{t('paragraf18')}</p>
                            <br />
                            <p style={{ marginTop: "auto", fontSize: "1rem", fontWeight: "400", letterSpacing: "inherit" }}>{t('paragraf19')}</p>
                        </div>
                    </div>
                    <br />
                    <div className='row'>
                        <div className='col-sm-12'>
                            <h3 style={{ color: "#212529", fontSize: "1.75rem", fontWeight: "500", marginTop: "0", marginBottom: "0.5rem", textAlign: "left", textTransform: "none", fontFamily: "inherit" }}>{t('naslov11')}</h3>
                            <p style={{ marginTop: "auto", fontSize: "1rem", fontWeight: "400", letterSpacing: "inherit" }}>{t('paragraf20')}</p>
                        </div>
                    </div>
                    <br />
                    <div className='row'>
                        <div className='col-sm-12'>
                            <h3 style={{ color: "#212529", fontSize: "1.75rem", fontWeight: "500", marginTop: "0", marginBottom: "0.5rem", textAlign: "left", textTransform: "none", fontFamily: "inherit" }}>{t('naslov12')}</h3>
                            <p style={{ marginTop: "auto", fontSize: "1rem", fontWeight: "400", letterSpacing: "inherit" }}>{t('paragraph21')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Terms
