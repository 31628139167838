import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getBenefitRangList } from '../redux/actions/otherActions';
import MessageInfoBox from '../components/other/MessageInfoBox';
import Form from 'react-bootstrap/Form';
import { axiosinstance } from '../configAxios';
import { newYearRegistration } from '../redux/actions/userActions';
import PageHelmet from '../components/helmet/PageHelmet';
import { globalStatistic } from '../redux/actions/otherActions';
import { getPageSeo } from '../redux/actions/pageActions';
import { useTranslation } from 'react-i18next';
import SecureLS from 'secure-ls';

const NewYearPartyPartnersLanding = () => {

    const [isValidName, setIsValidName] = useState(0);
    const [isValidCompanyName, setIsValidCompanyName] = useState(0);
    const [isValidEmail, setIsValidEmail] = useState(0);
    const [name, setName] = useState('');
    const [company, setCompany] = useState('');
    const [email, setEmail] = useState('');
    const [isRegistered, setIsRegistered] = useState(0);
    const [pageSeoData, setPageSeoData] = useState({});

    // const [profileImage, setProfileImage] = useState('');
    // const [profileImageUrl, setProfileImageUrl] = useState('');
    // const [isRegistered, setIsRegistered] = useState(0);

    // console.log(getUserInfo, 'getUserInfo')
    // console.log(anCompanyKey, 'anCompanyKey')
    // console.log(anDepartmentKey, 'anDepartmentKey')

    const dispatch = useDispatch();
    // const getBenefitList = useSelector((state) => state.getBenefitList.items)
    // const anCompanyKey = useSelector((state) => state.userInfo.userInfo.company.anCompanyKey);
    const { t } = useTranslation('newYearLoyalty');

    useEffect(() => {
        dispatch(getPageSeo(770)).then(res => {
            if (res) {
                setPageSeoData(res);
            }
        });
        dispatch(globalStatistic(770, 'PAGE'));
        let ls = new SecureLS({ encodingType: 'aes' });
        var userInfoData = ls.get('userInfo');
        const attributes = {};
        attributes["anUserKey"] = userInfoData ? userInfoData.company.department[0].user[0].anUserKey : 0;
        attributes["acHappeningName"] = "NYParty2022";

        const configRes = axiosinstance(attributes, "8224", "happeningCheck");

        axios(configRes)
            .then(function (response) {
                if (response.data.response.isOk != 1) {
                    setIsRegistered(1)
                } else {
                    setIsRegistered(2)
                }
            })
            .catch(function (error) {
                console.log(error, "error");
            });
    }, [])

    // useEffect(() => {
    //     getBenefitList.forEach((item) => {
    //         if (item.anCompanyKey === anCompanyKey) {
    //             setIsRegistered(1)
    //             setName(item.acFirstName)
    //             setSurname(item.acLastName)
    //             // console.log(item, 'item')
    //         }
    //     })
    // }, [getBenefitList, anCompanyKey])

    const onSubmitRegistration = (e) => {
        e.preventDefault();
    }

    // const resetFileValue = (e) => {
    //     e.target.value = null
    // }


    // const handleFileInput = (e) => {
    //     e.preventDefault();
    //     e.stopPropagation();
    //     e.nativeEvent.stopImmediatePropagation();

    //     const formData = new FormData();
    //     formData.append('document', e.target.files[0]);
    //     saveImg(e.target.name, formData);

    //     setProfileImage(e.target.files[0]);
    // }

    // const saveImg = (e, formData) => {
    //     const uploadPath = "bodovi";

    //     axios.post('https://newb2b.ewe.rs:5000/image-upload/' + uploadPath + '', formData)
    //         .then(res => {
    //             if (res.data.isOk == 0) {
    //                 toast(<MessageInfoBox message={res.data.msg} type={res.data.isOk} />, {
    //                     className: res.data.isOk == 1 ? 'green-background' : 'red-background',
    //                 });
    //             } else {
    //                 setProfileImageUrl(`/media/${uploadPath}/` + res.data.fileName)
    //             }
    //         })
    // }


    const handleInput = () => {

        if (name == '') {
            setIsValidName(1)
        } else {
            setIsValidName(2)
        }

        if (company == '') {
            setIsValidCompanyName(1)
        } else {
            setIsValidCompanyName(2)
        }

        if (email == '') {
            setIsValidEmail(1)
        } else {
            if (validateEmail(email)) {
                setIsValidEmail(2)
            } else {
                setIsValidEmail(1)
            }
        }


        if (isValidName != 2 || isValidCompanyName != 2) {
            toast(<MessageInfoBox message="errorMissingOneOrMoreFields" type="0" />, {
                className: 'red-background',
            });
        } else if (isValidEmail != 2) {
            toast(<MessageInfoBox message="emailNotFoundError" type="0" />, {
                className: 'red-background',
            });
        } else {
            let values = {
                'acName': name,
                'acCompany': company,
                'acEmail': email,
                'acType': "NYParty2022",
            }

            // console.log(values, 'podaci za slanje')

            dispatch(newYearRegistration(values)).then(res => {
                if (res.isOk == 1) {
                    setIsRegistered(1);
                } else {
                    setIsRegistered(2);
                }
            })
        }
    }

    const onChangeInput = (e) => {

        if (e.target.name == 'inputName' && e.target.value == '') {
            // setIsValidName(1)
            setName('')
        } else if (e.target.name == 'inputName' && e.target.value != '') {
            if (!hasNumber(e.target.value)) {
                // setIsValidName(2)
                setName(e.target.value)
            } else {
                setName(name)
                toast(<MessageInfoBox message="validationErrorNumberExist" type="0" />, {
                    className: 'red-background',
                });
            }
        }

        if (e.target.name == 'inputCompanyName' && e.target.value == '') {
            // setIsValidCompanyName(1)
            setCompany('')
        } else if (e.target.name == 'inputCompanyName' && e.target.value != '') {
            // setIsValidCompanyName(2)
            setCompany(e.target.value)
        }

        if (e.target.name == 'inputEmail' && e.target.value == '') {
            // setIsValidEmail(1)
            setEmail('')
        } else if (e.target.name == 'inputEmail' && e.target.value != '') {
            if (validateEmail(e.target.value)) {
                // setIsValidEmail(2)
            } else {
                // setIsValidEmail(1)
            }
            setEmail(e.target.value)
        }

    }
    const onFocusOut = (e) => {

        if (e.target.name == 'inputName' && e.target.value == '') {
            setIsValidName(1)
        } else if (e.target.name == 'inputName' && e.target.value != '') {
            if (!hasNumber(e.target.value)) {
                setIsValidName(2)
            } else {
                toast(<MessageInfoBox message="validationErrorNumberExist" type="0" />, {
                    className: 'red-background',
                });
            }
        }

        if (e.target.name == 'inputCompanyName' && e.target.value == '') {
            setIsValidCompanyName(1)
        } else if (e.target.name == 'inputCompanyName' && e.target.value != '') {
            setIsValidCompanyName(2)
        }

        if (e.target.name == 'inputEmail' && e.target.value == '') {
            setIsValidEmail(1)
        } else if (e.target.name == 'inputEmail' && e.target.value != '') {
            if (validateEmail(e.target.value)) {
                setIsValidEmail(2)
            } else {
                setIsValidEmail(1)
            }
        }

    }

    // regex for validating email
    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:~`\s@\"]+(\.[^<>()[\]\\.,;:~`\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const hasNumber = (str) => {
        return /\d/.test(str);
    }

    return (
        <>
            {Object.keys(pageSeoData).length !== 0 &&
                <PageHelmet
                    title={pageSeoData ? `${pageSeoData.acSeoTitle}` : "Ewe Comp"}
                    description={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    keywords={pageSeoData ? `${pageSeoData.acSeoKeywords}` : ""}
                    ogDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    ogTitle={pageSeoData ? `${pageSeoData.acSeoTitle}` : ""}
                    ogUrl={pageSeoData ? `https://ewe.rs/${pageSeoData.acSeoUrl}` : ""}
                    ogImg="https://www.ewe.rs/media/ewe-og-img.jpg"
                    twitterDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                />
            }
            {isRegistered == 1 ?
                <div className="container-fluid new-year-party-clients-registered"></div>
                : isRegistered == 0 ? ""
                    : <div className="container-fluid new-year-party-clients">
                        <div className="row">
                            <div className="new-year-party-container-holder">
                                <div className="login-holder-block-form-new-year">
                                    <div className="container-new-year-reg-form">
                                        {
                                            <>
                                                <form onSubmit={(e) => onSubmitRegistration(e)} noValidate="novalidate">
                                                    {/* {isRegistered == 0 ? <h2>Registracija</h2> : <div><p>Uspešno ste se registrovali</p></div>} */}
                                                    <div className="form-group">
                                                        <div className='input-name'>{t('kompanija')}</div>
                                                        <Form.Control
                                                            type="text"
                                                            className="form-control1 custom-new-year-input-input"
                                                            name="inputCompanyName"
                                                            aria-describedby="companyNameHelp"
                                                            onChange={(e) => { onChangeInput(e) }}
                                                            onBlur={(e) => { onFocusOut(e) }}
                                                            isInvalid={isValidCompanyName == 1}
                                                            isValid={isValidCompanyName == 2}
                                                            value={company}
                                                            autoComplete="off"
                                                            required
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <div className='input-name'>{t('imeIprezime')}</div>
                                                        <Form.Control
                                                            type="text"
                                                            className="form-control1 custom-new-year-input-input"
                                                            name="inputName"
                                                            aria-describedby="nameHelp"
                                                            onChange={(e) => { onChangeInput(e) }}
                                                            onBlur={(e) => { onFocusOut(e) }}
                                                            isInvalid={isValidName == 1}
                                                            isValid={isValidName == 2}
                                                            value={name}
                                                            autoComplete="off"
                                                            required
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <div className='input-name'>{t('email')}</div>
                                                        <Form.Control
                                                            type="email"
                                                            className="form-control1 custom-new-year-input-input"
                                                            name="inputEmail"
                                                            aria-describedby="emailHelp"
                                                            onChange={(e) => { onChangeInput(e) }}
                                                            onBlur={(e) => { onFocusOut(e) }}
                                                            isInvalid={isValidEmail == 1}
                                                            isValid={isValidEmail == 2}
                                                            value={email}
                                                            autoComplete="off"
                                                            required
                                                        />
                                                    </div>
                                                </form>
                                            </>
                                        }
                                        <div className="button-login-content-ny text-center">
                                            <div className='border'>
                                                <button type="submit" className="text-center custom-button-new-year" onClick={handleInput}>{t('posaljiPrijavu')}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>
    );
};

export default NewYearPartyPartnersLanding;
