import React, { useEffect, useState, useMemo } from 'react';
import {
  Switch,
  Route,
  useHistory
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import axios from 'axios';
import { axiosinstance } from './configAxios';
import { HelmetProvider } from 'react-helmet-async';

//komponente
import LeftSidebar from './components/LeftSidebar';

//stranice
import HomePage from './pages/HomePage';
import CatalogPage from './pages/CatalogPage';
import SearchPage from './pages/SearchPage';
import LoginPage from './pages/LoginPage';
import CustomPages from './pages/CustomPages';
import ProductDetailPage from './pages/ProductDetailPage';
import HomePageLogout from './pages/HomePageLogout';
import NewsPage from './pages/NewsPage';
import CartPage from './pages/CartPage';
import ComparePage from './pages/ComparePage';
import IntroPage from './pages/IntroPage';
import IntroBrandsPage from './pages/IntroBrandsPage';
import CartEditingPage from './pages/CartEditingPage';
import PickReturnPage from './pages/PickReturnPage';
import DeliveryPage from './pages/DeliveryPage';
import ProfilePage from './pages/ProfilePage';
import OrderStatusPage from './pages/OrderStatusPage';
import TrendStatusPage from './pages/TrendStatusPage';
import AuctionPage from './pages/AuctionPage';
import ReclamationStatusPage from './pages/ReclamationStatusPage';
import InvoiceOverviewPage from './pages/InvoiceOverviewPage';
import DeliverySchedulePage from './pages/DeliverySchedulePage';
import CurrencyPage from './pages/CurrencyPage';
import TargetPage from './pages/TargetPage';
import AnalyticPage from './pages/AnalyticPage';
import ReservationPage from './pages/ReservationPage';
import ContractPage from './pages/ContractPage';
import NotFoundPage from './pages/NotFoundPage';
import WishListPage from './pages/WishListPage';
import LoyaltyShopPage from './pages/LoyaltyShopPage';
import HumanityPage from './pages/HumanityPage';
import HumanityTreePage from './pages/HumanityTreePage';
import BeHumanityPage from './pages/BeHumanityPage';
import HumanitySolidarity from './pages/HumanitySolidarity';
import RegistrationPage from './pages/RegistrationPage';
import LoyaltyShopLandingPage from './pages/LoyaltyShopLandingPage';
import Terms from './pages/Terms';
import PrivacyPolicy from './pages/PrivacyPolicy';
import OutletPage from './pages/OutletPage';
import SpecialActionPage from './pages/SpecialActionPage';
import ArticlesPage from './pages/ArticlesPage';
import LastProductListPage from './pages/LastProductListPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ContactPage from './pages/ContactPage';
import CustomerCare from './pages/CustomerCare';
import WePresentPage from './pages/WePresentPage';
import CustomExportCatalog from './pages/CustomExportCatalog';
import BekoPage from './pages/BekoPage';
import DellPage from './pages/DellPage';
import NewsletterPage from './pages/NewsletterPage';
import NewYearPartyClientsLanding from './pages/NewYearPartyClientsLanding';
import ProductPdf from './components/exports/ProductPdf';
import CustomExportCatalogPDF from './components/exports/CustomExportCatalogPDF';
import CustomOffer from "./components/exports/CustomOffer";
import CompareProductsPdf from "./components/exports/CompareProductsPdf";
import Sidebar from './components/sidebarMenu/Sidebar';
import ExportProductsPdf from './components/exports/ExportProductsPdf';
import PageHelmet from './components/helmet/PageHelmet';
import InstructionsForUsers from './pages/InstructionsForUsers';
import EweGoCartLanding from './pages/campaigns/EweGoCartLanding';
import EweGoCartLogin from './pages/campaigns/EweGoCartLogin';
import EweGoCartRanking from './pages/campaigns/EweGoCartRanking';
import EweGoCartProfile from './pages/campaigns/EweGoCartProfile';
import OrderStatusPageTest from './pages/OrderStatusPageTest';
// Privremeno ugasene stranice
// import LoyaltyShopBlackFridayLandingPage from './pages/LoyaltyShopBlackFridayLandingPage';
// import CoinsRegistrationPage from './pages/CoinsRegistrationPage';
// import CoinsRangList from './pages/CoinsRangList';
// import CoinsOverview from './pages/CoinsOverview';
// import NewYearLoyaltyLanding from './pages/NewYearLoyaltyLanding';
// import LoyaltyRegistrationPage from './pages/LoyaltyRegistrationPage';
// import EwowocherLanding from './pages/EwowocherLanding';
// import NewYearPartyPartnersLanding from './pages/NewYearPartyPartnersLanding';
// import NewYearPartySubmit from './pages/NewYearPartySubmit';
// import EweSkiLanding from './pages/EweSkiLanding';
// import EweSkiLandingKupci from './pages/EweSkiLandingKupci';
// import SencorLogin from './pages/SencorLogin';
// import SencorLandingPage from './pages/SencorLandingPage';
// import SencorRangList from './pages/SencorRangList';
// import SencorOverview from './pages/SencorOverview';
import TehnovacijeRegistrationPage from './pages/TehnovacijeRegistrationPage';
import Tehnovacije2023LoyaltyShopPage from './pages/Tehnovacije2023LoyaltyShopPage';
import CustomMenuPage from './pages/CustomMenuPage';

import { functionChangeCollapsedMenu } from './redux/actions/menuActions';
import Header from './components/Header';
import { signout, getB2bSettings } from '../src/redux/actions/userActions';

//rute
import PrivateRoute from './routes/PrivateRoute';
import PublicRoute from './routes/PublicRoute';
import LoginRoute from './routes/LoginRoute';
import { useTranslation } from 'react-i18next';
import SecureLS from 'secure-ls';
// import { getB2bSettings } from '../src/redux/actions/userActions';

function App() {

  const dispatch = useDispatch();
  const userSignin = useSelector((state) => state.userInfo);
  const { userInfo } = userSignin;
  // Add your own authentication on the below line.
  const isLoggedIn = userInfo;
  const isDesktop = window.innerWidth > 1020;
  let ls = new SecureLS({ encodingType: 'aes' });
  var userInfoData = ls.get('userInfo');
  const helmetContext = {};
  const [routeConfig, setRouteConfig] = useState([]);
  const [isRouteConfigLoaded, setIsRouteConfigLoaded] = useState(false);
  const { t, ready } = useTranslation(['routes', 'metaTags']);
  const [translatedRoutes, setTranslatedRoutes] = useState(null);
  const history = useHistory();

  useEffect(() => {
    // const APP_VERSION = '1.0.2'; // Ovde stavite trenutnu verziju aplikacije ukoliko je potrebno da se obrise localStorage sa klijentskih masina

    // const storedVersion = localStorage.getItem('appVersion') ? localStorage.getItem('appVersion') : '';

    // if (APP_VERSION !== storedVersion) {
    //   localStorage.clear();
    //   ls.clear();
    //   localStorage.setItem('appVersion', APP_VERSION);
    //   window.location.reload();
    // }

    const currentLanguage = document.documentElement.lang ? document.documentElement.lang : 'en';
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'language_change',
      'language': currentLanguage
    });
    // Povlačenje jezika kako bi se poslao u GTM
    // fetch('/language.json')
    //   .then(response => {
    //     if (!response.ok) {
    //       throw new Error(`HTTP error! status: ${response.status}`);
    //     }
    //     return response.json();
    //   })
    //   .then(language => {
    //     window.dataLayer = window.dataLayer || [];
    //     window.dataLayer.push({
    //       'event': 'language_change',
    //       'language': currentLanguage
    //     });
    //   })
    //   .catch(error => {
    //     console.error('Error:', error);
    //   });
    dispatch(getB2bSettings());

    if (!isLoggedIn) {
      dispatch(functionChangeCollapsedMenu(false, 'homePage', true));

      dispatch({
        type: 'SET_ACTIVE_VIEW',
        payload: 1
      });
    }

    // Globalni tajmer koji proverava da li je istekla sesija ulogovanog korisnika
    const intervalId = setInterval(() => {
      // console.log('Globalni tajmer koji proverava da li je istekla sesija ulogovanog korisnika');
      const isSignoutDispatched = localStorage.getItem('isSignoutDispatched') === 'true';
      const currentDate = new Date();
      const expireDate = new Date(localStorage.getItem('endOfDay'));
      // console.log(isSignoutDispatched, currentDate, expireDate);
      
      if (currentDate > expireDate && !isSignoutDispatched) {
        // console.log('Istekla sesija');
        // console.log('currentDate > expireDate && !isSignoutDispatched = true');
        dispatch(signout(history));
        localStorage.setItem('isSignoutDispatched', 'true');
      }
    }, 3600000);

    // uzimanje IP adrese korisnika
    axios.get(`https://newb2b.ewe.rs:5000/get-client-ip`)
      .then(res => {
        if (res.data.isOk === 1) {
          localStorage.setItem('getClientIp', JSON.stringify(res.data.ip));
        }
        else {
          localStorage.setItem('getClientIp', JSON.stringify(''));
        }
      })

    async function fetchRouteConfig() {
      try {

        const routesInfo = [
          { pageId: 78, component: Terms, publicRoute: true, sidebar: LeftSidebar }, // uslovi koriscenja
          { pageId: 363, component: LoyaltyShopLandingPage, publicRoute: false, sidebar: Sidebar }, // loyalty pocetna
          { pageId: 540, component: AuctionPage, publicRoute: false, sidebar: LeftSidebar }, // aukcija
          { pageId: 548, component: WePresentPage, publicRoute: true, sidebar: LeftSidebar }, // predstavljamo
          { pageId: 764, component: NewsPage, publicRoute: true, sidebar: LeftSidebar }, // vesti
          { pageId: 543, component: CartEditingPage, publicRoute: false, sidebar: LeftSidebar }, // uredjivanje korpi
          { pageId: 763, component: HomePageLogout, publicRoute: true, sidebar: LeftSidebar }, // home page (proizvodi)
          { pageId: 547, component: ProfilePage, publicRoute: false, sidebar: LeftSidebar }, // uredjivanje profila
          { pageId: 352, component: PickReturnPage, publicRoute: false, sidebar: LeftSidebar }, // preuzimanje robe
          { pageId: 350, component: DeliveryPage, publicRoute: false, sidebar: LeftSidebar }, // isporuka krajnjem kupcu
          { pageId: 302, component: OrderStatusPage, publicRoute: false, sidebar: LeftSidebar }, // status porudzbine
          { pageId: 351, component: DeliverySchedulePage, publicRoute: false, sidebar: LeftSidebar }, // raspored dostave
          { pageId: 545, component: CurrencyPage, publicRoute: false, sidebar: LeftSidebar }, // valutacija
          { pageId: 544, component: ReservationPage, publicRoute: false, sidebar: LeftSidebar }, // rezervacije
          { pageId: 542, component: WishListPage, publicRoute: false, sidebar: LeftSidebar }, // lista zelja
          { pageId: 760, component: HumanityTreePage, publicRoute: true, sidebar: Sidebar }, // humanost - posadi svoj hlad
          { pageId: 759, component: BeHumanityPage, publicRoute: true, sidebar: Sidebar }, // budi human
          { pageId: 79, component: PrivacyPolicy, publicRoute: true, sidebar: LeftSidebar }, // politika privatnosti
          { pageId: 355, component: OutletPage, publicRoute: false, sidebar: LeftSidebar }, // outlet
          { pageId: 541, component: LastProductListPage, publicRoute: false, sidebar: LeftSidebar }, // poslednje pregledani proizvodi
          { pageId: 378, component: ContactPage, publicRoute: false, sidebar: LeftSidebar }, // kontakt
          { pageId: 55, component: CustomerCare, publicRoute: false, sidebar: LeftSidebar }, // briga o klijentima
          { pageId: 394, component: NewsletterPage, publicRoute: false, sidebar: LeftSidebar }, // pregled newsletter-a
          { pageId: 761, component: DellPage, publicRoute: true, sidebar: LeftSidebar }, // dell strana
          { pageId: 762, component: BekoPage, publicRoute: true, sidebar: LeftSidebar }, // beko strana
          { pageId: 758, component: InstructionsForUsers, publicRoute: false, sidebar: LeftSidebar }, // uputstvo
          { pageId: 767, component: HumanitySolidarity, publicRoute: true, sidebar: Sidebar }, // humanost solidarnost
          { pageId: 769, component: RegistrationPage, publicRoute: true, sidebar: Sidebar }, // registracija
          { pageId: 770, component: NewYearPartyClientsLanding, publicRoute: true, sidebar: LeftSidebar }, // novogodisnja zurka prijava
          { pageId: 771, component: ReclamationStatusPage, publicRoute: false, sidebar: LeftSidebar }, // status reklamacija
          { pageId: 772, component: ContractPage, publicRoute: false, sidebar: LeftSidebar }, // pregled ugovora i aneksa
          { pageId: 773, component: AnalyticPage, publicRoute: false, sidebar: LeftSidebar }, // analiticka kartica
          { pageId: 765, component: TrendStatusPage, publicRoute: false, sidebar: LeftSidebar }, // trend meseca
          { pageId: 768, component: InvoiceOverviewPage, publicRoute: false, sidebar: LeftSidebar }, // pregled faktura
          { pageId: 774, component: TargetPage, publicRoute: false, sidebar: LeftSidebar }, // pregled targeta
          { pageId: 766, component: LoyaltyShopPage, publicRoute: false, sidebar: LeftSidebar }, // loyalty artikli
          { pageId: 546, component: HumanityPage, publicRoute: true, sidebar: Sidebar }, // humanost
        ];
        const routes = await Promise.all(routesInfo.map(route => getPageSeoUrl(route.pageId)));

        const routeConfig = routes.map((route, index) => ({
          path: route,
          component: routesInfo[index].component,
          isPublic: routesInfo[index].publicRoute,
          sidebar: routesInfo[index].sidebar
        }));

        setRouteConfig(routeConfig);
        setIsRouteConfigLoaded(true);

      } catch (error) {
        console.log(error);
      }
    }

    fetchRouteConfig();
    // Ciscenje intervala prilikom unmount komponente
    return () => {
      clearInterval(intervalId);
    };
  }, []);
  useEffect(() => {
    if (ready) {
      setTranslatedRoutes([
        <LoginRoute key={`${t('prijava')}`} exact path={`/${t('prijava')}`} component={LoginPage} sidebar={LeftSidebar} />,
        <Route key={`${t('intro')}`} exact path={`/${t('intro')}`} component={IntroPage} />,
        <Route key={`${t('brendovi')}`} exact path={`/${t('brendovi')}`} component={IntroBrandsPage} />,
        <Route key={`${t('proizvodDetaljnoPdf')}`} exact path={`/${t('proizvodDetaljnoPdf')}/:productId/:productName?`} component={ProductPdf} />,
        <Route key={`${t('pdfKatalog')}`} exact path={`/${t('pdfKatalog')}/:categoryName?`} component={ExportProductsPdf} />,
        <Route key={`${t('pdfUporediProizvode')}`} exact path={`/${t('pdfUporediProizvode')}/:name?`} component={CompareProductsPdf} />,
        <PublicRoute key={`${t('katalog')}`} exact path={`/${t('katalog')}/:catagoryType/:categoryId/:categoryName?`} component={CatalogPage} sidebar={LeftSidebar} />,
        <PublicRoute key={`${t('proizvod')}`} exact path={`/${t('proizvod')}/:productId/:productName?`} component={ProductDetailPage} sidebar={LeftSidebar} />,
        <PublicRoute key={`${t('pretraga')}`} exact path={`/${t('pretraga')}/`} component={SearchPage} sidebar={LeftSidebar} />,
        <PublicRoute key={`${t('proizvodi')}`} exact path={`/${t('proizvodi')}/:id/:categoryName?`} component={HomePageLogout} sidebar={LeftSidebar} />,
        <PublicRoute key={`${t('uporediProizvode')}`} exact path={`/${t('uporediProizvode')}/:name`} component={ComparePage} sidebar={LeftSidebar} />,
        <PublicRoute key={`${t('stranica')}`} exact path={`/${t('stranica')}/:id/:name?`} component={CustomPages} sidebar={LeftSidebar} />,
        <PublicRoute key={`${t('zaboravljenaLozinka')}`} exact path={`/${t('zaboravljenaLozinka')}/:token/`} component={ForgotPasswordPage} sidebar={LeftSidebar} />,
        <PublicRoute key={`${t('artikli')}`} exact path={`/${t('artikli')}/`} component={ArticlesPage} sidebar={LeftSidebar} />,
        <PrivateRoute key={`${t('korpa')}`} exact path={`/${t('korpa')}/:cartId`} component={CartPage} sidebar={LeftSidebar} />,
        <PrivateRoute key={`${t('pdfPonudaKatalog')}`} exact path={`/${t('pdfPonudaKatalog')}/:categoryName?`} component={CustomExportCatalogPDF} />,
        <PrivateRoute key={`${t('proizvodCustom')}`} exact path={`/${t('proizvodCustom')}/:productName?`} component={CustomOffer} />,
        <PrivateRoute key={`${t('specijalnaAkcija')}`} exact path={`/${t('specijalnaAkcija')}/:name/:id/:pageId`} component={SpecialActionPage} sidebar={LeftSidebar} />,
        <PrivateRoute key={`${t('kreirajPonudu')}`} exact path={`/${t('kreirajPonudu')}/:categoryType/:categoryId/:categoryName?`} component={CustomExportCatalog} sidebar={LeftSidebar} />,
        <PrivateRoute key={`${t('mobilniMeni')}`} exact path={`/${t('mobilniMeni')}/:id/:menuName?`} component={CustomMenuPage} sidebar={LeftSidebar} />
      ]);
    }
  }, [ready, t]);

  const routes = useMemo(() => routeConfig.map(({ path, component, isPublic, sidebar }) =>
    isPublic ?
      <PublicRoute key={path} exact path={path} component={component} sidebar={sidebar} /> :
      <PrivateRoute key={path} exact path={path} component={component} sidebar={sidebar} />
  ), [routeConfig]);

  async function getPageSeoUrl(pageId) {
    const pageSeoUrl = await asyncGetPageSeoUrl(pageId);
    return `${pageSeoUrl}`;
  };

  async function asyncGetPageSeoUrl(pageId) {
    const attributes = {};
    if (pageId) {
      attributes['anElementId'] = pageId;
    } else {
      attributes['anElementId'] = 0;
    }

    const configRes = axiosinstance(attributes, '8223', 'postSeoElementDef');

    try {
      const response = await axios(configRes);

      if (response.data.isOk === '1') {
        return response.data.response.setSeoElementDef.acSeoUrl;
      } else {
        return response.data.response;
      }
    } catch (error) {
      console.log(error, 'error');
    }
  }

  return (
    <HelmetProvider context={helmetContext}>
      <div>
        <PageHelmet
          title={t('metaTags:title')}
          description={t('metaTags:description')}
          ogDescription={t('metaTags:description')}
          ogTitle={t('metaTags:ogTitle')}
          keywords={t('metaTags:keywords')}
          ogUrl={t('metaTags:ogUrl')}
          ogType="website"
          twitterDescription={t('metaTags:description')}
        />
        <div className="wrapper">
          {!isDesktop && <Header />}
          <Switch>

            {isRouteConfigLoaded && routes}

            {/* TODO: vratiti rute ukoliko bude potrebno da linkovi budu na cirilici */}
            {/* {translatedRoutes && translatedRoutes} */}
            <LoginRoute exact path="/prijava" component={LoginPage} sidebar={LeftSidebar} />
            {/* <Route exact path="/ls" component={LeftSidebar} /> */}
            <Route exact path="/brendovi" component={IntroBrandsPage} />
            <Route exact path="/intro" component={IntroPage} />
            <Route exact path="/proizvod-detaljno-pdf/:productId/:productName?" component={ProductPdf} />
            <Route exact path="/pdf-katalog/:categoryName?" component={ExportProductsPdf} />
            <Route exact path="/pdf-uporedi-proizvode/:name?" component={CompareProductsPdf} />

            {/* Public routes */}
            <PublicRoute exact path="/katalog/:catagoryType/:categoryId/:categoryName?" component={CatalogPage} sidebar={LeftSidebar} />
            <PublicRoute exact path="/proizvod/:productId/:productName?" component={ProductDetailPage} sidebar={LeftSidebar} />
            <PublicRoute exact path="/pretraga/" component={SearchPage} sidebar={LeftSidebar} />
            <PublicRoute exact path="/proizvodi/:id/:categoryName?" component={HomePageLogout} sidebar={LeftSidebar} />
            <PublicRoute exact path="/uporedi-proizvode/:name" component={ComparePage} sidebar={LeftSidebar} />
            <PublicRoute exact path="/stranica/:id/:name?" component={CustomPages} sidebar={LeftSidebar} />
            <PublicRoute exact path="/zaboravljena-lozinka/:token/" component={ForgotPasswordPage} sidebar={LeftSidebar} />
            <PublicRoute exact path="/artikli" component={ArticlesPage} sidebar={LeftSidebar} />
            <PublicRoute exact path="/status-porudzbine-dev" component={OrderStatusPageTest} sidebar={LeftSidebar} />

            {/* Privremeno ugasene stranice */}
            {/* <PublicRoute exact path="/ewe-ski-prijava-kupci" component={EweSkiLandingKupci} sidebar={LeftSidebar} /> */}
            {/* <PublicRoute exact path="/ewe-ski-prijava-vendori" component={EweSkiLanding} sidebar={LeftSidebar} /> */}
            {/* <PublicRoute exact path="/sencor-akcija-prijava" component={SencorLogin} sidebar={LeftSidebar} /> */}
            <PublicRoute exact path="/tehnovacije-prijava" component={TehnovacijeRegistrationPage} sidebar={LeftSidebar} />

            {/* Private routes */}
            <PrivateRoute exact path="/ewe-go-cart" component={EweGoCartLanding} sidebar={Sidebar} />
            <PrivateRoute exact path="/ewe-go-cart-login" component={EweGoCartLogin} sidebar={Sidebar} />
            <PrivateRoute exact path="/ewe-go-cart-ranking" component={EweGoCartRanking} sidebar={Sidebar} />
            <PrivateRoute exact path="/ewe-go-cart-profile" component={EweGoCartProfile} sidebar={Sidebar} />

            <PrivateRoute exact path="/korpa/:cartId" component={CartPage} sidebar={LeftSidebar} />
            <PrivateRoute exact path="/pdf-ponuda-katalog/:categoryName?" component={CustomExportCatalogPDF} />
            <PrivateRoute exact path="/proizvod-custom/:productName?" component={CustomOffer} />
            <PrivateRoute exact path="/specijalna-akcija/:name/:id/:pageId" component={SpecialActionPage} sidebar={LeftSidebar} />
            <PrivateRoute exact path="/kreiraj-ponudu/:categoryType/:categoryId/:categoryName?" component={CustomExportCatalog} sidebar={LeftSidebar} />

            {/* Privremeno ugasene stranice */}
            {/* <PrivateRoute exact path="/loyalty-pocetna-black-friday" component={LoyaltyShopBlackFridayLandingPage} sidebar={Sidebar} /> */}
            {/* <PrivateRoute exact path="/rang-lista" component={CoinsRangList} sidebar={LeftSidebar} /> */}
            {/* <PrivateRoute exact path="/pregled-bodova" component={CoinsOverview} sidebar={LeftSidebar} /> */}
            {/* <PrivateRoute exact path="/novogodisnji-loyalty" component={NewYearLoyaltyLanding} sidebar={Sidebar} /> */}
            {/* <PrivateRoute exact path="/novogodisnji-loyalty-registracija" component={CoinsRegistrationPage} sidebar={LeftSidebar} /> */}
            {/* <PrivateRoute exact path="/novogodisnji-loyalty-prijava" component={LoyaltyRegistrationPage} sidebar={LeftSidebar} /> */}
            {/* <PrivateRoute exact path="/ewowcher" component={EwowocherLanding} sidebar={LeftSidebar} /> */}
            {/* <PrivateRoute exact path="/novogodisnja-zurka-prijava-kupci" component={NewYearPartyPartnersLanding} sidebar={LeftSidebar} /> */}
            {/* <PrivateRoute exact path="/novogodisnja-zurka-uspesna-prijava" component={NewYearPartySubmit} sidebar={LeftSidebar} /> */}
            {/* <PrivateRoute exact path="/sencor-akcija" component={SencorLandingPage} sidebar={LeftSidebar} /> */}
            {/* <PrivateRoute exact path="/rang-lista-sencor" component={SencorRangList} sidebar={LeftSidebar} /> */}
            {/* <PrivateRoute exact path="/moji-bodovi-sencor" component={SencorOverview} sidebar={LeftSidebar} /> */}
            <PrivateRoute exact path="/ewe-go-cart-loyalty" component={Tehnovacije2023LoyaltyShopPage} sidebar={LeftSidebar} />
            <PrivateRoute exact path="/meni/:id/:menuName?" component={CustomMenuPage} sidebar={LeftSidebar} />

            {/* Stranice koje se pozivaju preko */}
            {/* <PrivateRoute exact path="/status-reklamacija" component={ReclamationStatusPage} sidebar={LeftSidebar} /> */}
            {/* <PrivateRoute exact path="/pregled-ugovora-i-aneksa" component={ContractPage} sidebar={LeftSidebar} /> */}
            {/* <PrivateRoute exact path="/analiticka-kartica" component={AnalyticPage} sidebar={LeftSidebar} /> */}
            {/* <PrivateRoute exact path="/trend-meseca" component={TrendStatusPage} sidebar={LeftSidebar} /> */}
            {/* <PrivateRoute exact path="/pregled-faktura" component={InvoiceOverviewPage} sidebar={LeftSidebar} /> */}
            {/* <PrivateRoute exact path="/pregled-targeta" component={TargetPage} sidebar={LeftSidebar} /> */}
            {/* <PrivateRoute exact path="/loyalty-artikli" component={LoyaltyShopPage} sidebar={Sidebar} /> */}
            {/* <PrivateRoute exact path="/profil" component={ProfilePage} sidebar={LeftSidebar} /> */}
            {/* <PrivateRoute exact path="/uredjivanje-korpi" component={CartEditingPage} sidebar={LeftSidebar} /> */}
            {/* <PrivateRoute exact path="/preuzimanje-robe" component={PickReturnPage} sidebar={LeftSidebar} /> */}
            {/* <PrivateRoute exact path="/isporuka-krajnjem-kupcu" component={DeliveryPage} sidebar={LeftSidebar} /> */}
            {/* <PrivateRoute exact path="/status-porudzbine" component={OrderStatusPage} sidebar={LeftSidebar} /> */}
            {/* <PrivateRoute exact path="/aukcija" component={AuctionPage} sidebar={LeftSidebar} /> */}
            {/* <PrivateRoute exact path="/raspored-dostave" component={DeliverySchedulePage} sidebar={LeftSidebar} /> */}
            {/* <PrivateRoute exact path="/valutacija" component={CurrencyPage} sidebar={LeftSidebar} /> */}
            {/* <PrivateRoute exact path="/rezervacije" component={ReservationPage} sidebar={LeftSidebar} /> */}
            {/* <PrivateRoute exact path="/lista-zelja" component={WishListPage} sidebar={LeftSidebar} /> */}
            {/* <PrivateRoute exact path="/loyalty-pocetna" component={LoyaltyShopLandingPage} sidebar={Sidebar} /> */}
            {/* <PrivateRoute exact path="/outlet" component={OutletPage} sidebar={LeftSidebar} /> */}
            {/* <PrivateRoute exact path="/pregledani-proizvodi" component={LastProductListPage} sidebar={LeftSidebar} /> */}
            {/* <PrivateRoute exact path="/kontakt" component={ContactPage} sidebar={LeftSidebar} /> */}
            {/* <PrivateRoute exact path="/briga-o-klijentima" component={CustomerCare} sidebar={LeftSidebar} /> */}
            {/* <PrivateRoute exact path="/pregled-newslettera" component={NewsletterPage} sidebar={LeftSidebar} /> */}
            {/* <PrivateRoute exact path="/uputstvo" component={InstructionsForUsers} sidebar={LeftSidebar} /> */}
            {/* <PublicRoute exact path="/humanost-ewe" component={HumanityPage} sidebar={Sidebar} /> */}
            {/* <PublicRoute exact path="/humanost-ewe-solidarnost" component={HumanitySolidarity} sidebar={Sidebar} /> */}
            {/* <PublicRoute exact path="/registracija" component={RegistrationPage} sidebar={LeftSidebar} /> */}
            {/* <PublicRoute exact path="/novogodisnja-zurka-prijava" component={NewYearPartyClientsLanding} sidebar={LeftSidebar} /> */}
            {/* <PublicRoute exact path="/proizvodi" component={HomePageLogout} sidebar={LeftSidebar} /> */}
            {/* <PublicRoute exact path="/vesti" component={NewsPage} sidebar={LeftSidebar} /> */}
            {/* <PublicRoute exact path="/humanost-ewe-posadi-svoj-hlad" component={HumanityTreePage} sidebar={Sidebar} /> */}
            {/* <PublicRoute exact path="/budi-human-ewe" component={BeHumanityPage} sidebar={Sidebar} /> */}
            {/* <PublicRoute exact path="/uslovi-koriscenja" component={Terms} sidebar={LeftSidebar} /> */}
            {/* <PublicRoute exact path="/politika-privatnosti" component={PrivacyPolicy} sidebar={LeftSidebar} /> */}
            {/* <PublicRoute exact path="/nedeljna-akcija" component={WeeklyActionPage} sidebar={LeftSidebar} /> */}
            {/* <PublicRoute exact path="/predstavljamo" component={WePresentPage} sidebar={LeftSidebar} /> */}
            {/* <PublicRoute exact path="/beko" component={BekoPage} sidebar={LeftSidebar} /> */}
            {/* <PublicRoute exact path="/dell" component={DellPage} sidebar={LeftSidebar} /> */}

            {userInfoData ? (
              <PublicRoute exact path="/" component={HomePage} sidebar={LeftSidebar} />
            ) : (
              <Route exact path="/" component={IntroPage} />
            )}

            {/* Cekamo da stignu podaci o rutama a tek onda dozvoljavamo rutu za 404 stranu ukoliko trazeni url ne postoji definisan u rutama */}
            {isRouteConfigLoaded && <PublicRoute component={NotFoundPage} sidebar={LeftSidebar} />}
          </Switch>
        </div>
      </div>
    </HelmetProvider>
  );
}

export default withRouter(App);
