import React, { createRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import Moment from 'react-moment';
import MaterialTable from "material-table";
import { Paper } from '@material-ui/core';

import PatchedPagination from '../../components/other/PatchedPagination'

import { addToCart, setCartClone } from '../../redux/actions/cartActions'
import { useTranslation } from 'react-i18next';

function CurrencyFormatFunction(num) {
    return num == null ? 0 : num.toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
};

const ModalOrderStatus = ({ show, handleClose, item, getCurrencyItems }) => {
    const tableRef = createRef();
    const dispatch = useDispatch();
    const { t } = useTranslation('modal');

    const getActiveCartFunction = useSelector((state) => state.getActiveCartFunction);

    const addItemToCart = (item2) => {
        dispatch(addToCart(item2, item2.anQty, getActiveCartFunction))
        handleClose()
    }

    const setFunctionAgainOrderAction = (data, val) => {
        dispatch(setCartClone(data, getCurrencyItems, val))
        handleClose()
    };

    return (
        <Modal
            show={show}
            onHide={handleClose}
            keyboard={false}
            dialogClassName='modal-xl'
            aria-labelledby=""
            centered
        >
            <div className="close-action-modal" variant="secondary" onClick={handleClose}>
                <i className="far fa-times-circle"></i>
            </div>
            <Modal.Header>
                <Modal.Title>
                    {t('nalog')} <b>{item.acKey}</b>
                    {item.acDocKey && <h5>{t('faktura')} <b>{item.acDocKey}</b></h5>}
                </Modal.Title>

                <span><Moment format="DD.MM.YYYY.">{item.adDate}</Moment></span>
            </Modal.Header>
            <Modal.Body>
                <MaterialTable
                    components={{
                        Pagination: PatchedPagination,
                        Container: props => <Paper {...props} elevation={0} />
                    }}
                    localization={{
                        header: {
                            actions: ''
                        },
                        pagination: {
                            labelRowsSelect: '',
                            firstAriaLabel: t('prvaStrana'),
                            firstTooltip: t('prvaStrana'),
                            previousAriaLabel: t('preth'),
                            previousTooltip: t('preth'),
                            nextAriaLabel: t('sledStr'),
                            nextTooltip: t('sledStr'),
                            lastAriaLabel: t('posleStr'),
                            lastTooltip: t('posleStr'),
                        },
                    }}
                    data={item.itemList}
                    tableRef={tableRef}
                    columns={[
                        {
                            title: <>P <i className="fas fa-sort mx-1"></i></>,
                            field: "anNo",
                        },
                        {
                            title: <>{t('artikal')} <i className="fas fa-sort mx-1"></i></>,
                            field: "acProduct",
                            type: "string",
                        },
                        // {
                        //     title: "Brend",
                        //     field: "acDept",
                        // },
                        {
                            title: <>{t('naziv')} <i className="fas fa-sort mx-1"></i></>,
                            field: "acName",
                            render: item2 => {
                                return <div className="order-status-item" title={item2.acDept + " " + item2.acName}>{item2.acDept} {item2.acName}</div>
                            },
                            cellStyle: { minWidth: "250px", width: "250px", padding: "8px" }
                        },
                        {
                            title: <>K <i className="fas fa-sort mx-1"></i></>,
                            field: "anQty",
                        },
                        {
                            title: <>{t('jedCena')}<i className="fas fa-sort mx-1"></i></>,
                            field: "anPrice",
                            render: item2 => {
                                return CurrencyFormatFunction(item2.anPrice) + ' ' + item.acCurrency
                            }
                        },
                        {
                            title: <>{t('porez')} <i className="fas fa-sort mx-1"></i></>,
                            field: "anVAT",
                            render: item2 => {
                                return (item2.anVAT + "%");
                            }
                        },
                        {
                            title: <>{t('ukupno')} <i className="fas fa-sort mx-1"></i></>,
                            field: "anValue",
                            render: item2 => {
                                return CurrencyFormatFunction(item2.anValue) + ' ' + item.acCurrency
                            }
                        },
                        {
                            title: "",
                            render: item2 => {
                                return (
                                    <div onClick={() => addItemToCart(item2)}>
                                        <div className="text-center btn btn-success w-100 btn-sm">
                                            <div className="add-button"><i className="fas fa-shopping-cart"></i>&nbsp; {t('dodaj')}
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        },
                    ]}
                    options={{
                        pageSize: 5,
                        pageSizeOptions: [5, 10, 20],
                        headerStyle: {
                            // whiteSpace: "nowrap",
                            borderBottom: "2px solid #dee2e6",
                            fontWeight: "bold",
                            padding: "8px"
                        },
                        rowStyle: {
                            // whiteSpace: "nowrap",
                        },
                        cellStyle: {
                            padding: "8px"
                        },
                        search: false,
                        showTitle: false,
                        toolbar: false,
                        filtering: false,
                        draggable: false,
                        thirdSortClick: false,
                    }}
                    title=""
                />
                {/* <table className="table detail-status-order-table-modal">
                    <thead>
                        <tr>
                            <th title="Pozicija">Poz.</th>
                            <th>Artikal</th>
                            <th>Brend</th>
                            <th>Naziv</th>
                            <th title="Količina">Kol.</th>
                            <th className="price-value-column-modal-status-order" title="Jedinična cena">Jed. cena</th>
                            <th>Porez</th>
                            <th className="price-value-column-modal-status-order">Ukupno</th>
                        </tr>
                    </thead>
                    <tbody>
                        {item.itemList.map((item2, key) => (
                            <tr>
                                <td>{item2.anNo}</td>
                                <td>{item2.acProduct}</td>
                                <td>{item2.acDept}</td>
                                <td>{item2.acName}</td>
                                <td>{item2.anQty}</td>
                                <td><CurrencyFormat num={item2.anPrice} /> RSD</td>
                                <td>{item2.anVAT}%</td>
                                <td><CurrencyFormat num={item2.anValue} /> RSD</td>
                            </tr>
                        ))}
                    </tbody>
                </table> */}
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-info add-all" onClick={() => setFunctionAgainOrderAction(item, "I")}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51.33 43.28">
                        <g data-name="Layer 2">
                            <g data-name="Layer 1">
                                <path
                                    d="M45.42 9.66a6 6 0 011.28 1.28l2.85-2.07a9.15 9.15 0 00-2.06-2.06zm5.91 10.72c0-2.79 0-5-.19-6.81a9.38 9.38 0 00-1.59-4.7l-2.85 2.07a6 6 0 01.94 3c.16 1.55.17 3.56.17 6.43zM36 8.55c2.87 0 4.88 0 6.43.17a6 6 0 013 .94l2.07-2.85a9.38 9.38 0 00-4.7-1.59C41 5 38.77 5 36 5zm10.7 21.77a5.55 5.55 0 01-1.28 1.28l2.07 2.85a9.15 9.15 0 002.06-2.06zM36 36.23c2.79 0 5 0 6.81-.19a9.29 9.29 0 004.7-1.59l-2.09-2.85a5.9 5.9 0 01-3 .94c-1.55.17-3.56.17-6.43.17zm11.81-15.35c0 2.87 0 4.88-.17 6.43a6 6 0 01-.94 3l2.85 2.07a9.38 9.38 0 001.59-4.7c.19-1.78.19-4 .19-6.81zM17.54 31.77a6 6 0 01-1.25-1.1l-2.69 2.27a9.26 9.26 0 002 1.78zm-6.74-9c.31 2.47.56 4.45.92 6a9.52 9.52 0 001.88 4.12l2.69-2.27A5.91 5.91 0 0115.16 28c-.32-1.36-.54-3.13-.86-5.67zM26 32.71c-2.56 0-4.35 0-5.73-.14a5.92 5.92 0 01-2.76-.8l-1.92 2.95a9.32 9.32 0 004.32 1.35c1.61.16 3.6.16 6.09.16zM14.3 22.36l-2-15.79L8.82 7l2 15.79zM36 32.71H26v3.52h10zm11.81-12.33v.5h3.52v-.5zM10.57 8.55H36V5H10.57zm37.56 1.36l-1.43 1zm-1.67-1.67l-1 1.42zm0 24.79l1 1.42zm1.67-1.67l-1.43-1zM15 31.8l-1.4 1.14zm1.63 1.44l1-1.47zm-6-26.45V5h-2l.25 2z"
                                    className="cls-1"
                                ></path>
                                <path
                                    d="M10.29 2.08A4.26 4.26 0 006.63 0v3.52a.74.74 0 01.64.37zM6.63 0H1.76v3.52h4.87zm6.71 7.15l-3.05-5.07-3 1.81L10.32 9zM1.76 0a1.76 1.76 0 000 3.52z"
                                    className="cls-1"
                                ></path>
                                <circle cx="40.76" cy="40.76" r="2.52" className="cls-1"></circle>
                                <circle cx="23.15" cy="40.76" r="2.52" className="cls-1"></circle>
                                <path
                                    d="M40.26 16.86h-6.54v3.52h6.54zm-6.54 0a1.76 1.76 0 100 3.52zm6.54 3.52a1.76 1.76 0 100-3.52z"
                                    className="cls-1"
                                ></path>
                            </g>
                        </g>
                    </svg>
                    &nbsp; {t('dodajSve')}</button>
                <button className="btn btn-warning" onClick={() => setFunctionAgainOrderAction(item, "C")}>{t('ponoviKupovinu')}</button>
                <button className="btn btn-secondary" onClick={handleClose}>{t('zatvori')}</button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalOrderStatus;