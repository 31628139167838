var a = {
  "Č":"c",
  "č":"c",
  "Ć":"c",
  "ć":"c",
  "Ž":"z",
  "ž":"z",
  "Š":"s",
  "š":"s",
  "Đ":"dj",
  "đ":"dj",
  "А":"a",
  "Б":"b",
  "В":"v",
  "Г":"g",
  "Д":"d",
  "Ђ":"dj",
  "Е":"e",
  "Ж":"z",
  "З":"z",
  "И":"i",
  "Ј":"j",
  "К":"k",
  "Л":"l",
  "Љ":"lj",
  "М":"m",
  "Н":"n",
  "Њ":"nj",
  "О":"o",
  "П":"p",
  "Р":"r",
  "С":"s",
  "Т":"t",
  "Ћ":"c",
  "У":"u",
  "Ф":"f",
  "Х":"h",
  "Ц":"c",
  "Ч":"c",
  "Џ":"dz",
  "Ш":"s",
  "а":"a",
  "б":"b",
  "в":"v",
  "г":"g",
  "д":"d",
  "ђ":"dj",
  "е":"e",
  "ж":"z",
  "з":"z",
  "и":"i",
  "ј":"j",
  "к":"k",
  "л":"l",
  "љ":"lj",
  "м":"m",
  "н":"n",
  "њ":"nj",
  "о":"o",
  "п":"p",
  "р":"r",
  "с":"s",
  "т":"t",
  "ћ":"c",
  "у":"u",
  "ф":"f",
  "х":"h",
  "ц":"c",
  "ч":"c",
  "џ":"dz",
  "ш":"s",
  "Q":"q",
  "W":"w",
  "E":"e",
  "R":"r",
  "T":"t",
  "Y":"y",
  "U":"u",
  "I":"i",
  "O":"o",
  "P":"p",
  "A":"a",
  "S":"s",
  "D":"d",
  "F":"f",
  "G":"g",
  "H":"h",
  "J":"j",
  "K":"k",
  "L":"l",
  "Z":"z",
  "X":"x",
  "C":"c",
  "V":"v",
  "B":"b",
  "N":"n",
  "M":"m",
};

export function searchLetters(word){
  
  return word && word.split('').map(function (char) { 
    return a[char] || char; 
  }).join("");
};