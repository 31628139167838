import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { axiosinstance } from '../../configAxios';

import { SetVatFunction, changeProductView, changeProductSort, editUserFunction } from '../../redux/actions/userActions';

import CurrencyMenuItem from '../other/CurrencyMenuItem';
import MessageInfoBox from '../other/MessageInfoBox';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import SecureLS from 'secure-ls';

const UserProfile = ({ user }) => {

    const dispatch = useDispatch();
    const location = useLocation();
    let ls = new SecureLS({ encodingType: 'aes' });

    const getCurrencyItems = useSelector((state) => state.getCurrencyItems);
    const activeVat = JSON.parse(localStorage.getItem('activeVat'));
    const userSettings = ls.get('userInfo');
    const activeCurrency = useSelector((state) => state.activeCurrency);
    const currentPassword = user.acPassword;

    const activeProductSort = useSelector((state) => state.activeProductSort.item);
    const [sortList, setSortList] = useState([]);

    const activeProductView = useSelector((state) => state.activeProductView.item);
    const [catalogView, setCatalogView] = useState(activeProductView > 0 ? activeProductView : 1);

    const [values, setValues] = useState({
        acFirstName: userSettings.company.department[0].user[0].acFirstName,
        acLastName: userSettings.company.department[0].user[0].acLastName,
        acJobPosition: userSettings.company.department[0].user[0].acJobPosition,
        acPassword: "",
        newPassword: "",
        passRep: "",
        priceVat: activeVat,
        anCatalogViewType: activeProductView,
        anCatalogSortingType: activeProductSort,
        anMargin: userSettings.company.department[0].user[0].userSettings[0].anMargin,
        anMarkUp: userSettings.company.department[0].user[0].userSettings[0].anMarkUp,
    });
    const { t } = useTranslation('profile');

    const [oldPass, setOldPass] = useState(0);
    const [passwordRep, setPasswordRep] = useState(0);

    const functionListSort = () => {
        const attributes = {};
        const configRes = axiosinstance(attributes, '8224', 'getSort');
        axios(configRes)
            .then(function (response) {
                setSortList(response.data.response.sortList);
            })
            .catch(function (error) {

            });
    };

    const onChangeFunction = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });

        if ([e.target.name] == "priceVat") {
            dispatch(SetVatFunction(e.target.value, location));
        }

        if ([e.target.name] == "anCatalogSortingType") {
            dispatch(changeProductSort(e.target.value));
        }

        if ([e.target.name] == "anCatalogViewType") {
            setCatalogView(e.target.value);
            dispatch(changeProductView(e.target.value));
        }

        if ([e.target.name] == "acPassword") {
            if (e.target.value != currentPassword) {
                setOldPass(1);
            } else {
                setOldPass(2);
            }

            if (e.target.value == "") {
                setOldPass(0);
            }
        }

        if ([e.target.name] == "passRep") {
            if (e.target.value !== values.newPassword) {
                setPasswordRep(1)
            } else {
                setPasswordRep(2)
            }

            if (e.target.value == "") {
                setPasswordRep(0);
            }
        }
    }

    const handleInput = () => {
        if (oldPass == 2 && passwordRep == 2) {
            dispatch(editUserFunction(values, ""));
        } else if (oldPass == 0 && passwordRep == 0) {
            dispatch(editUserFunction(values, ""));
        } else {
            toast(<MessageInfoBox message="errorReqiuredPasswordInputs" type="0" />, {
                className: 'red-background',
            });
        }


    }

    useEffect(() => {
        functionListSort();
        setValues({
            acFirstName: userSettings.company.department[0].user[0].acFirstName,
            acLastName: userSettings.company.department[0].user[0].acLastName,
            acJobPosition: userSettings.company.department[0].user[0].acJobPosition,
            acPassword: "",
            newPassword: "",
            passRep: "",
            priceVat: activeVat,
            anCatalogViewType: activeProductView,
            anCatalogSortingType: activeProductSort,
            anMargin: userSettings.company.department[0].user[0].userSettings[0].anMargin,
            anMarkUp: userSettings.company.department[0].user[0].userSettings[0].anMarkUp,
        })
    }, [])

    return (
        <>
            <div className="row">
                <div className="col-sm-12">
                    <div className="block-profile-data">
                        <h4 className="title-block-profile-data">{t('osnovniPodaci')}</h4>
                        <div className="block-profile-data-content">
                            <form>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="acFirstName">{t('ime')}</label>
                                            <input
                                                id='acFirstName'
                                                name="acFirstName"
                                                type="text"
                                                className="form-control custom-input-check form-control-sm"
                                                value={values.acFirstName}
                                                onChange={(e) => onChangeFunction(e)}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="acLastName">{t('prezime')}</label>
                                            <input
                                                id='acLastName'
                                                name="acLastName"
                                                type="text"
                                                className="form-control custom-input-check form-control-sm"
                                                value={values.acLastName}
                                                onChange={(e) => onChangeFunction(e)}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="acEmail">{t('email')}:</label>
                                            <input
                                                id='acEmail'
                                                type="email"
                                                readOnly
                                                className="form-control custom-input-check form-control-sm"
                                                defaultValue={user.acEmail}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="acPassword">{t('staraLozinka')}</label>
                                            <Form.Control
                                                size="sm"
                                                type="password"
                                                id="acPassword"
                                                name="acPassword"
                                                isInvalid={oldPass == 1}
                                                isValid={oldPass == 2}
                                                autoComplete="new-password"
                                                onChange={(e) => onChangeFunction(e)}
                                            />
                                            {oldPass == 1 &&
                                                <div className="invalid-feedback d-block">
                                                    {t('pogresnaLozinka')}
                                                </div>
                                            }
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="newPassword">{t('novaLozinka')}</label>
                                            <Form.Control
                                                size="sm"
                                                type="password"
                                                id="newPassword"
                                                name="newPassword"
                                                autoComplete="off"
                                                onChange={(e) => onChangeFunction(e)}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="passRep">{t('ponovoLozinka')}</label>
                                            <Form.Control
                                                size="sm"
                                                type="password"
                                                id="passRep"
                                                name="passRep"
                                                autoComplete="off"
                                                isInvalid={passwordRep == 1}
                                                isValid={passwordRep == 2}
                                                onChange={(e) => onChangeFunction(e)}
                                            />
                                            {passwordRep == 1 &&
                                                <div className="invalid-feedback d-block">
                                                    {t('nijeIsta')}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div className="text-right">
                                <div className="btn-sbmit-delviery text-center" onClick={handleInput}>{t('promeniPodatke')}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="block-profile-data">
                        <h4 className="title-block-profile-data">{t('podesavanja')}</h4>
                        <div className="block-profile-data-content">
                            <form>
                                <div className="row">
                                    <div className="form-group col-sm-8">
                                        <div className="block-profile-data-content-price-value">
                                            <div className="row">
                                                <div className="col">
                                                    <div className="dashboard-value-item">
                                                        <div className="dashboard-item-radio-name">
                                                            {t('valuta')}
                                                        </div>
                                                        <div className="dashboard-item-radio-buttons">
                                                            {getCurrencyItems.map((item, key) => (
                                                                <CurrencyMenuItem key={key} item={item} isPriceInEur={activeCurrency._id} />
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="dashboard-value-price">
                                                        <div className="dashboard-item-radio-name">
                                                            {t('cenePdv')}
                                                        </div>
                                                        <div className="dashboard-item-radio-buttons">
                                                            <div className="form-check form-check-inline">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="priceVat"
                                                                    id="priceVat1"
                                                                    defaultValue="1"
                                                                    defaultChecked={activeVat == 1 && 'checked'}
                                                                    onChange={(e) => onChangeFunction(e)}
                                                                />
                                                                <label className="form-check-label" htmlFor="priceVat1">{t('da')}</label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="priceVat"
                                                                    id="priceVat2"
                                                                    defaultValue="0"
                                                                    defaultChecked={activeVat == 0 && 'checked'}
                                                                    onChange={(e) => onChangeFunction(e)}
                                                                />
                                                                <label className="form-check-label" htmlFor="priceVat2">{t('ne')}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row user-info-select">
                                                <div className="col-sm-6">
                                                    <div className="form-group dashboard-value-price">
                                                        <label className="form-check-label" htmlFor="anCatalogViewType">{t('tipPregledaKataloga')}</label>
                                                        <select
                                                            className="form-control user-info-select-input form-control form-control-sm"
                                                            name="anCatalogViewType"
                                                            value={catalogView}
                                                            onChange={(e) => onChangeFunction(e)}
                                                        >
                                                            <option key={1} value={1}>{t('mrezni')}</option>
                                                            <option key={2} value={2}>{t('tabelarni')}</option>
                                                            <option key={3} value={3}>{t('listni')}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group dashboard-value-price">
                                                        <label className="form-check-label" htmlFor="anCatalogSortingType">{t('tipSortiranja')}</label>
                                                        <select
                                                            className="form-control user-info-select-input form-control form-control-sm"
                                                            name="anCatalogSortingType"
                                                            value={activeProductSort}
                                                            onChange={(e) => onChangeFunction(e)}
                                                        >
                                                            {sortList.map((item, key) => (
                                                                <option
                                                                    key={key}
                                                                    value={item._id}
                                                                    defaultValue={item._id}
                                                                >
                                                                    {item.acName}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 user-info-margin">
                                        <div className="col">
                                            <div className="form-group">
                                                <label className="form-check-label" htmlFor="anMarkUp">{t('margina')}</label>
                                                <input
                                                    className="form-control custom-input-check form-control-sm"
                                                    type="number"
                                                    name="anMarkUp"
                                                    id="anMarkUp"
                                                    value={values.anMarkUp}
                                                    onChange={(e) => onChangeFunction(e)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="form-group">
                                                <label className="form-check-label" htmlFor="anMargin">{t('marza')}</label>
                                                <input
                                                    className="form-control custom-input-check form-control-sm"
                                                    type="number"
                                                    name="anMargin"
                                                    id="anMargin"
                                                    value={values.anMargin}
                                                    onChange={(e) => onChangeFunction(e)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="text-right">
                            <div className="btn-sbmit-delviery text-center" onClick={handleInput}>{t('promeniPodatke')}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserProfile