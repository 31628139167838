import { ActionTypes } from '../contants/action-types';

const initialState = {
    showMenu: false
};

const initialStateSettings = {
    isPriceInEur: 0,
    isPriceWithVat: 0,
};

export const userReducer = (state = {}, action) => {
    switch (action.type) {
        case ActionTypes.USER_SIGNIN_REQUEST:
            return { loading: true }
        case ActionTypes.USER_SIGNIN_SUCCESS:
            return {
                loading: false,
                userInfo: action.payload
            }
        case ActionTypes.USER_SIGNIN_FAIL:
            return {
                loading: false,
                isOk: action.payload,
                message: action.message,
            }
        case ActionTypes.USER_SIGNOUT:
            return {};
        default:
            return state;
    }
}

export const getUserNewsReducer = (state = { items: [] }, action) => {
    switch (action.type) {
        case ActionTypes.GET_USER_NEWS:
            return {
                items: action.payload
            }
        default:
            return state;
    };
};

export const getUserPopupReducer = (state = { items: [] }, action) => {
    switch (action.type) {
        case ActionTypes.GET_USER_POPUP:
            return {
                items: action.payload
            }
        default:
            return state;
    };
};

export const getUserBannerReducer = (state = { items: [] }, action) => {
    switch (action.type) {
        case ActionTypes.GET_USER_BANNER:
            return {
                items: action.payload
            }
        default:
            return state;
    };
};

export const getUserSurveyReducer = (state = { items: [] }, action) => {
    switch (action.type) {
        case "GET_USER_SURVEY":
            return {
                items: action.payload
            }
        default:
            return state;
    };
};

export const menuReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SET_MENU_MOBILE:
            return { showMenu: action.payload }
        default:
            return state;
    };
};

export const getUserSettings = (state = initialStateSettings, action) => {
    switch (action.type) {
        case 'SET_USER_SETTINGS':
            return {
                items: {
                    isPriceInEur: action.payload.isPriceInEur,
                    isPriceWithVat: action.payload.isPriceWithVat,
                }
            };
        default:
            return state;
    };
};

export const SetCurrencyFunction = (state = { item: [] }, action) => {
    switch (action.type) {
        case 'SET_ACTIVE_CURRENCY':
            return {
                item: action.payload
            };
        default:
            return state;
    };
};

export const SetVatFunction = (state = { item: [] }, action) => {
    switch (action.type) {
        case 'SET_ACTIVE_VAT':
            return {
                item: action.payload
            }
        default:
            return state;
    };
};

export const SetSortFunction = (state = { item: [] }, action) => {
    switch (action.type) {
        case 'SET_ACTIVE_SORT':
            return {
                item: action.payload
            }
        default:
            return state;
    };
};

export const SetViewFunction = (state = { item: [] }, action) => {
    switch (action.type) {
        case 'SET_ACTIVE_VIEW':
            return {
                item: action.payload
            }
        default:
            return state;
    };
};

export const SetMessageInfo = (state = { items: [] }, action) => {
    switch (action.type) {
        case 'SET_MESSAGE_INFO':
            return {
                items: {
                    acMsg: action.payload.response.acMsg,
                    isOk: action.payload.response.isOk
                }
            }
        case 'CLEAR_MESSAGE_INFO':
            return {
                items: []
            }
        default:
            return state;
    };
};

export const getUserVoucherList = (state = { items: [] }, action) => {
    switch (action.type) {
        case "SET_VOUCHER_ITEMS":
            return {
                loading: false,
                items: action.payload,
            };
        default:
            return state;
    }
};

export const getCompanyUserList = (state = { items: [] }, action) => {
    switch (action.type) {
        case "SET_COMPANY_USERS_LIST":
            return {
                loading: false,
                items: action.payload,
            };
        default:
            return state;
    }
};

export const getCompanyDepartmentList = (state = { items: [] }, action) => {
    switch (action.type) {
        case "SET_COMPANY_DEPARTMENT_LIST":
            return {
                loading: false,
                items: action.payload,
            };
        default:
            return state;
    }
};

export const getRolesList = (state = { items: [] }, action) => {
    switch (action.type) {
        case "SET_ROLES_LIST":
            return {
                loading: false,
                items: action.payload,
            };
        default:
            return state;
    }
};

export const getSaleTargetList = (state = { items: [] }, action) => {
    switch (action.type) {
        case "SET_SALE_TARGET_LIST":
            return {
                loading: false,
                items: action.payload,
            };
        default:
            return state;
    }
};
export const getB2bSettings = (state = { items: [] }, action) => {
    switch (action.type) {
        case "SET_B2B_SETTINGS":
            return {
                loading: false,
                items: action.payload,
            };
        default:
            return state;
    }
};