import React, { useState } from 'react';
import { useHistory } from "react-router";
import { useTranslation } from 'react-i18next';

const SearchBoxLogoutPage = () => {

    const [name, setName] = useState('');
    const history = useHistory();
    const { t } = useTranslation(['search', 'routes']);

    const onSubmit = (e) => {
        e.preventDefault();
        
        history.push(`/${t('routes:pretraga')}?q=${name.toLowerCase()}`);
        // document.getElementById('search-input').value = '';
        setName('');
    }

    const searchFunction = (valItem) => {
        setName(valItem);
    };

    return (
        <form className="form" onSubmit={(e) => onSubmit(e)}>
            <div className="search-box">
                <input 
                    type="text" 
                    id="search-input" 
                    className="form-control" 
                    placeholder={t('placeholder1')}
                    autoComplete="off" 
                    onChange={(e) => setName(e.target.value)} 
                    // onClick={(e) => searchFunctionClick()}
                    value={name}
                />
                <span className="search-icon"> { name === '' ? <i className="fas fa-search"></i> : <i className="fas fa-times clear-search-box" onClick={(e) => searchFunction('')}></i> }</span>
            </div>
        </form>
    )
}

export default SearchBoxLogoutPage;