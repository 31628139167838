import React, { useEffect, useState } from 'react';

import PageHelmet from '../components/helmet/PageHelmet';
import { useDispatch } from 'react-redux';
import { globalStatistic } from '../redux/actions/otherActions';
import { getPageSeo } from '../redux/actions/pageActions';
import { useTranslation, Trans } from 'react-i18next';

import styles from "../css/Humanity.module.css";

import globalVariable from '../global';

const scrollToTop = () => {
    window.scrollTo(0, 0)
}

const BeHumanityPage = () => {

    const dispatch = useDispatch();
    const [pageSeoData, setPageSeoData] = useState({});
    const { t } = useTranslation('beHumanityPage');

    useEffect(() => {
        dispatch(getPageSeo(759)).then(res => {
            if (res) {
                setPageSeoData(res);
            }
        });
        scrollToTop();
        dispatch(globalStatistic(759, 'PAGE'));
    }, [])

    return (
        <>
            {Object.keys(pageSeoData).length !== 0 &&
                <PageHelmet
                    title={pageSeoData ? `${pageSeoData.acSeoTitle}` : "Ewe Comp"}
                    description={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    keywords={pageSeoData ? `${pageSeoData.acSeoKeywords}` : ""}
                    ogDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    ogTitle={pageSeoData ? `${pageSeoData.acSeoTitle}` : ""}
                    ogUrl={pageSeoData ? `https://ewe.rs/${pageSeoData.acSeoUrl}` : ""}
                    ogImg="https://www.ewe.rs/media/ewe-og-img.jpg"
                    ogType="article"
                    twitterDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                />
            }
            <div className={`container-fluid ${styles['humanity-page']}`}>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="row">
                            <img src={`${globalVariable.domainName}/gui/humanity/header2.png`} className="img-fluid" style={{ width: "100%" }} />
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className={styles['container-text-block']}>
                                    <p className={styles['title-block-humanity2']}>{t('paragraf1')}</p>
                                    <p className={styles['body-block-humanity']}>{t('paragraf2')}</p>
                                    <p className={styles['body-block-humanity']}>{t('paragraf3')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="row" style={{ backgroundColor: "#efefef" }}>
                            <div className="container">
                                <div className='row'>
                                    <div className="col-md-6">
                                        <img src={`${globalVariable.domainName}/gui/humanity/slika-1.png`} className="img-fluid" />
                                    </div>
                                    <div className="col-md-6">
                                        <p className={styles['content-body-block-3']}>{t('paragraf4')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{ backgroundColor: "#efefef" }}>
                            <div className="container">
                                <div className='row'>
                                    <div className="col-md-6 text-right">
                                        <p className={styles['content-body-block-3']}>{t('paragraf5')}</p>
                                    </div>
                                    <div className="col-md-6">
                                        <img src={`${globalVariable.domainName}/gui/humanity/slika-2.png`} className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12">
                    <div className="row">
                        <div className="container">
                            <div className="col-sm-12 text-center">
                                <p className={styles['content-body-block-6']} style={{ paddingTop: "40px" }}>{t('paragraf6')}</p>
                                <p className={styles['content-body-block-6']} style={{ paddingTop: "40px" }}>{t('paragraf7')}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12" style={{ marginTop: "120px" }}>
                    <div className="row">
                        <div className="container">
                            <div className='row'>
                                <div className="col-md-6 text-right">
                                    <img src={`${globalVariable.domainName}/gui/humanity/slika-3.png`} className="img-fluid" />
                                </div>
                                <div className="col-md-6 text-left">
                                    <p className={styles['content-body-block-4']}>{t('paragraf8')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="container">
                            <div className='row'>
                                <div className="col-md-6 text-right">
                                    <p className={styles['content-body-block-4']}>{t('paragraf9')}</p>
                                </div>
                                <div className="col-md-6 text-right">
                                    <img src={`${globalVariable.domainName}/gui/humanity/slika-4.png`} className="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12" style={{ backgroundColor: "#fafafa", paddingBottom: "60px" }}>
                    <div className="row">
                        <div className="container">

                            <div className="col-sm-12">
                                <img src={`${globalVariable.domainName}/gui/humanity/slika-5.png`} className="img-fluid" style={{ margin: "auto" }} />
                            </div>

                            <div className="col-sm-12 text-center">
                                <p className={`text-center ${styles['content-body-block-6']}`} style={{ paddingTop: "40px" }}><Trans i18nKey="beHumanityPage:paragraf10" components={{ br: <br /> }} /></p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default BeHumanityPage
