import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { globalStatistic } from '../../redux/actions/otherActions';

const SidebarMenuItem = ({ sidebar, item, functionShowModalMenu }) => {
    const dispatch = useDispatch();

    // console.log(item);
    const functionShownModalMenu = (valItem) => {
        functionShowModalMenu(valItem, item);
    }

    const [hover, setHover] = useState(false);

    // console.log(item, "item")

    const buttonStyle = ({ hover }) => ({
        background: hover ? item.acHoverColor : 'transparent',
    });
    const gatherStatistic = () => {
        dispatch(globalStatistic(item.anMenuKey, 'MENU'));
    }
    return (
        <>
            {item.acLink == '' || item.acLink == '/' ? (

                <div
                    className="item-list-sidebar"
                    // onMouseEnter={() => functionShownModalMenu(1)}
                    onClick={() => { functionShownModalMenu(1); gatherStatistic() }}
                >
                    {item.acPicture !== '' ?
                        (
                            <div className="sidebar-menu-item-image">
                                <img alt="" src={`https://resource.ewe.rs/${item.acPicture}`} className="img-fluid" />
                            </div>
                        )
                        :
                        (
                            <>
                                {item.acIcon.includes('/') ? (
                                    <div
                                        className={`item-name item-name-icon-image`}
                                        style={buttonStyle({ hover })}
                                        onPointerOver={() => setHover(true)}
                                        onPointerOut={() => setHover(false)}
                                    >
                                        <img alt="" src={`https://resource.ewe.rs/${item.acIcon}`} className="img-fluid" title={item.acMenu.split(' ')[0]} />
                                        {!sidebar && <span>{item.acMenu.split(' ')[0]}</span>}
                                    </div>
                                ) :
                                    (
                                        <div
                                            className={`item-name`}
                                            style={buttonStyle({ hover })}
                                            onPointerOver={() => setHover(true)}
                                            onPointerOut={() => setHover(false)}
                                        >
                                            <div className="icon-item-sidebar">
                                                <i className={item.acIcon} style={{ color: item.acIconColor }}></i>
                                            </div>
                                            <span className={`${sidebar ? 'display-hide-block' : 'display-show-block'}`} style={{ color: item.acFontColor }}>{item.acMenu.split(' ')[0]}</span>
                                        </div>
                                    )}
                            </>
                        )
                    }
                </div>
            ) : (
                <Link to={item.acLink}>
                    <div
                        className="item-list-sidebar"
                        // onMouseEnter={() => functionShownModalMenu(1)}
                        onClick={() => { functionShownModalMenu(1); gatherStatistic(); }}
                    >
                        {item.acPicture !== '' ?
                            (
                                <div className="sidebar-menu-item-image">
                                    <img alt="" src={`https://resource.ewe.rs/${item.acPicture}`} className="img-fluid" />
                                </div>
                            )
                            :
                            (
                                <>
                                    {item.acIcon.includes('/') ? (
                                        <div
                                            className={`item-name item-name-icon-image`}
                                            style={buttonStyle({ hover })}
                                            onPointerOver={() => setHover(true)}
                                            onPointerOut={() => setHover(false)}
                                        >
                                            <img alt="" src={`https://resource.ewe.rs/${item.acIcon}`} className="img-fluid" title={item.acMenu.split(' ')[0]} />
                                            {!sidebar && <span>{item.acMenu.split(' ')[0]}</span>}
                                        </div>
                                    ) :
                                        (
                                            <div
                                                className={`item-name`}
                                                style={buttonStyle({ hover })}
                                                onPointerOver={() => setHover(true)}
                                                onPointerOut={() => setHover(false)}
                                            >
                                                <div className="icon-item-sidebar">
                                                    <i className={item.acIcon} style={{ color: item.acIconColor }}></i>
                                                </div>
                                                <span className={`${sidebar ? 'display-hide-block' : 'display-show-block'}`} style={{ color: item.acFontColor }}>{item.acMenu.split(' ')[0]}</span>
                                            </div>
                                        )}
                                </>
                            )
                        }
                    </div>
                </Link>
            )}
        </>
    );
};

export default SidebarMenuItem;