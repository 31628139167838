import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import FilterCheckboxInput from './FilterCheckboxInput';
import FilterCheckboxInputChecked from './FilterCheckboxInputChecked';
import { globalStatistic } from '../../redux/actions/otherActions';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';


const FilterItemCheckbox = ({ index, subFilter, item, item2, onClickCheckboxFilter, selectedFilters, filters }) => {
    const dispatch = useDispatch();
    const params = useParams();
    const { categoryId } = params;

    const onClickCheckboxFilterFunction = (e, item2, item, type, idx) => {
        onClickCheckboxFilter(e, item2, item, type, idx);
        // proveriti sa veljom da li ide categoryId + item2.anattributeValue u tom formatu
        dispatch(globalStatistic(categoryId + '-'+ item2.anAttributeValue, 'FILTER_CATEGORY'));
    }
    const location = useLocation();
    var checkedBox = 0;

    var selectedFilters = [];
    var checkboxItems = location.search.split('?');
    if(checkboxItems.slice(0, -1).length > 0) {
        checkboxItems.forEach(item => {
            if(item != '') {
                var checboxItem = item.split('&');
                checboxItem.forEach(item2 => {
                    selectedFilters.push(item2.split('=')[1].split('_')[1]);
                });
            }
        });
    }
    checkedBox = selectedFilters.includes(item2.anAttributeValue.toString());
    var checkBoxValue = checkedBox ? 1 : 0;

    return (
        <div key={index} className={`filter-item-detail ${ index <= 6 ? 'filter-show-item' : subFilter ? 'filter-show-item' : 'filter-hide-item'}`}>
            <div className="form-group form-check" >


                { checkBoxValue == 0 ? (
                    <FilterCheckboxInput checkedBox={checkBoxValue} item={item} item2={item2} idx={index} onClickCheckboxFilterFunction={onClickCheckboxFilterFunction} />
                ) : (
                    <FilterCheckboxInputChecked checkedBox={checkBoxValue} item={item} item2={item2} idx={index} onClickCheckboxFilterFunction={onClickCheckboxFilterFunction} />
                )}
                
                <label className="form-check-label" htmlFor={`checbox${item2.anAttributeValue}`}>{item2.acAttributeValue} <span className="total-count-filter">({item2.anProductCnt})</span></label>
            </div>
        </div>
    )
}

export default FilterItemCheckbox;