import React from 'react';

const ElementBanner = ({ item }) => {

    return (
        <div className="custom-banner-element-holder">
            <div className="custom-banner-element-body" style={{ backgroundImage: `url(https://resource.ewe.rs/media/${item.acBannerImage})` }}>
                <div className="custom-banner-elemnt-text custom-banner-elemnt-text-center-left">Ovo je neki tekst</div>
            </div>
        </div>
    );
};

export default ElementBanner;