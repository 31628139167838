import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from "../css/Humanity.module.css";
import globalVariable from '../global';
import { useDispatch } from 'react-redux';

import { globalStatistic } from '../redux/actions/otherActions';
import { getPageSeo } from '../redux/actions/pageActions';
import PageHelmet from '../components/helmet/PageHelmet';
import { useTranslation, Trans } from 'react-i18next';

const scrollToTop = () => {
    window.scrollTo(0, 0)
}

const HumanityPage = () => {

    const dispatch = useDispatch();
    const [pageSeoData, setPageSeoData] = useState({});
    const { t } = useTranslation('humanityPage');

    useEffect(() => {
        scrollToTop();
        dispatch(getPageSeo(546)).then(res => { // 546 zakucan ID stranice Humanost
            if (res) {
                setPageSeoData(res);
                // console.log(res, 'seo data')
            }
        });
        dispatch(globalStatistic(546, 'PAGE'));
    }, [])


    return (
        <>
            {Object.keys(pageSeoData).length !== 0 &&
                <PageHelmet
                    title={pageSeoData ? `${pageSeoData.acSeoTitle}` : "Ewe Comp"}
                    description={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    keywords={pageSeoData ? `${pageSeoData.acSeoKeywords}` : ""}
                    ogDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    ogTitle={pageSeoData ? `${pageSeoData.acSeoTitle}` : ""}
                    ogUrl={pageSeoData ? `https://ewe.rs/${pageSeoData.acSeoUrl}` : ""}
                    ogImg="https://www.ewe.rs/media/ewe-og-img.jpg"
                    ogType="article"
                    twitterDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                />
            }
            <div className={`container-fluid ${styles['humanity-page']}`}>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="row">
                            <img src={`${globalVariable.domainName}/gui/humanity/header.jpg`} className="img-fluid" style={{ width: "100%" }} />
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className={styles['container-text-block']}>
                                    <p className={styles['title-block-humanity']}><Trans i18nKey="humanityPage:naslov1" components={{ b: <b></b> }} /></p>
                                    <p className={styles['body-block-humanity']}>{t('paragraf1')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="row" style={{ backgroundColor: "#efefef" }}>

                            <div className="container">
                                <div className='row'>
                                    <div className="col-md-6 text-right">
                                        <p className={styles['content-body-block-3']}>{t('paragraf2')}</p>
                                    </div>
                                    <div className="col-md-6 text-center">
                                        <img src={`${globalVariable.domainName}/gui/humanity/slika2.png`} className={`img-fluid ${styles['image-block-rotate']}`} />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-sm-12" style={{ marginTop: "120px" }}>
                        <div className="row">
                            <div className="container">
                                <div className='row'>
                                    <div className="col-md-6 text-center">
                                        <img src={`${globalVariable.domainName}/gui/humanity/slika1.png`} className={`img-fluid ${styles['image-block-rotate-second']}`} />
                                    </div>
                                    <div className="col-md-6 text-left">
                                        <p className={styles['content-body-block-4']}>{t('paragraf3')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="row">
                            <div className="container">
                                <div className="col-sm-12 text-center">
                                    <p className={styles['content-body-block-6']}>{t('paragraf4')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className={`row ${styles['row-content-background']}`}>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className={`col-lg-6 ${styles['page-style']}`}>
                                        <div className='row'>
                                            <div className={`col-md-6 ${styles['container-block-footer']}`}>
                                                <p className={styles['title-content-block-5']}>{t('naslov2')}</p>
                                                <p className={styles['content-body-block-5']}>{t('paragraf5')}</p>
                                                <Link onClick={scrollToTop} to={"/budi-human-ewe"}><span className={styles['button-content-block-5']} style={{ cursor: "pointer" }}>{t('dugme')}</span></Link>
                                            </div>
                                            <div className="col-md-6 text-center">
                                                <img src={`${globalVariable.domainName}/gui/humanity/slika3.png`} className={`img-fluid ${styles['thumb-img']}`} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`col-lg-6 ${styles['last-block-new']} ${styles['page-style']}`}>
                                        <div className='row'>
                                            <div className={`col-md-6 ${styles['container-block-footer']}`}>
                                                <p className={styles['title-content-block-5']}>{t('naslov2')}</p>
                                                <p className={styles['content-body-block-5']}>{t('paragraf6')}</p>
                                                <Link onClick={scrollToTop} to={"/humanost-ewe-posadi-svoj-hlad"}><span className={styles['button-content-block-5']} style={{ cursor: "pointer" }}>{t('dugme')}</span></Link>
                                            </div>
                                            <div className="col-md-6 text-center">
                                                <img src={`${globalVariable.domainName}/gui/humanity/slika333.png`} className={`img-fluid ${styles['thumb-img']}`} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`row ${styles['second-row-page']}`} style={{ marginTop: "40px" }}>
                                    <div className={`col-lg-6 ${styles['last-block-new']} ${styles['page-style']}`}>
                                        <div className='row'>
                                            <div className={`col-md-6 ${styles['container-block-footer']}`}>
                                                <p className={styles['title-content-block-5']}>{t('naslov2')}</p>
                                                <p className={styles['content-body-block-5']}>{t('paragraf7')}</p>
                                                <Link onClick={scrollToTop} to={"/humanost-ewe-solidarnost"}><span className={styles['button-content-block-5']} style={{ cursor: "pointer" }}>{t('dugme')}</span></Link>
                                            </div>
                                            <div className="col-md-6 text-center">
                                                <img src={`${globalVariable.domainName}/gui/humanity/landing_slika.png`} className={`img-fluid ${styles['thumb-img']}`} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HumanityPage
