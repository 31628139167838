// This is used to determine if a user is authenticated and
// if they are allowed to visit the page they navigated to.

// If they are: they proceed to the page
// If not: they are redirected to the login page.
import React from 'react'
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SecureLS from 'secure-ls';

const PrivateRoute = ({ component: Component, sidebar: LeftSidebar, ...rest }) => {

  const userSignin = useSelector((state) => state.userInfo);
  const { userInfo } = userSignin;
  let ls = new SecureLS({ encodingType: 'aes' });
  var userInfoData = ls.get('userInfo');
  const { t } = useTranslation('routes');

  return (
    <Route
      {...rest}
      render={props =>
        userInfoData ? (
          <>
            {LeftSidebar &&
              <LeftSidebar {...props} />
            }
            <Component {...props} />
          </>
        ) : (
          // <>
          //   <LeftSidebar {...props} />
          //   <Component {...props} />
          // </>
          <Redirect to={{ pathname: `/${t('prijava')}` }} />
        )
      }
    />
  )
}

export default PrivateRoute