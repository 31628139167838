import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DebitDiagramItem from '../components/other/DebitDiagramItem';
import DebitDiagramItemMobileLeft from '../components/other/DebitDiagramItemMobileLeft';
import DebitDiagramItemMobileRight from '../components/other/DebitDiagramItemMobileRight';
import PageHelmet from '../components/helmet/PageHelmet';

import { currencyFormat } from "../helperFunctions";
import { globalStatistic } from '../redux/actions/otherActions';
import { getPageSeo } from '../redux/actions/pageActions';

import axios from 'axios';
import { axiosinstance } from '../configAxios';
import { useTranslation } from 'react-i18next';
import SecureLS from 'secure-ls';

const CurrencyPage = () => {
    const dispatch = useDispatch();
    const isNotMobile = window.innerWidth > 610;
    let ls = new SecureLS({ encodingType: 'aes' });
    
    const [companyAdditionalInfo, setCompanyAdditionalInfo] = useState([]);
    const [overdueIn, setOverdueIn] = useState([]);
    const [overdueOut, setOverdueOut] = useState([]);
    const [pageSeoData, setPageSeoData] = useState({});
    const { t } = useTranslation('currencyPage');

    const getAxiosData = () => {
        try {
            const attributes = {};
            var userInfoData = ls.get('userInfo');

            attributes['anCompanyKey'] = userInfoData ? userInfoData.company.anCompanyKey : 0;
            attributes['anUserKey'] = userInfoData ? userInfoData.company.department[0].anDepartmentKey : 0;

            const configRes = axiosinstance(attributes, '8224', 'postCompanyAdditionalInfo');

            axios(configRes)
                .then(function (response) {
                    if (response.data.isOk == 1) {
                        setCompanyAdditionalInfo(response.data.response.companyAdditionalInfo);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });

        } catch (error) {
            console.log(error)
        }
    }


    useEffect(() => {
        dispatch(getPageSeo(545)).then(res => {
            if (res) {
                setPageSeoData(res);
            }
        });
        getAxiosData();
        dispatch(globalStatistic(545, 'PAGE'));
    }, [])

    useEffect(() => {
        setOverdueIn(
            [
                {
                    overdue: companyAdditionalInfo.anDaysOverdueIn7,
                    percent: 0,
                    title: `7 ${t('dana')}`
                },
                {
                    overdue: companyAdditionalInfo.anDaysOverdueIn15,
                    percent: 0,
                    title: `15 ${t('dana')}`
                },
                {
                    overdue: companyAdditionalInfo.anDaysOverdueIn30,
                    percent: 0,
                    title: `30 ${t('dana')}`
                },
                {
                    overdue: companyAdditionalInfo.anDaysOverdueIn45,
                    percent: 0,
                    title: `45 ${t('dana')}`
                },
                {
                    overdue: companyAdditionalInfo.anDaysOverdueOver45,
                    percent: 0,
                    title: `${t('preko')} 45 ${t('dana')}`
                }
            ]
        );
        setOverdueOut(
            [
                {
                    overdue: companyAdditionalInfo.anDueIn15Days,
                    percent: 0,
                    title: t('dospevaZa15')
                },
                {
                    overdue: companyAdditionalInfo.anDueOver15Days,
                    percent: 0,
                    title: t('dospevaPreko15')
                }
            ]
        )
    }, [companyAdditionalInfo])

    // calculating graph percentage
    // Ivan Zarkovic, Oblak Tehnologije, 2022.
    let currentInMax = 0;
    let currentOutMax = 0;
    let totalIn = 0;
    let totalOut = 0;

    // round on 2 decimals
    function percentage(partialValue, totalValue) {
        if (partialValue && totalValue)
            return Math.round((((100 * partialValue) / totalValue) + Number.EPSILON) * 100) / 100;
        else
            return 0;
    }

    if (overdueIn) {
        overdueIn.map(element => {
            totalIn += element.overdue
            if (element.overdue > currentInMax) {
                currentInMax = element.overdue
            }
            // return { ...element, percent: percentage(element.overdue, currentInMax) };
        })
    }
    // setOverdueIn(newOverdueIn);

    if (overdueOut) {
        overdueOut.map(element => {
            totalOut = + element.overdue
            if (element.overdue > currentOutMax) {
                currentOutMax = element.overdue
            }
            //return { ...element, percent: percentage(element.overdue, currentOutMax) };
        })
    }

    return (
        <>
            {Object.keys(pageSeoData).length !== 0 &&
                <PageHelmet
                    title={`${pageSeoData.acSeoTitle}`}
                    description={`${pageSeoData.acSeoDescription}`}
                    keywords={`${pageSeoData.acSeoKeywords}`}
                    ogDescription={`${pageSeoData.acSeoDescription}`}
                    ogTitle={`${pageSeoData.acSeoTitle}`}
                    ogUrl={`https://ewe.rs/${pageSeoData.acSeoUrl}`}
                    ogImg="https://www.ewe.rs/media/ewe-og-img.jpg"
                    ogType="website"
                    twitterDescription={`${pageSeoData.acSeoDescription}`}
                />
            }
            {
                isNotMobile && companyAdditionalInfo && overdueIn && overdueOut ? (
                    <div className="container-content container-main-content currency-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="stats-order-content-holder">
                                        <h1>{pageSeoData.acSeoHeader ? pageSeoData.acSeoHeader : t('naslov')}</h1>
                                        {pageSeoData.acSeoShortPageDescription && <p>{pageSeoData.acSeoShortPageDescription}</p>}
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="currency-box-list">
                                        {/* <div className="currency-box-list-item">
                                            <div className="currency-box-list-item-content">
                                                <div className="currency-box-list-item-title">Kreditni limit:</div>
                                                <div className="currency-box-list-item-value">11.888,00</div>
                                            </div>
                                        </div> */}
                                        <div className="currency-box-list-item">
                                            <div className="currency-box-list-item-content">
                                                <div className="currency-box-list-item-title">{t('ukupanDug')}</div>
                                                <div className="currency-box-list-item-value">{currencyFormat(companyAdditionalInfo.anFinancialState)} {t('rsd')}</div>
                                            </div>
                                        </div>
                                        <div className="currency-box-list-item">
                                            <div className="currency-box-list-item-content">
                                                <div className="currency-box-list-item-title">{t('dugVanValute')}</div>
                                                <div className="currency-box-list-item-value">{currencyFormat(companyAdditionalInfo.anOverDue)} {t('rsd')}</div>
                                            </div>
                                        </div>
                                        <div className="currency-box-list-item">
                                            <div className="currency-box-list-item-content">
                                                <div className="currency-box-list-item-title">{t('usloviPlacanja')}</div>
                                                <div className="currency-box-list-item-value">{companyAdditionalInfo.anDaysForPayment}{companyAdditionalInfo.anDaysForPayment == 1 ? t('dan') : t('dana2')}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="debit-content-info">
                                        <div className="debit-info-content-diagram">
                                            <div className="row">
                                                <div className="col-sm-6">{t('dospelaZaduzenja')}</div>
                                                <div className="col-sm-6">{t('zaduzenjaKojaDospevaju')}</div>
                                            </div>
                                        </div>
                                        <div className="debit-content-box">
                                            <div className="debit-content-info-rows-block">
                                                {overdueIn.map(e => {
                                                    return (
                                                        <DebitDiagramItem
                                                            title={e.title}
                                                            left={percentage(e.overdue, currentInMax) == 0 ? "0" : currencyFormat(e.overdue)}
                                                            percentageLeft={percentage(e.overdue, currentInMax) == 0 ? "1%" : `${percentage(e.overdue, currentInMax)}%`}
                                                            right=""
                                                            percentageRight="" />
                                                    )
                                                })}
                                                {overdueOut.map(e => {
                                                    return (
                                                        <DebitDiagramItem
                                                            title={e.title}
                                                            left=""
                                                            percentageLeft=""
                                                            right={percentage(e.overdue, currentOutMax) == 0 ? "0" : currencyFormat(e.overdue)}
                                                            percentageRight={percentage(e.overdue, currentOutMax) == 0 ? "1%" : `${percentage(e.overdue, currentOutMax)}%`} />
                                                    )
                                                })}
                                            </div>
                                            <div className="debit-info-box-footer-content">
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div className="debit-info-box-footer debit-info-box-footer-red">{t('ukupnoDospeloZaduzenje')}<span>{currencyFormat(totalIn)}</span></div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="debit-info-box-footer debit-info-box-footer-green">{t('ukupnoZaduzenjeKojeDospeva')}<span>{currencyFormat(totalOut)}</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="container-content container-main-content currency-content">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="stats-order-content-holder">
                                            <h1>{t('naslov')}</h1>
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="currency-box-list">
                                            <div className="currency-box-list-item">
                                                <div className="currency-box-list-item-content">
                                                    <div className="currency-box-list-item-title">{t('ukupanDug')}</div>
                                                    <div className="currency-box-list-item-value">{currencyFormat(companyAdditionalInfo.anFinancialState)}</div>
                                                </div>
                                            </div>
                                            <div className="currency-box-list-item">
                                                <div className="currency-box-list-item-content">
                                                    <div className="currency-box-list-item-title">{t('dugVanValute')}</div>
                                                    <div className="currency-box-list-item-value">{currencyFormat(companyAdditionalInfo.anOverDue)} {t('rsd')}</div>
                                                </div>
                                            </div>
                                            <div className="currency-box-list-item">
                                                <div className="currency-box-list-item-content">
                                                    <div className="currency-box-list-item-title">{t('usloviPlacanja')}</div>
                                                    <div className="currency-box-list-item-value">{companyAdditionalInfo.anDaysForPayment}{companyAdditionalInfo.anDaysForPayment == 1 ? " dan " : " dana"}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="debit-content-info">
                                            <div className="debit-info-content-diagram">
                                                <div className="row">
                                                    <div className="col-sm-6">{t('dospelaZaduzenja')}</div>
                                                </div>
                                            </div>
                                            <div className="debit-content-box">
                                                <div className="debit-content-info-rows-block">
                                                    {overdueIn.map(e => {
                                                        return (
                                                            <DebitDiagramItemMobileLeft
                                                                title={e.title}
                                                                left={percentage(e.overdue, currentInMax) == 0 ? "0" : currencyFormat(e.overdue)}
                                                                percentageLeft={percentage(e.overdue, currentInMax) == 0 ? "1%" : `${percentage(e.overdue, currentInMax)}%`}
                                                                right=""
                                                                percentageRight="" />
                                                        )
                                                    })}
                                                </div>
                                                <div className="debit-info-box-footer-content">
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                                <div className="debit-info-box-footer debit-info-box-footer-red">{t('ukupnoDospeloZaduzenje')}
                                                                <div className="debit-info-box-footer-amount-red">{currencyFormat(totalIn)}</div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="debit-info-content-diagram">
                                                <div className="row">
                                                    <div className="col-sm-6">{t('zaduzenjaKojaDospevaju')}</div>
                                                </div>
                                            </div>
                                            <div className="debit-content-box">
                                                <div className="debit-content-info-rows-block">
                                                    {overdueOut.map(e => {
                                                        return (
                                                            <DebitDiagramItemMobileRight
                                                                title={e.title}
                                                                left=""
                                                                percentageLeft=""
                                                                right={percentage(e.overdue, currentOutMax) == 0 ? "0" : currencyFormat(e.overdue)}
                                                                percentageRight={percentage(e.overdue, currentOutMax) == 0 ? "1%" : `${percentage(e.overdue, currentOutMax)}%`} />
                                                        )
                                                    })}
                                                </div>
                                                <div className="debit-info-box-footer-content">
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                                <div className="debit-info-box-footer debit-info-box-footer-green">{t('ukupnoDospeloZaduzenje')}
                                                                <div className="debit-info-box-footer-amount-green">{currencyFormat(totalOut)}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
        </>

    );
};

export default CurrencyPage;