import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";

import { getVoucherLists, addVoucherFunction } from '../../redux/actions/cartActions';

import InputQtyPlusMinusCart from '../../components/other/InputQtyPlusMinusCart';
import CurrencyFormat from '../other/CurrencyFormat';
import RemoveCartItem from '../other/RemoveCartItem';
import CartItemModal from '../cart/CartItemModal';
import globalVariable from '../../global';

import { removeSpecialChar } from '../../helperFunctions'
import { useTranslation } from 'react-i18next';

const CartItem = ({ item, isActiveCurrent }) => {

    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const activeCurrency = useSelector((state) => state.activeCurrency.item);
    const getVoucherList = useSelector((state) => state.getVoucherList.items);
    const getActiveCartFunction = useSelector((state) => state.getActiveCartFunction);
    const { t } = useTranslation(['cart', 'routes']);

    const addDefaultSrc = (e) => {
        e.target.src = `${globalVariable.imagesPath}products/noImage.jpg`;
    };

    const handleClose = () => {
        setShow(false);
    };

    const setShowFunction = (item) => {
        dispatch(getVoucherLists(item));
        setShow(true);
    };

    const checkedFuncCount = (item2) => {
        dispatch(addVoucherFunction(item2, item, getActiveCartFunction));
    }

    return (
        <>
            <tr className={`${item.isAvailable == 0 && 'sold-out-cart sold-out'}`}>
                <td className="main-cart-item-image main-cart-item-value productImage">
                    <Link to={`/${t('routes:proizvod')}/${item.anProductKey}/${removeSpecialChar(item.acProducName)}`}>
                        <img src={`${globalVariable.imagesPath}products/${item.acIdent}_v.jpg`} alt={`${item.acDept} ${item.acProducName}`} onError={(e) => addDefaultSrc(e)} />
                        {item.anDiscount != 0 && <div className="percetange-loyatly-product" title={t('cart:ostvariliSte')`${item.anDiscount}% ` + t('cart:popustaNaKupovinu')}>{item.anDiscount}<small>%</small></div>}
                    </Link>

                </td>
                <td className="main-cart-item-name main-cart-item-value text-left">
                    {item.acIdent}
                </td>
                <td className="main-cart-item-name main-cart-item-value">
                    <div className="item-cart-td item-cart-td-brand-name">
                        <Link to={`/${t('routes:proizvod')}/${item.anProductKey}/${removeSpecialChar(item.acProducName)}`} title={item.acDept.replace(" 1****", "") + " " + item.acProducName}>
                            {item.acDept.replace(" 1****", "")} {item.acProducName}
                        </Link>
                    </div>
                </td>
                <td className="main-cart-item-price main-cart-item-value">
                    <div className="item-cart-td item-cart-td-width-mobile">
                        {item.isAvailable != 0 && (
                            <>
                                <CurrencyFormat num={item.anPriceNoPdv} /> {item.anPriceNoPdv != 0 && activeCurrency.acShortName}
                            </>
                        )}
                    </div>
                </td>
                <td className="main-cart-item-price main-cart-item-value">
                    <div className="item-cart-td item-cart-td-width-mobile">
                        {item.isAvailable != 0 && (
                            <>
                                <CurrencyFormat num={item.anPriceWithPdv} /> {item.anPriceWithPdv != 0 && activeCurrency.acShortName}
                            </>
                        )}
                    </div>
                </td>
                {isActiveCurrent == 1 ? (
                    <td className={`${item.isAvailable == 2 && 'sold-out-cart-qty'} main-cart-item-qty main-cart-item-value`}>
                        {item.isAvailable != 0 && (
                            <>
                                <InputQtyPlusMinusCart product={item} qty={item.anQty} />
                            </>
                        )}
                    </td>
                ) : (
                    <td className={`${item.isAvailable == 2 && 'sold-out-cart-qty'} main-cart-item-name main-cart-item-value`}>
                        <div className="item-cart-td item-cart-td-brand-name">
                            {item.anQty}
                        </div>
                    </td>
                )
                }
                <td className="main-cart-item-price main-cart-item-value text-center">
                    {item.anStock}
                </td>
                <td className="main-cart-item-total main-cart-item-value">
                    <div className="item-cart-td item-cart-td-width-mobile">
                        {item.isAvailable != 0 && (
                            <>
                                <CurrencyFormat num={item.anSumPriceNoPdv} /> {item.anSumPriceNoPdv != 0 && activeCurrency.acShortName}
                            </>
                        )}
                    </div>
                </td>
                <td className="main-cart-item-sum-total main-cart-item-value">
                    <div className="item-cart-td item-cart-td-width-mobile-100">
                        {item.isAvailable != 0 && (
                            <>
                                <CurrencyFormat num={item.anSumPriceWithPdv} /> {item.anSumPriceWithPdv != 0 && activeCurrency.acShortName}
                            </>
                        )}
                    </div>
                </td>
                <td className="main-cart-item-voucher-name main-cart-item-value">
                    <div className="item-cart-td item-cart-td-width-mobile">
                        {item.isAvailable != 0 && (
                            <>
                                <CurrencyFormat num={item.anVoucherValue} /> {item.anVoucherValue != 0 && activeCurrency.acShortName}
                            </>
                        )}
                    </div>
                </td>
                {isActiveCurrent == 1 && (
                    <>
                        <td className="main-cart-item-voucher-value main-cart-item-value">
                            <div className="item-cart-td">
                                {item.isAvailable != 0 && (
                                    <>
                                        <button className="voucher-btn-cart btn btn-info btn-sm" onClick={(e) => setShowFunction(item)}>{t('cart:unesi')}</button>
                                    </>
                                )}
                            </div>
                        </td>
                        <td>
                            <div className="item-cart-td">
                                <div className="remove-main-cart-item">
                                    <RemoveCartItem anCartItemKey={item.anCartItemKey} />
                                </div>
                            </div>
                        </td>
                    </>
                )}

            </tr>

            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="modal-lg"
            >
                <Modal.Header>
                    <div className="modal-loyalty-header-item">
                        <h3 className="body-modal-loyalty-specification-h3">{t('cart:vauceriZaArtikal')}<b>{item.acIdent}</b></h3>
                    </div>
                </Modal.Header>
                {getVoucherList.length > 0 || (item.acPromoCodeList.length > 0 && item.acPromoCodeList.filter(i => i.acType == 'Voucher').length > 0) ? (
                    <Modal.Body className="body-modal-loyalty-specification">
                        {item.acPromoCodeList.length > 0 &&
                            <>
                                {t('cart:odabraniVauceri')}
                                <table className="table table-voucher-list">
                                    <thead>
                                        <tr>
                                            <th>{t('cart:id')}</th>
                                            <th>{t('cart:promoKod')}</th>
                                            <th>{t('cart:vrednost')}</th>
                                            {/* <th>Usage</th> */}
                                            <th>{t('cart:trajanje')}</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {item.acPromoCodeList.map((item3, key3) => (
                                            <CartItemModal key={key3} item2={item3} item={item} checkedFunctionCount={checkedFuncCount} />
                                        ))}
                                    </tbody>
                                </table>
                            </>
                        }
                        {getVoucherList.length > 0 &&
                            <table className="table table-voucher-list">
                                <thead>
                                    <tr>
                                        <th>{t('cart:id')}</th>
                                        <th>{t('cart:promoKod')}</th>
                                        <th>{t('cart:vrednost')}</th>
                                        {/* <th>Usage</th> */}
                                        <th>{t('cart:trajanje')}</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {getVoucherList.map((item2, key2) => (
                                        <CartItemModal checkedFunctionCount={checkedFuncCount} key={key2} item2={item2} item={item} />
                                    ))}
                                </tbody>
                            </table>
                        }
                    </Modal.Body>
                ) : (
                    <Modal.Body className="body-modal-loyalty-specification">
                        <div className="alert alert-primary" role="alert">
                            {t('cart:nemaAktVauc')}
                        </div>
                    </Modal.Body>
                )}
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t('cart:zatvoriPregled')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default CartItem;