import React, { useState } from 'react';

const CatalogProductBadge = ({ item, view, key }) => {

    const [showActionInfo, setShowActionInfo] = useState(false);
    const [showActionKey, setShowActionKey] = useState();

    const showText = (key) => {
        setShowActionInfo(true)
        setShowActionKey(key)
    }

    const hideText = () => {
        setShowActionInfo(false)
    }
    return (
        <>
            {view == 'classicItem' && (
                <>
                    {item.acIconType == 1 ? (
                        <div className="product-catalog-badge" title={item.acText} style={{ backgroundColor: `${item.acColor}` }}><img src={`https://resource.ewe.rs/media/${item.acImage}`} className="product-catalog-badge-image-top" alt="Bedž" /> {item.acAdditionalInfoName}</div>
                    ) : (
                        <>
                            <div className="product-catalog-badge" title={item.acText} style={{ backgroundColor: `${item.acColor}` }}>{item.acIcon != '' ? <i className={item.acIcon}></i> : <i className="fas fa-info-circle"></i>} {item.acAdditionalInfoName}</div>
                        </>
                    )}
                </>
            )}
            {view == 'tableItem' && (
                <>
                    {item.acIconType == 1 ? (
                        <div
                            className="product-table-view-badge text-center"
                            style={{ backgroundColor: `${item.acColor}` }}
                            onMouseEnter={() => showText(key)}
                            onMouseLeave={hideText}
                        >
                            <img src={`https://resource.ewe.rs/media/${item.acImage}`} className="product-catalog-badge-image-top" alt="Bedž" />
                            {showActionInfo && showActionKey == key && item.acAdditionalInfoName && <span className="table-action-info" title={item.acAdditionalInfoName} style={{ backgroundColor: item.acColor }}>&nbsp;{item.acAdditionalInfoName}</span>}
                        </div>
                    ) : (
                        <div
                            className="product-table-view-badge text-center"
                            style={{ backgroundColor: `${item.acColor}` }}
                            onMouseEnter={() => showText(key)}
                            onMouseLeave={hideText}
                        >
                            <i className={item.acIcon} title={item.acAdditionalInfoName}></i>
                            {showActionInfo && showActionKey == key && item.acAdditionalInfoName && <span className="table-action-info" title={item.acAdditionalInfoName} style={{ backgroundColor: item.acColor }}>&nbsp;{item.acAdditionalInfoName}</span>}
                        </div>
                    )}
                </>
            )}
        </>
    )
}

export default CatalogProductBadge;
