import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanyPromoMaterial } from '../redux/actions/companyPromoMaterialActions';
import { getLoyaltyProducts } from "../redux/actions/loyaltyActions";


import BlockPoll from '../components/blocks/BlockPoll';
import BlockActionSlider from '../components/blocks/BlockActionSlider';
import BlockBanner from '../components/blocks/BlockBanner';
import BlockSlider from '../components/blocks/BlockSlider';
import BlockNewsNotices from '../components/blocks/BlockNewsNotices';
import BlockActionArticle from '../components/blocks/BlockActionArticle';
import HomePageExports from '../components/exports/HomePageExports';
import LoyaltyBlockHome from '../components/loyalty/LoyaltyBlockHome';
import { useTranslation } from 'react-i18next';

const HomePage = () => {

    const dispatch = useDispatch();
    // const loyaltyItems = useSelector((state) => state.getLoyaltyProducts);
    const getUserSurvey = useSelector((state) => state.getUserSurvey.items[0]);
    const { t } = useTranslation('homePage');

    useEffect(() => {
        dispatch(getCompanyPromoMaterial(4));
        //     dispatch(getLoyaltyProducts());
    }, []);

    const popups = useSelector((state) => state.getUserPopup.items);
    // dummy data for testing
    // const [popups, setPopups] = useState(
    //     [{
    //         "anPopupKey": 55,
    //         "acPopupImage": "popup/2022/09/2022-09-21455589.jpg",
    //         "acBrend": null,
    //         "acPopupProduct": null,
    //         "acPopupContent": "",
    //         "acPopupTitle": "RAC21501",
    //         "acPopupLink": "https://www.ewe.rs/proizvod/amd-konfiguracije/ryzen-5-35008gb480gbgtx1650-4gb-notm-RAC21501",
    //         "anPopupOrgId": 1322,
    //         "acPopupType": "2",
    //         "acPopupSubtitle": "",
    //         "anPromoPrice": 0,
    //         "isPaymentAdvanceIncluded": 0,
    //         "isLoggedIn": 1,
    //         "anClicked": 40,
    //         "isSeen": 1,
    //         "isActive": 1,
    //         "acPopupLogo": "",
    //         "anPaymentAdvance": 0
    //     },
    //     {
    //         "anPopupKey": 56,
    //         "acPopupImage": "popup/2022/09/2022-09-21455589.jpg",
    //         "acBrend": null,
    //         "acPopupProduct": "RAC21501",
    //         "acPopupContent": "",
    //         "acPopupTitle": "RAC215011",
    //         "acPopupLink": "https://www.ewe.rs/proizvod/amd-konfiguracije/ryzen-5-35008gb480gbgtx1650-4gb-notm-RAC21501",
    //         "anPopupOrgId": 1322,
    //         "acPopupType": "0",
    //         "acPopupSubtitle": "",
    //         "anPromoPrice": 0,
    //         "isPaymentAdvanceIncluded": 0,
    //         "isLoggedIn": 1,
    //         "anClicked": 40,
    //         "isSeen": 0,
    //         "isActive": 1,
    //         "acPopupLogo": "",
    //         "anPaymentAdvance": 0
    //     }]
    // );
    let toShowP = popups.filter((res) => res.isActive == 1);

    const isLaptop = window.innerWidth > 1279 && window.innerWidth < 1441;

    // useEffect(() => {
    //     var toShowP = popups.filter((res) => res.acPopupType == 0);
    //     if(toShowP.length == 0) {
    //         toShowP = popups.filter((res) => res.acPopupType == 1);
    //     }
    //     setPopupsToShow(toShowP);
    // }, [popups]);

    return (
        <div className="container-content top-margin-navbar-escape">
            <div className="app">
                <div className="block-item">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="home-page-blocks">
                                <BlockBanner />
                                <BlockNewsNotices />
                                {isLaptop && <BlockSlider />}
                                {getUserSurvey ? <BlockPoll item={getUserSurvey} /> : null}
                                {!isLaptop && <BlockSlider />}
                                {toShowP.length > 0 ?
                                    <BlockActionArticle item={toShowP} />
                                    :
                                    <div className="action-block action-block-no-content">
                                        <div className="action-block-content action-block-content-no-content">
                                            <img src={t("banerPlaceholder")} alt="Placeholder" title="Placeholder GIF video"></img>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <LoyaltyBlockHome />
                        <BlockActionSlider isHomepage="true" type="weekAction" title={t('nedAkcija')} />
                        <BlockActionSlider isHomepage="true" type="inStockAgain" title={t('ponovoNaStanju')} />
                        <BlockActionSlider isHomepage="true" type="recommendedForYou" title={t("prep")} />
                    </div>
                    <HomePageExports />
                </div>
            </div>
        </div>
    );
};

export default HomePage;