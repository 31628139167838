import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { filtersDataCheck } from '../../functions/filters';

const CatalogFilterSelectedItem = ({ item, isActive }) => {

    const filtersData = useSelector((state) => state.catalogFilters);
    const history = useHistory();
    const dispatch = useDispatch();
    let searchHistory = history.location.search;
    var itemInfo = item.split(';');
    const removeClickFitler = () => {

        var itemItem = {};
        var itemItem2 = {};

        itemItem['acAttributeName'] = itemInfo[0];
        itemItem['acAttributeURLName'] = itemInfo[1];
        itemItem['anAttributeId'] = itemInfo[2];

        itemItem2['acAttributeValue'] = itemInfo[3];

        if (typeof itemInfo[5] == "string") {
            if (itemInfo[5].includes("_")) {
                itemItem2['acUrlValue'] = itemInfo[5].replace("_", "")
            } else {
                itemItem2['acUrlValue'] = itemInfo[5];
            }
        } else {
            itemItem2['acUrlValue'] = itemInfo[5];
        }


        itemItem2['anAttributeValue'] = itemInfo[4];

        const checked = false;
        const type = 'filter';
        var slider = false;

        if (itemInfo[1] == 'cena') {
            slider = true;
        }

        filtersDataCheck(checked, itemItem2, itemItem, type, history, searchHistory, slider);

        dispatch({
            type: 'SET_DEFAULT_URL_FILTERS',
            payload: ""
        });
    };

    return (
        <div className={`catalog-filter-top-item ${isActive == 1 && 'active'}`}>{itemInfo[0]}: {itemInfo[3].replace(" 1****", "")} <i className="fas fa-times" onClick={(e) => removeClickFitler()}></i></div>
    );
};

export default CatalogFilterSelectedItem;