import React, { useState } from 'react';
// import CurrencyFormat from '../other/CurrencyFormat';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { globalStatistic } from '../../redux/actions/otherActions';

import {
    setActiveCartFunction,
    // removeCartFunction,
    // removeCartItemFunction
} from '../../redux/actions/cartActions';

import CurrencyFormat from '../other/CurrencyFormat';
import ModalConfirmation from '../modal/ModalConfirmation';
import MessageInfoBox from '../other/MessageInfoBox';
import { useTranslation } from 'react-i18next';

const CartEditingItem = ({ item }) => {

    const dispatch = useDispatch();
    const activeCurrency = useSelector((state) => state.activeCurrency.item);
    const getCartListFunction = useSelector((state) => state.getCartListFunction.items);
    const activeVat = useSelector((state) => state.activeVat.item);
    const [show, setShow] = useState(false);

    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');
    const [action, setAction] = useState('');
    const [values, setValues] = useState('');
    const { t } = useTranslation(['cart', 'routes']);

    const setActiveCart = (id) => {
        dispatch(setActiveCartFunction(id));
    };
    const removeCart = (id) => {

        const yourCart = t('cart:vasaKorpa');
        const confirmDelete = t('cart:potvrdaBrisanjaKorpe');
        const confirmDelCart = t('cart:daLiSteSigurniBrisanjeKorpe');
        const cartTextDel = t('cart:korpaKojuBrisete');

        var cartName = item.acCartName == 'Default' ? yourCart : item.acCartName;

        if (item.acCartName == 'Default') {
            toast(<MessageInfoBox message="cartErrorRemoveDefault" type="0" />, {
                className: 'red-background',
            });

        } else {
            setTitle(confirmDelete);
            setBody(`
                ${t('cart:popupBrisanje')} <b>"${cartName}"</b>

                ${item.isActive === 1 ? cartTextDel : ''}
            `);
            setAction('removeCartFunction');
            setValues([id]);
            setShow(true);
        }

    };

    const clearCartItemsFunction = (anCartItemKey) => {
        const yourCart = t('cart:vasaKorpa');
        const confirmEmptyCart = t('cart:daLiSteSigurniPraznjenjeKorpe');
        var cartName = item.acCartName == 'Default' ? yourCart : item.acCartName;

        setTitle(confirmEmptyCart);
        setBody(t('cart:daLiSteSigurniPraznjenjeKorpe') + ' <b>' + cartName + '</b>');
        setAction('removeCartItemFunction');
        setValues([item, anCartItemKey]);
        setShow(true);
    };

    const handleClose = () => {
        setShow(false);
    };
    const gatherStatisticActiveCart = () => {
        dispatch(globalStatistic(0, 'ACTIVE_CART'));
    }
    const gatherStatisticDeleteCart = () => {
        dispatch(globalStatistic(0, 'DELETE_CART'));
    }
    const gatherStatisticEmptyCart = () => {
        dispatch(globalStatistic(0, 'EMPTY_CART'));
    }



    return (
        <>
            <tr>
                <td>
                    <div className="table-row-editing-cart-item-name">
                        {/* {item.isActive == 0 ? (
                            <>
                                {item.acCartName == 'Default' ? 'Vaša korpa' : item.acCartName}
                            </>
                        ) : ( */}
                        <Link to={`/${t('routes:korpa')}/${item._id}`}>
                            {item.acCartName == 'Default' ? t('cart:vasaKorpa') : item.acCartName}
                        </Link>
                    </div>
                </td>
                <td>
                    <div className="table-row-editing-cart-item table-padding-item-border-both text-center">{item.anSumQty}</div>
                </td>
                <td>
                    <div className="table-row-editing-cart-item table-padding-item-border-right text-center">{activeVat == 1 ? <CurrencyFormat num={item.anValueWithPdv} /> : <CurrencyFormat num={item.anValueNoPdv} />} {activeCurrency.acShortName}</div>
                </td>
                <td>
                    <div className="buttons-editing-cart-all">
                        {item.isActive == 0 &&
                            <div
                                className="btn-custom-editing-cart blue-btn-editing-cart"
                                onClick={(e) => { setActiveCart(item._id); gatherStatisticActiveCart() }}
                            >
                                <div className="btn-custom-editing-cart-text"><i className="far fa-check-square"></i> {t('cart:postaviKaoAktivnu')}</div>
                            </div>
                        }
                        <div className="btn-custom-editing-cart orange-btn-editing-cart"  >
                            <button
                                className="btn-custom-editing-cart-text"
                                onClick={(e) => { clearCartItemsFunction(0); gatherStatisticEmptyCart() }}
                            >
                                <i className="far fa-window-close"></i> {t('cart:isprazniKorpu')}
                            </button>
                        </div>
                        <div className="btn-custom-editing-cart red-btn-editing-cart">
                            <button
                                className="btn-custom-editing-cart-text"
                                onClick={(e) => { removeCart(item._id); gatherStatisticDeleteCart() }}
                            >
                                <i className="far fa-window-close"></i> {t('cart:izbrisiKorpu')}
                            </button>
                        </div>
                    </div>
                </td>
            </tr>
            <ModalConfirmation show={show} handleClose={handleClose} title={title} body={body} action={action} values={values} />
        </>
    )
}

export default CartEditingItem;