import React, { useState, useEffect } from 'react';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { useTranslation } from 'react-i18next';


const OrderStatusCustomDatePicker = (props) => {
    const [selectedDate, setSelectedDate] = useState(null);
    const { t } = useTranslation('other');
    // console.log(props);
    useEffect(() => {
        setSelectedDate(props.value);
    }, [props.value]);
    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <DatePicker
                clearLabel={t('ponisti')}
                clearable cancelLabel={t('odustani')}
                value={selectedDate}
                onChange={(event) => {
                    if (event != null) {
                        event = event.toDate()
                    }

                    setSelectedDate(event);
                    props.onFilterChanged(props.id, event);
                }}
                format="DD.MM.YYYY"
                style={{ width: "100px" }}
                placeholder={props.placeholder}
            />
        </MuiPickersUtilsProvider>
    )
}

export default OrderStatusCustomDatePicker;
