import React from 'react';
import {
    Page,
    Text,
    View,
    Document,
    Image,
    Font,
    StyleSheet,
} from "@react-pdf/renderer";

const ProductSpecificationItemPdf = ({ name, desc, key }) => {

    const renderSpec = desc.map((item, key) => {
        return (item.filters !== 'N/A') ? (item.acFilterSet + ": " + item.filters.replaceAll("&#216;", "Ø") + '  ') : '';
    });

    const styles = StyleSheet.create({
        tableRow: { flexDirection: "row" },
        tableColLeft: {
            width: "20%",
            backgroundColor: "#fafafa",
            borderBottom: 1,
            borderBottomStyle: "solid",
            paddingVertical: 5,
            paddingHorizontal: 10,
            borderColor: "#e6e6e6",
            justifyContent: "center",
            textAlign: "left",
        },
        tableColRight: {
            width: "80%",
            paddingHorizontal: 5,
            borderBottom: 1,
            borderBottomStyle: "solid",
            paddingVertical: 5,
            paddingHorizontal: 10,
            borderColor: "#e6e6e6",
            textAlign: "left",
        },
    });



    return (
        <View style={styles.tableRow} wrap={false}>
            <View style={styles.tableColLeft}>
                <Text>{name}</Text>
            </View>
            <View style={styles.tableColRight}>
                {renderSpec.map(item => {
                    return <Text>{item}</Text>
                })}
            </View>
        </View>
    );
};

export default ProductSpecificationItemPdf;
