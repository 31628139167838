import React from 'react';
import { Link } from 'react-router-dom';

const MenuLogo = ({ sidebar }) => {
  const currentUrlSlug = window.location.pathname;
  return (
    currentUrlSlug === '/' ?
      <h1 title='Ewe Comp d.o.o.'>
        <Link to="/">
          < img src={sidebar ? (process.env.PUBLIC_URL + '/logo-small.jpg') : (process.env.PUBLIC_URL + '/logo.jpg')} className={`img-fluid customImageLogo ${sidebar ? 'active' : ''}`} alt="Ewe Comp Logotip" />
        </Link >
      </h1>
      :
      <Link to="/">
        < img src={sidebar ? (process.env.PUBLIC_URL + '/logo-small.jpg') : (process.env.PUBLIC_URL + '/logo.jpg')} className={`img-fluid customImageLogo ${sidebar ? 'active' : ''}`} alt="Ewe Comp Logotip" />
      </Link >
  );
};

export default MenuLogo;
