import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { globalStatistic } from '../../redux/actions/otherActions';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

const ProductDetailSliderItemSmallImage = ({ image, modalIcon, keyVal, youtubeImage }) => {

    const dispatch = useDispatch();
    const params = useParams();
    const { productId } = params;

    // const [youtubeImage, setYoutubeImage] = useState('');
    // var youtubeCode = image.acImage;
    // var youtubeKey = 'AIzaSyCcp3vQueKPj0OfU12LmKGF_xRaeJUl66E';
    // var youtubeApi = 'https://www.googleapis.com/youtube/v3/videos?key=' + youtubeKey + '&part=snippet&id=' + youtubeCode + '';

    // function youtubeApiFunction() {
    //     if (image.acType == 'video') {
    //         axios.get(youtubeApi).then((response) => {
    //             if (response.data.items.length > 0) {
    //                 setYoutubeImage(response.data.items[0].snippet.thumbnails.high.url);
    //             }
    //         });
    //     }
    // }

    // useEffect(() => {
    //     if (image.acType == 'video') {
    //         youtubeApiFunction();
    //     }
    // }, []);

    const gatherStatisticVideoProduct = () => {
        dispatch(globalStatistic(productId, 'VIDEO_PRODUCT'));
    }
    const gatherStatisticImageProduct = () => {
        dispatch(globalStatistic(productId, 'IMAGE_PRODUCT'));
    }
    const gatherStatistic3DProduct = () => {
        dispatch(globalStatistic(productId, '3D_PRODUCT'));
    }

    return (
        <>
            {image.acType == 'image' && (
                <>
                    {keyVal == 0 ? (
                        <>
                            <img className="mini-images-slider-product" src={`${image.acThumbnail}`} alt={image.acSeoDescription} title={image.acSeoTitle} />
                            {modalIcon == 1 && (
                                <>
                                    <div className="product-detail-item-3d mini-images-slider-product-video" onClick={(e) => gatherStatistic3DProduct()}>
                                        <div className="mask-div-image-360">
                                            <img alt="3D logo" src={process.env.PUBLIC_URL + `/360logo.png`} className="img-logo-360" />
                                        </div>
                                    </div>
                                </>
                            )}
                        </>
                    ) : <img className="mini-images-slider-product" src={`${image.acThumbnail}`} alt={image.acSeoDescription} title={image.acSeoTitle} onClick={(e) => gatherStatisticImageProduct()} />}
                </>
            )}
            {image.acType == 'video' && (
                <>
                    <img className="mini-images-slider-product " src={`${youtubeImage}`} alt={image.acSeoDescription} title={image.acSeoTitle} />
                    <div className="product-detail-item-3d mini-images-slider-product-video" onClick={(e) => gatherStatisticVideoProduct()}>
                        <div className="mask-div-image-360">
                            <img alt="Play button" src={process.env.PUBLIC_URL + `/player-btn.png`} className="img-logo-360" />
                        </div>
                    </div>
                </>
            )}

        </>
    )
}

export default ProductDetailSliderItemSmallImage;