import React, { useEffect, useRef, useState } from 'react';
import SearchBoxItemModal from './SearchBoxItemModal';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import LoaderMask from '../other/LoaderMask';
import { useTranslation } from 'react-i18next';

const SearchBoxModal = ({ searchBoxModal, searchBoxModalFunction, items, searchBoxModalClosed, searchText }, props) => {

    const getSearchItemsBox = useSelector((state) => state.getSearchItemsBox);
    const getMenuSettings = useSelector((state) => state.menuSettings);
    const [sidebar, setSidebar] = useState(true);

    let ref = useRef(null);
    const { t } = useTranslation(['search', 'routes']);

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            props.onClickOutside && props.onClickOutside();
            searchBoxModalFunction();
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    });

    useEffect(() => {
        var menuSettingsItem = JSON.parse(localStorage.getItem('menuSettings'));
        if (menuSettingsItem) {
            if (window.location.pathname == '/') {
                setSidebar(menuSettingsItem.homePage)
            } else {
                setSidebar(menuSettingsItem.otherPage)
            }
        }
    }, [getSearchItemsBox]);

    const searchBoxModalClosedFunction = () => {
        searchBoxModalClosed()
    }

    return (
        <div ref={ref} className={`search-box-modal ${searchBoxModal == 1 ? 'active' : ''} ${sidebar ? 'active-sidebar-collapsed' : ''}`}>
            {getSearchItemsBox.loading === true ? (
                <LoaderMask />
            ) : (
                <>
                    <div className="search-box-category-brands">
                        <div className="search-box-category-content">
                            <div className="search-box-catbrends-title">
                                {t('kategorija')}
                            </div>
                            <div className="search-box-carbrands-list">
                                <ul>
                                    {getSearchItemsBox &&
                                        getSearchItemsBox.categoryList.length > 0 ? (
                                        getSearchItemsBox.categoryList.map((item, key) => (
                                            <Link
                                                to={`/${t('routes:pretraga')}?q=${searchText}&category=${item.anSubCategoryKey}`}
                                                style={{ color: '#000' }}
                                                onClick={(e) => searchBoxModalClosed()}
                                            ><li><b>{searchText}</b> {t('u')} <i>{item.acSubCategory}</i></li></Link>

                                        ))
                                    ) : (
                                        t('nemaRezultata')
                                    )
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="search-box-brands-content">
                            <div className="search-box-catbrends-title">
                                {t('brendovi')}
                            </div>
                            <div className="search-box-carbrands-list">
                                <ul>
                                    {getSearchItemsBox &&
                                        getSearchItemsBox.brendList.length > 0 ? (
                                        getSearchItemsBox.brendList.map((item, key) => (
                                            <Link
                                                to={`/${t('routes:pretraga')}?q=${searchText}&brand=${item}`}
                                                style={{ color: '#000' }}
                                                onClick={(e) => searchBoxModalClosed()}
                                            ><li><b>{searchText}</b> {t('u')} <i>{item}</i></li></Link>

                                        ))
                                    ) : (
                                        t('nemaRezultata')
                                    )
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    <h3 className="searchbox-title-modal">{t('popularniProizvodi')} <span>({getSearchItemsBox.searchList && getSearchItemsBox.searchList.length})</span></h3>
                    <div className="search-box-list-items">
                        {getSearchItemsBox &&
                            getSearchItemsBox.productList.length > 0 ? (
                            getSearchItemsBox.productList.map((item, key) => (
                                <SearchBoxItemModal key={key} serachBoxClosedClick={searchBoxModalClosedFunction} itemValue={item} />
                            ))
                        ) : (
                            t('nemaRezultata')
                        )
                        }<Link
                            to={`/${t('routes:pretraga')}?q=${searchText}`}
                            onClick={(e) => searchBoxModalClosed()}
                        >
                            <button className="btn btn-info mt-2 float-right">{t('prikaziSve')}</button>
                        </Link>
                    </div>
                </>
            )}
        </div>
    )
}

export default SearchBoxModal;
