/**
 * @fileoverview Ovaj fajl služi za definisanje svih prevoda koje koristimo u aplikaciji.
 * @description Definišemo sve prevode koje koristimo u aplikaciji.
 * @author Ivan Žarković
 * @contributors Maša Maslovarić, Vesna Jović, Marko Milojević
 * @version 0.0.1
 * @module i18n
 * @requires i18next
 * @see https://react.i18next.com/latest
 * @date 11.04.2024.
 * @edited 08.05.2024.
 */

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Srpski prevod
import srTranslations from './locales/sr/sr.json';
import srMenuModalTranslations from './locales/sr/menuModal.json';
import srCartTranslations from './locales/sr/cart.json';
import srBannerTranslations from './locales/sr/banner.json';
import srBlockTranslations from './locales/sr/block.json';
import srCatalogTranslations from './locales/sr/catalog.json';
import srCompareTranslations from './locales/sr/compare.json';
import srFiltersTranslations from './locales/sr/filters.json';
import srFormTranslations from './locales/sr/form.json';
import srLoyaltyTranslations from './locales/sr/loyalty.json';
import srMenuTranslations from './locales/sr/menu.json';
import srModalTranslations from './locales/sr/modal.json';
import srNewsTranslations from './locales/sr/news.json';
import srOtherTranslations from './locales/sr/other.json';
import srProductTranslations from './locales/sr/product.json';
import srProfileTranslations from './locales/sr/profile.json';
import srSearchTranslations from './locales/sr/search.json';
import srSidebarCategoryTranslations from './locales/sr/sidebarCategory.json';
import srComponentsTranslations from './locales/sr/components.json';
import srEweSkiLandingTranslations from './locales/sr/eweSkiLanding.json';
import srHomePageTranslations from './locales/sr/homePage.json';
import srDeliveryPageTranslations from './locales/sr/deliveryPage.json';
import srEwowocherLandingTranslations from './locales/sr/EwowocherLanding.json';
import srDellPageTranslations from './locales/sr/dellPage.json';
import srCustomMenuPageTranslations from './locales/sr/customMenuPage.json';
import srContractPageTranslations from './locales/sr/contractPage.json';
import srCoinsTranslations from './locales/sr/coins.json';
import srReclamationStatusPageTranslations from './locales/sr/reclamationStatusPage.json';
import srProfiePageTranslations from './locales/sr/profiePage.json';
import srProductDetailPageTranslations from './locales/sr/productDetailPage.json';
import srPrivacyPolicyTranslations from './locales/sr/privacyPolicy.json';
import srPickReturnPageTranslations from './locales/sr/pickReturnPage.json';
import srOutletTranslations from './locales/sr/outlet.json';
import srOrderStatusPageTranslations from './locales/sr/orderStatusPage.json';
import srNotFoundPageTranslations from './locales/sr/notFoundPage.json';
import srNewYearLoyaltyTranslations from './locales/sr/newYearLoyalty.json';
import srNewsletterPageTranslations from './locales/sr/newsletterPage.json';
import analyticPageSrTranslations from './locales/sr/AnalyticPage.json';
import auctionPageSrTranslations from './locales/sr/AuctionPage.json';
import auctionPageMeTranslations from './locales/me/AuctionPage.json';
import wishListPageSrTranslations from './locales/sr/WishListPage.json';
import wePresentPageSrTranslations from './locales/sr/WePresentPage.json';
import weeklyActionPageSrTranslations from './locales/sr/WeeklyActionPage.json';
import trendStatusPageSrTranslations from './locales/sr/TrendStatusPage.json';
import termsSrTranslations from './locales/sr/Terms.json';
import targetPageSrTranslations from './locales/sr/TargetPage.json';
import searchPageSrTranslations from './locales/sr/SearchPage.json';
import reservationPageSrTranslations from './locales/sr/ReservationPage.json';
import beHumanityPageSrTranslations from './locales/sr/BeHumanityPage.json';
import bekoPageSrTranslations from './locales/sr/BekoPage.json';
import cartEditingPageSrTranslations from './locales/sr/CartEditingPage.json';
import cartPageSrTranslations from './locales/sr/CartPage.json';
import catalogPageSrTranslations from './locales/sr/CatalogPage.json';
import comparePageSrTranslations from './locales/sr/ComparePage.json';
import contactPageSrTranslations from './locales/sr/ContactPage.json';
import currencyPageSrTranslations from './locales/sr/CurrencyPage.json';
import customerCareSrTranslations from './locales/sr/CustomerCare.json';
import customExportCatalogSrTranslations from './locales/sr/CustomExportCatalog.json';
import deliveryScheduleSrTranslations from './locales/sr/DeliverySchedule.json';
import forgotPasswordPageSrTranslations from './locales/sr/ForgotPasswordPage.json';
import homePageLogoutSrTranslations from './locales/sr/HomePageLogout.json';
import humanityPageSrTranslations from './locales/sr/HumanityPage.json';
import humanitySolidaritySrTranslations from './locales/sr/HumanitySolidarity.json';
import humanityTreePageSrTranslations from './locales/sr/HumanityTreePage.json';
import introBrandsPageSrTranslations from './locales/sr/IntroBrandsPage.json';
import introPageSrTranslations from './locales/sr/IntroPage.json';
import lastProductListPageSrTranslations from './locales/sr/LastProductListPage.json';
import loginPageSrTranslations from './locales/sr/LoginPage.json';
import loyaltyShopLandingPageSrTranslations from './locales/sr/LoyaltyShopLandingPage.json';
import loyaltyShopPageSrTranslations from './locales/sr/LoyaltyShopPage.json';
import newsPageSrTranslations from './locales/sr/NewsPage.json';
import registrationPageSrTranslations from './locales/sr/RegistrationPage.json';
import customCatalogPdfTemplateSrTranslations from './locales/sr/CustomCatalogPdfTemplate.json';
import outletCategoryItemSrTranslations from './locales/sr/OutletCategoryItem.json';
import wishListButtonSrTranslations from './locales/sr/WishListButton.json';
import metaTagsSrTranslations from './locales/sr/metaTags.json';
import compareProductsPdfItemsSrTranslations from './locales/sr/CompareProductsPdfItems.json';

// Makedonski prevod
import mkTranslations from './locales/mk/mk.json';
import mkMenuModalTranslations from './locales/mk/menuModal.json';
import mkCartTranslations from './locales/mk/cart.json';
import mkBannerTranslations from './locales/mk/banner.json';
import mkBlockTranslations from './locales/mk/block.json';
import mkCatalogTranslations from './locales/mk/catalog.json';
import mkCompareTranslations from './locales/mk/compare.json';
import mkFiltersTranslations from './locales/mk/filters.json';
import mkFormTranslations from './locales/mk/form.json';
import mkLoyaltyTranslations from './locales/mk/loyalty.json';
import mkMenuTranslations from './locales/mk/menu.json';
import mkModalTranslations from './locales/mk/modal.json';
import mkNewsTranslations from './locales/mk/news.json';
import mkOtherTranslations from './locales/mk/other.json';
import mkProductTranslations from './locales/mk/product.json';
import mkProfileTranslations from './locales/mk/profile.json';
import mkSearchTranslations from './locales/mk/search.json';
import mkSidebarCategoryTranslations from './locales/mk/sidebarCategory.json';
import mkComponentsTranslations from './locales/mk/components.json';
import mkEweSkiLandingTranslations from './locales/mk/eweSkiLanding.json';
import mkHomePageTranslations from './locales/mk/homePage.json';
import mkDeliveryPageTranslations from './locales/mk/deliveryPage.json';
import mkEwowocherLandingTranslations from './locales/mk/EwowocherLanding.json';
import mkDellPageTranslations from './locales/mk/dellPage.json';
import mkCustomMenuPageTranslations from './locales/mk/customMenuPage.json';
import mkContractPageTranslations from './locales/mk/contractPage.json';
import mkCoinsTranslations from './locales/mk/coins.json';
import mkReclamationStatusPageTranslations from './locales/mk/reclamationStatusPage.json';
import mkProfiePageTranslations from './locales/mk/profiePage.json';
import mkProductDetailPageTranslations from './locales/mk/productDetailPage.json';
import mkPrivacyPolicyTranslations from './locales/mk/privacyPolicy.json';
import mkPickReturnPageTranslations from './locales/mk/pickReturnPage.json';
import mkOutletTranslations from './locales/mk/outlet.json';
import mkOrderStatusPageTranslations from './locales/mk/orderStatusPage.json';
import mkNotFoundPageTranslations from './locales/mk/notFoundPage.json';
import mkNewYearLoyaltyTranslations from './locales/mk/newYearLoyalty.json';
import mkNewsletterPageTranslations from './locales/mk/newsletterPage.json';
import analyticPageMkTranslations from './locales/mk/AnalyticPage.json';
import analyticPageMeTranslations from './locales/me/AnalyticPage.json';
import auctionPageMkTranslations from './locales/mk/AuctionPage.json';
import wishListPageMkTranslations from './locales/mk/WishListPage.json';
import wePresentPageMkTranslations from './locales/mk/WePresentPage.json';
import weeklyActionPageMkTranslations from './locales/mk/WeeklyActionPage.json';
import trendStatusPageMkTranslations from './locales/mk/TrendStatusPage.json';
import termsmkTranslations from './locales/mk/Terms.json';
import targetPageMkTranslations from './locales/mk/TargetPage.json';
import searchPageMkTranslations from './locales/mk/SearchPage.json';
import reservationPageMkTranslations from './locales/mk/ReservationPage.json';
import beHumanityPageMkTranslations from './locales/mk/BeHumanityPage.json';
import bekoPageMkTranslations from './locales/mk/BekoPage.json';
import cartEditingPageMkTranslations from './locales/mk/CartEditingPage.json';
import cartPageMkTranslations from './locales/mk/CartPage.json';
import catalogPageMkTranslations from './locales/mk/CatalogPage.json';
import comparePageMkTranslations from './locales/mk/ComparePage.json';
import contactPageMkTranslations from './locales/mk/ContactPage.json';
import currencyPageMkTranslations from './locales/mk/CurrencyPage.json';
import customerCaremkTranslations from './locales/mk/CustomerCare.json';
import customExportCatalogmkTranslations from './locales/mk/CustomExportCatalog.json';
import deliverySchedulemkTranslations from './locales/mk/DeliverySchedule.json';
import forgotPasswordPageMkTranslations from './locales/mk/ForgotPasswordPage.json';
import homePageLogoutmkTranslations from './locales/mk/HomePageLogout.json';
import humanityPageMkTranslations from './locales/mk/HumanityPage.json';
import humanitySolidaritymkTranslations from './locales/mk/HumanitySolidarity.json';
import humanityTreePageMkTranslations from './locales/mk/HumanityTreePage.json';
import introBrandsPageMkTranslations from './locales/mk/IntroBrandsPage.json';
import introPageMkTranslations from './locales/mk/IntroPage.json';
import lastProductListPageMkTranslations from './locales/mk/LastProductListPage.json';
import loginPageMkTranslations from './locales/mk/LoginPage.json';
import loyaltyShopLandingPageMkTranslations from './locales/mk/LoyaltyShopLandingPage.json';
import loyaltyShopPageMkTranslations from './locales/mk/LoyaltyShopPage.json';
import newsPageMkTranslations from './locales/mk/NewsPage.json';
import registrationPageMkTranslations from './locales/mk/RegistrationPage.json';
import customCatalogPdfTemplateMkTranslations from './locales/mk/CustomCatalogPdfTemplate.json';
import outletCategoryItemMkTranslations from './locales/mk/OutletCategoryItem.json';
import wishListButtonMkTranslations from './locales/mk/WishListButton.json';
import metaTagsMkTranslations from './locales/mk/metaTags.json';
import compareProductsPdfItemsMkTranslations from './locales/mk/CompareProductsPdfItems.json';

// Engleski prevod
import enTranslations from './locales/en/en.json';
import enMenuModalTranslations from './locales/en/menuModal.json';
import enCartTranslations from './locales/en/cart.json';
import enBannerTranslations from './locales/en/banner.json';
import enBlockTranslations from './locales/en/block.json';
import enCatalogTranslations from './locales/en/catalog.json';
import enCompareTranslations from './locales/en/compare.json';
import enFiltersTranslations from './locales/en/filters.json';
import enFormTranslations from './locales/en/form.json';
import enLoyaltyTranslations from './locales/en/loyalty.json';
import enMenuTranslations from './locales/en/menu.json';
import enModalTranslations from './locales/en/modal.json';
import enNewsTranslations from './locales/en/news.json';
import enOtherTranslations from './locales/en/other.json';
import enProductTranslations from './locales/en/product.json';
import enProfileTranslations from './locales/en/profile.json';
import enSearchTranslations from './locales/en/search.json';
import enSidebarCategoryTranslations from './locales/en/sidebarCategory.json';
import enComponentsTranslations from './locales/en/components.json';
import enEweSkiLandingTranslations from './locales/en/eweSkiLanding.json';
import enHomePageTranslations from './locales/en/homePage.json';
import enDeliveryPageTranslations from './locales/en/deliveryPage.json';
import enEwowocherLandingTranslations from './locales/en/EwowocherLanding.json';
import enDellPageTranslations from './locales/en/dellPage.json';
import enCustomMenuPageTranslations from './locales/en/customMenuPage.json';
import enContractPageTranslations from './locales/en/contractPage.json';
import enCoinsTranslations from './locales/en/coins.json';
import enReclamationStatusPageTranslations from './locales/en/reclamationStatusPage.json';
import enProfiePageTranslations from './locales/en/profiePage.json';
import enProductDetailPageTranslations from './locales/en/productDetailPage.json';
import enPrivacyPolicyTranslations from './locales/en/privacyPolicy.json';
import enPickReturnPageTranslations from './locales/en/pickReturnPage.json';
import enOutletTranslations from './locales/en/outlet.json';
import enOrderStatusPageTranslations from './locales/en/orderStatusPage.json';
import enNotFoundPageTranslations from './locales/en/notFoundPage.json';
import enNewYearLoyaltyTranslations from './locales/en/newYearLoyalty.json';
import enNewsletterPageTranslations from './locales/en/newsletterPage.json';
import analyticPageEnTranslations from './locales/en/AnalyticPage.json';
import auctionPageEnTranslations from './locales/en/AuctionPage.json';
import wishListPageEnTranslations from './locales/en/WishListPage.json';
import wePresentPageEnTranslations from './locales/en/WePresentPage.json';
import weeklyActionPageEnTranslations from './locales/en/WeeklyActionPage.json';
import trendStatusPageEnTranslations from './locales/en/TrendStatusPage.json';
import termsEnTranslations from './locales/en/Terms.json';
import targetPageEnTranslations from './locales/en/TargetPage.json';
import searchPageEnTranslations from './locales/en/SearchPage.json';
import reservationPageEnTranslations from './locales/en/ReservationPage.json';
import beHumanityPageEnTranslations from './locales/en/BeHumanityPage.json';
import bekoPageEnTranslations from './locales/en/BekoPage.json';
import cartEditingPageEnTranslations from './locales/en/CartEditingPage.json';
import cartPageEnTranslations from './locales/en/CartPage.json';
import catalogPageEnTranslations from './locales/en/CatalogPage.json';
import comparePageEnTranslations from './locales/en/ComparePage.json';
import contactPageEnTranslations from './locales/en/ContactPage.json';
import currencyPageEnTranslations from './locales/en/CurrencyPage.json';
import customerCareEnTranslations from './locales/en/CustomerCare.json';
import customExportCatalogEnTranslations from './locales/en/CustomExportCatalog.json';
import deliveryScheduleEnTranslations from './locales/en/DeliverySchedule.json';
import forgotPasswordPageEnTranslations from './locales/en/ForgotPasswordPage.json';
import homePageLogoutEnTranslations from './locales/en/HomePageLogout.json';
import humanityPageEnTranslations from './locales/en/HumanityPage.json';
import humanitySolidarityEnTranslations from './locales/en/HumanitySolidarity.json';
import humanityTreePageEnTranslations from './locales/en/HumanityTreePage.json';
import introBrandsPageEnTranslations from './locales/en/IntroBrandsPage.json';
import introPageEnTranslations from './locales/en/IntroPage.json';
import lastProductListPageEnTranslations from './locales/en/LastProductListPage.json';
import loginPageEnTranslations from './locales/en/LoginPage.json';
import loyaltyShopLandingPageEnTranslations from './locales/en/LoyaltyShopLandingPage.json';
import loyaltyShopPageEnTranslations from './locales/en/LoyaltyShopPage.json';
import newsPageEnTranslations from './locales/en/NewsPage.json';
import registrationPageEnTranslations from './locales/en/RegistrationPage.json';
import customCatalogPdfTemplateEnTranslations from './locales/en/CustomCatalogPdfTemplate.json';
import outletCategoryItemEnTranslations from './locales/en/OutletCategoryItem.json';
import wishListButtonEnTranslations from './locales/en/WishListButton.json';
import metaTagsEnTranslations from './locales/en/metaTags.json';
import compareProductsPdfItemsEnTranslations from './locales/en/CompareProductsPdfItems.json';

// Prevod ruta (potrebno ispraviti zakucane linkove u kodu) - 08.05.2024.
import routesSrTranslations from './locales/sr/routes.json';
import routesMkTranslations from './locales/mk/routes.json';
import routesEnTranslations from './locales/en/routes.json';

const lang = document.documentElement.lang ? document.documentElement.lang : 'en';

// Definišemo sve prevode koje koristimo u aplikaciji 
const resources = {
    en: {
        translation: enTranslations,
        menuModal: enMenuModalTranslations,
        cart: enCartTranslations,
        banner: enBannerTranslations,
        block: enBlockTranslations,
        catalog: enCatalogTranslations,
        compare: enCompareTranslations,
        filters: enFiltersTranslations,
        form: enFormTranslations,
        loyalty: enLoyaltyTranslations,
        menu: enMenuTranslations,
        modal: enModalTranslations,
        news: enNewsTranslations,
        other: enOtherTranslations,
        product: enProductTranslations,
        profile: enProfileTranslations,
        search: enSearchTranslations,
        sidebarCategory: enSidebarCategoryTranslations,
        components: enComponentsTranslations,
        eweSkiLanding: enEweSkiLandingTranslations,
        homePage: enHomePageTranslations,
        deliveryPage: enDeliveryPageTranslations,
        ewowocherLanding: enEwowocherLandingTranslations,
        dellPage: enDellPageTranslations,
        customMenuPage: enCustomMenuPageTranslations,
        contractPage: enContractPageTranslations,
        coins: enCoinsTranslations,
        reclamationStatusPage: enReclamationStatusPageTranslations,
        profiePage: enProfiePageTranslations,
        productDetailPage: enProductDetailPageTranslations,
        privacyPolicy: enPrivacyPolicyTranslations,
        pickReturnPage: enPickReturnPageTranslations,
        outlet: enOutletTranslations,
        orderStatusPage: enOrderStatusPageTranslations,
        notFoundPage: enNotFoundPageTranslations,
        newYearLoyalty: enNewYearLoyaltyTranslations,
        newsletterPage: enNewsletterPageTranslations,
        analyticPage: analyticPageEnTranslations,
        auctionPage: auctionPageEnTranslations,
        beHumanityPage: beHumanityPageEnTranslations,
        bekoPage: bekoPageEnTranslations,
        cartEditingPage: cartEditingPageEnTranslations,
        cartPage: cartPageEnTranslations,
        catalogPage: catalogPageEnTranslations,
        comparePage: comparePageEnTranslations,
        contactPage: contactPageEnTranslations,
        currencyPage: currencyPageEnTranslations,
        routes: routesEnTranslations,
        wishListPage: wishListPageEnTranslations,
        wePresentPage: wePresentPageEnTranslations,
        weeklyActionPage: weeklyActionPageEnTranslations,
        trendStatusPage: trendStatusPageEnTranslations,
        terms: termsEnTranslations,
        targetPage: targetPageEnTranslations,
        searchPage: searchPageEnTranslations,
        reservationPage: reservationPageEnTranslations,
        customerCare: customerCareEnTranslations,
        customExportCatalog: customExportCatalogEnTranslations,
        deliverySchedule: deliveryScheduleEnTranslations,
        forgotPasswordPage: forgotPasswordPageEnTranslations,
        homePageLogout: homePageLogoutEnTranslations,
        humanityPage: humanityPageEnTranslations,
        humanitySolidarity: humanitySolidarityEnTranslations,
        humanityTreePage: humanityTreePageEnTranslations,
        introBrandsPage: introBrandsPageEnTranslations,
        introPage: introPageEnTranslations,
        lastProductListPage: lastProductListPageEnTranslations,
        loginPage: loginPageEnTranslations,
        loyaltyShopLandingPage: loyaltyShopLandingPageEnTranslations,
        loyaltyShopPage: loyaltyShopPageEnTranslations,
        newsPage: newsPageEnTranslations,
        registrationPage: registrationPageEnTranslations,
        customCatalogPdfTemplate: customCatalogPdfTemplateEnTranslations,
        outletCategoryItem: outletCategoryItemEnTranslations,
        wishListButton: wishListButtonEnTranslations,
        metaTags: metaTagsEnTranslations,
        compareProductsPdfItems: compareProductsPdfItemsEnTranslations
    },
    sr: {
        translation: srTranslations,
        menuModal: srMenuModalTranslations,
        cart: srCartTranslations,
        banner: srBannerTranslations,
        block: srBlockTranslations,
        catalog: srCatalogTranslations,
        compare: srCompareTranslations,
        filters: srFiltersTranslations,
        form: srFormTranslations,
        loyalty: srLoyaltyTranslations,
        menu: srMenuTranslations,
        modal: srModalTranslations,
        news: srNewsTranslations,
        other: srOtherTranslations,
        product: srProductTranslations,
        profile: srProfileTranslations,
        search: srSearchTranslations,
        sidebarCategory: srSidebarCategoryTranslations,
        components: srComponentsTranslations,
        eweSkiLanding: srEweSkiLandingTranslations,
        homePage: srHomePageTranslations,
        deliveryPage: srDeliveryPageTranslations,
        ewowocherLanding: srEwowocherLandingTranslations,
        dellPage: srDellPageTranslations,
        customMenuPage: srCustomMenuPageTranslations,
        contractPage: srContractPageTranslations,
        coins: srCoinsTranslations,
        reclamationStatusPage: srReclamationStatusPageTranslations,
        profiePage: srProfiePageTranslations,
        productDetailPage: srProductDetailPageTranslations,
        privacyPolicy: srPrivacyPolicyTranslations,
        pickReturnPage: srPickReturnPageTranslations,
        outlet: srOutletTranslations,
        orderStatusPage: srOrderStatusPageTranslations,
        notFoundPage: srNotFoundPageTranslations,
        newYearLoyalty: srNewYearLoyaltyTranslations,
        newsletterPage: srNewsletterPageTranslations,
        analyticPage: analyticPageSrTranslations,
        auctionPage: auctionPageSrTranslations,
        beHumanityPage: beHumanityPageSrTranslations,
        bekoPage: bekoPageSrTranslations,
        cartEditingPage: cartEditingPageSrTranslations,
        cartPage: cartPageSrTranslations,
        catalogPage: catalogPageSrTranslations,
        comparePage: comparePageSrTranslations,
        contactPage: contactPageSrTranslations,
        currencyPage: currencyPageSrTranslations,
        routes: routesSrTranslations,
        wishListPage: wishListPageSrTranslations,
        wePresentPage: wePresentPageSrTranslations,
        weeklyActionPage: weeklyActionPageSrTranslations,
        trendStatusPage: trendStatusPageSrTranslations,
        terms: termsSrTranslations,
        targetPage: targetPageSrTranslations,
        searchPage: searchPageSrTranslations,
        reservationPage: reservationPageSrTranslations,
        customerCare: customerCareSrTranslations,
        customExportCatalog: customExportCatalogSrTranslations,
        deliverySchedule: deliveryScheduleSrTranslations,
        forgotPasswordPage: forgotPasswordPageSrTranslations,
        homePageLogout: homePageLogoutSrTranslations,
        humanityPage: humanityPageSrTranslations,
        humanitySolidarity: humanitySolidaritySrTranslations,
        humanityTreePage: humanityTreePageSrTranslations,
        introBrandsPage: introBrandsPageSrTranslations,
        introPage: introPageSrTranslations,
        lastProductListPage: lastProductListPageSrTranslations,
        loginPage: loginPageSrTranslations,
        loyaltyShopLandingPage: loyaltyShopLandingPageSrTranslations,
        loyaltyShopPage: loyaltyShopPageSrTranslations,
        newsPage: newsPageSrTranslations,
        registrationPage: registrationPageSrTranslations,
        customCatalogPdfTemplate: customCatalogPdfTemplateSrTranslations,
        outletCategoryItem: outletCategoryItemSrTranslations,
        wishListButton: wishListButtonSrTranslations,
        metaTags: metaTagsSrTranslations,
        compareProductsPdfItems: compareProductsPdfItemsSrTranslations
    },
    mk: {
        translation: mkTranslations,
        analyticPage: analyticPageMkTranslations,
        auctionPage: auctionPageMkTranslations,
        routes: routesMkTranslations,
        menuModal: mkMenuModalTranslations,
        cart: mkCartTranslations,
        banner: mkBannerTranslations,
        block: mkBlockTranslations,
        catalog: mkCatalogTranslations,
        compare: mkCompareTranslations,
        filters: mkFiltersTranslations,
        form: mkFormTranslations,
        loyalty: mkLoyaltyTranslations,
        menu: mkMenuTranslations,
        modal: mkModalTranslations,
        news: mkNewsTranslations,
        other: mkOtherTranslations,
        product: mkProductTranslations,
        profile: mkProfileTranslations,
        search: mkSearchTranslations,
        sidebarCategory: mkSidebarCategoryTranslations,
        components: mkComponentsTranslations,
        eweSkiLanding: mkEweSkiLandingTranslations,
        homePage: mkHomePageTranslations,
        deliveryPage: mkDeliveryPageTranslations,
        ewowocherLanding: mkEwowocherLandingTranslations,
        dellPage: mkDellPageTranslations,
        customMenuPage: mkCustomMenuPageTranslations,
        contractPage: mkContractPageTranslations,
        coins: mkCoinsTranslations,
        reclamationStatusPage: mkReclamationStatusPageTranslations,
        profiePage: mkProfiePageTranslations,
        productDetailPage: mkProductDetailPageTranslations,
        privacyPolicy: mkPrivacyPolicyTranslations,
        pickReturnPage: mkPickReturnPageTranslations,
        outlet: mkOutletTranslations,
        orderStatusPage: mkOrderStatusPageTranslations,
        notFoundPage: mkNotFoundPageTranslations,
        newYearLoyalty: mkNewYearLoyaltyTranslations,
        newsletterPage: mkNewsletterPageTranslations,
        beHumanityPage: beHumanityPageMkTranslations,
        bekoPage: bekoPageMkTranslations,
        cartEditingPage: cartEditingPageMkTranslations,
        cartPage: cartPageMkTranslations,
        catalogPage: catalogPageMkTranslations,
        comparePage: comparePageMkTranslations,
        contactPage: contactPageMkTranslations,
        currencyPage: currencyPageMkTranslations,
        wishListPage: wishListPageMkTranslations,
        wePresentPage: wePresentPageMkTranslations,
        weeklyActionPage: weeklyActionPageMkTranslations,
        trendStatusPage: trendStatusPageMkTranslations,
        terms: termsmkTranslations,
        targetPage: targetPageMkTranslations,
        searchPage: searchPageMkTranslations,
        reservationPage: reservationPageMkTranslations,
        customerCare: customerCaremkTranslations,
        customExportCatalog: customExportCatalogmkTranslations,
        deliverySchedule: deliverySchedulemkTranslations,
        forgotPasswordPage: forgotPasswordPageMkTranslations,
        homePageLogout: homePageLogoutmkTranslations,
        humanityPage: humanityPageMkTranslations,
        humanitySolidarity: humanitySolidaritymkTranslations,
        humanityTreePage: humanityTreePageMkTranslations,
        introBrandsPage: introBrandsPageMkTranslations,
        introPage: introPageMkTranslations,
        lastProductListPage: lastProductListPageMkTranslations,
        loginPage: loginPageMkTranslations,
        loyaltyShopLandingPage: loyaltyShopLandingPageMkTranslations,
        loyaltyShopPage: loyaltyShopPageMkTranslations,
        newsPage: newsPageMkTranslations,
        registrationPage: registrationPageMkTranslations,
        customCatalogPdfTemplate: customCatalogPdfTemplateMkTranslations,
        outletCategoryItem: outletCategoryItemMkTranslations,
        wishListButton: wishListButtonMkTranslations,
        metaTags: metaTagsMkTranslations,
        compareProductsPdfItems: compareProductsPdfItemsMkTranslations
    },
    me: {
        analyticPage: analyticPageMeTranslations,
        auctionPage: auctionPageMeTranslations
    },
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: lang,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
