import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import CompareEmptyItemBox from './CompareEmptyItemBox';
import { useHistory } from 'react-router';
import {
    removeProductCompare,
    removeProductCompareAll
} from '../../redux/actions/productActions';

import { removeSpecialChar } from '../../helperFunctions'
import { useTranslation } from 'react-i18next';

const CompareProducts = () => {

    const dispatch = useDispatch();
    const compareItems = useSelector((state) => state.compareItems.compareItems);
    const boxes = compareItems.length < 4 ? 4 - compareItems.length : 0;
    const [compareShow, setCompareShow] = useState(1);
    const { t } = useTranslation(['compare', 'routes']);

    const params = useParams();
    const history = useHistory();
    let pathname = history.location.pathname;

    const getEmptyCompareBoxes = () => {
        let content = [];
        let numMax = 4;
        for (let index = 1; index < boxes + 1; index++) {
            const num = numMax - boxes + index;
            content.push(<CompareEmptyItemBox key={index} num={num} />);
        }
        return content;
    };

    return (
        <>
            {(!pathname.includes('uporedi-proizvode') && !pathname.includes('спореди-производи')) && (
                <>
                    {compareShow == 0 ? (
                        <div className={`compare-content-container ${compareItems.length === 0 && 'hidden'}`}>
                            <div className="compare-item-container-body">

                                {compareItems.map((item, key) => (
                                    <div key={key} className="compare-item-body compare-content-item active">
                                        <div className="compare-item-detail">
                                            <div className="comapre-item-number">{key + 1}.</div>
                                            <div className="compare-item-image">
                                                <img src={`https://resource.ewe.rs/products/${item.acProduct.trim()}.jpg`} className="img-compare-item" alt="" />
                                            </div>
                                            <div className="compare-item-name">{item.acDept} {item.acName}</div>
                                            <div className="close-compare-item-detail hidden" onClick={(e) => dispatch(removeProductCompare(item))}><i className="fas fa-times"></i></div>
                                        </div>
                                    </div>
                                ))}

                                {getEmptyCompareBoxes()}

                                <div className="compare-item-body comapre-buttons-list">
                                    <a target="_blank" href={`/${t('routes:uporediProizvode')}/${params.categoryName ? removeSpecialChar(params.categoryName) : params.name ? removeSpecialChar(params.name) : t('pretraga')}`}><div className="comapre-button-footer-submit">{t("uporediProizvode")}</div></a>
                                    <div className="comapre-button-footer-clear" onClick={(e) => dispatch(removeProductCompareAll())}>{t("ukloniSve")}</div>
                                    <div className="comapre-button-footer-minimize" onClick={(e) => setCompareShow(1)}><i className="fas fa-angle-double-down" title="Sakrij liniju poredjenja"></i></div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className={`comapre-content-container-minimize ${compareItems.length === 0 && 'hidden'}`}>
                            <div className="comapre-button-footer-maximize">
                                <div className="minimize-counter-products-compare d-block d-sm-none">
                                    {t('uporediProizvode')} ({compareItems.length}/4)
                                </div>
                                <div className="minimize-counter-products-compare d-none d-sm-block">
                                    {t("izabraniProizvodZaPoredjenje")} ({compareItems.length}/4)
                                </div>
                                <div className="icon-maximze-content">
                                    <div className="maximize-items-compare">
                                        <div className="maximize-compare-item-btn d-block d-sm-none mobile-compare-button">
                                            <a target="_blank" href={`/${t('routes:uporediProizvode')}/${params.categoryName ? removeSpecialChar(params.categoryName) : params.name ? removeSpecialChar(params.name) : t('pretraga')}`}><div className="comapre-button-footer-submit">{t('uporedi')}</div></a>
                                        </div>
                                        <div className="maximize-compare-item-btn d-none d-sm-block">
                                            <a target="_blank" href={`/${t('routes:uporediProizvode')}/${params.categoryName ? removeSpecialChar(params.categoryName) : params.name ? removeSpecialChar(params.name) : t('pretraga')}`}><div className="comapre-button-footer-submit">{t("uporediProizvode")}</div></a>
                                        </div>


                                        <div className="maximize-compare-item-btn d-block d-sm-none mobile-compare-button">
                                            <div className="comapre-button-footer-clear" onClick={(e) => dispatch(removeProductCompareAll())}>{t('ukloni')}</div>
                                        </div>

                                        <div className="maximize-compare-item-btn d-none d-sm-block">
                                            <div className="comapre-button-footer-clear" onClick={(e) => dispatch(removeProductCompareAll())}>{t('ukloniSve')}</div>
                                        </div>


                                        <div className="maximize-compare-item-btn maximize-btn-compare-click" onClick={(e) => setCompareShow(0)}>
                                            <i className="fas fa-angle-double-up" title="Prikazi liniju poredjenja"></i></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    )
}

export default CompareProducts;