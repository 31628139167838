import React from 'react';
import { useTranslation } from 'react-i18next';
import messages from '../../messages';

const MessageInfoBox = ({ type, message, dataItems }) => {
    const { i18n } = useTranslation();
    return (
        <>
            {type == 1 ? (
                <div className="message-succes-icon">
                    <div className="face">
                        <div className="eye"></div>
                        <div className="eye right"></div>
                        <div className="mouth happy"></div>
                    </div>
                    <div className="shadow scale"></div>
                </div>
            ) : (
                <div className="message-error-icon">
                    <div className="face2">
                        <div className="eye"></div>
                        <div className="eye right"></div>
                        <div className="mouth sad"></div>
                    </div>
                    <div className="shadow move"></div>
                </div>
            )}
            {message == "notFoundIndent" ? (
                <div className="message">{`Proizvod/i ${dataItems.slice(0, -1)} iz naloga nije na raspolaganju`}</div>
            ) : (
                <div className="message">{messages[i18n.language][message]}</div>
            )
            }
        </>
    )
}

export default MessageInfoBox;