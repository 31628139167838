import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { axiosinstance } from '../../configAxios';
import { useLocation } from "react-router-dom";
// import { fetchPosts } from '../../redux/actions/productActions';
import { getSearchItemsPage } from '../../redux/actions/productActions';
import { changeProductSort } from '../../redux/actions/userActions';
import { useDispatch, useSelector } from 'react-redux';

function useQuery() {
  return new URLSearchParams(useLocation().search);
};

const SearchSortItem = () => {

  const [sortList, setSortList] = useState([]);
  const { categoryId, catagoryType } = useParams();
  const dispatch = useDispatch();
  let query = useQuery();
  const activeProductSort = useSelector((state) => state.activeProductSort.item);

  const functionListSort = () => {
    const attributes = {};
    const configRes = axiosinstance(attributes, '8224', 'getSort');
      axios(configRes)
          .then(function (response) {
            setSortList(response.data.response.sortList);
          })
          .catch(function (error) {
  
          }); 
  };

  useEffect(() => {
    functionListSort();
  }, [])

  const changeCatalogProducts = (val) => {
    dispatch(changeProductSort(val));
    dispatch(getSearchItemsPage(query.get("q"), query.get("brand"), query.get("category")));
  };

  return (
    <>
      <div className="catalog-sort-box-input">
        <select className="catalog-sort-select-input form-control form-control-sm" onChange={(e) => changeCatalogProducts(e.target.value)} >
          { sortList.map((item, key) => (
            <option 
              key={key} 
              value={item._id}
              defaultValue={item._id}
              selected={item._id == activeProductSort && 'selected'}
            >
              {item.acName}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};

export default SearchSortItem;