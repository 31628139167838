import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageHelmet from '../components/helmet/PageHelmet';
import { globalStatistic } from '../redux/actions/otherActions';
import { getPageSeo } from '../redux/actions/pageActions';


const InstructionsForUsers = () => {

    const dispatch = useDispatch();
    const [pageSeoData, setPageSeoData] = useState({});

    useEffect(() => {
        dispatch(globalStatistic(758, 'PAGE'));
        dispatch(getPageSeo(758)).then(res => {
            if (res) {
                setPageSeoData(res);
            }
        })
    }, [])

    return (
        <>
            {Object.keys(pageSeoData).length !== 0 &&
                <PageHelmet
                    title={pageSeoData ? `${pageSeoData.acSeoTitle}` : "Ewe Comp"}
                    description={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    keywords={pageSeoData ? `${pageSeoData.acSeoKeywords}` : ""}
                    ogDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    ogTitle={pageSeoData ? `${pageSeoData.acSeoTitle}` : ""}
                    ogUrl={pageSeoData ? `https://ewe.rs/${pageSeoData.acSeoUrl}` : ""}
                    ogImg="https://www.ewe.rs/media/ewe-og-img.jpg"
                    ogType="article"
                    twitterDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                />
            }
            <div style={{ backgroundColor: "black" }} className="container-content2 ">
                <div className="container-fluid">
                    <div className='content'>
                        <div className='page-header'>
                            <img src="/images/header.jpg" className="img-header" alt="header" />
                        </div>
                        <div className='page-body'>
                            <img src="/images/col1.jpg" className="img-col1" alt="ewe" />
                            <img src="/images/col2.jpg" className="img-col2" alt="ewe" />
                        </div>
                    </div>
                </div>

            </div >
        </>

    )
}

export default InstructionsForUsers