import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getWishList } from '../redux/actions/productActions';
import { globalStatistic } from '../redux/actions/otherActions';
import styles from '../css/Wishlist.module.css';

import WishListItem from '../components/other/WishListItem';
import WishListButtonRemoveAll from '../components/other/WishListButtonRemoveAll';
import { useTranslation } from 'react-i18next';
const WishListPage = ({ isProfilePage }) => {

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getWishList());
        dispatch(globalStatistic(542, 'PAGE'));
    }, [])
    const wishListItems = useSelector((state) => state.wishListItems.items);
    const { t } = useTranslation('wishListPage');

    return (
        <div className={`container-content stats-order-content ${styles['container-catalog-content']} ${styles['wishlist-page']}`}>
            <div className="container-fluid">
                <div className="row">
                    {isProfilePage !== 'profile' &&
                        <div className="col-sm-12">
                            <div className="stats-order-content-holder">
                                <h2 className="weekly-title">{t('listaZelja')}</h2>
                            </div>
                        </div>
                    }
                    <div className="col-sm-12">
                        <div className={styles['wish-list-table']}>
                            <div className={styles['editing-cart-items-table']}>
                                {wishListItems.length == 0 ? (
                                    <div className="alert alert-primary" role="alert">
                                        {t('praznaListaZelja')}
                                    </div>
                                ) : (
                                    <table className={`table ${styles['editing-cart-table']}`}>
                                        <thead>
                                            <tr>
                                                <th>{t('kolona1')}</th>
                                                <th>{t('kolona2')}</th>
                                                <th>{t('kolona3')}</th>
                                                <th>{t('kolona4')}</th>
                                                <th className="text-center">{t('kolona5')}</th>
                                                <th></th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {wishListItems.map((item, key) => (
                                                <WishListItem key={key} item={item} />
                                            ))}
                                        </tbody>
                                    </table>
                                )}
                                {wishListItems.length > 0 && (
                                    <WishListButtonRemoveAll />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WishListPage;