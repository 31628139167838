import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const NotFoundPage = () => {
    const { t } = useTranslation('notFoundPage');

    return (
        <div className="container-content">
            <div className="container-fluid">
                <div className="row">
                    <div className="page-not-found-container container-fluid">
                        <div className="col-page-not-found-block col-page-not-found-block-image">
                            <img src="/images/page-not-found.png" className="img-fluid" alt="Not found" />
                        </div>
                        <div className="col-page-not-found-block">
                            <div className="content-not-found-block">
                                <div className="content-not-found-block-title">{t('greska404')}</div>
                                <div className="content-not-found-block-subtitle">{t('strNijePronadjena')}</div>
                                <div className="content-not-found-block-desc">{t('urlNijePronadjen')}</div>
                                <div className="content-not-found-block-button">
                                    <Link to="/">
                                        <button className="btn btn-info">{t('povratakNaPocetnu')}</button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotFoundPage;