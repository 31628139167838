import React from 'react';
import { useLocation } from 'react-router-dom';
import FilterCheckboxBrandInput from '../filters/FilterCheckboxBrandInput';
import FilterCheckboxBrandInputChecked from '../filters/FilterCheckboxBrandInputChecked';

const CatalogBrandFilterItem = ({ checkFilterFunction, item, index }) => {

  const location = useLocation();
  var checkedBox = '';

  const onClickCheckboxFilterFunction = (e, item2, item, type) => {
    checkFilterFunction(e, item2, item, type);
  };

  var selectedFilters = [];
  var checkboxItems = location.search.split('?');
  if (checkboxItems.slice(0, -1).length > 0) {
    checkboxItems.forEach(item => {
      if (item != '') {
        var checboxItem = item.split('&');
        checboxItem.forEach(item => {
          selectedFilters.push(item.split('=')[1].split('_')[1]);
        });
      }
    });
  }
  if (item.anId) {
    checkedBox = selectedFilters.includes(item.anId.toString());
  }
  return (
    <div key={index} className={`filter-item-detail filter-show-item`}>
      <div className="form-group form-check">
        {checkedBox ? (
          <FilterCheckboxBrandInputChecked item={item} onClickCheckboxFilterFunction={onClickCheckboxFilterFunction} />
        ) : (
          <FilterCheckboxBrandInput item={item} onClickCheckboxFilterFunction={onClickCheckboxFilterFunction} />
        )}
        <label className="form-check-label" htmlFor={`checbox${item.acString}`}>{item.acString.replace(" 1****", "")} <span className="total-count-filter">({item.anNumber})</span></label>
      </div>
    </div>
  );
};

export default CatalogBrandFilterItem;