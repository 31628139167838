import React from 'react';

const LoaderMaskLine = () => {
    return (
        <div className="loader-mask-line">
            <div className="loader-mask-item-line">
                <img src="/images/loader-line.gif" alt="" className="img-fluid" />
            </div>
        </div>
    );
};

export default LoaderMaskLine;