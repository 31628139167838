import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { PDFViewer } from "@react-pdf/renderer";

import axios from "axios";

import { getFilteredProducts, fetchWeeklyPosts, getSearchItemsPage, getActionProductsList } from "../../redux/actions/productActions";

import CatalogPdfTemplate from "./templates/CatalogPdfTemplate";



const ExportProductsPdf = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    const q = new URLSearchParams(location.search);
    const catalogType = q.get("type");

    const [productsList, setProductsList] = useState([]);

    const currency = useSelector((state) => state.activeCurrency.item.acShortName);
    const catalogProducts = useSelector((state) => state.catalogProducts.items);
    const catalogSearchProducts = useSelector((state) => state.searchProductsList.items);
    const weeklyProducts = useSelector((state) => state.weeklyProducts);
    const searchProductsList = useSelector((state) => state.searchProductsList);
    const getActionProducts = useSelector((state) => state.getActionProducts);
    const getUserInfo = useSelector((state) => state.userInfo.userInfo);

    useEffect(() => {
        if (catalogType == "katalog") {
            let categoryId = q.get("categoryId");
            let categoryType = q.get("categoryType");
            // let filters = q.get("filters");
            let filters = location.search.split("?")[2];
            dispatch(getFilteredProducts(categoryId, categoryType, filters));
        } else if (catalogType == "nedeljna-akcija") {
            dispatch(fetchWeeklyPosts());
        } else if (catalogType == "search") {
            // console.log(q.get("q"));
            dispatch(getSearchItemsPage(q.get("q"), q.get("brand"), q.get("category")));
        } else if (catalogType == "specijalna-akacija") {
            let id = q.get("id");
            dispatch(getActionProductsList(id));
        }
    }, []);

    useEffect(() => {
        setProductsList(catalogProducts);
    }, [catalogProducts]);

    useEffect(() => {
        setProductsList(weeklyProducts.items);
    }, [weeklyProducts]);

    useEffect(() => {
        setProductsList(searchProductsList);
    }, [searchProductsList]);

    useEffect(() => {
        setProductsList(catalogSearchProducts);
    }, [catalogSearchProducts]);

    useEffect(() => {
        setProductsList(getActionProducts.items);
    }, [getActionProducts]);
    //   return 1;
    return <PDFViewer style={{ width: "100vw", height: "100vh" }}>{productsList.length > 0 && <CatalogPdfTemplate list={productsList} currency={currency} getUserInfo={getUserInfo} />}</PDFViewer>;
}

export default ExportProductsPdf;
