import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import ProductDetailSpecificationItem from '../product/ProductDetailSpecificationItem';
import { useTranslation } from 'react-i18next';

const ModalLoyalty = ({ show, handleClose, item }) => {

    const productSpecificationList = useSelector((state) => state.getProductSpecification);
    const { t } = useTranslation('other');


    return (
        <Modal
            show={show}
            onHide={handleClose}
            dialogClassName="modal-custom-product-3d"
        >
            <Modal.Header>
                <div className="modal-loyalty-header-item">
                    <h3 className="body-modal-loyalty-specification-h3">{item.acProductName}</h3>
                </div>
            </Modal.Header>
            <Modal.Body className="body-modal-loyalty-specification">

                <div className="modal-loyalty-header-item product-ident-loyalty">{t('sifra')} {item.acProduct}</div>

                <div className="image-content-loyalty-modal">
                    <img src={`https://resource.ewe.rs/products/${item.acProduct}_v.jpg`} alt="Loyalty proizvod" className="loyalty-modal-image" />
                </div>
                {Object.keys(productSpecificationList).length > 0 ? (
                    <div className="product-detail-tab-specification active">
                        {productSpecificationList.list.map((item, key) => (
                            <ProductDetailSpecificationItem name={item.acGroupName} key={key} show={true} filterSet={item.filterSet} type={'loyalty'} />
                        ))}
                    </div>
                ) : (
                    <div className="alert alert-danger w-100 mt-4" role="alert">
                        {t('nemaInfoOartiklu')}
                    </div>
                )}



            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t('zatvoriPregled')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalLoyalty;