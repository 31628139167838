import React from 'react';
// import FiltersMobile from '../FiltersMobile';
import Filters from '../Filters';
import { useTranslation } from 'react-i18next';

const FilterSidebarModal = ({ filterModal, setFilterModal }) => {
    const { t } = useTranslation('filters');

    return (
        <div className={`filters-mobile-container ${filterModal && 'active'}`}>
            <div className="close-mobile-filter-modal" >
                <div className="close-mobile-filter-modal-item" onClick={(e) => setFilterModal(false)}>
                    <i className="fas fa-filter"></i>{t('primeniFiltere')}
                </div>
            </div>
            <Filters />
        </div>
    )
}

export default FilterSidebarModal;