import React from 'react';
import {
    addToCart
} from '../../redux/actions/cartActions';
import { useDispatch, useSelector } from 'react-redux';

const AddToCartButton = ({ product, qtyValue, name, activeCart }) => {



    const dispatch = useDispatch();

    const iconPath = process.env.PUBLIC_URL + '/media/';
    const getActiveCartFunction = useSelector((state) => state.getActiveCartFunction);
    const addToCartFunction = () => {
        dispatch(addToCart(product, qtyValue, getActiveCartFunction));
    };

    return (
        <div onClick={(e) => addToCartFunction()} className="add-button">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51.33 43.28">
                <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                    <path className="cls-1" d="M45.42,9.66a6,6,0,0,1,1.28,1.28l2.85-2.07a9.15,9.15,0,0,0-2.06-2.06Zm5.91,10.72c0-2.79,0-5-.19-6.81a9.38,9.38,0,0,0-1.59-4.7L46.7,10.94a6,6,0,0,1,.94,3c.16,1.55.17,3.56.17,6.43ZM36,8.55c2.87,0,4.88,0,6.43.17a6,6,0,0,1,3,.94l2.07-2.85a9.38,9.38,0,0,0-4.7-1.59C41,5,38.77,5,36,5ZM46.7,30.32a5.55,5.55,0,0,1-1.28,1.28l2.07,2.85a9.15,9.15,0,0,0,2.06-2.06ZM36,36.23c2.79,0,5,0,6.81-.19a9.29,9.29,0,0,0,4.7-1.59L45.42,31.6a5.9,5.9,0,0,1-3,.94c-1.55.17-3.56.17-6.43.17ZM47.81,20.88c0,2.87,0,4.88-.17,6.43a6,6,0,0,1-.94,3l2.85,2.07a9.38,9.38,0,0,0,1.59-4.7c.19-1.78.19-4,.19-6.81ZM17.54,31.77a6,6,0,0,1-1.25-1.1L13.6,32.94a9.26,9.26,0,0,0,2,1.78Zm-6.74-9c.31,2.47.56,4.45.92,6a9.52,9.52,0,0,0,1.88,4.12l2.69-2.27A5.91,5.91,0,0,1,15.16,28c-.32-1.36-.54-3.13-.86-5.67ZM26,32.71c-2.56,0-4.35,0-5.73-.14a5.92,5.92,0,0,1-2.76-.8l-1.92,2.95a9.32,9.32,0,0,0,4.32,1.35c1.61.16,3.6.16,6.09.16ZM14.3,22.36l-2-15.79L8.82,7l2,15.79ZM36,32.71H26v3.52H36ZM47.81,20.38v.5h3.52v-.5ZM10.57,8.55H36V5H10.57ZM48.13,9.91l-1.43,1ZM46.46,8.24l-1,1.42Zm0,24.79,1,1.42Zm1.67-1.67-1.43-1ZM15,31.8,13.6,32.94Zm1.63,1.44,1-1.47Zm-6-26.45V5h-2l.25,2Z" />
                    <path className="cls-1" d="M10.29,2.08A4.26,4.26,0,0,0,6.63,0V3.52a.74.74,0,0,1,.64.37ZM6.63,0H1.76V3.52H6.63Zm6.71,7.15L10.29,2.08l-3,1.81L10.32,9ZM1.76,0a1.76,1.76,0,0,0,0,3.52Z" />
                    <circle className="cls-1" cx="40.76" cy="40.76" r="2.52" />
                    <circle className="cls-1" cx="23.15" cy="40.76" r="2.52" />
                <path className="cls-1" d="M40.26,16.86H33.72v3.52h6.54Zm-6.54,0a1.76,1.76,0,1,0,0,3.52Zm6.54,3.52a1.76,1.76,0,1,0,0-3.52Z" />
            </g>
            </g>
</svg>&nbsp; {name}
        </div>
    )
}

export default AddToCartButton;