import React, { useEffect, useState } from 'react';
import PageBanner from '../components/banner/PageBanner';
import PageHelmet from '../components/helmet/PageHelmet';
import { useDispatch } from 'react-redux';
import { globalStatistic } from '../redux/actions/otherActions';
import { getPageSeo } from '../redux/actions/pageActions';
import { useTranslation } from 'react-i18next';

const BekoPage = () => {

    const dispatch = useDispatch();
    const [pageSeoData, setPageSeoData] = useState({});
    const { t } = useTranslation('bekoPage');

    useEffect(() => {
        dispatch(getPageSeo(762)).then(res => {
            if (res) {
                setPageSeoData(res);
            }
        });
        dispatch(globalStatistic(762, 'PAGE'));
    }, [])

    return (
        <>
            {Object.keys(pageSeoData).length !== 0 &&
                <PageHelmet
                    title={pageSeoData ? `${pageSeoData.acSeoTitle}` : "Ewe Comp"}
                    description={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    keywords={pageSeoData ? `${pageSeoData.acSeoKeywords}` : ""}
                    ogDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    ogTitle={pageSeoData ? `${pageSeoData.acSeoTitle}` : ""}
                    ogUrl={pageSeoData ? `https://ewe.rs/${pageSeoData.acSeoUrl}` : ""}
                    ogImg="https://www.ewe.rs/media/ewe-og-img.jpg"
                    ogType="article"
                    twitterDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                />
            }
            <div className="container-content container-catalog-content delivery-form-page">
                <div className="container-fluid-custom">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="banner-image-on-top-page">
                                <PageBanner categoryType="" categoryId="" pageId="396" />
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="title-beko-page">
                                <h1>{pageSeoData.acSeoHeader ? pageSeoData.acSeoHeader : t('glavniNaslov')}</h1>
                            </div>
                            <div className="subtitle-beko-page">
                                <p>{t('glavniPodnaslov')}</p>
                                {pageSeoData.acSeoShortPageDescription && <p>{pageSeoData.acSeoShortPageDescription}</p>}
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="beko-page-items">
                                <div className="beko-page-item">
                                    <img src="/media/Liderstvo.png" alt="lidership"/>
                                    <h2>{t('naslov1')}</h2>
                                    <p>{t('podNaslov1')}</p>
                                </div>
                                <div className="beko-page-item">
                                    <img src="/media/Kvalitet.png" alt="quality"/>
                                    <h2>{t('naslov2')}</h2>
                                    <p>{t('podNaslov2')}</p>
                                </div>
                                <div className="beko-page-item">
                                    <img src="/media/Inspiracija.png" alt="inspiration"/>
                                    <h2>{t('naslov3')}</h2>
                                    <p>{t('podNaslov3')}</p>
                                </div>
                                <div className="beko-page-item">
                                    <img src="/media/Inovacija.png" alt="inovation"/>
                                    <h2>{t('naslov4')}</h2>
                                    <p>{t('podNaslov4')}</p>
                                </div>
                                <div className="beko-page-item">
                                    <img src="/media/Timski-duh.png" alt="team spirit"/>
                                    <h2>{t('naslov5')}</h2>
                                    <p>{t('podNaslov5')}</p>
                                </div>
                                <div className="beko-page-item">
                                    <img src="/media/Tehnologija.png" alt="technology"/>
                                    <h2>{t('naslov6')}</h2>
                                    <p>{t('podNaslov6')}</p>
                                </div>
                                <div className="beko-page-item">
                                    <img src="/media/Dizajn.png" alt="design"/>
                                    <h2>{t('naslov7')}</h2>
                                    <p>{t('podNaslov7')}</p>
                                </div>
                                <div className="beko-page-item">
                                    <img src="/media/Garancija.png" alt="varanty"/>
                                    <h2>{t('naslov8')}</h2>
                                    <p>{t('podNaslov8')}</p>
                                </div>
                                <div className="beko-page-item">
                                    <img src="/media/Eko-odgovornost.png" alt="eco responsibility"/>
                                    <h2>{t('naslov9')}</h2>
                                    <p>{t('podNaslov9')}</p>
                                </div>
                                <div className="beko-page-item">
                                    <img src="/media/Usluge.png" alt="services"/>
                                    <h2>{t('naslov10')}</h2>
                                    <p>{t('podNaslov10')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BekoPage;