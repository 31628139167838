import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Page, Text, View, Document, StyleSheet, Font, Image } from "@react-pdf/renderer";
import CustomCatalogPdfItem from "./CustomCatalogPdfItem";
import globalVariable from '../../../global';
import { useTranslation } from 'react-i18next';
import SecureLS from 'secure-ls';

const CustomCatalogPdfTemplate = (props) => {

    let ls = new SecureLS({ encodingType: 'aes' });

    const { list, currency, activeVat } = props;
    const userInfo = ls.get('userInfo');
    const [lastPage, setLastPage] = useState(1);
    const { t } = useTranslation('customCatalogPdfTemplate');

    const today = new Date();
    const godina = today.getFullYear();
    let mesec = today.getMonth() + 1; // mesec pocinje od 0!
    let dan = today.getDate();

    if (dan < 10) dan = '0' + dan;
    if (mesec < 10) mesec = '0' + mesec;

    const formattedToday = dan + '.' + mesec + '.' + godina + '.';

    Font.register({
        family: "Roboto",
        src: "/css/fonts/roboto/RobotoCondensed-Light.ttf",
    });
    const styles = StyleSheet.create({
        body: {
            paddingTop: 10,
            paddingBottom: 30,
            paddingHorizontal: 10,
            width: 100,
            fontFamily: "Roboto",
            fontSize: 11,
            fontWeight: 200,
        },
        header: {
            paddingHorizontal: "0.5cm",
            marginBottom: 30,
        },
        headerTable: {
            borderBottomStyle: "dotted",
        },

        table: {
            display: "table",
            width: "150",
            position: "fixed",
            left: 0,
            top: 0,
            right: 0,
            marginBottom: 10,
            width: "auto",
        },
        tableRow: { flexDirection: "row", borderBottom: "1px solid #111111" },
        tableCol: {
            width: "50%",
            borderBottom: 1,
            borderBottomStyle: "dotted",
            paddingVertical: 2,
            borderColor: "#ccc",
        },
        tableCol1: {
            width: "85px",
            borderBottom: 1,
            borderBottomStyle: "dotted",
            paddingVertical: 2,
            borderColor: "#ccc",
            padding: "10px",
        },
        tableCol2: {
            width: "70%",
            borderColor: "#ccc",
            paddingTop: "10px",
            paddingBottom: "10px",
        },
        tableCol3: {
            maxWidth: "100px",
            borderBottom: 1,
            borderBottomStyle: "dotted",
            paddingVertical: 2,
            borderColor: "#ccc",
            alignItems: "right",
            paddingTop: "10px",
            paddingBottom: "10px",
        },
        tableCellLeft: {
            textAlign: "left",
        },
        tableCellRight: {
            textAlign: "right",
        },
        tableCellHeader: { margin: "auto", marginTop: 5, fontSize: 10 },
        pageNumber: {
            position: "absolute",
            fontSize: 12,
            bottom: 10,
            left: 0,
            right: 0,
            textAlign: "center",
            color: "grey",
        },
        footerImg: {
            position: "absolute",
            bottom: 40,
            left: 0,
            right: 0,
            paddingTop: 10,
            marginBottom: 10,
            borderTop: 1,
            borderTopStyle: "dotted",
            borderColor: "#ccc",
        },
        dateCreated: {
            position: "absolute",
            bottom: 30,
            right: 0,
            fontSize: 11,
            textAlign: "right",
            paddingRight: "10px",
            color: "#555555",
            marginTop: "10px",
        },
    });

    return (
        <Document>
            <Page size="A4" style={styles.body} wrap>
                {userInfo.company.acCompanyLogo.trim() != "" &&
                    <Image src={`${globalVariable.imagesPath}${userInfo.company.acCompanyLogo}`} style={{ maxWidth: "100px", marginBottom: 15 }} />
                }
                {userInfo.company.acCompanyMemoHeader.trim() != "" &&
                    <Image src={`${globalVariable.imagesPath}${userInfo.company.acCompanyMemoHeader}`} style={{ marginBottom: 15 }} />
                }
                <View style={styles.tableRow} wrap={false}>
                    <View style={styles.tableCol1}>
                        <Text style={{ paddingVertical: 3 }}>{t('slika')}</Text>
                    </View>
                    <View style={styles.tableCol2}>
                        <Text style={{ paddingVertical: 3 }}>{t('sifraNaziv')}</Text>
                    </View>
                    <View style={styles.tableCol3}>
                        <Text style={{ paddingVertical: 3 }}>{t('cena')} {currency} {activeVat == 1 ? t('saPdv') : t('bezPdv')}</Text>
                    </View>
                </View>
                <View style={styles.table}>
                    {list.map((item, key) => (
                        <CustomCatalogPdfItem key={key} item={item} currency={currency} />
                    ))}
                </View>
                <View render={({ pageNumber, totalPages }) => {
                    totalPages > lastPage && setLastPage(totalPages)
                }} />
                {lastPage > 1 && userInfo.company.acCompanyMemoFooter.trim() != "" &&
                    <Image src={`${globalVariable.imagesPath}${userInfo.company.acCompanyMemoFooter}`} style={styles.footerImg} />
                }
                <Text style={styles.dateCreated} render={() => `${t('kreirano')} ${formattedToday}`} fixed />
                <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} fixed />
            </Page>
        </Document>
    );
};

export default CustomCatalogPdfTemplate;
