import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const CategorySidebarTypes = ({ setSidebarCategory, history }) => {

    const [categoryType, setCategoryType] = useState('category')
    const catalogDefaultFilters = useSelector((state) => state.catalogDefaultFilters);
    const { t } = useTranslation(['sidebarCategory', 'routes']);
    const setSidebarItem = (item) => {
        setSidebarCategory(item);
        setCategoryType(item);
    }

    const pathname = history.location.pathname;
    // console.log(pathname, 'pathname')

    useEffect(() => {
        {
            pathname.includes('brend')
                ? setSidebarItem('brand')
                : pathname.includes(t('routes:katalog'))
                    ? setSidebarItem('filter')
                    // : pathname.includes('outlet')
                    //     ? setSidebarItem('filter')
                    : pathname.includes(t('routes:pretraga'))
                        ? setSidebarItem('catbrand')
                        : setSidebarItem('category')
        }
    }, [catalogDefaultFilters, pathname, t])

    // console.log(categoryType, 'categoryType')

    return (
        <div className="category-sidebar-types">
            <div className="category-type-items">
                <div className={`category-type-item ${categoryType === 'category' ? 'active' : ''}`} onClick={() => setSidebarItem('category')}>{t('sidebarCategory:kategorije')}</div>
                {pathname.includes(t('routes:katalog')) ? (
                    <div className={`category-type-item ${categoryType === 'filter' ? 'active' : ''}`} onClick={() => setSidebarItem('filter')}>{t('sidebarCategory:filteri')}</div>
                    // ) : pathname.includes('outlet') ? (
                    //     <div className={`category-type-item ${categoryType === 'filter' ? 'active' : ''}`} onClick={() => setSidebarItem('filter')}>Filteri</div>
                ) : pathname.includes(t('routes:pretraga')) ? (
                    <div className={`category-type-item ${categoryType === 'catbrand' ? 'active' : ''}`} onClick={() => setSidebarItem('catbrand')}>{t('sidebarCategory:katBrend')}</div>
                ) : (
                    <div className={`category-type-item ${categoryType === 'brand' ? 'active' : ''}`} onClick={() => setSidebarItem('brand')}>{t('sidebarCategory:brendovi')}</div>
                )}
            </div>
        </div>
    )
};

export default CategorySidebarTypes;