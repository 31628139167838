import React from 'react';
import imageExists from 'image-exists';

class ProductDetailIcon extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            image: 'https://resource.ewe.rs/attribute_values/' + props.src,
            isImage: 0,
            srcImage: '',
            alt: '',
            title: ''
        };
    };

    componentDidMount() {
        imageExists(this.state.image, img => {
            if (img) {
                this.setState({
                    isImage: 1,
                    srcImage: this.state.image,
                    alt: this.state.alt,
                    title: this.state.title
                });
            };
        });
    };

    render() {
        return (
            <>
                {this.state.isImage === 1 && (
                    <img
                        className="img-responsive productIcon"
                        src={this.state.srcImage}
                        alt={this.state.alt}
                        title={this.state.title}
                    />
                )}
            </>
        );
    };
};

export default ProductDetailIcon;