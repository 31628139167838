import React from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import LoaderMask from '../other/LoaderMask';

const ModalCityCargo = ({ data, show, handleClose }) => {
    const userInfoData = useSelector((state) => state.userInfo.userInfo);
    const { t } = useTranslation('modal');

    return (
        <Modal
            show={show}
            onHide={handleClose}
            keyboard={false}
            dialogClassName='modal-xxl'
            aria-labelledby=""
            centered
        >
            <div className="close-action-modal" variant="secondary" onClick={handleClose}>
                <i className="far fa-times-circle"></i>
            </div>
            <Modal.Header>
                <Modal.Title>
                    {t('cityModalNaslov')} {data.shipmentId ? data.shipmentId : ''}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {Object.keys(data).length !== 0 ? 
                    <div className="row">
                        {/* <div className="col-sm-12">
                            <div className="alert alert-primary" role="alert">Napomena: dobijeni podaci su testni dok se ne proširi kolona sa ID-em Cargo-a. Korišćen ID: 45359598</div>
                        </div> */}
                        <div className="col-sm-8">
                            <div className="city-column-title">
                                {t('dostavniCentar')}
                            </div>
                            <div className="city-column-data">
                                {data.receiverDepo}
                            </div>
                            <div className="city-column-title">
                                {t('posiljalac')}
                            </div>
                            <div className="city-column-data">
                                {data.sender}
                            </div>
                            <div className="city-column-title">
                                {t('adresaDostave')}
                            </div>
                            <div className="city-column-data">
                                {data.address}
                            </div>
                            <div className="city-column-title">
                                {t('datumSlanja')}
                            </div>
                            <div className="city-column-data">
                                {data.sendDate ? new Date(data.sendDate).toLocaleDateString('sr-RS', {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric',
                                }) : ''}
                            </div>
                            <div className="city-column-title">
                                {t('detaljiPracenja')}
                            </div>
                            <div className="city-details-data">
                                {[...data.trackingDetails].reverse().map((item) => {
                                    // Konvertovanje ISO datuma u željeni format
                                    const date = new Date(item.TimeOfScan);
                                    const formattedDate = date.toLocaleDateString('sr-RS', {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric',
                                    }) + ' ' + date.toLocaleTimeString('sr-RS', {
                                        hour: '2-digit',
                                        minute: '2-digit'
                                    });

                                    return (
                                        <div key={item.TimeOfScan} style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '8px' }}>
                                            <span className='city-tracking-date'>{formattedDate}</span>
                                            <span>{item.StatusDescription}</span>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="city-column-title">
                                {t('povratniDokumenti')}
                            </div>
                            <div className="city-details-data">
                                {data.returnDocuments.map((item) => {
                                    // Konvertovanje ISO datuma u željeni format
                                    const date = new Date(item.TimeOfScan);
                                    const formattedDate = date.toLocaleDateString('sr-RS', {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric',
                                    }) + ' ' + date.toLocaleTimeString('sr-RS', {
                                        hour: '2-digit',
                                        minute: '2-digit'
                                    });

                                    return (
                                        <div key={item.TimeOfScan} style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '8px' }}>
                                            <span className='city-tracking-date'>{formattedDate}</span>
                                            <span>{item.StatusDescription}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="city-column-title">
                                {t('referentniBroj')}
                            </div>
                            <div className="city-column-data">
                                {data.refNo}
                            </div>
                            <div className="city-column-title">
                                {t('tezinaPaketa')}
                            </div>
                            <div className="city-column-data">
                                {`${data.packageWeight.toFixed(2)} kg`}
                            </div>
                            <div className="city-column-title">
                                {t('statusDostave')}
                            </div>
                            <div className="city-column-data">
                                {data.status}
                            </div>
                            <div className="city-column-title">
                                {t('datumIsporuke')}
                            </div>
                            <div className="city-column-data">
                                {data.deliveryDate ? new Date(data.deliveryDate).toLocaleDateString('sr-RS', {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric',
                                }) : '/'}
                            </div>
                        </div>
                    </div>
                    : 
                        <div className='city-modal-loader'><LoaderMask /></div>
                    }
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-secondary" onClick={handleClose}>{t('zatvorite')}</button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalCityCargo;