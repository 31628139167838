import React from 'react';

const ContractItem = () => {
    return (
        <tr>
            <td>
                <div className="table-row-editing-cart-item table-padding-item-border-right text-left">Ugovor o donaciji</div>
            </td>
            <td>
                <div className="table-row-editing-cart-item table-padding-item-border-right text-center">Ugovor o prodaji robe</div>
            </td>
            <td>
                <div className="table-row-editing-cart-item table-padding-item-border-right text-center">12/01/2021</div>
            </td>
            <td>
                <div className="table-row-editing-cart-item table-padding-item-border-right text-center">12/01/2021</div>
            </td>
            <td>
                <div className="table-row-editing-cart-item table-padding-item-border-right text-center">2020111101/02</div>
            </td>
            <td>
                <div className="table-row-editing-cart-item table-padding-item-border-right text-center">Opomena za puštanje menice</div>
            </td>
            <td>
                <div className="table-row-editing-cart-item text-center">
                    <div className="btn-download-document-contract">
                        <div className="btn-contract-item-button"><i className="fas fa-download"></i> Preuzmi</div>
                    </div>
                </div>
            </td>
        </tr>
    );
};

export default ContractItem;