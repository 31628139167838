import axios from 'axios';
import { axiosinstance } from '../../configAxios';
// import { useSelector } from 'react-redux';
import SecureLS from 'secure-ls';

let ls = new SecureLS({ encodingType: 'aes' });
export const getMenuItems = (userInfo) => async dispatch => {

    var userInfoData = ls.get('userInfo');
    // console.log(localStorage.getItem('userInfo'), 'isLoggedIn');
    // var userInfoData = JSON.parse(isLoggedIn);
    const attributes = {};
    attributes['isForLoggedUser'] = userInfoData ? 1 : 2;
    attributes['anRoleId'] = userInfoData ? 1 : 2;
    attributes['anCompanyKey'] = userInfoData ? userInfoData.company.anCompanyKey : 0;
    // attributes['session'] = '1516d174fc38a9313ff8c9864113a431ebd4013716c5da253c89fcdf37afd1d2';
    const configRes = axiosinstance(attributes, '8224', 'getSetMenu');

    axios(configRes)
        .then(function (response) {
            dispatch({
                type: 'MENU_ITEMS',
                payload: response.data.response
            })
            dispatch({
                type: 'GET_CURRENCY_ITEMS',
                payload: response.data.response
            });
        })
        .catch(function (error) {
            console.log(error);
        });
};

export const getCatalogMenuList = () => async dispatch => {

    const attributes = {};
    attributes['anCategorieId'] = 0;
    const configRes = axiosinstance(attributes, '8224', 'getCategories');

    axios(configRes)
        .then(function (response) {
            if (response.data.isOk == 1) {
                dispatch({
                    type: 'CATEGORY_MENU_ITEMS_LIST',
                    payload: response.data.response.categories
                });
                // console.log(response)
            };
        })
        .catch(function (error) {
            console.log(error);
        });
};

export const getBrandsMenuList = (brandName) => (dispatch, state) => {
    var userInfoData = ls.get('userInfo');

    const attributes = {};
    if (brandName) {
        attributes['acBrandName'] = brandName;
    } else {
        attributes['acBrandName'] = '';
    }
    attributes['anCompanyKey'] = userInfoData ? userInfoData.company.anCompanyKey : 0;

    const configRes = axiosinstance(attributes, '8224', 'getBrands');

    return axios(configRes)
        .then(response => {
            if (response.data.isOk == 1) {
                dispatch({
                    type: 'BRAND_ITEMS_LIST',
                    payload: response.data.response.brands
                });
            };
        })
        .catch(function (error) {
            console.log(error);
        });
};

export const functionChangeCollapsedMenu = (val, type, pageVal) => async dispatch => {
    // console.log('HELLO')
    // console.log(type, 'type-NEMANJA')
    if (type == 'homePage') {
        dispatch({
            type: 'SET_HOMEPAGE_MENU',
            payload: val,
            pageVal: pageVal,
        });

        const items = {};
        items['homePage'] = val;
        items['otherPage'] = pageVal;
        localStorage.setItem('menuSettings', JSON.stringify(items));
    } else {
        dispatch({
            type: 'SET_OTHERPAGE_MENU',
            payload: val,
            pageVal: pageVal,
        });

        const items = {};
        items['homePage'] = pageVal;
        items['otherPage'] = val;
        localStorage.setItem('menuSettings', JSON.stringify(items));
    }
};