import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { menuMobileShow } from '../../redux/actions/userActions';

import { removeSpecialChar } from '../../helperFunctions';
import { useTranslation } from 'react-i18next';

const CategorySidebarVirtualSubItem = ({ item, key, setMenuItem }) => {

    // const [subnav, setSubnav] = useState(false);
    const dispatch = useDispatch();
    const isDesktop = window.innerWidth > 1020;
    const { t } = useTranslation('routes');

    // const showSubnav = () => {
    //     setSubnav(!subnav);
    // }

    const setMobileFunction = () => {
        dispatch(menuMobileShow(false));
        if (!isDesktop) {
            setMenuItem(false)
        }
    }

    const setDeafultFilters = () => {
        dispatch({
            type: 'SET_FINISHED_FILTERS',
            payload: false
        });
        dispatch({
            type: 'SET_DEFAULT_URL_FILTERS',
            payload: {
                url: item.acUrl.split("?")[1],
                name: item.acName
            }
        });
        setMobileFunction()
    }

    return (
        // <Link className="category-main-no-sub" to={`${item.acUrl}`}>
        //     <li className="subcategory-menu-item" key={item.anCategory}>{item.acName}</li>
        // </Link>
        <>
            {item.acUrl.includes("/" + t('pretraga')) ? (
                <Link className="category-main-no-sub" to={item.acUrl} onClick={setMobileFunction}>
                    <li className="subcategory-menu-item" key={key}>{item.acName}</li>
                </Link>
            ) : (
                <Link className="category-main-no-sub" to={`${item.acUrl.split("?")[0]}/${removeSpecialChar(item.acName)}`} onClick={setDeafultFilters}>
                    <li className="subcategory-menu-item" key={key}>{item.acName}</li>
                </Link>
            )
            }
        </>
    )
};

export default CategorySidebarVirtualSubItem;