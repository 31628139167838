import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { filtersDataCheck } from '../../functions/filters';
import CatalogFilterTopItemModal from './CatalogFilterTopItemModal';

function useOutsideAlerter(ref) {
  useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
              alert("You clicked outside of me!");
          }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
          // Unbind the event listener on clean up
          document.removeEventListener("mousedown", handleClickOutside);
      };
  }, [ref]);
}

const CatalogFilterItemTop = ({ valueItem, isActive, toggleIndex, elementActive, clickFunctionItemFunction }) => {

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  const history = useHistory();
  const location = useLocation();
  let searchHistory = history.location.search;

  const setActiveElement = (ident) => {
    if(elementActive == ident) {
      toggleIndex(0);
    } else {
      toggleIndex(ident);
    }
  };

  const setViewModalFunction = (val) => {
    toggleIndex(0);
  }

  const clickFunctionItem = (e, item2, item, type, key) => {
    const checked = e.target.checked;
    filtersDataCheck(checked, item2, item, type, history, searchHistory);
  };

  var selectedFilters = [];
  var selectedItem = 0;
  var checkboxItems = location.search.split('?');
  if(checkboxItems.slice(0, -1).length > 0) {
      checkboxItems.forEach(item => {
          if(item != '') {
              var checboxItem = item.split('&');
              checboxItem.forEach(item2 => {
                  selectedFilters.push(item2.split('=')[0].split('_')[0]);
              });
          }
      });
  }
  selectedItem = selectedFilters.includes(valueItem.acAttributeURLName.toString());

  return (
    <div className="filter-top-item-content" >
      <div 
        className={`catalog-filter-top-item ${ selectedItem == 1 && 'active'}`} 
        onClick={(e) => setActiveElement(valueItem.anAttributeId)}
      >
        { valueItem.acAttributeName } <i className={`fas fa-chevron-${elementActive === valueItem.anAttributeId ? 'down' : 'up'}`}></i>
      </div>
      <div className="modal-top-filter-holder">
        { elementActive === valueItem.anAttributeId && (
          <CatalogFilterTopItemModal 
            items={valueItem} 
            functionItemClickCheckbox={clickFunctionItem} 
            functionCloseModal={setViewModalFunction}
            ref={wrapperRef}
          /> 
        )}
      </div>
    </div>
  );
};

export default CatalogFilterItemTop;